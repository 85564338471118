import { OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { ComponentRef, ElementRef, Injectable } from '@angular/core';

import { ComponentOverlay } from '../../models/component-overlay';
import { DynamicOverlayService } from '../dynamic-overlay/dynamic-overlay.service';

/***
 * @summary Service that allows the overlaying of a component with another component.
 */
@Injectable({
  providedIn: 'root'
})
export class ComponentOverlayService {
  constructor(private _dynamicOverlayService: DynamicOverlayService) {}

  public create<T extends ComponentOverlay>(reference: ComponentRef<any> | ElementRef, componentType: ComponentType<T>): ComponentRef<T> {
    const elementRef = this.referenceGuard(reference) ? reference : reference.injector.get(ElementRef);

    if (elementRef) {
      const overlayConfig: OverlayConfig = new OverlayConfig();

      overlayConfig.disposeOnNavigation = false;
      overlayConfig.hasBackdrop = false;
      overlayConfig.height = '100%';
      overlayConfig.minWidth = '100%';
      overlayConfig.scrollStrategy = this._dynamicOverlayService.scrollStrategies.noop();
      overlayConfig.positionStrategy = this._dynamicOverlayService.position().global().centerHorizontally().centerVertically();
      this._dynamicOverlayService.setContainerElement(elementRef.nativeElement);

      const overlayRef = this._dynamicOverlayService.create(overlayConfig);
      const componentOverlayRef = overlayRef.attach(new ComponentPortal(componentType));

      componentOverlayRef.instance.setOverlay(overlayRef);

      return componentOverlayRef;
    }
  }

  private referenceGuard<T>(elementRef: ElementRef | ComponentRef<any>): elementRef is ElementRef {
    return elementRef instanceof ElementRef;
  }
}
