import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { defaultAvatar } from '../../shared/models/default-avatar';
import { LoginAccount } from './../_models/login-account';
import { AccountListService } from './../_services/account-list.service';

@Component({
  selector: 'app-account-list',
  templateUrl: './account-list.component.html',
  styleUrls: ['../login-form-base.scss', './account-list.component.scss']
})
export class AccountListComponent implements OnInit {
  constructor(public accountService: AccountListService, private router: Router, private _sanitizer: DomSanitizer) {}

  public ngOnInit(): void {
    if (this.accountService.selectedAccount == null) {
      this.router.navigate(['login/new']);
    }
  }
  public get accounts(): LoginAccount[] {
    return this.accountService.accounts;
  }

  public setAccount(value: LoginAccount): void {
    this.accountService.selectedAccount = value;
    this.router.navigate(['login']);
  }

  public sanatizeIcon(icon: string): SafeUrl {
    return this._sanitizer.bypassSecurityTrustUrl(icon || defaultAvatar);
  }
}
