import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';

import { AccountListComponent } from './account-list/account-list.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { LoginRoutingModule } from './login-routing.module';
import { LoginWrapperComponent } from './login-wrapper/login-wrapper.component';
import { NewLoginFormSubmitComponent } from './new-login-form-submit/new-login-form-submit.component';
import { NewLoginFormComponent } from './new-login-form/new-login-form.component';
import { RemoveAccountComponent } from './remove-account/remove-account.component';

@NgModule({
  declarations: [
    AccountListComponent,
    LoginFormComponent,
    NewLoginFormComponent,
    LoginWrapperComponent,
    RemoveAccountComponent,
    NewLoginFormSubmitComponent
  ],
  imports: [
    CommonModule,
    LoginRoutingModule,
    ReactiveFormsModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatCheckboxModule
  ]
})
export class LoginModule {}
