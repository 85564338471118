import { DecimalPipe } from '@angular/common';
import { Injector, Pipe, PipeTransform, Type } from '@angular/core';
import { CurrencyConfigService } from '../services/configs/currency-config.service';

@Pipe({ name: 'cloudCurrency' })
export class CurrencyPipe implements PipeTransform {
  constructor(private _injector: Injector, private _currencyService: CurrencyConfigService) {}

  public transform(value: any, symbol?: string, position?: string, formatPipeToken?: Type<PipeTransform>, format?: string): any {
    const formatMatcher = /(\d+\.\d+-\d+)/gi;
    const currencyConfig = this._currencyService.getConfig();

    let newValue = value;
    let newPosition = currencyConfig.symbolPosition;
    let formatPipe: PipeTransform = null;
    let number = 0;

    try {
      if (value) {
        number = Number(value);
        if (Number.isNaN(number)) {
          number = Number(value.replace(',', '.'));
        }
      }

      symbol = symbol || currencyConfig.symbol;
      newPosition = position ? Number(position) : newPosition;
      format = format || this._currencyService.getFormat();

      if (!formatMatcher.test(format)) {
        format = '1.2-2';
      }

      formatPipeToken = formatPipeToken || DecimalPipe;
      formatPipe = this._injector.get(formatPipeToken);
      newValue = formatPipe.transform(number, format);

      if (newPosition === 1) {
        newValue = `${newValue}${symbol}`;
      } else {
        newValue = `${symbol}${newValue}`;
      }
    } catch (error) {
      console.log(error);
    }

    return newValue;
  }
}
