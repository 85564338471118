import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatTooltipDefaultOptions, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { CloudPaginatorIntl } from '../../shared/models/cloud-paginator-intl';
import { ElementCreationData } from '../../shared/models/element-creation-data';
import { ComponentOverlayService } from '../../shared/services/component-overlay/component-overlay.service';
import { SpinnerService } from '../../shared/services/spinner/spinner.service';
import { ICloudWidget } from '../models/cloud-widget';
import { WidgetLayout } from '../models/widget-layout';
import { WidgetPropertyGetter } from '../models/widget-property-getter';
import { WidgetValueConfiguration } from '../models/widget-value-configuration';
import { WidgetService } from '../services/widget.service';
import { WidgetComponent } from '../widget.component';

const CUSTOM_TOOLTIP_DEFAULTS: MatTooltipDefaultOptions = {
  showDelay: 1000,
  hideDelay: 250,
  touchendHideDelay: 1000
};

const DEFAULT_PAGINATOR_SIZE = 5;

const SMALL_VIEWPORT = [Breakpoints.Handset, Breakpoints.Small, Breakpoints.XSmall];

@Component({
  selector: `app-dynamic-report`,
  templateUrl: './dynamic-report.component.html',
  styleUrls: ['../widget.component.scss', './dynamic-report.component.scss'],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: CUSTOM_TOOLTIP_DEFAULTS },
    { provide: MatPaginatorIntl, useClass: CloudPaginatorIntl }
  ]
})
export class DynamicReportComponent extends WidgetComponent implements OnDestroy {
  public MatPaginatorType = MatPaginator;
  @Input() public parametersOverrides: Array<WidgetPropertyGetter>;
  @ViewChild(MatPaginator, { static: true }) public paginator: MatPaginator;
  public showPaginator: boolean;
  public dataSource$: Observable<MatTableDataSource<any>>;
  public valuesConfigs$: Observable<Array<WidgetValueConfiguration>>;
  public columns$: Observable<Array<string>>;
  public paginatorSize: number;

  constructor(
    private _breakpointObserver: BreakpointObserver,
    widgetService: WidgetService,
    elementRef: ElementRef,
    spinnerService: SpinnerService,
    componentOverlayService: ComponentOverlayService
  ) {
    super(widgetService, elementRef, spinnerService, componentOverlayService);
    this.paginatorSize = 5;
  }

  public ngOnDestroy(): void {
    // Used by the rxjs operator untilDestroy
  }

  public setupWidget(widget$: Observable<ICloudWidget>): Observable<ICloudWidget> {
    const sharedWidget = widget$.pipe(shareReplay(1));

    this.dataSource$ = sharedWidget.pipe(
      map(cloudWidget => {
        const dataSource = new MatTableDataSource(cloudWidget?.values);

        this.showPaginator = dataSource.data.length > 0;

        return dataSource;
      })
    );
    this.valuesConfigs$ = sharedWidget.pipe(map(cloudWidget => cloudWidget?.valuesConfiguration[0] || []));
    this.columns$ = this.valuesConfigs$.pipe(map(valuesConfigs => valuesConfigs.map(vc => vc.name)));

    return sharedWidget;
  }
  public setupWidgetSize(layout: WidgetLayout, cloudWidget: ICloudWidget): void {
    if (cloudWidget) {
      if (this._breakpointObserver.isMatched(SMALL_VIEWPORT)) {
        this.paginatorSize = layout.rowspan * DEFAULT_PAGINATOR_SIZE;
      } else {
        this.paginatorSize = layout.rowspan * DEFAULT_PAGINATOR_SIZE + DEFAULT_PAGINATOR_SIZE * (layout.rowspan - 1);
      }
    }
  }

  public setDefaultConfig(config: any): void {
    if (config) {
      if (Object.prototype.hasOwnProperty.call(config, 'factor')) {
        this.paginatorSize = config.factor * DEFAULT_PAGINATOR_SIZE + DEFAULT_PAGINATOR_SIZE * (config.factor - 1);
      }
    }
  }

  public onCreatePaginator(dataSource: MatTableDataSource<any>, data: ElementCreationData<MatPaginator>): void {
    dataSource.paginator = data.element;
  }
}
