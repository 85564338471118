import { format } from 'date-fns/esm/fp';

/**
 * This function is used to format dates to use in http service requests.
 * @param date
 * @returns The given date formatted in the following manner yyyy-MM-ddTHH:mm:ss (ex.: "2022-07-11T11:43:27").
 */
export function formatServiceRequestDatetime(date: Date): string {
  return format("yyyy-MM-dd'T'HH:mm:ss", date);
}

export default {
  formatServiceRequestDatetime
};
