<mat-card class="mat-card">
  <mat-card-title class="mat-card-title" i18n="RemoveAccountTitle|Remove Account Title@@removeAccount_title">Remover contas</mat-card-title>

  <mat-card-content>
    <mat-list class="list">
      <mat-list-item *ngFor="let loginAccount of accounts" class="list-item">
        <img class="accountAvatar" [src]="sanatizeIcon(loginAccount.icon)" mat-list-icon />
        <div class="delete-account-button" (click)="deleteAccount(loginAccount)">
          <mat-icon color="warn" type="flat" mat-list-icon>remove_circle_outline</mat-icon>
        </div>
        <div class="accountTitleSize" mat-line>{{loginAccount.account}}</div>
        <div mat-line>
          <div *ngIf="!!loginAccount.description" class="accountDescriptionSize" >{{loginAccount.description}}</div>
        </div>
      </mat-list-item>
    </mat-list>

    <mat-card-actions class="nextButton" align="end">
      <button type="submit" routerLink="/login/list" mat-raised-button color="primary"
        i18n="RemoveAccountSubmit|Remove Account Submit Button@@removeAccount_removeAccount">Concluído</button>
    </mat-card-actions>
  </mat-card-content>

</mat-card>
