<mat-card>
  <mat-card-title class="title" i18n="NewLoginFormCardTitle|New Login Form Card Title@@newLoginForm_title">Iniciar sessão</mat-card-title>

  <mat-card-content>
    <form class="form" [formGroup]="loginForm" id="loginForm" (ngSubmit)="onSubmit()">
      <mat-form-field class="form-line">
        <input
          type="text"
          matInput
          formControlName="account"
          i18n-placeholder="NewLoginAccountPlaceholder|New Login Account Placeholder@@newLoginForm_accountPlaceholder"
          placeholder="Conta"
        />
        <mat-error
          *ngIf="account.invalid && (account.dirty || account.touched)"
          i18n="NewLoginFormPasswordError|New Login Form Password Error@@newLoginForm_passwordError"
          >Por favor, insira uma conta válida</mat-error
        >
      </mat-form-field>
    </form>

    <mat-card-actions class="buttons" align="end">
      <button
        (click)="onSubmit()"
        type="submit"
        mat-raised-button
        color="primary"
        [disabled]="!loginForm.valid"
        i18n="NewLoginFormCardSubmitButton|New Login Form Card Submit Button Label@@newLoginForm_formSubmit"
        >Seguinte</button
      >
      <button
        class="goBack"
        type="flat"
        mat-raised-button
        color="primary"
        routerLink="/login/list"
        [disabled]="buttonDisable"
        i18n="NewLoginFormCardGoBackButton|New Login Form Card Go Back Button Label@@newLoginForm_formgoBack"
        >Voltar</button
      >
    </mat-card-actions>
  </mat-card-content>

  <div *ngIf="loginError">
    <mat-error *ngIf="loginError" i18n="NewLoginAccountError|New Login Form Invalid Account Error Label@@newLoginForm_accountError"
      >Conta inválida</mat-error
    >
  </div>
</mat-card>
