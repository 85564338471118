export enum ValueDataKind {
  none = 0,
  number = 1,
  string = 2,
  date = 3,
  time = 4,
  datetime = 5,
  currency = 6,
  percentage = 7,
  signalNumber = 8,
  signalCurrency = 9,
  boolean = 10,
  chartSeries = 11,
  collection = 12
}
