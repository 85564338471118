<div class="report-widget-container">
  <mat-tab-group class="widget-tabs-container" [selectedIndex]="selectedPage">
    <mat-tab *ngFor="let tab of tabsMap">
      <ng-container *ngFor="let value of tab">
        <app-report-widget-value [value]="value" [valuesConfiguration]="cloudWidget.valuesConfiguration"></app-report-widget-value>
      </ng-container>
    </mat-tab>
  </mat-tab-group>
  <mat-paginator
    class="report-paginator"
    (page)="onPageChange($event)"
    [pageIndex]="selectedPage"
    [length]="paginatorLength"
    [pageSize]="tabsMap[0].length"
  >
  </mat-paginator>
</div>
