import { catchError } from 'rxjs/operators';
import { EMPTY, ObservableInput, OperatorFunction, ObservedValueOf } from 'rxjs';

export function logError<T, O extends ObservableInput<any>>(rethrow?: boolean): OperatorFunction<T, T | ObservedValueOf<O>> {
  return catchError(err => {
    console.log(err);

    if (rethrow) {
      throw err;
    }

    return EMPTY;
  });
}
