/* eslint-disable @angular-eslint/no-host-metadata-property */
import { Directive, Input } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'img[defaultImage]',
  host: {
    '(error)': 'updateUrl()',
    '[src]': 'src'
  }
})
export class DefaultImageDirective {
  @Input() public src: string;
  @Input() public defaultImage: string;
  public updateUrl(): void {
    this.src = this.defaultImage;
  }
}
