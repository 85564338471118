<article class="col m-1 mat-card-content">
  <div class="widgetContainer">
    <ng-container *ngIf="widget$ | async as templateWidget">
      <section class="p-1 m-1 d-inline-flex w-100">
        <div class="w-100 center-widget-title" *ngIf="templateWidget.configuration?.showLabel">
          <strong class="text-truncate text-success" [style.font-size]="titleSize + 'em'">
            {{ templateWidget.name }}
          </strong>
        </div>
        <button mat-icon-button *ngIf="isOverlaying" (click)="goBack()">
          <mat-icon>exit_to_app</mat-icon>
        </button>
        <button mat-icon-button (click)="toggleLegend()" [hidden]="!isLegendButtonVisible || !templateWidget.values?.length">
          <mat-icon>view_list</mat-icon>
        </button>
        <button mat-icon-button *ngIf="templateWidget.configuration?.supportsConfig" (click)="configure()">
          <mat-icon fontSet="material-icons-outlined">settings_applications</mat-icon>
        </button>
        <button mat-icon-button *ngIf="templateWidget.configuration?.supportsRefresh" (click)="refresh()">
          <mat-icon>cached</mat-icon>
        </button>
      </section>
      <br />

      <section col-12 class="widget-overflow" (elementResize)="onElementResize($event)">
        <ng-container *ngIf="templateWidget.values?.length; else emptyReport">
          <ng-container *ngTemplateOutlet="valueTemplate"> </ng-container>
        </ng-container>
      </section>
    </ng-container>
    <ng-container *ngIf="hasError$ | async; then errorReport"></ng-container>
  </div>
</article>

<ng-template #errorReport>
  <div class="center-error">
    <div class="pl-3 pr-3">
      <span class="col text-center text-body" i18n="ReportWidgetComponentError|Report Widget Component Error Label@@chart_unavailableWidget"
        >Gráfico indisponível</span
      >
    </div>
    <p>
      <button
        mat-flat-button
        (click)="refresh()"
        i18n="ReportWidgetComponentErrorRetry|Report Widget Component Error retry Label@@chart_retryGetWidget"
      >
        Tentar novamente
      </button>
    </p>
  </div>
</ng-template>

<ng-template #emptyReport>
  <app-empty-container class="emptyContainer"></app-empty-container>
</ng-template>

<ng-template #valueTemplate>
  <ng-container [ngSwitch]="config.chartType">
    <ng-container *ngSwitchCase="DxChartType.BarChart">
      <ng-container *ngIf="config.isDynamicSeries; then dynamicChartTemplate; else multiSeriesTemplate"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="DxChartType.PieChart">
      <ng-container *ngIf="config.isDynamicSeries; then dynamicPieChartTemplate; else multiSeriesPieTemplate"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="DxChartType.LineChart">
      <ng-container *ngIf="config.isDynamicSeries; then dynamicChartTemplate; else multiSeriesTemplate"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="DxChartType.AreaChart">
      <ng-container *ngIf="config.isDynamicSeries; then dynamicChartTemplate; else multiSeriesTemplate"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="DxChartType.RadarChart">
      <ng-container *ngIf="config.isDynamicSeries; then dynamicRadarChartTemplate; else multiSeriesRadarTemplate"></ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #dynamicChartTemplate>
  <dx-chart class="chart" #chart [dataSource]="dataSource" (onIncidentOccurred)="incidentOcurred($event)" (onDrawn)="chartDrawn($event)">
    <dxo-animation easing="linear" [duration]="500" [maxPointCountSupported]="100" [enabled]="true"></dxo-animation>
    <dxo-common-series-settings
      [argumentField]="seriesList[0]?.argument || 'name'"
      [type]="seriesList[0]?.type || 'bar'"
      [valueField]="seriesList[0]?.value || 'value'"
    >
    </dxo-common-series-settings>
    <dxo-series-template nameField="serie"></dxo-series-template>

    <dxo-size [height]="chartHeight$ | async" [width]="chartWidth$ | async"></dxo-size>
    <dxo-argument-axis>
      <dxo-label overlappingBehavior="rotate" [rotationAngle]="45"></dxo-label>
    </dxo-argument-axis>
    <dxo-legend [visible]="isLegendVisible" verticalAlignment="bottom" horizontalAlignment="center" itemTextPosition="right"></dxo-legend>
    <dxo-tooltip enabled="true" [zIndex]="5000"></dxo-tooltip>
  </dx-chart>
</ng-template>

<ng-template #dynamicPieChartTemplate>
  <dx-pie-chart
    class="chart"
    #pieChart
    [type]="seriesList[0]?.type || 'donut'"
    [dataSource]="dataSource"
    (onIncidentOccurred)="incidentOcurred($event)"
    (onDrawn)="chartDrawn($event)"
  >
    <dxo-animation easing="linear" [duration]="500" [maxPointCountSupported]="100" [enabled]="true"></dxo-animation>
    <dxo-common-series-settings [argumentField]="seriesList[0]?.argument || 'name'" [valueField]="seriesList[0]?.value || 'value'">
      <dxo-label [visible]="false" format="fixedPoint">
        <dxo-connector [visible]="true"></dxo-connector>
      </dxo-label>
    </dxo-common-series-settings>
    <dxo-series-template nameField="serie"></dxo-series-template>

    <dxo-size [height]="chartHeight$ | async" [width]="chartWidth$ | async"></dxo-size>
    <dxo-legend [visible]="isLegendVisible" verticalAlignment="bottom" horizontalAlignment="center"></dxo-legend>
    <dxo-tooltip enabled="true" [zIndex]="5000"></dxo-tooltip>
  </dx-pie-chart>
</ng-template>

<ng-template #dynamicRadarChartTemplate>
  <dx-polar-chart
    class="chart"
    #chart
    [dataSource]="dataSource"
    (onIncidentOccurred)="incidentOcurred($event)"
    (onDrawn)="chartDrawn($event)"
  >
    <dxo-animation easing="linear" [duration]="500" [maxPointCountSupported]="100" [enabled]="true"></dxo-animation>
    <dxo-common-series-settings
      [argumentField]="seriesList[0]?.argument || 'name'"
      [valueField]="seriesList[0]?.value || 'value'"
      [type]="seriesList[0]?.type || 'scatter'"
    >
    </dxo-common-series-settings>
    <dxo-series-template nameField="serie"></dxo-series-template>

    <dxo-size [height]="chartHeight$ | async" [width]="chartWidth$ | async"></dxo-size>
    <dxo-margin [top]="50" [bottom]="50"></dxo-margin>

    <dxo-legend [visible]="isLegendVisible" verticalAlignment="bottom" horizontalAlignment="center"></dxo-legend>
    <dxo-tooltip enabled="true" [zIndex]="5000"></dxo-tooltip>
  </dx-polar-chart>
</ng-template>

<ng-template #multiSeriesTemplate>
  <dx-chart class="chart" #chart [dataSource]="dataSource" (onIncidentOccurred)="incidentOcurred($event)" (onDrawn)="chartDrawn($event)">
    <dxo-animation easing="linear" [duration]="500" [maxPointCountSupported]="100" [enabled]="true"></dxo-animation>
    <dxi-series
      *ngFor="let serieValue of seriesList"
      [argumentField]="serieValue.argument"
      [valueField]="serieValue.value"
      [name]="serieValue.name"
      [type]="serieValue.type"
    >
    </dxi-series>

    <dxo-size [height]="chartHeight$ | async" [width]="chartWidth$ | async"></dxo-size>
    <dxo-legend [visible]="isLegendVisible" verticalAlignment="bottom" horizontalAlignment="center" itemTextPosition="right"></dxo-legend>
    <dxo-tooltip enabled="true" [zIndex]="5000"></dxo-tooltip>
  </dx-chart>
</ng-template>

<ng-template #multiSeriesPieTemplate>
  <dx-pie-chart
    class="chart"
    #chart
    [dataSource]="dataSource"
    (onIncidentOccurred)="incidentOcurred($event)"
    (onDrawn)="chartDrawn($event)"
  >
    <dxo-animation easing="linear" [duration]="500" [maxPointCountSupported]="100" [enabled]="true"></dxo-animation>
    <dxi-series
      *ngFor="let serieValue of seriesList"
      [argumentField]="serieValue.argument"
      [valueField]="serieValue.value"
      [name]="serieValue.name"
      [type]="serieValue.type || 'donut'"
    >
    </dxi-series>

    <dxo-size [height]="chartHeight$ | async" [width]="chartWidth$ | async"></dxo-size>
    <dxo-legend [visible]="isLegendVisible" verticalAlignment="bottom" horizontalAlignment="center" itemTextPosition="right"></dxo-legend>
    <dxo-tooltip enabled="true" [zIndex]="5000"></dxo-tooltip>
  </dx-pie-chart>
</ng-template>

<!-- This type of charts dont exist so this code isnt tested -->
<ng-template #multiSeriesRadarTemplate>
  <dx-polar-chart
    class="chart"
    #chart
    [dataSource]="dataSource"
    (onIncidentOccurred)="incidentOcurred($event)"
    (onDrawn)="chartDrawn($event)"
  >
    <dxo-animation easing="linear" [duration]="500" [maxPointCountSupported]="100" [enabled]="true"></dxo-animation>
    <dxi-series
      *ngFor="let serieValue of seriesList"
      [argumentField]="serieValue.argument"
      [valueField]="serieValue.value"
      [name]="serieValue.name"
      [type]="serieValue.type"
    >
    </dxi-series>

    <dxo-size [height]="chartHeight$ | async" [width]="chartWidth$ | async"></dxo-size>
    <dxo-legend [visible]="isLegendVisible" verticalAlignment="bottom" horizontalAlignment="center" itemTextPosition="right"></dxo-legend>
    <dxo-tooltip enabled="true" [zIndex]="5000"></dxo-tooltip>
  </dx-polar-chart>
</ng-template>
