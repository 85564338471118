import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ConnectableObservable, merge, Observable, Subject } from 'rxjs';
import { debounceTime, map, multicast } from 'rxjs/operators';
import { StoreService } from '../../../entities/store/store.service';
import { Store } from '../../../shared/models/store';
import { untilDestroy } from '../../../shared/rxjs/operators/until-destroy';
import { ProxySubject } from '../../../shared/rxjs/proxy-subject';
import { SpinnerService } from '../../../shared/services/spinner/spinner.service';
import { ICloudWidget } from '../../../widgets/models/cloud-widget';
import { WidgetData } from '../../../widgets/models/widget-data';
import { WidgetKind } from '../../../widgets/models/widget-enums';
import { IWidget } from '../../../widgets/models/widget.interface';
import { WidgetCarouselContainerComponent } from '../../../widgets/widget-containers/widget-carousel-container/widget-carousel-container.component';

const SEARCHABLE_PROPERTIES = ['name', 'account'];

export interface IStoresTableData {
  storeId: number;
  name: string;
  account: string;
  sales: number;
  cashier: number;
}

@Component({
  selector: 'app-store-widget-container',
  templateUrl: './store-widget-container.component.html',
  styleUrls: ['./store-widget-container.component.scss']
})
export class StoreWidgetContainerComponent implements OnInit, OnDestroy {
  public searchInputEvent: EventEmitter<string> = new EventEmitter<string>();
  public stores$: Observable<Array<Store>>;
  public storeWidgetData: Map<string, ICloudWidget>;
  public hasError$: Observable<boolean>;
  public showDetails: boolean;
  public displayedColumns: string[];
  public dataSource: MatTableDataSource<IStoresTableData>;
  public searchControl: UntypedFormControl;
  public filteredStores$: Observable<Array<Store>>;

  public get searchableProperties(): string[] {
    return SEARCHABLE_PROPERTIES;
  }

  private readonly _stores: Map<string, Store>;
  private readonly _hasErrorSubject: Subject<boolean>;
  private readonly _storesSubject: Subject<Array<Store>>;
  @ViewChild(WidgetCarouselContainerComponent)
  private _WidgetContainer: WidgetCarouselContainerComponent;

  constructor(
    private readonly _storeService: StoreService,
    private readonly _spinnerService: SpinnerService,
    private readonly _elementRef: ElementRef
  ) {
    this._stores = new Map<string, Store>();
    this._storesSubject = new Subject<Array<Store>>();
    this.stores$ = this._storesSubject.asObservable();
    this.storeWidgetData = new Map<string, ICloudWidget>();
    this.displayedColumns = ['name', 'account', 'sales', 'cashier'];
    this.dataSource = new MatTableDataSource();
    this.searchControl = new UntypedFormControl();
  }

  public ngOnInit(): void {
    this.initializeStores();
    this.initializeSearch();
    this.loadStores();
  }
  public initializeSearch(): void {
    this.filteredStores$ = merge(
      this.stores$,
      this.searchControl.valueChanges.pipe(
        debounceTime(300),
        map(value => this.storeFilter(value))
      )
    );
  }

  public ngOnDestroy(): void {
    // Used by the rxjs operator untilDestroy
  }

  public initializeStores(): void {
    this.stores$.pipe(untilDestroy(this)).subscribe(ss => {
      const widgets = ss.map(s => <IWidget>{ id: String(s.storeId), kind: WidgetKind.store });

      this._stores.clear();
      ss.forEach(s => this._stores.set(String(s.storeId), s));
      this._WidgetContainer.setupWidgets(widgets);
    });
  }

  /**
   * Load all stores
   *
   * @public
   * @memberof StoreWidgetContainerComponent
   */
  public loadStores(): void {
    const proxyStores = new ProxySubject(this._storesSubject, this._hasErrorSubject);
    const stores = this._spinnerService
      .showFor(this._storeService.getStores(), this._elementRef)
      .pipe(multicast(proxyStores)) as ConnectableObservable<Store[]>;

    stores.connect();
  }

  public searchValue(value?: string): void {
    this.searchInputEvent.emit(value);
  }

  public searchStore(store?: Store): void {
    this.searchInputEvent.emit(store?.name);
  }

  public onRefreshWidget(widgetData: WidgetData): void {
    const store = this._stores.get(widgetData.widgetId);

    this.storeWidgetData.set(widgetData.widgetId, widgetData.cloudWidget);

    if (store) {
      widgetData.cloudWidget.values[0]['account'] = store.account;
      widgetData.cloudWidget.values[0]['image'] = store.image;
    }

    const tableData = [];

    for (const [_, widget] of this.storeWidgetData) {
      tableData.push(widget.values[0]);
    }

    this.dataSource.data = tableData;
  }

  public getWidgetDefaultValues(widgetId: string): any[] {
    const store = this._stores.get(widgetId);

    return [
      {
        name: store.name,
        account: store.account,
        image: store.image
      }
    ];
  }

  public getStoreDescription(store?: Store): string {
    return store?.name;
  }

  public refresh(): void {
    this._WidgetContainer.refresh();
  }

  private storeFilter(value: string): Array<Store> {
    const result: Array<Store> = [];

    if (value) {
      const pattern = new RegExp(`.*${value}.*`, 'i');

      for (const [_, store] of this._stores) {
        if (pattern.test(store.name) || pattern.test(store.account)) {
          result.push(store);
        }
      }
    } else {
      this._stores.forEach(store => result.push(store));
    }

    return result;
  }
}
