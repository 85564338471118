import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxChartModule } from 'devextreme-angular/ui/chart';
import { DxPieChartModule } from 'devextreme-angular/ui/pie-chart';
import { DxTooltipModule } from 'devextreme-angular/ui/tooltip';
import { WidgetsModule } from '../widgets/widgets.module';
import { SharedModule } from './../shared/shared.module';
import { BussinessSummaryRoutingModule } from './bussiness-summary-routing.module';
import { AccumulatedTotalsContainerComponent } from './components/accumulated-totals-container/accumulated-totals-container.component';
import { DaysChartComponent } from './components/days-chart/days-chart.component';
import { ItemGroupsChartComponent } from './components/item-groups-chart/item-groups-chart.component';
import { ItemgroupAccumulatedTotalComponent } from './components/itemgroup-accumulated-total/itemgroup-accumulated-total.component';
import { ItemsChartComponent } from './components/items-chart/items-chart.component';
import { MonthsChartComponent } from './components/months-chart/months-chart.component';
import { SalesAccumulatedTotalComponent } from './components/sales-accumulated-total/sales-accumulated-total.component';
import { SalesTableComponent } from './components/sales-table/sales-table.component';
import { SalesmanAccumulatedTotalComponent } from './components/salesman-accumulated-total/salesman-accumulated-total.component';
import { SalesmanChartComponent } from './components/salesman-chart/salesman-chart.component';
import { StoreSummaryComponent } from './components/store-summary/store-summary.component';
import { StoreWidgetContainerComponent } from './components/store-widget-container/store-widget-container.component';
import { StoresChartComponent } from './components/stores-chart/stores-chart.component';
import { StoresOverviewComponent } from './components/stores-overview/stores-overview.component';
import { TerminalsChartComponent } from './components/terminals-chart/terminals-chart.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    StoreSummaryComponent,
    DaysChartComponent,
    MonthsChartComponent,
    SalesmanChartComponent,
    TerminalsChartComponent,
    ItemsChartComponent,
    ItemGroupsChartComponent,
    StoresChartComponent,
    SalesTableComponent,
    StoresOverviewComponent,
    StoreWidgetContainerComponent,
    AccumulatedTotalsContainerComponent,
    ItemgroupAccumulatedTotalComponent,
    SalesAccumulatedTotalComponent,
    SalesmanAccumulatedTotalComponent
  ],
  imports: [
    CommonModule,
    DxChartModule,
    DxPieChartModule,
    DxTooltipModule,
    DxButtonModule,
    ReactiveFormsModule,
    MatGridListModule,
    MatIconModule,
    MatCardModule,
    MatTooltipModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatInputModule,
    MatTableModule,
    MatToolbarModule,
    MatExpansionModule,
    MatButtonModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    OverlayModule,
    SharedModule,
    WidgetsModule,
    MatMenuModule,
    BussinessSummaryRoutingModule,
    MatCheckboxModule,
    FormsModule
  ],
  exports: [StoreSummaryComponent, StoresOverviewComponent]
})
export class BusinessSummaryModule {}
