<mat-card>
  <mat-card-title class="title" i18n="NewLoginFormSubmitTitle|New Login Form Submit Title@@newLoginFormSubmit_title"
    >Bem Vindo</mat-card-title
  >

  <div class="wrapper">
    <button class="accountDescription" type="flat" (click)="redirect()">
      <img class="accountAvatar" [src]="sanatizeIcon()" mat-list-icon />
      <a class="descriptionLabel">{{ accountID }}</a>
      <mat-icon class="icons" color="primary">expand_more</mat-icon>
    </button>
  </div>

  <mat-card-content>
    <form class="form" [formGroup]="loginForm" (ngSubmit)="onSubmit()" id="loginForm">
      <mat-form-field class="form-line">
        <input
          type="password"
          matInput
          formControlName="password"
          i18n-placeholder="
            NewLoginFormSubmitPasswordPlaceholder|New Login Form Submit Password Placeholder@@newLoginFormSubmit_passwordPlaceholder"
          placeholder="Palavra-passe"
          [ngClass]="{ 'is-invalid': password.invalid && (password.dirty || password.touched) }"
          [type]="hide ? 'password' : 'text'"
        />
        <mat-icon class="icon" matSuffix (click)="hide = !hide">{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
        <mat-error
          *ngIf="password.invalid && (password.dirty || password.touched)"
          i18n="NewLoginFormSubmitPasswordValueError|New Login Form Submit Password Value Error@@newLoginFormSubmit_passwordError"
          >Por favor, insira uma senha válida</mat-error
        >
      </mat-form-field>
    </form>

    <mat-checkbox
      class="checkbox"
      [formControl]="remember"
      type="checkbox"
      i18n="NewLoginFormSubmitRememberMe|New Login Form Submit Remember Me@@newLoginFormSubmit_rememberLogin"
      >Lembrar</mat-checkbox
    >

    <mat-card-actions class="nextButton" align="end">
      <button
        (click)="onSubmit()"
        type="submit"
        mat-raised-button
        form="loginForm"
        color="primary"
        [disabled]="!loginForm.valid"
        i18n="NewLoginFormCardSubmitButton|New Login Form Card Submit Button Label@@newLoginForm_formSubmit"
        >Seguinte</button
      >
    </mat-card-actions>
  </mat-card-content>

  <div *ngIf="loginError || submitError">
    <mat-error
      *ngIf="loginError"
      i18n="NewLoginInvalidPasswordError|New Login Form Submit Invalid Password Error Label@@newLoginFormSubmit_invalidPasswordError"
      >Senha inválida</mat-error
    >
    <mat-error
      *ngIf="submitError"
      i18n="NewLoginFormLoginSubmitError|New Login Form Login Submit Error Label@@newLoginFormSubmit_submitError"
      >Houve um erro ao iniciar sessão! Por favor, tente novamente mais tarde.</mat-error
    >
  </div>
</mat-card>
