import { ElementRef, Directive, Input } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[id]'
})
export class TranslationDirective {
  @Input() public id: string;

  constructor(private element: ElementRef) {}

  public get value(): string {
    return this.element.nativeElement.innerText;
  }
}
