import { ValueDataKind } from './../../../shared/models/value-data-kind';
import { ExportService } from '../../../shared/services/export-service/export.service';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { subMonths, format } from 'date-fns/esm/fp';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CurrencyPipe } from '../../../shared/pipes/Currency.pipe';
import { untilDestroy } from '../../../shared/rxjs/operators/until-destroy';
import { SpinnerService } from '../../../shared/services/spinner/spinner.service';
import { TranslateService } from '../../../shared/services/translate-service/translate.service';
import { BusinessSummarySales, BusinessSummarySalesType } from '../../model/businessSummarySales';
import { BusinessSummaryService } from '../../services/business-summary.service';
import { ExportData } from '../../../shared/models/export-data';
import { DxChartComponent } from 'devextreme-angular';
import { ColumnConfig } from '../../../shared/models/column-config';
import { ServiceTaxService } from '../../../shared/services/local-storage-tax-service/local-storage-tax-service.service';
import { LoginInfoService } from '../../../shared/services/login-info/login-info.service';
import { XDCountryISO } from '../../../shared/models/xdcountry-ISO';

@Component({
  selector: 'app-months-chart',
  templateUrl: './months-chart.component.html',
  styleUrls: ['./months-chart.component.scss']
})
export class MonthsChartComponent implements OnInit, OnDestroy {
  public monthlySales$: Observable<BusinessSummarySales[]>;
  public monthlySalesData: BusinessSummarySales[];
  public monthlySalesDataSource = new MatTableDataSource<BusinessSummarySales>();
  public originalSalesData: BusinessSummarySales[];
  public monthsTableColumns: string[];
  public monthsInterval: Array<{ value: number; description: string }>;
  public selectedMonth: {
    value: number;
    description: string;
  };
  public isChecked: boolean = false;
  public displayCheckbox: boolean = false;

  @ViewChild('monthsChart', { static: false, read: DxChartComponent }) public monthsChartBars: DxChartComponent;
  @ViewChild('monthsChart', { static: true, read: ElementRef })
  private monthsChart: ElementRef;
  public monthsTableVisible = false;
  @Input() public requestedStores: string[];

  constructor(
    public businessSummaryService: BusinessSummaryService,
    private _spinnerService: SpinnerService,
    private _translateService: TranslateService,
    public currencyPipe: CurrencyPipe,
    private _exportservice: ExportService,
    private _serviceTaxService: ServiceTaxService,
    private _loginInfoService: LoginInfoService
  ) {
    this.customizeTooltip = this.customizeTooltip.bind(this);
    this.monthsInterval = new Array<{ value: number; description: string }>();
    this.monthsInterval = [
      { value: 6, description: this._translateService.translate('last_6_months') },
      { value: 12, description: this._translateService.translate('last_12_months') },
      { value: 24, description: this._translateService.translate('last_24_months') }
    ];
    this.selectedMonth = this.monthsInterval[1];
    this.monthsTableColumns = new Array<string>();
  }

  public ngOnInit(): void {
    this.displayCheckbox = this.isDisplayCheckbox();
    this.monthlyIntervalChanged();
  }

  public ngOnDestroy(): void {
    // Used by the rxjs operator untilDestroy
  }

  public monthlyIntervalChanged(): void {
    const now = new Date();

    this.monthlySales$ = this._spinnerService
      .showFor(
        this.businessSummaryService.getSales(
          this.requestedStores,
          subMonths(this.selectedMonth.value, now),
          now,
          BusinessSummarySalesType.byMonth
        ),
        this.monthsChart
      )
      .pipe(
        untilDestroy(this),
        tap(list => {
          list.forEach(l => (l.date = `${format('yyyy/MM')(new Date(l.date))}/01`));
          const data = this.processTaxServiceData(list);
          this.monthlySalesData = data;
          this.originalSalesData = data;
          this.monthlySalesDataSource = new MatTableDataSource(data);
          this.monthlySalesDataSource.data.forEach(e => {
            for (const key in e) {
              if (this.monthsTableColumns.indexOf(key) === -1) {
                this.monthsTableColumns.push(key);
              }
            }
          });
        })
      );
  }

  public customizeTooltip(info: any): any {
    return {
      text: this.currencyPipe.transform(info.valueText, null, null, null, null)
    };
  }

  public showMoreMonthlySales(): void {
    this.monthsTableVisible = !this.monthsTableVisible;
  }

  public updateStores(value: string[]): void {
    this.requestedStores = value;
    this.monthlyIntervalChanged();
  }

  public exportXlSX(): void {
    // Data estructure to exportXLSX
    const data = this.monthlySalesData;
    const header = this.getExportXLSXColumnConfigs();
    const fileName = this._translateService.translate('storeSummary_monthsChartTitle');
    const exportData = new ExportData(null, data, header, fileName);
    this._exportservice.ExportXLSX(exportData);
  }

  public exportChartPdf(): void {
    const fileName = this._translateService.translate('storeSummary_monthsChartTitle');
    this.monthsChartBars?.instance.exportTo(fileName, 'pdf');
  }

  public exportChartPng() {
    const fileName = this._translateService.translate('storeSummary_monthsChartTitle');
    this.monthsChartBars?.instance.exportTo(fileName, 'png');
  }

  public getExportXLSXColumnConfigs(): ColumnConfig[] {
    return this.monthsTableColumns.map(c => ({
      header: this._translateService.translate(c),
      propertyName: c,
      kind: ValueDataKind.currency,
      columnConfigs: null,
      usesParentHeader: false
    }));
  }

  public onCheckboxChange(): void {
    const data = this.updateSalesList(this.originalSalesData, this.isChecked);
    this.monthlySalesData = data;
    this.monthlySalesDataSource = new MatTableDataSource(data);
  }

  private processTaxServiceData(data: BusinessSummarySales[]): BusinessSummarySales[] {
    if (this.displayCheckbox) {
      if (this._serviceTaxService.get()) {
        this.isChecked = true;
        return this.updateSalesList(data, this.isChecked);
      }
    }

    return data;
  }

  private isDisplayCheckbox(): boolean {
    const infoLogin = this._loginInfoService.get();
    if (infoLogin.countryISO == XDCountryISO.BR) {
      return true;
    }
    return false;
  }

  private updateSalesList(salesList: BusinessSummarySales[], add: boolean): BusinessSummarySales[] {
    return salesList.map(sales => {
      if (sales.totalServiceTax) {
        sales.total = add ? sales.total + sales.totalServiceTax : sales.total - sales.totalServiceTax;
      }
      return sales;
    });
  }
}
