import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../auth/services/authentication.service';
import { logError } from '../../rxjs/operators/log-error';

@Component({
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  constructor(private router: Router, private authService: AuthenticationService) {}

  public ngOnInit(): void {
    this.authService.logout().pipe(logError(true)).subscribe();
  }
}
