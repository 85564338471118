import { OverlayContainer } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

/**
 * Local Storage key const for theme_id
 *
 * @const THEME_STORAGE_ID
 */
const THEME_STORAGE_ID = 'xdcloud_theme_id';

/**
 * @summary Sets and Gets themeId from local storage, also gives access to all available themes.
 *
 * @export
 * @class ThemingService
 */
@Injectable({
  providedIn: 'root'
})
export class ThemingService {
  private static readonly defaultTheme = 'dark-blue-theme';
  private _selected: string;

  private _available: Map<string, { name: string; color: string }> = new Map<string, { name: string; color: string }>([
    ['light-blue-theme', { name: 'Azul Claro', color: '#36b0db' }],
    ['dark-blue-theme', { name: 'Azul Escuro', color: '#0372a3' }],
    ['light-grey-theme', { name: 'Cinzento Claro', color: 'gainsboro' }],
    ['dark-grey-theme', { name: 'Cinzento Escuro', color: 'grey' }]
  ]);

  private _themeChangedSubject: Subject<string>;

  /***
   * @summary Get all available themes.
   */
  public get available(): Map<string, { name: string; color: string }> {
    return this._available;
  }

  /***
   * @summary Get the selected theme id.
   */
  public get selected(): string {
    return this._selected && this._available.has(this._selected) ? this._selected : (this._selected = ThemingService.defaultTheme);
  }

  public get themeChanged$(): Observable<string> {
    return this._themeChangedSubject.asObservable();
  }

  constructor(private themeOverlayContainer: OverlayContainer) {
    const containerElement = this.themeOverlayContainer.getContainerElement();

    this._themeChangedSubject = new Subject<string>();
    this._selected = localStorage.getItem(THEME_STORAGE_ID);
    containerElement.classList.add(this.selected);
  }

  private replaceOverlayTheme(previousThemeId: string, newThemeId: string): void {
    const containerElement = this.themeOverlayContainer.getContainerElement();

    containerElement.classList.remove(previousThemeId);
    containerElement.classList.add(newThemeId);
  }

  public setThemeId(themeId: string): void {
    const previousThemeId = this._selected;
    this._selected = themeId && this._available.has(themeId) ? themeId : ThemingService.defaultTheme;
    this.replaceOverlayTheme(previousThemeId, this._selected);
    localStorage.setItem(THEME_STORAGE_ID, this._selected);
    this._themeChangedSubject.next(this._selected);
  }
}
