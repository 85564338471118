export enum AccumulatedTotalsDateParametersEnum {
  lastWeek = 1,
  lastMonth = 2,
  lastYear = 3,
  last5Year = 4,
  custom = 5
}

export class AccumulatedTotalsDateParameters {
  constructor(init?: Partial<AccumulatedTotalsDateParameters>) {
    Object.assign(this, init);
  }

  public key: AccumulatedTotalsDateParametersEnum;
  public initialDate: Date;
  public finalDate: Date;
  public customInititalDate: Date;
  public customFinalDate: Date;
}
