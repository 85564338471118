/**
 * The file contents for the current environment will overwrite these during build.
 * The build system defaults to the dev environment which uses `environment.ts`, but if you do
 * `ng build --env=prod` then `environment.prod.ts` will be used instead.
 * The list of which env maps to which file can be found in `.angular-cli.json`.
 *
 * @export environment
 */

import { IEnvironment } from './environment.interface';

export const environment: IEnvironment = {
  production: true,
  schema: 'https',
  host: 'www.xdcloud.com',
  api: {
    schema: 'https',
    host: 'core.xdcloud.com/api'
  },
  disableNotifications: false,
  enableRouteTrancing: false
};
