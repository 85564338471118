import { Directionality } from '@angular/cdk/bidi';
import {
  Overlay,
  OverlayKeyboardDispatcher,
  OverlayOutsideClickDispatcher,
  OverlayPositionBuilder,
  ScrollStrategyOptions
} from '@angular/cdk/overlay';
import { DOCUMENT, Location } from '@angular/common';
import { ComponentFactoryResolver, Inject, Injectable, Injector, NgZone, Renderer2, RendererFactory2 } from '@angular/core';
import { DynamicOverlayContainerService } from './dynamic-overlay-container.service';

@Injectable({
  providedIn: 'root'
})
export class DynamicOverlayService extends Overlay {
  private readonly _dynamicOverlayContainerService: DynamicOverlayContainerService = null;
  private readonly _renderer: Renderer2 = null;

  constructor(
    scrollStrategyOptions: ScrollStrategyOptions,
    dynamicOverlayContainerService: DynamicOverlayContainerService,
    componentFactoryResolver: ComponentFactoryResolver,
    overlayPositionBuilder: OverlayPositionBuilder,
    overlayKeyboardDispatcher: OverlayKeyboardDispatcher,
    injector: Injector,
    ngZone: NgZone,
    @Inject(DOCUMENT) document: any,
    directionality: Directionality,
    rendererFactory2: RendererFactory2,
    location: Location,
    outsideClickDispatcher: OverlayOutsideClickDispatcher
  ) {
    super(
      scrollStrategyOptions,
      dynamicOverlayContainerService,
      componentFactoryResolver,
      overlayPositionBuilder,
      overlayKeyboardDispatcher,
      injector,
      ngZone,
      document,
      directionality,
      location,
      outsideClickDispatcher
    );

    this._dynamicOverlayContainerService = dynamicOverlayContainerService;
    this._renderer = rendererFactory2.createRenderer(null, null);
  }

  /**
   * Sets the container where the overlay will be created
   *
   * @param htmlElement - Element for the overlay
   */
  public setContainerElement(htmlElement: HTMLElement): void {
    try {
      this._renderer.setStyle(htmlElement, 'transform', 'translateZ(0)');
      this._dynamicOverlayContainerService.setContainerElement(htmlElement);
    } catch (err) {
      console.error(err);
    }
  }
}
