import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewContainerRef
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { ElementCreationData } from '../../../shared/models/element-creation-data';
import { untilDestroy } from '../../../shared/rxjs/operators/until-destroy';
import { SpinnerService } from '../../../shared/services/spinner/spinner.service';
import { WidgetData } from '../../models/widget-data';
import { WidgetPropertyGetter } from '../../models/widget-property-getter';
import { IWidget } from '../../models/widget.interface';
import { WidgetService } from '../../services/widget.service';
import { WidgetFactoryService } from '../../widget-factory';
import { WidgetComponent } from '../../widget.component';
import { WidgetContainerComponent } from '../widget-container.component';

const PAGE_WIDGET_COUNT_SMALL = 1;
const PAGE_WIDGET_COUNT_MEDIUM = 2;
const PAGE_WIDGET_COUNT_LARGE = 3;
const PAGE_WIDGET_COUNT_XLARGE = 4;

@Component({
  selector: 'app-widget-carousel-container',
  templateUrl: './widget-carousel-container.component.html',
  styleUrls: ['./widget-carousel-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetCarouselContainerComponent extends WidgetContainerComponent implements OnInit, OnDestroy {
  @Input() public parametersOverrides: WidgetPropertyGetter[];
  @Input() public getWidgetDefaultValues: (widgetId: string) => any[];
  @Input() public searchableProperties: string[];
  @Input() public searchValue: EventEmitter<string>;
  public selectedPage = 0;
  public selectedNavigationPage = 0;
  public navigationPageCount = 0;
  public selectedWidget: string;
  public pages$: Observable<IWidget[][]>;
  public navigationPages$: Observable<number[][]>;

  public get pageCount(): number {
    return this._pageCount;
  }
  public get widgetsPerPage(): number {
    return this._widgetsPerPage;
  }
  public get navigationPageSize(): number {
    return this._navigationPageSize;
  }
  @Output()
  public get refreshWidget(): EventEmitter<WidgetData> {
    return this._refreshWidget;
  }

  private readonly _searchableValues: Map<string, string[]>;
  private readonly _pagesSubject: Subject<IWidget[][]>;
  private readonly _refreshWidget: EventEmitter<WidgetData>;
  private readonly _widgetsViewContainers: Map<string, ViewContainerRef>;
  private _widgetsPerPage = 3;
  private _navigationPageSize = 5;
  private _widgets: IWidget[];
  private _pageCount: number;
  private _widgetsPages: Map<string, number>;
  private _isRearranging = false;

  constructor(
    private _breakpointObserver: BreakpointObserver,
    private _widgetFactory: WidgetFactoryService,
    widgetService: WidgetService,
    spinnerService: SpinnerService
  ) {
    super(widgetService, spinnerService, _widgetFactory);
    this._searchableValues = new Map<string, string[]>();
    this._pagesSubject = new Subject<IWidget[][]>();
    this._refreshWidget = new EventEmitter<WidgetData>();
    this._widgetsViewContainers = new Map<string, ViewContainerRef>();
    this.pages$ = this._pagesSubject.asObservable();
    this.navigationPages$ = this._pagesSubject.pipe(
      map(pages => {
        const navPages: number[][] = [];
        const pagesCopy = [...pages];

        while (pagesCopy.length) {
          navPages.push(pagesCopy.splice(0, this.navigationPageSize).map((_, pi) => pi));
        }

        return navPages;
      })
    );
    this._widgetsPages = new Map<string, number>();
    this.getWidgetDefaultValues = () => [];
  }

  public ngOnInit(): void {
    this.initializeWigetsPerPage([Breakpoints.Handset, Breakpoints.Small, Breakpoints.XSmall], PAGE_WIDGET_COUNT_SMALL);
    this.initializeWigetsPerPage([Breakpoints.Medium, Breakpoints.Tablet], PAGE_WIDGET_COUNT_MEDIUM);
    this.initializeWigetsPerPage([Breakpoints.Large], PAGE_WIDGET_COUNT_LARGE);
    this.initializeWigetsPerPage([Breakpoints.XLarge], PAGE_WIDGET_COUNT_XLARGE);
    this.searchValue.pipe(untilDestroy(this), debounceTime(500)).subscribe(value => this.searchWidget(value));
  }

  public ngOnDestroy(): void {
    // Used by the rxjs operator untilDestroy
  }

  public onCreateWidgetContainer(widget: IWidget, data: ElementCreationData<null>): void {
    const defaults = this.getWidgetDefaultValues(widget.id);
    let widgetRef: ComponentRef<WidgetComponent>;

    if (this._isRearranging) {
      if (this.widgetsComponents.has(widget.id)) {
        widgetRef = this.widgetsComponents.get(widget.id);
        data.containerRef.insert(widgetRef.hostView);
      } else {
        widgetRef = this._widgetFactory.createWidget(widget, this.parametersOverrides, data.containerRef, defaults);
      }
    } else {
      widgetRef = this._widgetFactory.createWidget(widget, this.parametersOverrides, data.containerRef, defaults);
    }

    this._widgetsViewContainers.set(widget.id, data.containerRef);
    this.widgetsComponents.set(widget.id, widgetRef);
    this.setupSearchableProperties(widgetRef);
  }

  public previous(): void {
    if (this.selectedPage === 0) {
      this.selectedPage = this.pageCount - 1;
      this.selectedNavigationPage = this.navigationPageCount - 1;
    } else {
      this.selectedPage--;
      this.selectedNavigationPage = Math.floor(this.selectedPage / this.navigationPageSize);
    }
  }

  public next(): void {
    if (this.pageCount - this.selectedPage === 1) {
      this.selectedPage = 0;
      this.selectedNavigationPage = 0;
    } else {
      this.selectedPage++;
      this.selectedNavigationPage = Math.floor(this.selectedPage / this.navigationPageSize);
    }
  }

  public skipPagesLeft(): void {
    if (this.selectedPage - this.navigationPageSize < 0) {
      this.selectedPage = 0;
    } else {
      this.selectedPage -= this.navigationPageSize;
    }

    this.selectedNavigationPage--;
  }

  public skipPagesRight(): void {
    if (this.selectedPage + this.navigationPageSize >= this.pageCount) {
      this.selectedPage = this.pageCount - 1;
    } else {
      this.selectedPage += this.navigationPageSize;
    }

    this.selectedNavigationPage++;
  }

  public skipToFirst(): void {
    this.selectedPage = 0;
    this.selectedNavigationPage = 0;
  }

  public skipToLast(): void {
    this.selectedPage = this.pageCount - 1;
    this.selectedNavigationPage = this.navigationPageCount - 1;
  }

  public setupWidgets(widgets: IWidget[]): void {
    this._isRearranging = false;
    this.configureWidgets(widgets);
  }

  public refresh(): void {
    this.widgetsComponents.forEach(widgetRef => {
      widgetRef.instance.refresh();
    });
  }

  public swipeLeft(event: any): void {
    const pointerEvent = <PointerEvent>event.srcEvent;

    pointerEvent.stopPropagation();
    this.next();
  }

  public swipeRight(event: any): void {
    const pointerEvent = <PointerEvent>event.srcEvent;

    pointerEvent.stopPropagation();
    this.previous();
  }

  private configureWidgets(widgets: IWidget[]) {
    const widgetsCopy = [...widgets];
    const pageCount = Math.ceil(widgets.length / this.widgetsPerPage);
    const pages = [...Array(pageCount).keys()].map(pageId => {
      const pageWidgets = widgetsCopy.splice(0, this.widgetsPerPage);
      pageWidgets.forEach(widget => this._widgetsPages.set(widget.id, pageId));

      return pageWidgets;
    });

    this.navigationPageCount = Math.ceil(pageCount / this.navigationPageSize);
    this._widgets = [...widgets];
    this._pageCount = pageCount;
    this._pagesSubject.next(pages);
  }

  private rearrangeWidgets(widgets: IWidget[]): void {
    this._isRearranging = true;
    this._widgetsViewContainers.forEach(widgetContainer => widgetContainer.detach());
    this.configureWidgets(widgets);
  }

  private initializeWigetsPerPage(breakpoints: string[], widgetsPerPage: number): void {
    this._breakpointObserver
      .observe(breakpoints)
      .pipe(untilDestroy(this))
      .subscribe(breakPointState => {
        if (breakPointState.matches) {
          this._widgetsPerPage = widgetsPerPage;

          if (this._widgets) {
            this.rearrangeWidgets(this._widgets);
          }
        }
      });
  }

  private setupSearchableProperties(widgetRef: ComponentRef<WidgetComponent>): void {
    widgetRef.instance.onRefresh$.subscribe(widgetData => {
      if (this.searchableProperties && this.searchableProperties.length) {
        let searchableValues: string[] = (this.searchableProperties || []).reduce((acc, prop) => {
          const propertyDescriptor = Object.getOwnPropertyDescriptor(widgetData.cloudWidget, prop);

          if (propertyDescriptor) {
            acc.push(propertyDescriptor.value);
          }

          const valuesConfigs = widgetData.cloudWidget.valuesConfiguration;
          const values = widgetData.cloudWidget.values;

          if (valuesConfigs && values) {
            valuesConfigs.forEach(configs =>
              configs.forEach(config => {
                if (config.name === prop) {
                  values.forEach(row => row[prop] && acc.push(row[prop]));
                }
              })
            );
          }

          return acc;
        }, []);

        // Remove repeated values
        searchableValues = [...new Set(searchableValues)];

        this._searchableValues.set(widgetData.widgetId, searchableValues);
        this.refreshWidget.emit(widgetData);
      }
    });
  }

  private searchWidget(searchValue: string): void {
    this.selectedWidget = null;
    searchValue = searchValue.trim();

    if (searchValue) {
      const pattern = new RegExp(`.*${searchValue}.*`, 'i');

      // Enable circular search
      let searchableValues = [...this._searchableValues].sort(
        ([key1, _], [key2, __]) => this._widgetsPages.get(key1) - this._widgetsPages.get(key2)
      );
      const deletedValues = searchableValues.splice(0, this.selectedPage + 1);

      searchableValues = searchableValues.concat(deletedValues);

      for (const [key, searchableValue] of searchableValues) {
        if (searchableValue.find(value => pattern.test(value))) {
          const page = this._widgetsPages.get(key);

          this.selectedPage = page;
          this.selectedNavigationPage = Math.floor(this.selectedPage / this.navigationPageSize);
          this.selectedWidget = key;
          break;
        }
      }
    }
  }
}
