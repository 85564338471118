<mat-toolbar>
  <ng-container i18n="NotificationsContainerTitle|Notifications Container Title@@notificationContainer_title">Notificações</ng-container>
  <span class="spacer"></span>
  <button type="button" mat-icon-button (click)="clearAll()" style="margin-right: 10px; ">
    <mat-icon matBadgeColor="warn">delete_sweep</mat-icon>
  </button>
</mat-toolbar>
<!-- TODO: Notification Groups -->
<mat-accordion *ngIf="(groups$ | async)?.size > 0" multi="true" class="notifications-container">
  <mat-expansion-panel
    class="expansion-panel-no-padding"
    *ngFor="let group of (groups$ | async) | keyvalue; let i = index"
    [expanded]="group.value[0].expanded"
    (expandedChange)="expandedChange($event,group.value[0])"
  >
    <mat-expansion-panel-header [ngStyle]="{'background-color': getColors(i) }">
      <mat-panel-title>
        {{group.value[0].label}}
      </mat-panel-title>
      <mat-panel-description>
        {{group.value[0].labelDescription}}
      </mat-panel-description>
    </mat-expansion-panel-header>
    <mat-list>
      <mat-list-item *ngFor="let note of group.value[1]" style="height: 100%;">
        <app-notification [notification]="note" style="width: 100%;"></app-notification>
        <mat-divider></mat-divider>
      </mat-list-item>
    </mat-list>
  </mat-expansion-panel>
</mat-accordion>
<mat-list>
  <mat-list-item *ngFor="let note of notifications$ | async" style="height: 100%;">
    <app-notification [notification]="note" style="width: 100%;margin: 5px;"></app-notification>
    <mat-divider></mat-divider>
  </mat-list-item>
</mat-list>
<div #colorSelector class="mat-button mat-primary" style="display:none"></div>
