<mat-card #itemGroupsChart class="topItemGroups">
  <mat-card-header class="cardHeader">
    <mat-card-title>
      <span
        *ngIf="!itemGroupsTableVisible"
        i18n="storeSummaryTopItemGroupsChartTitle|store Summary Top ItemGroups Chart Title@@storeSummary_topItemGroupsChartTitle"
        >Top Famílias</span
      >
      <span
        *ngIf="itemGroupsTableVisible"
        i18n="storeSummaryTopItemGroupsTableTitle|store Summary Top ItemGroups Table Title@@storeSummary_topItemGroupsTableTitle"
        >Vendas | Famílias</span
      >
    </mat-card-title>
    <mat-card-subtitle *ngIf="topItemGroupsData?.length" (click)="showMoreItemGroupSales()" class="showMoreButton">
      <span
        *ngIf="!itemGroupsTableVisible"
        i18n="storeSummaryChartsShowMoreSubTitle|store Summary Charts Show More SubTitle@@storeSummary_chartsShowMoreSubTitle"
        >Ver mais</span
      >
      <span
        *ngIf="itemGroupsTableVisible"
        i18n="storeSummaryChartsShowLessSubTitle|store Summary Charts Show Less SubTitle@@storeSummary_chartsShowLessSubTitle"
        >Ver menos</span
      >
    </mat-card-subtitle>
    <div class="parameters">
      <mat-form-field class="dateInterval">
        <mat-select [(value)]="selectedItemGroupDate" (selectionChange)="itemGroupIntervalChanged()">
          <mat-option *ngFor="let date of topsInterval" [value]="date">
            {{ date.description }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-icon (click)="itemGroupIntervalChanged()" class="refreshButton">cached</mat-icon>
      <button mat-icon-button class="export-button" [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item [matMenuTriggerFor]="chartExportTo"
          ><mat-icon>donut_small</mat-icon><span i18n="ExportChartButton|Export Chart Button@@export_chart">Exportar Gráfico</span></button
        >
        <button mat-menu-item [matMenuTriggerFor]="tableExportTo"
          ><mat-icon>document_scanner</mat-icon
          ><span i18n="ExportTableButton|Export Table Button@@export_table">Exportar Tabela</span></button
        >
      </mat-menu>
      <mat-menu #chartExportTo="matMenu">
        <button mat-menu-item (click)="exportChartPdf()">PDF</button>
        <button mat-menu-item (click)="exportChartPng()">PNG</button>
      </mat-menu>
      <mat-menu #tableExportTo="matMenu">
        <button mat-menu-item (click)="exportXlSX()">XLS</button>
      </mat-menu>
    </div>
  </mat-card-header>
  <ng-container *ngIf="topItemGroups$ | async">
    <ng-container *ngIf="topItemGroupsData?.length; else emptyChart">
      <ng-container *ngIf="!itemGroupsTableVisible">
        <mat-card-content class="topItemgroupsContent">
          <dx-chart class="chart" [dataSource]="topItemGroupsData" [rotated]="true">
            <dxi-series argumentField="groupName" type="bar" valueField="total"></dxi-series>
            <dxo-margin [top]="22" [left]="20" [bottom]="38" [right]="20"></dxo-margin>
            <dxo-legend [visible]="false"></dxo-legend>
            <dxo-tooltip enabled="true" [zIndex]="5000" [customizeTooltip]="customizeTopsTooltip"></dxo-tooltip>
          </dx-chart>
        </mat-card-content>
      </ng-container>
      <ng-container *ngIf="itemGroupsTableVisible">
        <mat-card-content class="topItemGroupsTableContent">
          <table class="topItemGroupsTable" mat-table [dataSource]="topItemGroupsDataSource">
            <ng-container [matColumnDef]="col" *ngFor="let col of topItemGroupsTableColumns">
              <ng-container *ngIf="col === 'storeName' || col === 'groupName' || col === 'storeId' || col === 'groupId' || col === 'qnt'">
                <th class="tableHeaders" mat-header-cell *matHeaderCellDef> {{ col | translate }} </th>
                <td class="tableCells" mat-cell *matCellDef="let element"> {{ element[col] }} </td>
              </ng-container>
              <ng-container *ngIf="col !== 'storeName' && col !== 'groupName' && col !== 'storeId' && col !== 'groupId' && col !== 'qnt'">
                <th class="tableHeaders" mat-header-cell *matHeaderCellDef> {{ col | translate }} </th>
                <td class="tableCells" mat-cell *matCellDef="let element"> {{ element[col] | cloudCurrency }} </td>
              </ng-container>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="topItemGroupsTableColumns; sticky: true"></tr>
            <tr class="tableRows" mat-row *matRowDef="let row; columns: topItemGroupsTableColumns"></tr>
          </table>
        </mat-card-content>
      </ng-container>
    </ng-container>
  </ng-container>
</mat-card>

<ng-template #emptyChart>
  <div class="center-error">
    <div class="pl-3 pr-3">
      <p class="col text-center text-color" i18n="storeSummaryEmptyChartTitle|store Summary Empty Chart Title@@storeSummary_emptyChartTitle"
        >Não foram encontradas vendas para os parametros selecionados.</p>
      <p
        class="col text-center text-color"
        i18n="storeSummaryEmptyChartSubTitle|store Summary Empty Chart SubTitle@@storeSummary_emptyChartSubTitle"
        >As lojas selecionadas poderão ter estado encerradas durante o intervalo de tempo selecionado.</p
      >
    </div>
  </div>
</ng-template>
