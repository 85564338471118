export class BusinessSummaryCards {
  public type: BusinessSummaryCardType;
  public status: BusinessSummaryCardStatus;
  public description: string;
  public value: number;
  public balancePercentage: string;
  public balance: number;
  public tooltipDescription: string;
}

export enum BusinessSummaryCardType {
  others = 0,
  today = 1
}

export enum BusinessSummaryCardStatus {
  none = 0,
  negative = 1,
  neutral = 2,
  positive = 3
}
