import { Directive, Output, EventEmitter, ElementRef, Renderer2, Injector, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'iframe[crossSiteMessage]'
})
export class CrossSiteMessageDirective implements OnInit {
  @Output() public crossSiteMessage: EventEmitter<{ command: string; parameters: any }>;

  constructor(private _injector: Injector, private _renderer: Renderer2) {
    this.crossSiteMessage = new EventEmitter();
  }

  public ngOnInit(): void {
    const elementRef = this._injector.get(ElementRef);

    if (elementRef) {
      const contentWindow = (<HTMLIFrameElement>elementRef?.nativeElement)?.contentWindow;

      if (contentWindow) {
        this._renderer.listen(contentWindow, 'message', this.messageReceived.bind(this));
      }
    }
  }

  public messageReceived(message: MessageEvent): void {
    const host = `${environment.schema}://${environment.host}`;

    if (message.origin && message.origin.indexOf(host) >= 0) {
      this.crossSiteMessage.emit(message.data);
    } else {
      console.log(`Unkown message origin '${message.origin}'`);
    }
  }
}
