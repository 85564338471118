import { Component } from '@angular/core';

@Component({
  selector: 'app-stores-overview',

  templateUrl: './stores-overview.component.html',

  styleUrls: ['./stores-overview.component.scss']
})
export class StoresOverviewComponent {}
