import { Component, ViewChild } from '@angular/core';
import { DxBulletComponent } from 'devextreme-angular/ui/bullet';
import { WidgetValueComponent } from '../widget-value.component';

@Component({
  selector: 'app-bullet-value',
  templateUrl: './bullet-value.component.html',
  styleUrls: ['../widget-value.component.scss', './bullet-value.component.scss']
})
export class BulletValueComponent extends WidgetValueComponent {
  @ViewChild(DxBulletComponent, { static: true })
  public bullet: DxBulletComponent;

  public onResize(): void {
    if (this.bullet) {
      this.bullet.instance.render();
    }
  }
}
