import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { concat, from, Observable, throwError } from 'rxjs';
import { catchError, flatMap } from 'rxjs/operators';

import { AuthenticationService } from '../../auth/services/authentication.service';
import { InterceptorSkipHeader } from './skip-headers';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authService: AuthenticationService, private router: Router) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const skip = req.headers.has(InterceptorSkipHeader.error);

    if (skip) {
      req = req.clone({ headers: req.headers.delete(InterceptorSkipHeader.error) });

      return next.handle(req);
    }

    return next.handle(req).pipe(
      catchError(event => {
        let error = 'Unknown error';

        if (event) {
          error = (event.error && event.error.message) || event.statusText || error;

          if (event.status === 401) {
            // auto logout if 401 response returned from api
            return concat(this.authService.logout(), from(this.router.navigate(['login']))).pipe(flatMap(() => throwError(error)));
          }
        }
        return throwError(error);
      })
    );
  }
}
