import { XDProgram } from './xdprogram';
import { XDCountryISO } from './xdcountry-ISO';

export class LoginInfo {
  constructor(init?: Partial<LoginInfo>) {
    Object.assign(this, init);
  }

  public expiryDate: Date;
  public userId: number;
  public description: string;
  public icon: string;
  public program: XDProgram;
  public countryISO: XDCountryISO;
}
