import { Type, ComponentRef, Injectable, ElementRef, ViewContainerRef, ComponentFactoryResolver, Injector } from '@angular/core';
import { ComponentPortal, PortalOutlet } from '@angular/cdk/portal';

import { WidgetPropertyGetter } from './models/widget-property-getter';
import { WidgetKind } from './models/widget-enums';
import { WidgetComponent } from './widget.component';
import { IWidget } from './models/widget.interface';
import { ComponentOverlayService } from '../shared/services/component-overlay/component-overlay.service';
import { ReportComponent } from './report/report.component';
import { ChartComponent } from './chart/chart.component';
import { DynamicReportComponent } from './dynamic-report/dynamic-report.component';
import { StoreComponent } from './store/store.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WidgetFactoryService {
  constructor(private readonly _componentOverlayService: ComponentOverlayService) {}

  public createWidget(
    widget: IWidget,
    overrides: Array<WidgetPropertyGetter>,
    outlet: ElementRef | PortalOutlet | ViewContainerRef,
    defaults?: any[],
    config?: any
  ): ComponentRef<WidgetComponent> {
    const type: Type<WidgetComponent> = this.getWidgetType(widget.kind);
    let widgetRef: ComponentRef<WidgetComponent>;

    if (this.elementRefGuard(outlet)) {
      widgetRef = this._componentOverlayService.create(outlet, type);
    } else if (this.viewContainerRefGuard(outlet)) {
      widgetRef = outlet.createComponent(type);
    } else if (this.portalOutletRefGuard(outlet)) {
      const widgetPortal = this.getWidgetPortal(widget.kind);

      widgetRef = outlet.attach(widgetPortal);
    }

    widgetRef.instance.widgetId = widget.id;
    widgetRef.instance.kind = widget.kind;
    widgetRef.instance.parametersOverrides = overrides || [];

    if (defaults) {
      widgetRef.instance.setDefaults(defaults);
    }

    if (config) {
      widgetRef.instance.setDefaultConfig(config);
    }

    return widgetRef;
  }

  private getWidgetType(widgetKind: WidgetKind): Type<WidgetComponent> {
    let type: Type<WidgetComponent> = null;

    switch (widgetKind) {
      case WidgetKind.report:
        type = ReportComponent;
        break;

      case WidgetKind.dynamicChart:
        type = ChartComponent;
        break;

      case WidgetKind.dynamicReport:
        type = DynamicReportComponent;
        break;

      case WidgetKind.store:
        type = StoreComponent;
    }

    return type;
  }

  private getWidgetPortal(widgetTypeId: WidgetKind): ComponentPortal<WidgetComponent> {
    const type = this.getWidgetType(widgetTypeId);
    const portal: ComponentPortal<WidgetComponent> = new ComponentPortal(type);

    return portal;
  }

  private elementRefGuard(outlet: ElementRef | PortalOutlet | ViewContainerRef): outlet is ElementRef {
    return 'nativeElement' in outlet;
  }

  private portalOutletRefGuard(outlet: ElementRef | PortalOutlet | ViewContainerRef): outlet is PortalOutlet {
    return 'attached' in outlet;
  }

  private viewContainerRefGuard(outlet: ElementRef | PortalOutlet | ViewContainerRef): outlet is ViewContainerRef {
    return 'element' in outlet;
  }
}
