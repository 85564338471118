<ng-container *ngIf="data$ | async as values">
  <ng-container *ngIf="values?.length; else emptyChart">
    <div class="container">
      <button
        mat-raised-button
        class="button-container"
        *ngIf="!isFirstLevel"
        (click)="onButtonClick()"
        i18n="ItemGroupAccumulatedTotalBack|Item Group Accumulated Total Back Button@@itemGroupAccumulatedTotal_backButton"
        >Retroceder</button
      >
      <dx-chart
        id="chart"
        class="chart"
        [dataSource]="values"
        (onPointClick)="onPointClick($event)"
        (onLegendClick)="onLegendClick($event)"
      >
        <dxo-common-series-settings [argumentField]="argumentField" type="stackedBar" valueField="total"> </dxo-common-series-settings>
        <dxo-series-template nameField="storeName"> </dxo-series-template>
        <dxi-value-axis>
          <dxo-label [customizeText]="customizeValueLabel('{value}')"></dxo-label>
        </dxi-value-axis>
        <dxo-argument-axis>
          <dxo-label wordWrap="none" overlappingBehavior="rotate"></dxo-label>
        </dxo-argument-axis>
        <dxo-tooltip
          [enabled]="true"
          location="edge"
          [zIndex]="5000"
          [customizeTooltip]="customizeTooltip('{seriesName} \<br /\> \<br /\> {value}')"
        >
        </dxo-tooltip>
      </dx-chart>
    </div>
  </ng-container>
</ng-container>

<ng-template #emptyChart>
  <div class="center-error">
    <div class="pl-3 pr-3">
      <span
        i18n="ItemGroupAccumulatedTotalError|Item Group Accumulated Total Error Label@@itemGroupAccumulatedTotal_unavailableWidget"
        >Informação indisponível</span
      >
    </div>
    <p>
      <button
        mat-flat-button
        (click)="refresh()"
        i18n="ItemGroupAccumulatedTotalErrorRetry|Item Group Accumulated Total Error retry Label@@itemGroupAccumulatedTotal_retryWidget"
        >Tentar novamente</button
      >
    </p>
  </div>
</ng-template>
