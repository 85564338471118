import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { XsrfCookieExtractorService } from '../services/xsrf-cookie-extractor.service';
import { InterceptorSkipHeader } from './skip-headers';

const HEADER_NAME = 'X-XSRF-TOKEN';

@Injectable({
  providedIn: 'root'
})
export class XsrfInterceptor implements HttpInterceptor {
  constructor(private tokenService: XsrfCookieExtractorService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const skip = req.headers.has(InterceptorSkipHeader.xsrf);

    if (skip || req.method === 'GET' || req.method === 'HEAD' || req.method === 'OPTIONS' || req.method === 'TRACE') {
      if (skip) {
        req = req.clone({ headers: req.headers.delete(InterceptorSkipHeader.xsrf) });
      }

      return next.handle(req);
    }

    const token = this.tokenService.getToken();

    if (token !== null && !req.headers.has(HEADER_NAME)) {
      req = req.clone({ headers: req.headers.set(HEADER_NAME, token) });
    }

    return next.handle(req);
  }
}
