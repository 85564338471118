import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MyNotification, MyNotificationAction } from '../../models/notification';

@Component({
  selector: 'app-notification-show-more-dialog',
  templateUrl: './notification-show-more-dialog.component.html',
  styleUrls: ['./notification-show-more-dialog.component.scss']
})
export class NotificationShowMoreDialogComponent {
  public notification: MyNotification;
  public action: MyNotificationAction;

  constructor(
    public dialogRef: MatDialogRef<NotificationShowMoreDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { notification: MyNotification; action: MyNotificationAction }
  ) {
    this.notification = data.notification;
    this.action = data.action;
  }

  public onClose(): void {
    this.dialogRef.close();
  }
}
