<mat-grid-list cols="4" rowHeight="40px" style="width: 100%;margin-bottom: 2px;">
  <mat-grid-tile [colspan]="2">
    <div style="justify-content: left; width: 100%;">
      <span class="subject">{{note.subject}} </span>
    </div>
  </mat-grid-tile>
  <mat-grid-tile [colspan]="1">
    <span class="mat-small"> {{note.origin}} </span>
  </mat-grid-tile>
  <mat-grid-tile [colspan]="1">
    <span class="mat-small"> {{note.date | date}}</span>
  </mat-grid-tile>
  <mat-grid-tile [colspan]="4">
    <div style="justify-content: left; width: 100%;">
      <span class="mat-small">{{note.summary}} </span>
    </div>
  </mat-grid-tile>
  <mat-grid-tile [colspan]="4">
    <span class="spacer"></span>
    <div>
      <ng-template ngFor let-value [ngForOf]='note.actions' [ngForTemplate]='actionTemplate'></ng-template>
    </div>
  </mat-grid-tile>

  <ng-template #actionTemplate let-value>
    <button mat-flat-button (click)="execAction(value)" color="{{value.type}}" style="margin-right: 2px;">
      <mat-icon *ngIf="value.icon != ''">{{value.icon}}</mat-icon> <span class="mat-small">{{value.label}}</span>
    </button>
  </ng-template>

</mat-grid-list>
