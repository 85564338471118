import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ElementRef, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, mergeMap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { SpinnerComponent } from '../../components/spinner/spinner.component';
import { DynamicOverlayService } from '../dynamic-overlay/dynamic-overlay.service';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  private readonly _spinners: Map<string, OverlayRef> = null;
  private readonly _dynamicOverlayService: DynamicOverlayService = null;
  private readonly _overlay: Overlay = null;

  constructor(dynamicOverlayService: DynamicOverlayService, overlay: Overlay) {
    this._spinners = new Map<string, OverlayRef>();
    this._dynamicOverlayService = dynamicOverlayService;
    this._overlay = overlay;
  }

  /**
   * Show spinner for element "elemRef"
   *
   * @remarks If no element reference is provided then the spinner will overlay the entire webpage
   *
   * @param action$ - Show spinner until "action" finishes
   * @param elemRef - Element reference where spinner overlay will be placed
   */
  public showFor<T>(action$: Observable<T>, elemRef?: ElementRef): Observable<T> {
    const id: string = uuid();
    const spinnerObservable$: Observable<void> = new Observable(subscriber => {
      this.create(id, elemRef);
      subscriber.next();
      subscriber.complete();
    });
    return spinnerObservable$.pipe(
      mergeMap(() => action$),
      finalize(() => this.destroy(id))
    );
  }

  /**
   * Creates a spinner over the element referenced by elemRef
   *
   * @param elemRef - Element reference where spinner overlay will be placed
   * @returns Spinner id
   *
   * @remarks Use the returned spinner id to remove it otherwise it will stay over the elemRef forever
   */
  public createSpinner(elemRef: ElementRef): string {
    const id: string = uuid();
    this.create(id, elemRef);

    return id;
  }

  /**
   * Removes the spinner.
   *
   * @param id - The id of the spinner to be remove.
   */
  public removeSpinner(id: string): void {
    this.destroy(id);
  }

  private create(id: string, elemRef?: ElementRef): void {
    let spinnerRef: OverlayRef = null;

    if (elemRef) {
      const positionStrategy = this._dynamicOverlayService.position().global().centerHorizontally().centerVertically();

      this._dynamicOverlayService.setContainerElement(elemRef.nativeElement);
      spinnerRef = this._dynamicOverlayService.create({
        positionStrategy: positionStrategy,
        hasBackdrop: true
      });
      this._spinners.set(id, spinnerRef);
      // Attach must be used, otherwise overlay wont show
      spinnerRef.attach(new ComponentPortal(SpinnerComponent));
    } else {
      spinnerRef = this._overlay.create({
        positionStrategy: this._overlay.position().global().centerHorizontally().centerVertically(),
        hasBackdrop: true
      });
      spinnerRef.attach(new ComponentPortal(SpinnerComponent));
      this._spinners.set(id, spinnerRef);
    }
  }

  private destroy(id: string): void {
    if (this._spinners.has(id)) {
      const spinner = this._spinners.get(id);
      spinner.detach();
      spinner.dispose();
      this._spinners.delete(id);
    }
  }
}
