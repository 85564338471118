<div class='row'>
    <dx-bullet (resize)='onResize()'
      [elementAttr]='{ class: "col-10" }'
      [target]='1'
      [endScaleValue]='1'
      [value]='value[valueConfig.name]'
      [color]='"#e8c267"'
      [showTarget]='false'
      [tooltip]='{ enabled: false }'></dx-bullet>
    <span class='text-right pl-1 pt-1 pb-1 pr-3 p col-2 small' width='1rem'>{{value[valueConfig.name] | datatype:valueConfig.dataType:valueConfig.dataFormat}}</span>
</div>
