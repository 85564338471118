/**
 * Configuration data to currency
 *
 * @export
 * @class CurrencyConfig
 */
export class CurrencyConfig {
  constructor(init?: Partial<CurrencyConfig>) {
    Object.assign(this, init);
  }

  public id: number;
  public description: string;
  public symbol: string;
  /**
   * 1 - right
   * other - left
   *
   * @type {number}
   * @memberof CurrencyConfig
   */
  // public position: number;
  public symbolPosition: number;
  public decimalPlaces: number;
  /**
   * Item Prices decimal places
   *
   * @type {number}
   * @memberof CurrencyConfig
   */
  public decimalItemPlaces: number;
  public euroConvertionRate: number;
  public keyId: string;
  public date: Date;
  public syncStamp: Date;
  public maskCurrency: string;
}
