import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalizationService } from '../services/localization/localization.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageSettingGuard implements CanActivate {
  constructor(private _localizationService: LocalizationService) {}

  public canActivate(): boolean | Observable<boolean> {
    let canActivate: boolean | Observable<boolean> = false;

    canActivate = this._localizationService.checkLanguage();

    return canActivate;
  }
}
