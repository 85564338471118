import { Component } from '@angular/core';

import { WidgetValueComponent } from '../widget-value.component';

@Component({
  selector: 'app-slide-value',
  templateUrl: './slide-value.component.html',
  styleUrls: ['../widget-value.component.scss', './slide-value.component.scss']
})
export class SlideValueComponent extends WidgetValueComponent {}
