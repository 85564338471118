<div class="pl-3 pr-3">
  <ng-container *ngFor="let valuesConfig of valuesConfiguration">
    <div class="row">
      <ng-container *ngFor="let valueConfig of valuesConfig">
        <ng-container [ngSwitch]="valueConfig.displayType">
          <ng-container *ngSwitchCase="ValueDisplayKind.text">
            <app-text-value
              [value]="value"
              [valueConfig]="valueConfig"
              (followLink)="followLink(valueConfig.link, value)"
              class="col p-0 m-0 d-flex"
            ></app-text-value>
          </ng-container>
          <ng-container *ngSwitchCase="ValueDisplayKind.bullet">
            <app-bullet-value
              [value]="value"
              [valueConfig]="valueConfig"
              (followLink)="followLink(valueConfig.link, value)"
              class="col"
            ></app-bullet-value>
          </ng-container>
          <ng-container *ngSwitchCase="ValueDisplayKind.avatar">
            <app-avatar-value
              [value]="value[valueConfig.name]"
              [valueConfig]="valueConfig"
              (followLink)="followLink(valueConfig.link, value)"
            ></app-avatar-value>
          </ng-container>
          <ng-container *ngSwitchCase="ValueDisplayKind.progress">
            <app-progress-value
              [value]="value[valueConfig.name]"
              [valueConfig]="valueConfig"
              (followLink)="followLink(valueConfig.link, value)"
            ></app-progress-value>
          </ng-container>
          <ng-container *ngSwitchCase="ValueDisplayKind.slide">
            <app-slide-value
              [value]="value[valueConfig.name]"
              [valueConfig]="valueConfig"
              (followLink)="followLink(valueConfig.link, value)"
              class="col m-0 p-0 d-flex"
            ></app-slide-value>
          </ng-container>
          <ng-container *ngSwitchCase="ValueDisplayKind.emphasize">
            <app-emphasize-value
              [value]="value"
              [valueConfig]="valueConfig"
              (followLink)="followLink(valueConfig.link, value)"
              class="col m-0 p-0 d-flex"
            ></app-emphasize-value>
          </ng-container>
          <ng-container *ngSwitchCase="ValueDisplayKind.underemphasize">
            <app-emphasize-value
              [value]="value"
              [valueConfig]="valueConfig"
              (followLink)="followLink(valueConfig.link, value)"
              class="col m-0 p-0 d-flex"
            ></app-emphasize-value>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <div
              class="small"
              i18n="ReportWidgetComponentUnknownValueType|Report Widget Component Unknown ValueType Label@@report_unknownValueType"
              >Unknown value type</div
            >
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</div>
