import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, CanLoad, Route, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate, CanLoad {
  constructor(private _authService: AuthenticationService, private _router: Router) {}

  public canLoad(_route: Route, _segments: UrlSegment[]): boolean | Observable<boolean> | Promise<boolean> {
    let canLoad: boolean | Observable<boolean> = false;

    canLoad = this._authService.isLogged();

    return canLoad;
  }

  public canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean | Observable<boolean> {
    let canActivate: boolean | Observable<boolean> = false;

    canActivate = this._authService.isLogged();

    return canActivate;
  }
}
