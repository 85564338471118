<mat-card>
  <mat-card-title class="accountName">{{currentAccount}}</mat-card-title>

  <mat-card-content>
    <div class="wrapper">
      <button class="accountDescription" type="flat" routerLink="/login/list">
        <img class="accountAvatar" [src]=sanatizeIcon(accountIcon) />
        <a class="descriptionLabel" *ngIf="!!currentAccountDescription" >{{currentAccountDescription}}</a>
        <mat-icon class="icons" color="primary">expand_more</mat-icon>
      </button>
    </div>
    <form class="form" [formGroup]="loginForm" (ngSubmit)="onSubmit()" id="loginForm">
      <mat-form-field class="form-line">
        <input type="password" matInput i18n-placeholder="LoginFormPasswordPlaceholder|Login Form Password Placeholder@@loginForm_passwordPlaceholder" placeholder="Introduza a palavra-passe" formControlName="password"
          [ngClass]="{'is-invalid': password.invalid && (password.dirty || password.touched)}" [type]="hide ? 'password' : 'text'">
        <!-- <mat-hint>Hint: example Hint</mat-hint> -->
        <mat-icon class="icon" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        <mat-error *ngIf="password.invalid && (password.dirty || password.touched)" i18n="LoginFormLoginCardPasswordValueError|Login Form Login Card Password Value Error@@loginForm_passwordError">Por favor, insira uma senha válida</mat-error>
      </mat-form-field>
    </form>

    <mat-card-actions class="nextButton" align="end">
      <button type="submit" mat-raised-button form="loginForm" color="primary" [disabled]="!loginForm.valid"
        i18n="LoginFormLoginCardSubmit|Login Form Login Card Submit@@loginForm_loginSubmit">Seguinte</button>
    </mat-card-actions>
  </mat-card-content>

  <div *ngIf="loginError || submitError">
    <mat-error *ngIf="loginError" i18n="LoginFormLoginError|Login Form Login Error Label@@loginForm_loginError">Conta e/ou senha inválida(s)</mat-error>
    <mat-error *ngIf="submitError" i18n="LoginFormLoginSubmitError|Login Form Login Submit Error Label@@loginForm_submitError">Houve um erro ao iniciar sessão! Por favor, tente novamente mais tarde.</mat-error>
  </div>
</mat-card>
