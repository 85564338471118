<div class="center-card">
  <mat-card>
    <mat-card-title i18n="PageNotFound|PageNotFound@@pageNotFound_notFound">Página não encontrada</mat-card-title>

    <mat-card-content>
      <strong i18n="PageNotFoundWarning|Warning@@pageNotFound_warning">Atenção</strong>
      <p i18n="PageNotFoundDoenstExist|Page Not Found Doesnt Exist@@pageNotFound_doesntExist"
        >A página que está a tentar aceder não existe.</p
      >
    </mat-card-content>

    <mat-card-actions class="center-actions">
      <button mat-flat-button (click)="back()" i18n="PageNotFoundGoBackButton|Page Not Found Go Back Button@@pageNotFound_goBackButton"
        >Voltar</button
      >
    </mat-card-actions>
  </mat-card>
</div>
