import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { AuthenticationService } from './auth/services/authentication.service';
import { StoreService } from './entities/store/store.service';
import { LocalizationService } from './shared/services/localization/localization.service';
import { SpinnerService } from './shared/services/spinner/spinner.service';
import { LAST_SELECTED_SESSION_DATE } from './shared/shared.module';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private _authService: AuthenticationService,
    private _spinnerService: SpinnerService,
    private _router: Router,
    private _storeService: StoreService,
    localizationService: LocalizationService
  ) {
    localizationService.ensureSelectedLanguage();
  }

  public ngOnInit(): void {
    localStorage.removeItem(LAST_SELECTED_SESSION_DATE);

    // Force check if already logged on startup
    const loggedCheck = this._authService.isLogged().pipe(
      mergeMap(status => {
        let result$: Observable<boolean> = null;

        if (status && this._router.routerState.snapshot.url.includes('login')) {
          result$ = from(this._router.navigate(['/']));
        } else {
          result$ = of(status);
        }

        return result$;
      })
    );

    this._spinnerService
      .showFor(loggedCheck)
      .pipe(tap(isLogged => isLogged && this._storeService.getStores()))
      .subscribe();
  }
}
