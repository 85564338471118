// ResizeObserver type is not found by the typescript compiler
// https://github.com/Microsoft/TypeScript/issues/28502

// Once the problem is fixed, delete the reference and run this:
// npm uninstall -D @types/resize-observer-browser

/// <reference types="resize-observer-browser" />

import { Directive, ElementRef, EventEmitter, NgZone, OnDestroy, OnInit, Output } from '@angular/core';

// Based on: https://github.com/vdolek/angular-resize-event/blob/master/projects/angular-resize-event/src/lib/resized.directive.ts
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[elementResize]'
})
export class ResizeDirective implements OnInit, OnDestroy {
  private _observer: ResizeObserver;
  @Output()
  public elementResize: EventEmitter<DOMRectReadOnly>;
  constructor(private _element: ElementRef, private readonly _zone: NgZone) {
    this.elementResize = new EventEmitter<DOMRectReadOnly>();
    this._observer = new ResizeObserver(entries => this._zone.run(() => this.observe(entries)));
  }

  public ngOnInit(): void {
    this._observer.observe(this._element?.nativeElement);
  }

  public ngOnDestroy(): void {
    this._observer?.disconnect();
  }

  private observe(entries: ResizeObserverEntry[]): void {
    if (entries?.length) {
      this.elementResize.emit(entries[0].contentRect);
    }
  }
}
