import { Injectable } from '@angular/core';
import { LocalstorageService } from '../local-storage/localstorage.service';

const CHECK_BOX_STATE = 'checkboxState';
@Injectable({
  providedIn: 'root'
})
export class ServiceTaxService {
  constructor(private _localstorageService: LocalstorageService) {}

  public set(value: any): void {
    this._localstorageService.set(CHECK_BOX_STATE, value);
  }

  public get(): any | null {
    return this._localstorageService.get(CHECK_BOX_STATE);
  }

  public delete(): void {
    this._localstorageService.delete(CHECK_BOX_STATE);
  }
}
