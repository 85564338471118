import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss']
})
export class IFrameComponent {
  private _sanitizedSource: SafeResourceUrl;

  @Input() public set source(value: string) {
    this._sanitizedSource = this._sanitizer.bypassSecurityTrustResourceUrl(value);
  }

  @Output() public iframeLoad = new EventEmitter<Event>();
  @Output() public iframeMessage = new EventEmitter<{ command: string; parameters: any }>();

  public get sanitizedSource(): SafeResourceUrl {
    return this._sanitizedSource;
  }

  constructor(private _sanitizer: DomSanitizer) {}

  public onLoad(event: Event): void {
    this.iframeLoad.emit(event);
  }

  public onReceiveMessage(message: { command: string; parameters: any }): void {
    this.iframeMessage.emit(message);
  }
}
