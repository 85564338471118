import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

export const SELECTED_LANGUAGE_KEY = 'XDCLOUD_SELECTED_LANGUAGE';

export enum Language {
  'Portuguese' = 'pt',
  'Spanish' = 'es'
}

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {
  private _selectedLanguage: Language;
  private _baseLanguage: Language;
  private _language: BehaviorSubject<Language>;

  public readonly language$: Observable<Language>;

  constructor(@Inject(LOCALE_ID) private _locale: string, private _router: Router) {
    this._baseLanguage = this.locale2Language(this._locale);
    this._selectedLanguage = <Language>localStorage.getItem(SELECTED_LANGUAGE_KEY) || this._baseLanguage;
    this._language = new BehaviorSubject<Language>(this._baseLanguage);
    this.language$ = this._language.asObservable();
  }

  public setLanguage(language: Language): void {
    if (this._selectedLanguage !== language) {
      localStorage.setItem(SELECTED_LANGUAGE_KEY, language);
      this.changeLanguage(this._selectedLanguage);
    }
  }

  public ensureSelectedLanguage(): void {
    if (this._selectedLanguage !== this._baseLanguage) {
      this.changeLanguage(this._selectedLanguage);
    }
  }

  public checkLanguage(): boolean {
    return this._selectedLanguage === this._baseLanguage;
  }

  private locale2Language(locale: string): Language {
    let lang = Language.Portuguese;

    if (locale.startsWith(Language.Spanish)) {
      lang = Language.Spanish;
    }

    return lang;
  }

  private changeLanguage(lang: Language): void {
    window.location.href = `${environment.schema}://${environment.host}/${lang}${this._router.url}`;
  }
}
