import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { InterceptorSkipHeader } from './skip-headers';

const ANTIFORGERY_ENDPOINT = `${environment.api.schema}://${environment.api.host}/antiforgery/`;

@Injectable({
  providedIn: 'root'
})
export class AntiforgeryInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const skip = req.headers.has(InterceptorSkipHeader.antiforgery);

    if (skip || req.method === 'GET' || req.method === 'HEAD' || req.method === 'OPTIONS' || req.method === 'TRACE') {
      if (skip) {
        req.clone({ headers: req.headers.delete(InterceptorSkipHeader.antiforgery) });
      }

      return next.handle(req);
    }

    const httpClient: HttpClient = this.injector.get(HttpClient);

    return httpClient.get<void>(ANTIFORGERY_ENDPOINT, { withCredentials: true }).pipe(switchMap(() => next.handle(req)));
  }
}
