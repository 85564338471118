import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DxPieChartComponent } from 'devextreme-angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { untilDestroy } from '../../shared/rxjs/operators/until-destroy';
import { ComponentOverlayService } from '../../shared/services/component-overlay/component-overlay.service';
import { SpinnerService } from '../../shared/services/spinner/spinner.service';
import { ChartWidgetConfiguration, DiagramType, DxChartType, SeriesType } from '../models/chart-widget';
import { ICloudWidget } from '../models/cloud-widget';
import { WidgetPropertyGetter } from '../models/widget-property-getter';
import { WidgetValueChartConfiguration } from '../models/widget-value-configuration';
import { WidgetService } from '../services/widget.service';
import { WidgetComponent } from '../widget.component';

const SMALL_VIEWPORT = [Breakpoints.Handset, Breakpoints.Small, Breakpoints.XSmall];

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['../widget.component.scss', './chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartComponent extends WidgetComponent implements OnInit, OnDestroy {
  private _incidentOcurred = false;
  public _chartHeightSubject: BehaviorSubject<number>;
  public _chartWidthSubject: BehaviorSubject<number>;
  @ViewChild('pieChart', { read: DxPieChartComponent, static: false }) pieChart: DxPieChartComponent;
  @Input() public parametersOverrides: Array<WidgetPropertyGetter>;
  public isLegendVisible = true;
  public isLegendButtonVisible = true;
  public chartHeight$: Observable<number>;
  public chartWidth$: Observable<number>;
  public DiagramType = DiagramType;
  public SeriesType = SeriesType;
  public DxChartType = DxChartType;
  public dataSource: any[];
  public config: ChartWidgetConfiguration;
  public seriesList: { value: string; argument: string; name: string; type: string }[];

  constructor(
    widgetService: WidgetService,
    elementRef: ElementRef,
    spinnerService: SpinnerService,
    componentOverlayService: ComponentOverlayService,
    public breakpointObserver: BreakpointObserver
  ) {
    super(widgetService, elementRef, spinnerService, componentOverlayService);

    this._chartHeightSubject = new BehaviorSubject<number>(0);
    this.chartHeight$ = this._chartHeightSubject.asObservable().pipe(debounceTime(100));

    this._chartWidthSubject = new BehaviorSubject<number>(0);
    this.chartWidth$ = this._chartWidthSubject.asObservable().pipe(debounceTime(100));
  }

  public ngOnInit(): void {
    this.breakpointObserver
      .observe(SMALL_VIEWPORT)
      .pipe(untilDestroy(this))
      .subscribe(() => {
        const isVisible = this.isLegendVisible;
        try {
          if (!isVisible) {
            this.isLegendVisible = true;
          }
        } catch (err) {
          console.log(err);
          this.isLegendVisible = false;
        }
      });
  }

  public ngOnDestroy(): void {
    // Used by the rxjs operator untilDestroy
  }

  public refreshChart(cloudWidget: ICloudWidget): void {
    if (cloudWidget) {
      this.config = cloudWidget.configuration;
      this.dataSource = cloudWidget.values;

      this.seriesList = [];
      cloudWidget.valuesConfiguration.forEach(list => {
        list.forEach((vconfig: WidgetValueChartConfiguration) => {
          this.seriesList.push({
            value: vconfig.value,
            name: vconfig.name,
            argument: vconfig.argument,
            type: this.fromSeriesType(vconfig.type)
          });
        });
      });
    }
  }

  @HostListener('window:resize')
  public onresize(): void {
    this._incidentOcurred = false;
  }

  /***
   * @summary When it's impossible to draw legends an incident is raised by the chart component
   */
  public incidentOcurred(event: any): void {
    if (event.target.id === 'W2104') {
      this._incidentOcurred = true;
    }
  }

  public chartDrawn(event: any): void {
    this.isLegendButtonVisible = !this._incidentOcurred;
  }

  public setupWidget(widget$: Observable<ICloudWidget>): Observable<ICloudWidget> {
    return widget$.pipe(tap(cw => this.refreshChart(cw)));
  }

  public fromSeriesType(seriesTypeEnum: number): string {
    switch (seriesTypeEnum) {
      case SeriesType.SideBySideBar:
        this.config.chartType = DxChartType.BarChart;
        return 'bar';
      case SeriesType.StackedBar:
        this.config.chartType = DxChartType.BarChart;
        return 'stackedBar';
      case SeriesType.FullStackedBar:
        this.config.chartType = DxChartType.BarChart;
        return 'fullStackedBar';

      case SeriesType.Donut:
        this.config.chartType = DxChartType.PieChart;
        return 'donut';
      case SeriesType.Pie:
        this.config.chartType = DxChartType.PieChart;
        return 'pie';

      case SeriesType.Point:
        this.config.chartType = DxChartType.LineChart;
        return 'scatter';

      case SeriesType.Line:
        this.config.chartType = DxChartType.LineChart;
        return 'line';
      case SeriesType.StepLine:
        this.config.chartType = DxChartType.LineChart;
        return 'stepline';
      case SeriesType.StackedLine:
        this.config.chartType = DxChartType.LineChart;
        return 'stackedline';
      case SeriesType.FullStackedLine:
        this.config.chartType = DxChartType.LineChart;
        return 'fullstackedline';

      case SeriesType.Area:
        this.config.chartType = DxChartType.AreaChart;
        return 'area';
      case SeriesType.StackedArea:
        this.config.chartType = DxChartType.AreaChart;
        return 'stackedArea';
      case SeriesType.FullStackedArea:
        this.config.chartType = DxChartType.AreaChart;
        return 'fullstackedArea';
      case SeriesType.StepArea:
        this.config.chartType = DxChartType.AreaChart;
        return 'stepArea';

      case SeriesType.RadarArea:
        this.config.chartType = DxChartType.RadarChart;
        return 'area';
      case SeriesType.RadarLine:
        this.config.chartType = DxChartType.RadarChart;
        return 'line';
      case SeriesType.RadarPoint:
        this.config.chartType = DxChartType.RadarChart;
        return 'scatter';
    }
  }
  public toggleLegend(): void {
    this.isLegendVisible = !this.isLegendVisible;
  }

  public onElementResize(properties: DOMRectReadOnly): void {
    // this height might need adjustments
    const legendHeight = 30;
    this._chartHeightSubject.next(properties.height - legendHeight);
    this._chartWidthSubject.next(properties.width - legendHeight);
  }
}
