import { Component, ElementRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { from, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { untilDestroy } from '../../../shared/rxjs/operators/until-destroy';
import { SpinnerService } from '../../../shared/services/spinner/spinner.service';

@Component({
  templateUrl: './legacy-viewer.component.html',
  styleUrls: ['./legacy-viewer.component.scss']
})
export class LegacyViewerComponent implements OnDestroy {
  private spinnerId: string;
  public source$: Observable<string>;

  constructor(
    activatedRoute: ActivatedRoute,
    private _elementRef: ElementRef,
    private _spinnerService: SpinnerService,
    private _router: Router
  ) {
    this.source$ = activatedRoute.params.pipe(
      tap(() => (this.spinnerId = _spinnerService.createSpinner(_elementRef))),
      map(param => `${environment.schema}://${environment.host}/admin2/${param['link']}`)
    );
  }

  public ngOnDestroy(): void {
    // Used by the rxjs operator untilDestroy
  }

  public viewLoaded(): void {
    if (this.spinnerId) {
      this._spinnerService.removeSpinner(this.spinnerId);
      this.spinnerId = null;
    }
  }

  public messageReceived(message: { command: string; parameters: any }): void {
    if (message.command === 'navigate') {
      try {
        const parameters = <{ fragments: string[]; query: any }>message.parameters;

        this._spinnerService
          .showFor(from(this._router.navigate(parameters.fragments || [], { queryParams: parameters.query })), this._elementRef)
          .pipe(untilDestroy(this))
          .subscribe();
      } catch (ex) {
        console.log(ex);
      }
    } else {
      console.log(`legacy-viewer: command '${message.command}' was ignored!`);
    }
  }
}
