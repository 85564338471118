import { Component } from '@angular/core';
import { ValueDataKind } from '../../../../app/shared/models/value-data-kind';
import { WidgetValueComponent } from '../widget-value.component';

enum CellValueAlignments {
  left = 0,
  center = 1,
  right = 2
}

@Component({
  selector: 'app-text-cell-value',
  templateUrl: './text-cell-value.component.html',
  styleUrls: ['../widget-value.component.scss', './text-cell-value.component.scss']
})
export class TextCellValueComponent extends WidgetValueComponent {
  public align(): CellValueAlignments {
    switch (this.valueConfig.dataType) {
      case ValueDataKind.number:
        return CellValueAlignments.left;

      case ValueDataKind.string:
        return CellValueAlignments.left;

      case ValueDataKind.date:
        return CellValueAlignments.center;

      case ValueDataKind.time:
        return CellValueAlignments.center;

      case ValueDataKind.datetime:
        return CellValueAlignments.center;

      case ValueDataKind.currency:
        return CellValueAlignments.right;

      case ValueDataKind.percentage:
        return CellValueAlignments.right;

      case ValueDataKind.signalNumber:
        return CellValueAlignments.right;

      case ValueDataKind.signalCurrency:
        return CellValueAlignments.right;
    }
  }

  public get alignLeft(): boolean {
    return this.align() === CellValueAlignments.left;
  }

  public get alignCenter(): boolean {
    return this.align() === CellValueAlignments.center;
  }

  public get alignRight(): boolean {
    return this.align() === CellValueAlignments.right;
  }
}
