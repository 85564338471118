<ng-container *ngIf="data$ | async as values">
  <ng-container *ngIf="values?.length; else emptyChart">
    <dx-chart id="chart" [dataSource]="values">
      <dxi-series argumentField="salesmanName" valueField="total" type="bar"> </dxi-series>
      <dxi-value-axis>
        <dxo-label [customizeText]="customizeValuesText"></dxo-label>
      </dxi-value-axis>
      <dxo-argument-axis>
        <dxo-label wordWrap="none" overlappingBehavior="rotate"></dxo-label>
      </dxo-argument-axis>
      <dxo-legend [visible]="false"></dxo-legend>
      <dxo-tooltip enabled="true" [zIndex]="5000" [customizeTooltip]="customizeTooltip"></dxo-tooltip>
    </dx-chart>
  </ng-container>
</ng-container>

<ng-template #emptyChart>
  <div class="center-error">
    <div class="pl-3 pr-3">
      <span
        i18n="SalesmanAccumulatedTotalError|Salesman Accumulated Total Error Label@@salesmanAccumulatedTotal_unavailableWidget"
        >Informação indisponível</span
      >
    </div>
    <p>
      <button
        mat-flat-button
        (click)="refresh()"
        i18n="SalesmanAccumulatedTotalErrorRetry|Salesman Accumulated Total Error Retry Label@@salesmanAccumulatedTotal_retryWidget"
        >Tentar novamente</button
      >
    </p>
  </div>
</ng-template>
