import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ICloudWidget } from '../models/cloud-widget';
import { WidgetKind } from '../models/widget-enums';
import { WidgetReservedParameters } from '../widget-reserved-parameters';

const API_ENDPOINT = `${environment.api.schema}://${environment.api.host}`;
const WIDGET_ENDPOINT = `${API_ENDPOINT}/widget`;
const OFFLINE_WIDGET_ENDPOINT = `${WIDGET_ENDPOINT}/offline`;
const WIDGET_KIND_ENDPOINT = `${API_ENDPOINT}/widget/kind`;

@Injectable({
  providedIn: 'root'
})
export class WidgetService {
  constructor(private readonly _http: HttpClient) {}

  public getWidget(widgetId: string, parameters: any): Observable<ICloudWidget> {
    const endpoint = this.getEndpoint(WIDGET_ENDPOINT, widgetId, parameters);

    return this.getWidgetFromEndpoint(parameters, endpoint);
  }

  public getOfflineWidget(widgetId: string, parameters: any): Observable<ICloudWidget> {
    const endpoint = this.getEndpoint(OFFLINE_WIDGET_ENDPOINT, widgetId, parameters);

    return this.getWidgetFromEndpoint(parameters, endpoint);
  }

  public getWidgetKind(widgetTypeName: string): Observable<WidgetKind> {
    const endpoint = `${WIDGET_KIND_ENDPOINT}/${widgetTypeName}`;
    const result = this._http.get<WidgetKind>(endpoint, {
      withCredentials: true
    });

    return result;
  }

  private getWidgetFromEndpoint(parameters: any, endpoint: string): Observable<ICloudWidget> {
    const result$: Observable<ICloudWidget> = this._http
      .post<ICloudWidget>(endpoint, parameters || {}, {
        withCredentials: true
      })
      .pipe(map(cloudWidget => this.normalizeParamaters(cloudWidget)));

    return result$;
  }

  private normalizeParamaters(cloudWidget: ICloudWidget): ICloudWidget {
    if (cloudWidget && cloudWidget.parametersConfiguration) {
      for (let paramIdx = 0; paramIdx < cloudWidget.parametersConfiguration.length; paramIdx++) {
        const pattern = new RegExp(`${WidgetReservedParameters.sessionDate}`, 'i');

        if (pattern.test(cloudWidget.parametersConfiguration[paramIdx].name)) {
          const normalizedParameters: any = {};

          cloudWidget.parametersConfiguration[paramIdx].name = WidgetReservedParameters.sessionDate;

          for (const property in cloudWidget.parametersValues) {
            if (Object.prototype.hasOwnProperty.call(cloudWidget.parametersValues, property)) {
              if (pattern.test(property)) {
                normalizedParameters[WidgetReservedParameters.sessionDate] = cloudWidget.parametersValues[property];
              } else {
                normalizedParameters[property] = cloudWidget.parametersValues[property];
              }
            }
          }

          cloudWidget.parametersValues = normalizedParameters;
        }
      }
    }

    return cloudWidget;
  }

  private getEndpoint(baseEndpoint: string, widgetId: string, parameters: any): string {
    const storeIdSegment =
      parameters && parameters[WidgetReservedParameters.storeId] ? `/${parameters[WidgetReservedParameters.storeId]}` : '';
    const endpoint = `${baseEndpoint}${storeIdSegment}/${widgetId}`;

    return endpoint;
  }
}
