export enum XDCountryISO {
  PT = 'PRT',
  AO = 'AGO',
  BR = 'BRA',
  CV = 'CPV',
  DO = 'DMA',
  ES = 'ESP',
  FR = 'FRA',
  LUX = 'LUX',
  LP = 'PRT',
  OTHER = 'OTHER'
}
