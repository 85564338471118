import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { formatServiceRequestDatetime } from '../../shared/utils/date-time.utils';
import { BusinessSummaryCards } from '../model/businessSummaryCards';
import { BusinessSummarySales, BusinessSummarySalesType } from '../model/businessSummarySales';
import { BusinessSummaryTableSales } from '../model/businessSummaryTableSales';

const API_ENDPOINT = `${environment.api.schema}://${environment.api.host}`;
const BUSINESSSUMMARY_ENDPOINT = `${API_ENDPOINT}/businesssummary`;
const BUSINESSSUMMARY_CARDS_ENDPOINT = `${BUSINESSSUMMARY_ENDPOINT}/cards`;
const BUSINESSSUMMARY_DAILYSALES_ENDPOINT = `${BUSINESSSUMMARY_ENDPOINT}/sales`;
const BUSINESSSUMMARY_TABLESALES_ENDPOINT = `${BUSINESSSUMMARY_ENDPOINT}/tablesales`;

@Injectable({
  providedIn: 'root'
})
export class BusinessSummaryService {
  private readonly _http: HttpClient = null;

  constructor(http: HttpClient) {
    this._http = http;
  }

  public getCards(storeIds: string[]): Observable<BusinessSummaryCards[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      withCredentials: true
    };

    return this._http.post<BusinessSummaryCards[]>(BUSINESSSUMMARY_CARDS_ENDPOINT, { storeIds: storeIds }, httpOptions);
  }

  public getSales(
    storeIds: string[],
    initialDate: Date,
    finalDate: Date,
    groupByFlag: BusinessSummarySalesType
  ): Observable<BusinessSummarySales[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: new HttpParams()
        .set('initialDate', formatServiceRequestDatetime(initialDate))
        .set('finalDate', formatServiceRequestDatetime(finalDate))
        .set('groupByFlag', groupByFlag.toString()),
      withCredentials: true
    };

    return this._http.post<BusinessSummarySales[]>(BUSINESSSUMMARY_DAILYSALES_ENDPOINT, { storeIds: storeIds }, httpOptions);
  }

  public getTableSales(currentDate: Date): Observable<BusinessSummaryTableSales[]> {
    const httpOptions = {
      params: new HttpParams().set('currentDate', formatServiceRequestDatetime(currentDate)),
      withCredentials: true
    };

    return this._http.get<BusinessSummaryTableSales[]>(BUSINESSSUMMARY_TABLESALES_ENDPOINT, httpOptions);
  }
}
