import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { subMonths } from 'date-fns/esm/fp';
import { DxChartComponent } from 'devextreme-angular';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ExportData } from '../../../shared/models/export-data';
import { ValueDataKind } from '../../../shared/models/value-data-kind';

import { CurrencyPipe } from '../../../shared/pipes/Currency.pipe';
import { untilDestroy } from '../../../shared/rxjs/operators/until-destroy';
import { ExportService } from '../../../shared/services/export-service/export.service';
import { SpinnerService } from '../../../shared/services/spinner/spinner.service';
import { TranslateService } from '../../../shared/services/translate-service/translate.service';
import { ItemGroupAccumulatedTotal } from '../../model/accumulated-totals';
import { AccumulatedTotalsService } from '../../services/accumulated-totals.service';
import { ColumnConfig } from '../../../shared/models/column-config';

export enum unnecessaryKeys {
  syncDate = 'syncDate',
  syncGuid = 'syncGuid'
}

@Component({
  selector: 'app-item-groups-chart',
  templateUrl: './item-groups-chart.component.html',
  styleUrls: ['./item-groups-chart.component.scss']
})
export class ItemGroupsChartComponent implements OnInit, OnDestroy {
  public topItemGroups$: Observable<ItemGroupAccumulatedTotal[]>;
  public topItemGroupsData: ItemGroupAccumulatedTotal[];
  public ExportXLSXData: ItemGroupAccumulatedTotal[];
  public topItemGroupsDataSource = new MatTableDataSource<ItemGroupAccumulatedTotal>();
  public topItemGroupsTableColumns: string[];
  public topsInterval: Array<{ value: number; description: string }>;
  public selectedItemGroupDate: {
    value: number;
    description: string;
  };
  @ViewChild(DxChartComponent, { static: false }) itemGroupsBarChart: DxChartComponent;
  @ViewChild('itemGroupsChart', { static: true, read: ElementRef })
  private itemGroupsChart: ElementRef;
  public itemGroupsTableVisible = false;
  @Input() public requestedStores: string;

  constructor(
    public currencyPipe: CurrencyPipe,
    private _spinnerService: SpinnerService,
    private _translateService: TranslateService,
    private _exportservice: ExportService,
    private _accumulatedTotalsService: AccumulatedTotalsService
  ) {
    this.customizeTopsTooltip = this.customizeTopsTooltip.bind(this);
    this.topsInterval = new Array<{ value: number; description: string }>();
    this.topsInterval = [
      { value: 1, description: this._translateService.translate('last_month') },
      { value: 3, description: this._translateService.translate('last_3_months') },
      { value: 6, description: this._translateService.translate('last_6_months') }
    ];

    this.selectedItemGroupDate = this.topsInterval[0];
    this.topItemGroupsTableColumns = new Array<string>();
  }

  public ngOnInit(): void {
    this.itemGroupIntervalChanged();
  }

  public ngOnDestroy(): void {
    // Used by the rxjs operator untilDestroy
  }

  public itemGroupIntervalChanged(): void {
    const now = new Date();
    this.topItemGroups$ = this._spinnerService
      .showFor(
        this._accumulatedTotalsService.GetItemGroupAccumulatedTotalCloud(
          subMonths(this.selectedItemGroupDate.value, now),
          now,
          this.requestedStores
        ),
        this.itemGroupsChart
      )
      .pipe(
        untilDestroy(this),
        tap(list => {
          this.topItemGroupsData = list.length > 10 ? list.slice(list.length - 10, list.length) : list;
          this.topItemGroupsDataSource = new MatTableDataSource(list);
          this.topItemGroupsDataSource.data.forEach(e => {
            for (const key in e) {
              if (this.topItemGroupsTableColumns.indexOf(key) === -1) {
                if (key !== unnecessaryKeys.syncGuid && key !== unnecessaryKeys.syncDate) {
                  this.topItemGroupsTableColumns.push(key);
                }
              }
            }
          });
        })
      );
  }

  public customizeTopsTooltip(info: any): any {
    return {
      text: info.argumentText + '<br/>' + this.currencyPipe.transform(info.valueText, null, null, null, null)
    };
  }

  public showMoreItemGroupSales(): void {
    this.itemGroupsTableVisible = !this.itemGroupsTableVisible;
  }

  public updateStores(value: string): void {
    this.requestedStores = value;
    this.itemGroupIntervalChanged();
  }

  public exportXlSX(): void {
    this.topItemGroups$.pipe(untilDestroy(this)).subscribe(list => {
      const data = list;
      const headers = this.getExportColumnConfigs();
      data.forEach(row => {
        delete row.syncDate;
        delete row.qnt;
      });
      const fileName = this._translateService.translate('storeSummary_topItemGroupsTableTitle');
      const exportData = new ExportData(null, data, headers, fileName);
      this._exportservice.ExportXLSX(exportData);
    });
  }

  public exportChartPdf(): void {
    const fileName = this._translateService.translate('storeSummary_topItemGroupsChartTitle');
    this.itemGroupsBarChart?.instance.exportTo(fileName, 'pdf');
  }

  public exportChartPng() {
    const fileName = this._translateService.translate('storeSummary_topItemGroupsChartTitle');
    this.itemGroupsBarChart?.instance.exportTo(fileName, 'png');
  }

  public getExportColumnConfigs(): ColumnConfig[] {
    return [
      {
        header: this._translateService.translate('groupName'),
        propertyName: 'groupName',
        kind: ValueDataKind.string,
        columnConfigs: null,
        usesParentHeader: false
      },
      {
        header: this._translateService.translate('storeName'),
        propertyName: 'storeName',
        kind: ValueDataKind.string,
        columnConfigs: null,
        usesParentHeader: false
      },
      {
        header: this._translateService.translate('storeId'),
        propertyName: 'storeId',
        kind: ValueDataKind.number,
        columnConfigs: null,
        usesParentHeader: false
      },
      {
        header: this._translateService.translate('groupId'),
        propertyName: 'groupId',
        kind: ValueDataKind.number,
        columnConfigs: null,
        usesParentHeader: false
      },
      {
        header: this._translateService.translate('totalNet'),
        propertyName: 'totalNet',
        kind: ValueDataKind.currency,
        columnConfigs: null,
        usesParentHeader: false
      },
      {
        header: this._translateService.translate('total'),
        propertyName: 'total',
        kind: ValueDataKind.currency,
        columnConfigs: null,
        usesParentHeader: false
      },
      {
        header: this._translateService.translate('qnt'),
        propertyName: 'qnt',
        kind: ValueDataKind.number,
        columnConfigs: null,
        usesParentHeader: false
      },
      {
        header: this._translateService.translate('totalDiscountsNet'),
        propertyName: 'totalDiscountsNet',
        kind: ValueDataKind.currency,
        columnConfigs: null,
        usesParentHeader: false
      },
      {
        header: this._translateService.translate('totalDiscounts'),
        propertyName: 'totalDiscounts',
        kind: ValueDataKind.currency,
        columnConfigs: null,
        usesParentHeader: false
      },
      {
        header: this._translateService.translate('costNet'),
        propertyName: 'costNet',
        kind: ValueDataKind.currency,
        columnConfigs: null,
        usesParentHeader: false
      },
      {
        header: this._translateService.translate('cost'),
        propertyName: 'cost',
        kind: ValueDataKind.currency,
        columnConfigs: null,
        usesParentHeader: false
      },
      {
        header: this._translateService.translate('profitNet'),
        propertyName: 'profitNet',
        kind: ValueDataKind.currency,
        columnConfigs: null,
        usesParentHeader: false
      },
      {
        header: this._translateService.translate('profit'),
        propertyName: 'profit',
        kind: ValueDataKind.currency,
        columnConfigs: null,
        usesParentHeader: false
      }
    ];
  }
}
