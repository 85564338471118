import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ComponentOverlay } from '../../shared/models/component-overlay';
import { ValueDataKind } from '../../shared/models/value-data-kind';
import { WidgetParameterConfigurationOptionsKind } from '../models/widget-enums';
import { WidgetParameterConfiguration } from '../models/widget-parameter-configuration';

@Component({
  selector: 'app-parameters',
  templateUrl: './parameters.component.html',
  styleUrls: ['../widget.component.scss', './parameters.component.scss']
})
export class ParametersComponent extends ComponentOverlay implements OnInit, OnDestroy {
  private _goBack: EventEmitter<any>;
  private _confirm: EventEmitter<any>;

  public parametersForm: UntypedFormGroup;

  protected ParameterDataKind = ValueDataKind;
  protected OptionsKind = WidgetParameterConfigurationOptionsKind;

  protected options: Map<string, Array<[string, string]>>;

  @Input() public parametersConfig: Array<WidgetParameterConfiguration>;
  @Input() public parameters: any;
  @Output()
  public get goBack(): EventEmitter<any> {
    return this._goBack;
  }
  @Output()
  public get confirm(): EventEmitter<any> {
    return this._confirm;
  }

  constructor() {
    super();
    this._goBack = new EventEmitter<any>();
    this._confirm = new EventEmitter<any>();
    this.options = new Map<string, Array<[string, string]>>();
  }

  public ngOnInit(): void {
    const controlGroup = {};

    if (this.parametersConfig) {
      this.parametersConfig.forEach(config => {
        controlGroup[config.name] = new UntypedFormControl(this.parameters[config.name] || '');

        if (config.optionsKind !== WidgetParameterConfigurationOptionsKind.none && config.options) {
          const configOptions = new Array<[string, string]>();

          Object.getOwnPropertyNames(config.options).forEach(propName => configOptions.push([propName, config.options[propName]]));

          this.options.set(config.name, configOptions);
        }
      });

      this.parametersForm = new UntypedFormGroup(controlGroup);
    }
  }

  public ngOnDestroy(): void {
    // Used by the rxjs operator untilDestroy
  }

  public goBackHandler(): void {
    this.goBack.emit();
    this.close();
  }

  public confirmHandler(): void {
    if (this.parametersForm.valid) {
      this.parametersConfig.forEach(config => {
        this.parameters[config.name] = this.parametersForm.controls[config.name].value;
      });

      this.confirm.emit(this.parameters);
      this.close();
    }
  }
}
