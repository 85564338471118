import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

import { TranslateService } from '../services/translate-service/translate.service';

@Injectable({
  providedIn: 'root'
})
export class CloudPaginatorIntl extends MatPaginatorIntl {
  constructor(private _translateService: TranslateService) {
    super();
    this.getRangeLabel = (page: number, pageSize: number, length: number) => {
      let current = (page + 1) * pageSize;

      current = current > length ? length : current;

      return this._translateService.translateWithParams('rangeConector', current, length);
    };
  }
  public itemsPerPageLabel = this._translateService.translate('itemsPerPage');
  public firstPageLabel = this._translateService.translate('firstPage');
  public lastPageLabel = this._translateService.translate('lastPage');
  public nextPageLabel = this._translateService.translate('nextPage');
  public previousPageLabel = this._translateService.translate('previousPage');
  public getRangeLabel: (page: number, pageSize: number, length: number) => string;
}
