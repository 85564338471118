export class LoginAccount {
  private _account: string;
  private _description: string;
  private _icon: string;

  constructor(account: string, description: string, icon: string) {
    this._account = account;
    this._description = description;
    this._icon = icon;
  }

  public get account(): string {
    return this._account;
  }

  public set account(value: string) {
    this._account = value.toLocaleUpperCase();
  }

  public get description(): string {
    return this._description;
  }

  public set description(value: string) {
    this._description = value;
  }

  public get icon(): string {
    return this._icon;
  }

  public set icon(value: string) {
    this._icon = value;
  }

  /**
   * Serialize LoginAccount.
   *
   * @summary Used by JSON.stringify.
   * without this method JSON.stringify(loginAccountInstance) would return:
   *   { _account: string, description: string, icon: string }
   * instead of:
   *   { account: string, description: string, icon: string }
   */
  public toJSON(): any {
    return {
      account: this.account,
      description: this.description,
      icon: this.icon
    };
  }
}
