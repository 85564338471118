import { Injectable } from '@angular/core';
import { parseJSON } from 'date-fns/esm/fp';
import { LoginInfo } from '../../models/LoginInfo';
import { XDProgram } from '../../models/xdprogram';

const LOGIN_INFO_KEY = 'loginInfo';

@Injectable({
  providedIn: 'root'
})
export class LoginInfoService {
  private _loginInfo: LoginInfo;

  public get(): LoginInfo {
    if (!this._loginInfo) {
      try {
        const loginInfo = JSON.parse(localStorage.getItem(LOGIN_INFO_KEY));

        if (loginInfo) {
          loginInfo.program = loginInfo.program || XDProgram.XDPos;
          loginInfo.expiryDate = parseJSON(loginInfo.expiryDate);
          this._loginInfo = new LoginInfo(loginInfo);
        }
      } catch (error) {
        console.log(error);
      }
    }

    return this._loginInfo;
  }

  public set(loginInfo: LoginInfo): void {
    localStorage.setItem(LOGIN_INFO_KEY, JSON.stringify(loginInfo));
    this._loginInfo = loginInfo;
  }

  public delete(): void {
    localStorage.removeItem(LOGIN_INFO_KEY);
    this._loginInfo = null;
  }
}
