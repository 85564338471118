import { Component, ElementRef, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { MyNotification, NotificationGroup } from '../../models/notification';
import { NotificationService } from '../../services/notification/notification.service';

@Component({
  selector: 'app-notification-container',
  templateUrl: './notification-container.component.html',
  styleUrls: ['./notification-container.component.scss']
})
export class NotificationContainerComponent {
  @ViewChild('colorSelector', { static: true }) public colorSelector: ElementRef;
  public notifications$: Observable<MyNotification[]>;
  public groups$: Observable<Map<string, [NotificationGroup, MyNotification[]]>>;

  constructor(public notificationService: NotificationService) {
    this.notifications$ = this.notificationService.notifications$;
    this.groups$ = this.notificationService.groupedNotifications$;
  }

  public clearAll(): void {
    this.notificationService.clearAll();
  }
  public expandedChange(event: any, group: NotificationGroup): void {
    this.notificationService._groupExpandedValues.set(group.groupId, event);
  }

  public getColors(index: number): string {
    const primaryColor = getComputedStyle(this.colorSelector.nativeElement).color;

    if (index % 2 === 0) {
      return this.shadeRGBColor(primaryColor, 30);
    } else {
      return this.shadeRGBColor(primaryColor, 10);
    }
  }

  public shadeRGBColor(value: string, amount: number): string {
    const valuesOnly = value.substring(4, value.length - 1).split(',');
    const r = Number(valuesOnly[0]) + amount;
    const g = Number(valuesOnly[1]) + amount;
    const b = Number(valuesOnly[2]) + amount;
    return `rgb(${r},${g},${b})`;
  }
}
