import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MyNotification, MyNotificationAction, NotificationGroup } from '../../models/notification';
import { CalloutService } from '../callout/callout.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private _list: BehaviorSubject<Array<MyNotification>> = new BehaviorSubject<Array<MyNotification>>([]);
  private _groupsList: BehaviorSubject<Map<string, [NotificationGroup, MyNotification[]]>> = new BehaviorSubject<
    Map<string, [NotificationGroup, MyNotification[]]>
  >(new Map<string, [NotificationGroup, MyNotification[]]>());
  private _notificationCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public _groupExpandedValues: Map<string, boolean> = new Map<string, boolean>();

  public _calloutService: CalloutService;

  constructor(callOutService: CalloutService) {
    this._calloutService = callOutService;
  }

  public get notifications$(): Observable<MyNotification[]> {
    return this._list.asObservable();
  }
  public get groupedNotifications$(): Observable<Map<string, [NotificationGroup, MyNotification[]]>> {
    return this._groupsList.asObservable();
  }

  public get notificationCount$(): Observable<number> {
    return this._notificationCount.asObservable();
  }

  public addNotification(note: MyNotification): void {
    if (!note.group) {
      this.addSimpleNotification(note);
    } else {
      this.addNotificationToGroups(note);
    }

    if (note.timeout > 0) {
      setTimeout(() => {
        this.deleteNotification(note.id, note.group.groupId);
      }, note.timeout);
    }

    let groupCounter = 0;
    this._groupsList.value.forEach(elem => (groupCounter += elem[1].length));
    this._notificationCount.next(groupCounter + this._list.value.length);
  }

  private addSimpleNotification(note: MyNotification) {
    const notifications: Array<MyNotification> = this._list.getValue();
    const index = notifications.findIndex(n => n.id === note.id);
    if (index >= 0) {
      notifications.splice(index, 1, note);
    } else {
      notifications.push(note);
    }
    this._list.next(notifications);
  }

  private addNotificationToGroups(note: MyNotification) {
    const groupMap = this._groupsList.getValue();
    const tuple = groupMap.get(note.group.groupId);
    let notifications: MyNotification[];

    if (!tuple) {
      notifications = new Array<MyNotification>();
    } else {
      notifications = tuple[1];
    }

    const index = notifications.findIndex(n => n.id === note.id);
    if (index >= 0) {
      notifications.splice(index, 1, note);
    } else {
      notifications.push(note);
    }

    if (this._groupExpandedValues.has(note.group.groupId)) {
      note.group.expanded = this._groupExpandedValues.get(note.group.groupId);
    }

    groupMap.set(note.group.groupId, [note.group, notifications]);

    this._groupsList.next(groupMap);
  }

  public deleteNotification(id: string, groupId: string): void {
    let notifications: Array<MyNotification> = this._list.getValue();
    let index = notifications.findIndex(n => n.id === id);

    if (index >= 0) {
      notifications.splice(index, 1);
      this._list.next(notifications);
    } else if (groupId) {
      const groupsList = this._groupsList.getValue();
      const group = groupsList.get(groupId);
      notifications = group[1];

      index = notifications.findIndex(n => n.id === id);
      if (index >= 0) {
        notifications.splice(index, 1);
        if (notifications.length === 0) {
          groupsList.delete(groupId);
        } else {
          groupsList.set(groupId, [notifications[0].group, notifications]);
        }
        this._groupsList.next(groupsList);
      }
    }
    let groupCounter = 0;
    this._groupsList.value.forEach(elem => (groupCounter += elem[1].length));
    this._notificationCount.next(groupCounter + this._list.value.length);
  }

  public appendAction(note: MyNotification, action: MyNotificationAction): void {
    const notifications: Array<MyNotification> = this._list.getValue();
    const index = notifications.findIndex(n => n.id === note.id);
    const valueToEdit = notifications[index];
    valueToEdit.actions.push(action);
    this._list.next(notifications.splice(index, 1, valueToEdit));
  }

  public clearAll(): void {
    this._list.next(new Array<MyNotification>());

    let groupCounter = 0;
    this._groupsList.value.forEach(elem => (groupCounter += elem[1].length));

    this._notificationCount.next(groupCounter);
  }

  public clearAllByGroup(groupId: string): void {
    const groupsList = this._groupsList.getValue();

    groupsList.delete(groupId);

    this._groupsList.next(groupsList);

    let groupCounter = 0;
    this._groupsList.value.forEach(elem => (groupCounter += elem[1].length));
    this._notificationCount.next(groupCounter + this._list.value.length);
  }
}
