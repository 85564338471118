<mat-card class="storesSalesTable">
  <mat-card-header class="cardHeader">
    <mat-card-title i18n="storeSummaryTableTitle|store Summary Table Title@@storeSummary_tableTitle">Vendas | Loja</mat-card-title>
    <mat-icon (click)="getStoresTableData()" class="tableRefreshButton">cached</mat-icon>
    <mat-card-subtitle i18n="storeSummaryTableSubTitle|store Summary Table SubTitle@@storeSummary_tableSubTitle"
      >Vendas dos últimos 31 dias por loja</mat-card-subtitle
    >
  </mat-card-header>
  <ng-container *ngIf="storesSales$ | async">
    <ng-container *ngIf="storesSalesData?.length; else emptyTable">
      <mat-card-content class="storesTableContent">
        <table class="storesTable" mat-table [dataSource]="storesSalesDataSource">
          <ng-container matColumnDef="storeName" sticky>
            <th
              class="storeHeader"
              mat-header-cell
              *matHeaderCellDef
              i18n="storeSummaryTableNamesColumn|store Summary Table Names Column@@storeSummary_tableNamesColumn"
            >
              Nome
            </th>
            <td class="storeCells" mat-cell *matCellDef="let element"> {{ element.storeName }} </td>
            <td
              class="storeFooter"
              mat-footer-cell
              *matFooterCellDef
              i18n="storeSummaryTableTotalsRow|store Summary Table Totals Row@@storeSummary_tableTotalsRow"
            >
              Total
            </td>
          </ng-container>

          <ng-container [matColumnDef]="i" *ngFor="let i of daysList">
            <th class="daysHeaders" mat-header-cell *matHeaderCellDef> {{ customizeTableHeader(i) }} </th>
            <td class="daysCells" mat-cell *matCellDef="let element"> {{ element.total[i] | cloudCurrency }} </td>
            <td class="daysFooter" mat-footer-cell *matFooterCellDef> {{ getColumnsTotals(i) | cloudCurrency }} </td>
          </ng-container>

          <ng-container matColumnDef="lineTotal" stickyEnd>
            <th
              class="totalHeader"
              mat-header-cell
              *matHeaderCellDef
              i18n="storeSummaryTableTotalsColumn|store Summary Table Totals Column@@storeSummary_tableTotalsColumn"
            >
              Total
            </th>
            <td class="totalCells" mat-cell *matCellDef="let element"> {{ element.lineTotal | cloudCurrency }} </td>
            <td class="totalFooter" mat-footer-cell *matFooterCellDef> {{ getLineTotals() | cloudCurrency }} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr class="tableRows" mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
        </table>
      </mat-card-content>
    </ng-container>
  </ng-container>
</mat-card>

<ng-template #emptyTable>
  <div class="center-error">
    <div class="pl-3 pr-3">
      <p class="col text-center text-color" i18n="storeSummaryEmptyTableTitle|store Summary Empty Table Title@@storeSummary_emptyTableTitle"
        >Não foram encontradas vendas.</p
      >
      <p
        class="col text-center text-color"
        i18n="storeSummaryEmptyTableSubTitle|store Summary Empty Table SubTitle@@storeSummary_emptyTableSubTitle"
        >Parece que não existem vendas nos últimos 31 dias.</p
      >
    </div>
  </div>
</ng-template>
