import { BreakpointObserver } from '@angular/cdk/layout';
import { Location } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { StoreService } from '../../../entities/store/store.service';
import { Store } from '../../../shared/models/store';
import { untilDestroy } from '../../../shared/rxjs/operators/until-destroy';
import { SpinnerService } from '../../../shared/services/spinner/spinner.service';
import { TranslateService } from '../../../shared/services/translate-service/translate.service';
import { BusinessSummaryCards, BusinessSummaryCardStatus, BusinessSummaryCardType } from '../../model/businessSummaryCards';
import { BusinessSummarySalesType } from '../../model/businessSummarySales';
import { BusinessSummaryService } from '../../services/business-summary.service';
import { DaysChartComponent } from '../days-chart/days-chart.component';
import { ItemGroupsChartComponent } from '../item-groups-chart/item-groups-chart.component';
import { ItemsChartComponent } from '../items-chart/items-chart.component';
import { MonthsChartComponent } from '../months-chart/months-chart.component';
import { SalesmanChartComponent } from '../salesman-chart/salesman-chart.component';
import { TerminalsChartComponent } from '../terminals-chart/terminals-chart.component';

const onecard_viewport = '(max-width: 759px)';
const twocards_viewport = '(min-width: 760px) and (max-width: 1159px)';
const treecards_viewport = '(min-width: 1160px) and (max-width: 1544px)';
const fourcards_viewport = '(min-width: 1545px)';

@Component({
  selector: 'app-store-summary',
  templateUrl: './store-summary.component.html',
  styleUrls: ['./store-summary.component.scss']
})
export class StoreSummaryComponent implements OnInit, AfterViewInit, OnDestroy {
  public cardsData$: Observable<BusinessSummaryCards[]>;
  public businessStatus = BusinessSummaryCardStatus;
  public businessType = BusinessSummaryCardType;
  public businessGroupByType = BusinessSummarySalesType;

  public storeControl = new UntypedFormControl();
  public selectedStore: { value: string; description: string };
  public stores$: Observable<Store[]>;
  public availableStores: { value: string; description: string }[];
  public filteredStores$: Observable<{ value: string; description: string }[]>;
  public requestedStores: string[];
  public columns: number;
  public finishedStoresRequest: boolean;

  @ViewChild(DaysChartComponent)
  private daysChartComponent: DaysChartComponent;
  @ViewChild(MonthsChartComponent)
  private monthsChartComponent: MonthsChartComponent;
  @ViewChild(SalesmanChartComponent)
  private salesmansChartComponent: SalesmanChartComponent;
  @ViewChild(TerminalsChartComponent)
  private terminalsChartComponent: TerminalsChartComponent;
  @ViewChild(ItemsChartComponent)
  private itemsChartComponent: ItemsChartComponent;
  @ViewChild(ItemGroupsChartComponent)
  private itemGroupsChartComponent: ItemGroupsChartComponent;

  constructor(
    private readonly storeService: StoreService,
    private _activatedroute: ActivatedRoute,
    private _location: Location,
    private _breakpointObserver: BreakpointObserver,
    public businessSummaryService: BusinessSummaryService,
    private _spinnerService: SpinnerService,
    private _translateService: TranslateService
  ) {
    this.availableStores = new Array<{ value: string; description: string }>();
    this.requestedStores = new Array<string>();
  }

  public ngOnInit(): void {
    this.filteredStores$ = this.storeControl.valueChanges.pipe(
      debounceTime(300),
      map(val => {
        val = typeof val === 'string' || val instanceof String ? val : '';
        return this.filterResults(val);
      })
    );

    this._breakpointObserver
      .observe([onecard_viewport, twocards_viewport, treecards_viewport, fourcards_viewport])
      .pipe(untilDestroy(this))
      .subscribe(result => {
        if (result.breakpoints[onecard_viewport]) {
          this.columns = 1;
        } else if (result.breakpoints[twocards_viewport]) {
          this.columns = 2;
        } else if (result.breakpoints[treecards_viewport]) {
          this.columns = 3;
        } else if (result.breakpoints[fourcards_viewport]) {
          this.columns = 4;
        }
      });
  }

  public ngAfterViewInit(): void {
    const storeIdParam = this._activatedroute.snapshot.paramMap.get('id') || '0';

    this.stores$ = this.storeService.getStores();
    this._spinnerService
      .showFor(this.stores$)
      .pipe(
        tap(storelist => {
          let selectedStore: string[];
          if (storeIdParam !== '0') {
            selectedStore = [storeIdParam];
          } else {
            selectedStore = storelist.map(s => String(s.id));
          }

          if (
            storelist.length > 0 &&
            selectedStore.length === 1 &&
            storelist.findIndex(store => String(store.id) === selectedStore[0]) >= 0
          ) {
            this.selectedStore = {
              value: selectedStore[0],
              description: storelist.find(store => String(store.id) === selectedStore[0]).name
            };
          } else if (storelist.length > 0 && selectedStore.length > 1) {
            this.selectedStore = { value: '0', description: this._translateService.translate('allStores') };
          }

          storelist.forEach(s => {
            this.availableStores.push({ value: String(s.storeId), description: s.name });
          });

          if (this.availableStores.length > 1) {
            this.availableStores.push({ value: '0', description: 'Todas' });
          }

          if (this.selectedStore && this.availableStores.length > 0) {
            this.storeSelectionChanged();
            this.storeControl.setValue(this.selectedStore);
          }
        })
      )
      .subscribe(() => (this.finishedStoresRequest = true));
  }

  public ngOnDestroy(): void {
    // Used by the rxjs operator untilDestroy
  }

  public storeDisplayValue(store?: { value: string; description: string }): string {
    return (store && store.description) || (this.selectedStore && this.selectedStore.description) || '---';
  }

  public storeSelectionChanged(): void {
    const fromMultiStore = this.selectedStore.value === '0';

    if (this.storeControl.value && this.selectedStore.value !== this.storeControl.value.value) {
      this.selectedStore = this.storeControl.value;
    }

    if (this.selectedStore.value !== '0') {
      this.requestedStores = [this.selectedStore.value];
      this._location.replaceState('/summary/store/' + this.selectedStore.value);
    } else {
      this._location.replaceState('/summary');
      this.availableStores.forEach(e => {
        if (e.value !== '0') {
          this.requestedStores.push(e.value);
        }
      });
    }

    this.cardsData$ = this.businessSummaryService.getCards(this.requestedStores);
    if (this.storeControl.value) {
      this.refreshStoreCharts(fromMultiStore);
    }
  }

  public filterResults(v: string): { value: string; description: string }[] {
    let filteredStores: { value: string; description: string }[];

    if (v) {
      filteredStores = this.availableStores.filter(e => e.description.toLowerCase().includes(v.toLowerCase()));
    } else {
      filteredStores = this.availableStores;
    }

    return filteredStores;
  }

  public refreshStoreCharts(value: boolean): void {
    this.daysChartComponent.updateStores(this.requestedStores);
    this.monthsChartComponent.updateStores(this.requestedStores);
    this.salesmansChartComponent.updateStores(this.selectedStore.value);
    this.itemsChartComponent.updateStores(this.selectedStore.value);
    this.itemGroupsChartComponent.updateStores(this.selectedStore.value);

    if (!value && this.selectedStore.value !== '0') {
      this.terminalsChartComponent.updateStores(this.selectedStore.value);
    }
  }
}
