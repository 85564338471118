import { Injectable } from '@angular/core';

import { LoginAccount } from './../_models/login-account';

const ACCOUNT_STORAGE_ID = 'login_accounts_xdcloud';

@Injectable({
  providedIn: 'root'
})
export class AccountListService {
  private _accounts: LoginAccount[] = null;

  constructor() {
    try {
      // Simply using JSON.parse will return an object that only looks like a LoginAccount but
      // won't behave like one because LoginAccount has a getters and setters for its properties.
      const storedAccounts = JSON.parse(localStorage.getItem(ACCOUNT_STORAGE_ID)) || [];
      this._accounts = storedAccounts.map(sa => new LoginAccount(sa.account, sa.description, sa.icon));
    } catch {
      this._accounts = [];
    }
  }

  public get selectedAccount(): LoginAccount {
    return this.accounts[0] || null;
  }

  public set selectedAccount(value: LoginAccount) {
    this._accounts = [value, ...this.accounts.filter(la => la.account !== value.account)];

    localStorage.setItem(ACCOUNT_STORAGE_ID, JSON.stringify(this._accounts));
  }

  public get accounts(): LoginAccount[] {
    return this._accounts;
  }

  public addAccount(value: LoginAccount): void {
    this.selectedAccount = value;
  }

  public deleteAccount(value: LoginAccount): void {
    this._accounts = this.accounts.filter(la => la.account !== value.account);

    localStorage.setItem(ACCOUNT_STORAGE_ID, JSON.stringify(this._accounts));
  }

  public updateAccount(value: LoginAccount): void {
    this.selectedAccount = value;
  }
}
