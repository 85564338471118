<!-- General -->
<span class="translation" id="FeatureInDevelopment" i18n="FeatureInDevelopment|Feature in development@@translate_FeatureInDevelopment"
  >Funcionalidade em desenvolvimento</span
>
<span class="translation" id="close_1" i18n="close|close@@translate_close_1">Fechar</span>
<span class="translation" id="male" i18n="MaleGender|gender male@@translate_male">Masculino</span>
<span class="translation" id="female" i18n="FemaleGender|gender female@@translate_female">Feminino</span>
<span class="translation" id="netPrice" i18n="NetPrice|Net price@@translate_netPrice">Preço s/Imposto</span>
<span class="translation" id="discountPercentage1" i18n="DiscountPercentage1|Discount percentage1@@translate_discountPercentage1"
  >Percentagem 1</span
>
<span class="translation" id="discountPercentage2" i18n="DiscountPercentage2|Discount percentage2@@translate_discountPercentage2"
  >Percentagem 2</span
>
<span class="translation" id="discountPercentage3" i18n="DiscountPercentage3|Discount percentage3@@translate_discountPercentage3"
  >Percentagem 3</span
>
<span class="translation" id="id" i18n="id|id@@translate_id">Referência</span>
<span class="translation" id="keyId" i18n="keyId|id@@translate_keyId">Referência</span>
<span class="translation" id="description" i18n="description|description@@translate_description">Descrição</span>
<span class="translation" id="name" i18n="name|name@@translate_name">Nome</span>
<span class="translation" id="address" i18n="address|address@@translate_address">Morada</span>
<span class="translation" id="postalCode" i18n="postalCode|postalCode@@translate_postalCode">Cód. Postal</span>
<span class="translation" id="city" i18n="city|city@@translate_city">Cidade</span>
<span class="translation" id="state" i18n="state|state@@translate_state">Estado</span>
<span class="translation" id="country" i18n="country|country@@translate_country">País</span>
<span class="translation" id="balance" i18n="balance|balance@@translate_balance">Balanço</span>
<span class="translation" id="creationDate" i18n="creationDate|creationDate@@translate_creationDate">Data Criação</span>
<span class="translation" id="documentTypeId" i18n="documentTypeId|documentTypeId@@translate_documentTypeId">Documento</span>
<span class="translation" id="priceTableType" i18n="priceTableType|priceTableType@@translate_priceTableType">Tipo</span>
<span class="translation" id="startDate" i18n="startDate|startDate@@translate_startDate">Data Inicial</span>
<span class="translation" id="endDate" i18n="endDate|endDate@@translate_endDate">Data Final</span>
<span class="translation" id="past_month" i18n="past_month|past_month@@translate_past_month">Mês Passado</span>
<span class="translation" id="current_month" i18n="current_month|current_month@@translate_current_month">Mês Atual</span>
<span class="translation" id="sales" i18n="sales|sales@@translate_sales">Vendas</span>
<span class="translation" id="quantity" i18n="quantity|quantitytranslate_quantity">Quantidade</span>
<span class="translation" id="totalIncome" i18n="totalIncome|totalIncome@@translate_totalIncome">Total Bruto</span>
<span class="translation" id="totalNetAmount" i18n="totalNetAmount|totalNetAmount@@translate_totalNetAmount">Total Líquido</span>
<span class="translation" id="totalTaxAmount" i18n="totalTaxAmount|totalTaxAmount@@translate_totalTaxAmount">Total Impostos</span>
<span class="translation" id="discount" i18n="discount|discount@@translate_discount">Desconto</span>
<span class="translation" id="globalDiscount" i18n="globalDiscount|globalDiscount@@translate_globalDiscount">Desconto Global</span>

<span class="translation" id="January" i18n="January|January@@translate_january">Janeiro</span>
<span class="translation" id="February" i18n="February|February@@translate_february">Fevereiro</span>
<span class="translation" id="March" i18n="March|March@@translate_march">Março</span>
<span class="translation" id="April" i18n="April|April@@translate_april">Abril</span>
<span class="translation" id="May" i18n="May|May@@translate_may">Maio</span>
<span class="translation" id="June" i18n="June|June@@translate_june">Junho</span>
<span class="translation" id="July" i18n="July|July@@translate_july">Julho</span>
<span class="translation" id="August" i18n="August|August@@translate_august">Agosto</span>
<span class="translation" id="September" i18n="September|September@@translate_september">Setembro</span>
<span class="translation" id="October" i18n="October|October@@translate_october">Outubro</span>
<span class="translation" id="November" i18n="November|November@@translate_november">Novembro</span>
<span class="translation" id="December" i18n="December|December@@translate_december">Dezembro</span>
<!-- Errors -->
<span class="translation" id="error_ConflictingItem" i18n="ConflictingItem|Error Conflicting Item@@translate_error_conflictingItem"
  >Já existe um artigo com esta referência</span
>
<span
  class="translation"
  id="error_ConflictingItemGroup"
  i18n="ConflictingItemGroup|Error Conflicting ItemGroup@@translate_error_conflictingItemGroup"
  >Já existe uma família com esta referência</span
>
<span
  class="translation"
  id="error_ConflictingCustomer"
  i18n="ConflictingCustomer|Error Conflicting Customer@@translate_error_conflictingCustomer"
  >Já existe um clientes com esta referência</span
>
<span
  class="translation"
  id="error_ConflictingPriceTable"
  i18n="ConflictingPriceTable|Error Conflicting Price Table@@translate_error_conflictingPriceTable"
  >Já existe uma tabela de preços com esta referência</span
>
<span class="translation" id="error_StoreList" i18n="NoStoresAvailable|No stores available@@translate_error_storeList"
  >Não existem lojas válidas para esta entidade</span
>
<span
  class="translation"
  id="error_FectchingStoreList"
  i18n="FailedFetchingStores|Failed fetching stores@@translate_error_fectchingStoreList"
  >Erro a obter lojas</span
>
<span class="translation" id="error_missingTime" i18n="MissingTime|Missing time@@translate_error_missingTime"
  >Por favor insira uma hora válida</span
>
<span class="translation" id="error_missingDate" i18n="MissingDate|Missing date@@translate_error_missingDate"
  >Por favor insira uma data válida</span
>
<span class="translation" id="error_wrongStartDate" i18n="WrongStartDate|Wrong start date@@translate_error_wrongStartDate"
  >Data inicial não pode ser posterior à data final</span
>
<span class="translation" id="error_wrongStartTime" i18n="WrongStartTime|Wrong start time@@translate_error_wrongStartTime"
  >Hora inicial não pode ser posterior à Hora final</span
>
<span class="translation" id="error_wrongEndDate" i18n="WrongEndDate|Wrong end date@@translate_error_wrongEndDate"
  >Data final tem que ser posterior à data inicial</span
>
<span class="translation" id="error_wrongEndTime" i18n="WrongEndTime|Wrong end time@@translate_error_wrongEndTime"
  >Hora final tem que ser posterior à hora inicial</span
>
<span class="translation" id="error_requiredPassword" i18n="PasswordRequired|Password is required@@translate_error_requiredPassword"
  >Por favor, insira uma senha</span
>
<span class="translation" id="error_wrongPassword" i18n="InvalidPassword|Invalid password@@translate_error_wrongPassword"
  >Senha Inválida</span
>
<!-- Not Found Errors -->
<span class="translation" id="notfound_Customer" i18n="CustomerNotFound|Customer not found@@translate_notFound_customer"
  >Entidade não encontrada</span
>
<span class="translation" id="notfound_Item" i18n="ItemNotFound|Item not found@@translate_notFound_item">Artigo não encontrado</span>
<span class="translation" id="notfound_Items" i18n="ItemsNotFound|Items not found@@translate_notFound_items"
  >Não existem artigos válidos</span
>
<span class="translation" id="notfound_ItemGroups" i18n="ItemGroupsNotFound|Item groups not found@@translate_notfound_itemGroups"
  >Famílias não encontradas</span
>
<span class="translation" id="notfound_ItemGroup" i18n="ItemGroupNotFound|Item group not found@@translate_notfound_itemGroup"
  >Família não foi encontrada</span
>
<span class="translation" id="notfound_Taxes" i18n="TaxesNotFound|Taxes not found@@translate_notFound_Taxes">Taxas não encontradas</span>
<span class="translation" id="notfound_PriceTable" i18n="PriceTableNotFound|Price table not found@@translate_notfound_priceTable"
  >Tabela de Preço não encontrada</span
>
<span class="translation" id="notfound_Doc" i18n="DocNotFound|Document not found@@translate_notFound_Doc">Documento não encontrado</span>
<span
  class="translation"
  id="notfound_ItemAccumulated"
  i18n="ItemAccumulatedNotFound|Item Accumulated not found@@translate_notfound_ItemAccumulated"
  >Tabela de Artigos Acumulados não encontrada</span
>
<span
  class="translation"
  id="notfound_ItemGroupAccumulatedTotal"
  i18n="ItemGroupAccumulatedNotFound|Item Group Accumulated not found@@translate_notfound_ItemGroupAccumulatedTotal"
  >Tabela de Famílias Acumuladas não encontrada</span
>
<span
  class="translation"
  id="notfound_SalesmanAccumulatedTotal"
  i18n="SalesmanNotFound|Salesman Accumulated not found@@translate_notfound_SalesmanAccumulatedTotal"
  >Tabela de Vendedores Acumulados não encontrada</span
>
<span class="translation" id="notfound_Sales" i18n="SalesNotFound|Sales not found@@translate_notFound_Sales">Nenhuma venda encontrada</span>

<!-- Customer -->
<span class="translation" id="entityDescription" i18n="entityDescription|entityDescription@@translate_entityDescription">Cliente</span>
<span class="translation" id="sucess_CustomerAdd" i18n="CustomerAdded|Customer added successfully@@translate_success_customerAdd"
  >Cliente adicionado com sucesso</span
>
<span class="translation" id="sucess_CustomerEdit" i18n="CustomerEdited|Customer edited successfully@@translate_success_customerEdit"
  >Cliente editado com sucesso</span
>
<span class="translation" id="none" i18n="CreditTypeNone|Credit type none@@translate_none">Nenhum</span>
<span class="translation" id="amount" i18n="CreditTypeAmount|Credit type amount@@translate_amount">Valor</span>
<span class="translation" id="duedays" i18n="CreditTypeDueDate|Credit type due days@@translate_duedays">Dias</span>
<span
  class="translation"
  id="amountAndDueDays"
  i18n="CreditTyperAmountAndDueDate|Credit type amount and due days@@translate_amountAndDueDay"
  >Valor e Dias</span
>
<span class="translation" id="amountOrDueDays" i18n="CreditTypeAmoutOrDueDate|Credit type amount or due days@@translate_amountOrDueDays"
  >Valor ou Dias</span
>
<span class="translation" id="customerAddress" i18n="CustomerAddress|Customer Address@@translate_customerAddress">Morada</span>
<span class="translation" id="customerPostalCode" i18n="CustomerPostalCode|Customer Postal Code@@translate_customerPostalCode"
  >Cod. Postal</span
>
<span class="translation" id="customerVat" i18n="CustomerVat|Customer Vat@@translate_customerVat">Nº Contribuinte</span>
<span class="translation" id="customerBalance" i18n="CustomerBalance|Customer Balance@@translate_customerBalance">Saldo</span>
<span class="translation" id="vat" i18n="vat|vat@@translate_vat">Nº Contribuinte</span>

<span class="translation" id="ItemsSummary_topTitle" i18n="customersSummaryTopTitle|Customers Summary Top Title@@customersSummary_topTitle"
  >Top Clientes
</span>

<span
  class="translation"
  id="customersSummary_salesTitle"
  i18n="customersSummarySalesTitle|Customers Summary Sales Title@@customersSummary_salesTitle"
  >Vendas Clientes
</span>

<span
  class="translation"
  id="customersSummary_topDebtorsTitle"
  i18n="customersSummaryTopDebtorsTitle|Customers Summary Top Debtors Title@@customersSummary_topDebtorsTitle"
  >Top Devedores</span
>

<!-- Item -->
<span class="translation" id="item" i18n="Item|item@@translate_item">Artigo</span>
<span class="translation" id="itemId" i18n="ItemID|Item Id@@translate_itemID">Id Artigo</span>
<span class="translation" id="sucess_ItemAdd" i18n="ItemAdded|Item added successfully@@translate_success_itemAdd"
  >Artigo adicionado com sucesso</span
>
<span class="translation" id="sucess_ItemEdit" i18n="ItemEdited|Item edited successfully@@translate_success_itemEdit"
  >Artigo editado com sucesso</span
>
<span
  class="translation"
  id="storeSummary_topItemsChartTitle"
  i18n="storeSummaryTopItemsChartTitle|store Summary Top Items Chart Title@@storeSummary_topItemsChartTitle"
  >Top Artigos</span
>

<span class="translation" id="ItemsSummary_bottomTitle" i18n="ItemsSummaryBottomTitle|Items Summary Bottom Title@@ItemsSummary_bottomTitle"
  >Artigos menos vendidos</span
>

<span class="translation" id="itemKeyId" i18n="ItemKeyId|Item key id@@translate_itemKeyId">Referência</span>
<span class="translation" id="itemType" i18n="ItemType|Item type@@translate_itemType">Tipo de item</span>
<span class="translation" id="itemDescription" i18n="ItemDescription|Item description@@translate_itemDescription">Descrição</span>
<!-- Item: ItemTypes -->
<span class="translation" id="ItemType_Normal" i18n="ItemTypeNormal|Item type normal@@translate_itemType_normal">Normal</span>
<span class="translation" id="ItemType_Weight" i18n="ItemTypeWeight|Item type weight@@translate_itemType_weight">Peso</span>
<span class="translation" id="ItemType_Time" i18n="ItemTypeTime|Item type time@@translate_itemType_time">Tempo</span>
<span class="translation" id="ItemType_Complement" i18n="ItemTypeComplement|Item type complement@@translate_itemType_complement"
  >Complemento</span
>
<span class="translation" id="ItemType_Menu" i18n="ItemTypeMenu|Item type menu@@translate_itemtype_menu">Menu</span>
<span class="translation" id="ItemType_Service" i18n="ItemTypeService|Item type service@@translate_itemType_service">Serviço</span>
<span class="translation" id="ItemType_Pizza" i18n="ItemTypePizza|Item type pizza@@translate_itemType_pizza">Pizza</span>
<span
  class="translation"
  id="ItemType_PizzaSubtitle"
  i18n="ItemTypePizzaSubtitle|Item type pizza subtitle@@translate_itemType_pizzaSubtitle"
  >Pizza subtitle</span
>
<span
  class="translation"
  id="ItemType_ComplementScreen"
  i18n="ItemTypeComplementScreen|Item type complement screen@@translate_itemType_complementScreen"
  >Complemento de ecrã</span
>
<span class="translation" id="ItemType_Advance" i18n="ItemTypeAdvance|Item type advance@@translate_itemType_advance">Adiantamento</span>
<span
  class="translation"
  id="ItemType_ShipmentCosts"
  i18n="ItemTypeShippingCosts|Item type Shipping Costs @@translate_itemType_ShipmentCosts"
  >Portes de envio</span
>
<span class="translation" id="ItemType_Fractionated" i18n="ItemTypeFractionated|Item type fractionated@@translate_itemType_fractionated"
  >Fracionado</span
>
<span class="translation" id="ItemType_comment" i18n="ItemTypeComment|Item type comment@@translate_itemType_comment">Comentário</span>
<span
  class="translation"
  id="ItemType_ReturnablePackage"
  i18n="ItemTypeReturnablePackage|Item type returnable package@@translate_itemType_returnablePackage"
  >Embalagem retornável</span
>
<span
  class="translation"
  id="ItemType_InsuranceDeductible"
  i18n="ItemTypeInsuranceDeductible|Item type insurance deductible@@translate_itemType_insuranceDeductible"
  >Franquia</span
>
<span class="translation" id="ItemType_EcoTax" i18n="ItemTypeEcoTax|Item type EcoTax@@translate_itemType_ecoTax"
  >Imposto, taxa ou encargo parafiscal</span
>
<span class="translation" id="ItemType_CashIn" i18n="ItemTypeCahsIn|Item type cash in@@translate_itemType_cashIn">Entrada de Caixa</span>
<span class="translation" id="ItemType_CashOut" i18n="ItemTypeCashOut|Item type cash out@@translate_itemType_cashOut">Saída de Caixa</span>
<span class="translation" id="ItemType_Composite" i18n="ItemTypeComposite|Item type composite@@translate_itemType_composite">Composto</span>
<span class="translation" id="ItemType_Contract" i18n="ItemTypeContract|Item type contract@@translate_itemType_contract">Contrato</span>
<span
  class="translation"
  id="ItemType_TaxFreeSurety"
  i18n="ItemTypeTaxFreeSurety|Item type tax free surety@@translate_itemType_taxFreeSurety"
  >Caução Tax Free</span
>
<!-- Item: Barcode Type -->
<span class="translation" id="BarcodeType_Normal" i18n="BarcodeTypeNormal|Barcode type normal@@translate_barcodeType_normal">Normal</span>
<span class="translation" id="BarcodeType_Value" i18n="BarcodeTypeValue|Barcode type value@@translate_barcodeType_value"
  >Valor Incluído</span
>
<span class="translation" id="BarcodeType_Quantity" i18n="BarcodeTypeQuantity|Barcode type quantity@@translate_barcodeType_quantity"
  >Peso Incluído</span
>
<span class="translation" id="BarcodeType_Total" i18n="BarcodeTypeTotal|Barcode type total@@translate_barcodeType_total">Valor Total</span>
<span class="translation" id="BarcodeType_Attributes" i18n="BarcodeTypeAttributes|Barcode type attributes@@translate_barcodeType_attributes"
  >Atributos</span
>
<!-- ItemGroup -->
<span class="translation" id="itemGroup" i18n="ItemGroup|Item group@@translate_itemGroup">Família</span>
<span class="translation" id="itemGroupId" i18n="ItemGroupId|Item group id@@translate_itemGroupId">Id Família</span>
<span class="translation" id="idParent" i18n="idParent|Id parent@@translate_IdParent">Id Pai</span>
<span class="translation" id="extraDescription" i18n="ExtraDescription|Extra description@@translate_extraDescription">Descrição extra</span>
<span class="translation" id="sucess_ItemGroupAdd" i18n="ItemGroupAdded|Item group added successfully@@translate_sucess_itemGroupAdd"
  >Família adicionada com sucesso</span
>
<span class="translation" id="sucess_ItemGroupEdit" i18n="ItemGroupEdited|Item group edited successfully@@translate_sucess_itemGroupEdit"
  >Família editada com sucesso</span
>
<!-- PriceTable -->
<span class="translation" id="sucess_PriceTableAdd" i18n="PriceTableAdded|Price table added successfully@@translate_sucess_priceTableAdd"
  >Tabela de Preço adicionada com sucesso</span
>
<span
  class="translation"
  id="sucess_PriceTableEdit"
  i18n="PriceTableEdited|Price table edited successfully@@translate_sucess_priceTableEdit"
  >Tabela de Preço editada com sucesso</span
>
<span class="translation" id="itemPriceLineId" i18n="PriceTableLineId|Item price line id@@translate_itemPriceLineId">Linha de Preço</span>
<span class="translation" id="Purchase" i18n="Purchase|Purchase@@translate_purchase">Preço de Custo</span>
<span class="translation" id="PriceLine1" i18n="PriceLine1|Price line 1@@translate_priceLine1">Linha de Preço 1</span>
<span class="translation" id="PriceLine2" i18n="PriceLine2|Price line 2@@translate_priceLine2">Linha de Preço 2</span>
<span class="translation" id="PriceLine3" i18n="PriceLine3|Price line 3@@translate_priceLine3">Linha de Preço 3</span>
<span class="translation" id="PriceLine4" i18n="PriceLine4|Price line 4@@translate_priceLine4">Linha de Preço 4</span>
<span class="translation" id="PriceLine5" i18n="PriceLine5|Price line 5@@translate_priceLine5">Linha de Preço 5</span>
<!-- Sync-Notifications -->
<span class="translation" id="Send" i18n="SyncNotificationsSend|Sync Notifications Send@@translate_send">Enviar</span>
<span class="translation" id="Cancel_Sync" i18n="SyncNotificationsCancelSync|Sync Notifications Cancel Sync|@@translate_cancelSync"
  >Cancelar Sincronização</span
>
<span class="translation" id="Verification?" i18n="SyncNotificationsVerification|Sync Notifications Verification@@translate_verification"
  >Tem a certeza?</span
>
<span class="translation" id="Clients" i18n="SyncNotificationsClients|Sync Notifications Clients@@translate_clients">Clientes</span>
<span class="translation" id="items" i18n="SyncNotificationsItems|Sync Notifications Items@@translate_items">Artigos</span>
<span class="translation" id="Families" i18n="SyncNotificationsItemGroups|Sync Notifications Item Groups@@translate_families"
  >Famílias</span
>
<span class="translation" id="itemgroup" i18n="SyncNotificationsItemGroups|Sync Notifications Item Groups@@translate_families"
  >Famílias</span
>

<span class="translation" id="PriceTables" i18n="SyncNotificationsPriceTables|Sync Notifications Price Tables@@translate_priceTable"
  >Tabelas de preço</span
>
<span class="translation" id="pricetable" i18n="SyncNotificationsPriceTables|Sync Notifications Price Tables@@translate_priceTable"
  >Tabelas de preço</span
>

<span class="translation" id="Sync" i18n="SyncNotificationsSync|Sync Notifications Sync@@translate_sync">Sincronização</span>
<!-- Sync Notifications From Middleware -->
<span
  class="translation"
  id="syncNotificationCustomerOrigin"
  i18n="SyncNotificationCustomerOrigin|Sync Notification Customer Origin@@translate_syncNotificationCustomerOrigin"
  >Loja: [[0]]</span
>
<span
  class="translation"
  id="syncNotificationCustomerSubject"
  i18n="SyncNotificationCustomerSubject|Sync Notification Customer Subject@@translate_syncNotificationCustomerSubject"
  >Sincronização de cliente</span
>
<span
  class="translation"
  id="syncNotificationCustomerContent"
  i18n="SyncNotificationCustomerContent|Sync Notification Customer Content@@translate_syncNotificationCustomerContent"
  >O Cliente em questão não foi sincronizado com o serviço local. Pretende repetir o envio?</span
>
<span
  class="translation"
  id="syncNotificationCustomerSummary"
  i18n="SyncNotificationCustomerSummary|Sync Notification Customer Summary@@translate_syncNotificationCustomerSummary"
  >Cliente [[0]] não sincronizado.</span
>
<span
  class="translation"
  id="syncNotificationItemOrigin"
  i18n="SyncNotificationItemOrigin|Sync Notification Item Origin@@translate_syncNotificationItemOrigin"
  >Loja: [[0]]</span
>
<span
  class="translation"
  id="syncNotificationItemContent"
  i18n="SyncNotificationItemContent|Sync Notification Item Content@@translate_syncNotificationItemContent"
  >O Artigo em questão não foi sincronizado com o serviço local. Pretende repetir o envio?</span
>
<span
  class="translation"
  id="syncNotificationItemSubject"
  i18n="SyncNotificationItemSubject|Sync Notification Item Subject@@translate_syncNotificationItemSubject"
  >Sincronização de artigo</span
>
<span
  class="translation"
  id="syncNotificationItemSummary"
  i18n="SyncNotificationItemSummary|Sync Notification Item Summary@@translate_syncNotificationItemSummary"
  >Artigo [[0]] não sincronizado.</span
>
<span
  class="translation"
  id="syncNotificationItemGroupOrigin"
  i18n="SyncNotificationItemGroupOrigin|Sync Notification Item Group Origin@@translate_syncNotificationItemGroupOrigin"
  >Loja: [[0]]</span
>
<span
  class="translation"
  id="syncNotificationItemGroupContent"
  i18n="SyncNotificationItemGroupContent|Sync Notification Item Group Content@@translate_syncNotificationItemGroupContent"
  >A Família em questão não foi sincronizado com o serviço local. Pretende repetir o envio?</span
>
<span
  class="translation"
  id="syncNotificationItemGroupSubject"
  i18n="SyncNotificationItemGroupSubject|Sync Notification Item Group Subject@@translate_syncNotificationItemGroupSubject"
  >Sincronização de família</span
>
<span
  class="translation"
  id="syncNotificationItemGroupSummary"
  i18n="SyncNotificationItemGroupSummary|Sync Notification Item Group Summary@@translate_syncNotificationItemGroupSummary"
  >Família [[0]] não sincronizada.</span
>
<span
  class="translation"
  id="syncNotificationPriceTableOrigin"
  i18n="SyncNotificationPriceTableOrigin|Sync Notification Price Table Origin@@translate_syncNotificationPriceTableOrigin"
  >Loja: [[0]]</span
>
<span
  class="translation"
  id="syncNotificationPriceTableContent"
  i18n="SyncNotificationPriceTableContent|Sync Notification Price Table Content@@translate_syncNotificationPriceTableContent"
  >A Tabela de preço em questão não foi sincronizado com o serviço local. Pretende repetir o envio?</span
>
<span
  class="translation"
  id="syncNotificationPriceTableSubject"
  i18n="SyncNotificationPriceTableSubject|Sync Notification Price Table Subject@@translate_syncNotificationPriceTableSubject"
  >Sincronização de tabela de preço</span
>
<span
  class="translation"
  id="syncNotificationPriceTableSummary"
  i18n="SyncNotificationPriceTableSummary|Sync Notification Price Table Summary@@translate_syncNotificationPriceTableSummary"
  >Tabela de Preço [[0]] não sincronizada.</span
>
<!-- Paginator -->
<span class="translation" id="itemsPerPage" i18n="ItemsPerPageLabel|Items per Page label paginator@@translate_itemsPerPage"
  >Itens por página</span
>
<span class="translation" id="firstPage" i18n="firstPageButtonLabel|First Page Button label paginator@@translate_firstPage"
  >Primeira página</span
>
<span class="translation" id="lastPage" i18n="lastPageButtonLabel|Last Page Button label paginator@@translate_lastPage">Última página</span>
<span class="translation" id="nextPage" i18n="nextPageButtonLabel|Next Page Button label paginator@@translate_nextPage"
  >Próxima página</span
>
<span class="translation" id="previousPage" i18n="previousPageButtonLabel|Previous Page Button label paginator@@translate_previousPage"
  >Página anterior</span
>
<span class="translation" id="rangeConector" i18n="rangeConectorButtonLabel|Range Conector label paginator@@translate_rangeConector"
  >[[0]] de [[1]]</span
>
<!-- AccumulatedContainer -->
<span
  class="translation"
  id="last_week"
  i18n="AccumulatedTotalsDateParametersLastWeek|Accumulated Totals Date Parameters Last Week@@translate_DateParametersLastWeek"
  >Última Semana</span
>
<span
  class="translation"
  id="last_month"
  i18n="AccumulatedTotalsDateParametersLastMonth|Accumulated Totals Date Parameters Last Month@@translate_DateParametersLastMonth"
  >Último Mês</span
>
<span
  class="translation"
  id="last_year"
  i18n="AccumulatedTotalsDateParametersLastYear|Accumulated Totals Date Parameters Last Year@@translate_DateParametersLastYear"
  >Último Ano</span
>
<span
  class="translation"
  id="last_3_years"
  i18n="AccumulatedTotalsDateParametersLast3Years|Accumulated Totals Date Parameters Last 3 Years@@translate_DateParametersLast3Years"
  >Últimos 3 anos</span
>
<span
  class="translation"
  id="last_5_years"
  i18n="AccumulatedTotalsDateParametersLast5Years|Accumulated Totals Date Parameters Last 5 Years@@translate_DateParametersLast5Years"
  >Últimos 5 anos</span
>
<span
  class="translation"
  id="default"
  i18n="AccumulatedTotalsDateParametersDefault|Accumulated Totals Date Parameters Default@@translate_DateParametersDefault"
  >Padrão</span
>
<span
  class="translation"
  id="customized"
  i18n="AccumulatedTotalsDateParametersCustomized|Accumulated Totals Date Parameters Customized@@translate_DateParametersCustomized"
  >Personalizado</span
>
<!-- -->
<span class="translation" id="authdialogdescription" i18n="AuthDialogDescription|Auth Dialog Description@@translate_AuthDialogDescription"
  >Login Utilizador</span
>
<!-- StoreSummary -->
<span class="translation" id="allStores" i18n="StoreSummaryAllStores|Store Summary All Stores@@translate_allStores">Todas</span>
<span
  class="translation"
  id="yesterday"
  i18n="StoreSummaryYesterdaysTableColumn|Store Summary Yesterdays Table Column@@translate_yesterdaysTableColumn"
  >Ontem</span
>
<span
  class="translation"
  id="last_15_days"
  i18n="StoreSummaryDateParametersLast15Days|Store Summary Date Parameters Last 15 Days@@translate_DateParametersLast15Days"
  >Últimos 15 dias</span
>
<span
  class="translation"
  id="last_30_days"
  i18n="StoreSummaryDateParametersLast30Days|Store Summary Date Parameters Last 30 Days@@translate_DateParametersLast30Days"
  >Últimos 30 dias</span
>
<span
  class="translation"
  id="last_60_days"
  i18n="StoreSummaryDateParametersLast60Days|Store Summary Date Parameters Last 60 Days@@translate_DateParametersLast60Days"
  >Últimos 60 dias</span
>
<span
  class="translation"
  id="last_3_months"
  i18n="StoreSummaryDateParametersLast3Months|Store Summary Date Parameters Last 3 Months@@translate_DateParametersLast3Months"
  >Últimos 3 meses</span
>
<span
  class="translation"
  id="last_6_months"
  i18n="StoreSummaryDateParametersLast6Months|Store Summary Date Parameters Last 6 Months@@translate_DateParametersLast6Months"
  >Últimos 6 meses</span
>
<span
  class="translation"
  id="last_12_months"
  i18n="StoreSummaryDateParametersLast12Months|Store Summary Date Parameters Last 12 Months@@translate_DateParametersLast12Months"
  >Últimos 12 meses</span
>
<span
  class="translation"
  id="last_24_months"
  i18n="StoreSummaryDateParametersLast24Months|Store Summary Date Parameters Last 24 Months@@translate_DateParametersLast24Months"
  >Últimos 24 meses</span
>
<span
  class="translation"
  id="last_10_years"
  i18n="StoreSummaryDateParametersLast10years|Store Summary Date Parameters Last 10 Years@@translate_DateParametersLast10Years"
  >Últimos 10 anos</span
>
<span class="translation" id="ever" i18n="StoreSummaryDateParametersEver|Store Summary Date Parameters Ever@@translate_DateParametersEver"
  >Desde sempre</span
>

<span class="translation" id="date" i18n="StoreSummaryDateHeader|Store Summary Date Header@@translate_dateHeader">Data</span>
<span class="translation" id="qnt" i18n="StoreSummaryQuantityHeader|Store Summary Quantity Header@@translate_quantityHeader"
  >Quantidade</span
>
<span class="translation" id="total" i18n="StoreSummaryTotalHeader|Store Summary Total Header@@translate_totalHeader">Total</span>
<span class="translation" id="totalNet" i18n="StoreSummaryTotalNetHeader|Store Summary Total Net Header@@translate_totalNetHeader"
  >Total Líquido</span
>
<span
  class="translation"
  id="totalDiscounts"
  i18n="StoreSummaryTotalDiscountsHeader|Store Summary Total Discounts Header@@translate_totalDiscountsHeader"
  >Total de Descontos</span
>
<span
  class="translation"
  id="totalDiscountsNet"
  i18n="StoreSummaryTotalDiscountsNetHeader|Store Summary Total Discounts Net Header@@translate_totalDiscountsNetHeader"
  >Total Líquido de Descontos</span
>
<span class="translation" id="cost" i18n="StoreSummaryCostHeader|Store Summary Cost Header@@translate_costHeader">Custos</span>
<span class="translation" id="costNet" i18n="StoreSummaryCostNetHeader|Store Summary Cost Net Header@@translate_costNetHeader"
  >Custo Líquido</span
>
<span class="translation" id="profit" i18n="StoreSummaryProfitHeader|Store Summary Profit Header@@translate_profitHeader">Lucro</span>
<span class="translation" id="profitNet" i18n="StoreSummaryLucroNetHeader|Store Summary Lucro Net Header@@translate_lucroNetHeader"
  >Lucro Líquido</span
>

<span class="translation" id="storeId" i18n="StoreSummaryStoreIdHeader|Store Summary Store Id Header@@translate_storeIdHeader"
  >Id Loja</span
>
<span class="translation" id="storeName" i18n="StoreSummaryStoreNameHeader|Store Summary Store Name Header@@translate_storeNameHeader"
  >Loja</span
>
<span class="translation" id="salesmanId" i18n="StoreSummarySalesmanIdHeader|Store Summary Salesman Id Header@@translate_salesmanIdHeader"
  >Id Vendedor</span
>
<span
  class="translation"
  id="salesmanName"
  i18n="StoreSummarySalesmanNameHeader|Store Summary Salesman Name Header@@translate_salesmanNameHeader"
  >Vendedor</span
>
<span class="translation" id="itemName" i18n="StoreSummaryItemNameHeader|Store Summary Item Name Header@@translate_itemNameHeader"
  >Artigo</span
>
<span class="translation" id="terminalId" i18n="StoreSummaryTerminalIdHeader|Store Summary Terminal Id Header@@translate_terminalIdHeader"
  >Id Terminal</span
>
<span
  class="translation"
  id="terminalDescription"
  i18n="StoreSummaryTerminalDescriptionHeader|Store Summary Terminal Description Header@@translate_terminalDescriptionHeader"
  >Terminal</span
>
<span class="translation" id="groupId" i18n="StoreSummaryGroupIdHeader|Store Summary Group Id Header@@translate_groupIdHeader"
  >Id Família</span
>
<span class="translation" id="groupName" i18n="StoreSummaryGroupNameHeader|Store Summary Group Name Header@@translate_groupNameHeader"
  >Família</span
>

<span class="translation" id="Today" i18n="StoreSummaryTodayCard|Store Summary Today Card Description@@translate_todayCard">Hoje</span>
<span
  class="translation"
  id="YesterdayTooltip"
  i18n="StoreSummaryYesterdayCard|Store Summary Yesterday Card Tooltip@@translate_YesterdayCardTooltip"
  >Comparado com o dia ([[0]])</span
>
<span class="translation" id="LastWeek" i18n="StoreSummaryLastWeekCard|Store Summary Last Week Card Description@@translate_lastWeekCard"
  >Últimos 7 dias</span
>
<span
  class="translation"
  id="LastWeekTooltip"
  i18n="StoreSummaryLastWeekCard|Store Summary Last Week Card Tooltip@@translate_lastWeekCardTooltip"
  >Comparado com os 7 dias anteriores</span
>
<span
  class="translation"
  id="CurrentMonth"
  i18n="StoreSummaryCurrentMonthCard|Store Summary Current Month Card Description@@translate_CurrentMonthCard"
  >Mês Atual</span
>
<span
  class="translation"
  id="CurrentMonthTooltip"
  i18n="StoreSummaryCurrentMonthCard|Store Summary Current Month Card Tooltip@@translate_CurrentMonthCardTooltip"
  >Comparado com o mês anterior até ao dia ([[0]])</span
>
<span
  class="translation"
  id="FirstQuarter"
  i18n="StoreSummaryFirstQuarterCard|Store Summary First Quarter Card Description@@translate_firstQuarterCard"
  >1º Trimestre | Q1</span
>
<span
  class="translation"
  id="FirstQuarterTooltip"
  i18n="StoreSummaryFirstQuarterCard|Store Summary First Quarter Card Tooltip@@translate_firstQuarterCardTooltip"
  >Comparado com o 1º Trimestre do ano anterior</span
>
<span
  class="translation"
  id="SecondQuarter"
  i18n="StoreSummarySecondQuarterCard|Store Summary Second Quarter Card Description@@translate_secondQuarterCard"
  >2º Trimestre | Q2</span
>
<span
  class="translation"
  id="SecondQuarterTooltip"
  i18n="StoreSummarySecondQuarterCard|Store Summary Second Quarter Card Tooltip@@translate_secondQuarterCardTooltip"
  >Comparado com o 2º Trimestre do ano anterior</span
>
<span
  class="translation"
  id="ThirdQuarter"
  i18n="StoreSummaryThirdQuarterCard|Store Summary Third Quarter Card Description@@translate_thirdQuarterCard"
  >3º Trimestre | Q3</span
>
<span
  class="translation"
  id="ThirdQuarterTooltip"
  i18n="StoreSummaryThirdQuarterCard|Store Summary Third Quarter Card Tooltip@@translate_thirdQuarterCardTooltip"
  >Comparado com o 3º Trimestre do ano anterior</span
>
<span
  class="translation"
  id="FourthQuarter"
  i18n="StoreSummaryFourthQuarterCard|Store Summary Fourth Quarter Card Description@@translate_fourthQuarterCard"
  >4º Trimestre | Q4</span
>
<span
  class="translation"
  id="FourthQuarterTooltip"
  i18n="StoreSummaryFourthQuarterCard|Store Summary Fourth Quarter Card Tooltip@@translate_fourthQuarterCardTooltip"
  >Comparado com o 4º Trimestre do ano anterior</span
>
<span
  class="translation"
  id="ItemsSummary_itemTopStoresTitle"
  i18n="ItemsSummaryItemTopStoresTitle|Items summary item top stores title@@ItemsSummary_itemTopStoresTitle"
  >Top Lojas</span
>
<!-- Movements -->

<span class="translation" id="day" i18n="MovementsTableColumn|Movements day Table Column@@translate_dayTableColumn">Dia</span>
<span class="translation" id="movements_title" i18n="MovementsTitle|Movements Title@@movements_title">Movimentos</span>

<!-- Employee Activity -->

<span
  class="translation"
  id="employee"
  i18n="EmployeeActivityEmployeeTableColumn|Employee Activity Employee Table Column@@translate_employeeTableColumn"
  >Funcionário</span
>
<span
  class="translation"
  id="startTime"
  i18n="EmployeeActivityStartTimeTableColumn|Employee Activity StartTime Table Column@@translate_startTimeTableColumn"
  >Entradas</span
>
<span
  class="translation"
  id="endTime"
  i18n="EmployeeActivityEndTimeTableColumn|Employee Activity EndTime Table Column@@translate_endTimeTableColumn"
  >Saídas</span
>
<span
  class="translation"
  id="totalTime"
  i18n="EmployeeActivityTotalTimeTableColumn|Employee Activity TotalTime Table Column@@translate_totalTimeTableColumn"
  >Tempo Total</span
>
<span class="translation" id="emptyDescription" i18n="EmptyDescription|Empty description@@translate_emptyDescription">Sem descrição</span>
<span class="translation" id="netPriceInfo" i18n="NetPriceInfo|Net price info@@translate_netPriceInfo">s/IVA</span>
<span class="translation" id="datePickerStartDate" i18n="DatePickerStartDate|Date picker start date@@translate_datePickerStartDate"
  >Data inicial</span
>
<span class="translation" id="datePickerEndDate" i18n="DatePickerEndDate|Date picker end date@@translate_datePickerEndDate"
  >Data final</span
>
<span
  class="translation"
  id="datePickerInvalidStartDate"
  i18n="DatePickerInvalidStartDate|Date picker invalid start date@@translate_invalidStarDate"
  >Data inicial inválida</span
>
<span
  class="translation"
  id="datePickerInvalidEndDate"
  i18n="DatePickerInvalidEndDate|Date picker invalid end date@@translate_invalidEndDate"
  >Data final inválida</span
>
<span class="translation" id="daterangePickerlabel" i18n="DateRangePickerLabel|Date range picker label@@translate_dateRangePickerLabel"
  >Intervalo datas</span
>

<span class="translation" id="datePickerCancel" i18n="DatePickerCancel|Date picker cancel selection@@translate_datePickerCancel"
  >Cancelar</span
>
<span class="translation" id="datePickerApply" i18n="DatePickerApply|Date picker apply selection@@translate_datePickerApply"
  >Confirmar</span
>
<span class="translation" id="close" i18n="Close|Close@@translate_close">Fechar</span>
<span class="translation" id="edit" i18n="Edit|Edit@@translate_edit">Editar</span>

<span class="translation" id="weekday_0" i18n="WeekdaySunday|Weekday sunday@@translate_weekday_0">Domingo</span>
<span class="translation" id="weekday_1" i18n="WeekdayMonday|weekday monday@@translate_weekday_1">Segunda</span>
<span class="translation" id="weekday_2" i18n="WeekdayTuesday|Weekday tuesday@@translate_weekday_2">Terça</span>
<span class="translation" id="weekday_3" i18n="WeekdayWednesday|Weekday wednesday@@translate_weekday_3">Quarta</span>
<span class="translation" id="weekday_4" i18n="WeekdayThursday|Weekday Thursday@@translate_weekday_4">Quinta</span>
<span class="translation" id="weekday_5" i18n="WeekdayFriday|Weekday friday@@translate_weekday_5">Sexta</span>
<span class="translation" id="weekday_6" i18n="WeekdaySaturday|Weekday saturday@@translate_weekday_6">Sábado</span>

<span class="translation" id="transactions" i18n="@@translate_transactions">Transações</span>

<span
  class="translation"
  id="onlineSummary_LoadingCalendar"
  i18n="OnlineSummaryLoadingCalendar|Online summary loading calendar@@OnlineSummary_LoadingCalendar"
  >Dados calendário</span
>
<span
  class="translation"
  id="onlineSummary_LoadingDaySales"
  i18n="OnlineSummaryLoadingDaysSales|Online summary loading day sales@@OnlineSummary_LoadingDaySales"
  >Vendas diárias</span
>
<span
  class="translation"
  id="onlineSummary_LoadingTerminalSales"
  i18n="OnlineSummaryLoading|Online summary loading terminal sales@@onlineSummary_LoadingTerminalSales"
  >Vendas por terminal</span
>
<span
  class="translation"
  id="storeSummary_topTerminalsChartTitle"
  i18n="storeSummaryTopTerminalsChartTitle|store Summary Top Terminals Chart Title@@storeSummary_topTerminalsChartTitle"
  >Top Terminais</span
>
<span
  class="translation"
  id="onlineSummary_LoadingPaymentsByTerminal"
  i18n="OnlineSummaryLoading|Online summary loading payments by terminal@@onlineSummary_LoadingPaymentsByTerminal"
  >Pagamentos por terminal</span
>
<span
  class="translation"
  id="onlineSummary_LoadingSalesmenSales"
  i18n="OnlineSummaryLoading|Online summary loading salesmen sales@@onlineSummary_LoadingSalesmenSales"
  >Vendas por vendedor</span
>
<span
  class="translation"
  id="storeSummary_topSalesmanChartTitle"
  i18n="storeSummaryTopSalesmanChartTitle|store Summary Top Salesman Chart Title@@storeSummary_topSalesmanChartTitle"
  >Top Vendedores
</span>

<span
  class="translation"
  id="onlineSummary_LoadingMonthlySales"
  i18n="OnlineSummaryLoading|Online summary loading monthly sales@@onlineSummary_LoadingMonthlySales"
  >Resumo mensal</span
>
<span
  class="translation"
  id="onlineSummary_LoadingHomologousAnalysis"
  i18n="OnlineSummaryLoading|Online summary loading homologous analysis@@onlineSummary_LoadingHomologousAnalysis"
  >Análise homóloga</span
>
<span
  class="translation"
  id="onlineSummary_LoadingCardSales"
  i18n="onlineSummaryLoadingCardSales|Online summary loading card sales@@onlineSummary_LoadingCardSales"
  >Resumo de cartões</span
>
<span
  class="translation"
  id="onlineSummary_LoadingCardSeriesSales"
  i18n="onlineSummaryLoadingCardSerisSales|Online summary loading card series sales@@onlineSummary_LoadingCardSeriesSales"
  >Séries de cartões</span
>
<span
  class="translation"
  id="onlineSummary_LoadingBarSales"
  i18n="onlineSummaryLoadingBarSales|Online summary loading bar sales@@onlineSummary_LoadingBarSales"
  >Vendas por bares</span
>
<span
  class="translation"
  id="storeSummary_monthsChartTitle"
  i18n="storeSummaryMonthsChartTitle|store Summary Months Chart Title@@storeSummary_monthsChartTitle"
  >Vendas por Mês
</span>
<span
  class="translation"
  id="storeSummary_topItemsTableTitle"
  i18n="storeSummaryTopItemsTableTitle|store Summary Top Items Table Title@@storeSummary_topItemsTableTitle"
  >Vendas por Artigos</span
>
<span
  class="translation"
  id="storeSummary_topItemGroupsTableTitle"
  i18n="storeSummaryTopItemGroupsTableTitle|store Summary Top ItemGroups Table Title@@storeSummary_topItemGroupsTableTitle"
  >Vendas por Famílias</span
>
<span
  class="translation"
  id="storeSummary_topItemGroupsChartTitle"
  i18n="storeSummaryTopItemGroupsChartTitle|store Summary Top ItemGroups Chart Title@@storeSummary_topItemGroupsChartTitle"
  >Top Famílias</span
>

<span class="translation" id="employeeActivity_title" i18n="EmployeeActivityTitle|Employee Activity Title@@employeeActivity_title"
  >Registo de Ponto</span
>
<span
  class="translation"
  id="customerSummary_salesChartTitle"
  i18n="customerSummarySalesChart|Customer Summary Sales Chart Title@@customerSummary_salesChartTitle"
  >Resumo de Vendas</span
>
<!-- Exporter -->
<span class="translation" id="exportButton" i18n="ExportDataButton|Export Data Button@@export_data">Exportar</span>

<span class="translation" id="exportTable" i18n="ExportTableButton|Export Table Button@@export_table">Exportar Tabela</span>

<span class="translation" id="exportChart" i18n="ExportChartButton|Export Chart Button@@export_chart">Exportar Gráfico</span>

<span class="translation" id="progressBarDescription" i18n="exportXLSX|Export XLSX with progress description @@exportXLSX">
  de [[0]] até [[1]]</span
>

<span class="translation" id="exportXLSX" i18n="exportXLSX|Export XLSX with progress Title @@exportXLSX">Exportação</span>
<span
  class="translation"
  id="entitiesListings_exportCurrentPageButton"
  i18n="EntitiesListingsExportCurrentPageButton|Entities Listings Export Current Page Button@@entitiesListings_exportCurrentPageButton"
  >Exportar Página
</span>

<span
  class="translation"
  id="entitiesListings_exportAllPageButton"
  i18n="EntitiesListingsExportAllPageButton|Entities Listings Export Export All Page Button@@entitiesListings_exportAllPageButton"
  >Exportar Todos
</span>

<span
class="translation"
id="storeSelection_entityId_conflict_description_item"
i18n="StoreReasonBlocking|Selected store already contains an item with the same key Header@@storeSelection_entityId_conflict_description_item"
  >Loja selecionada já contem um artigo com a mesma chave</span
>

<span
class="translation"
id="storeSelection_entityId_conflict_description_itemGroup"
i18n="StoreReasonBlocking|Selected store already contains an item with the same key Header@@storeSelection_entityId_conflict_description_itemGroup"
  >Loja selecionada já contem um artigo com a mesma chave</span
>

<span
class="translation"
id="storeSelection_entityId_conflict_description_customer"
i18n="StoreReasonBlocking|Selected store already contains a customer with the same key Header@@storeSelection_entityId_conflict_description_customer"
  >Loja selecionada já contem um cliente com a mesma chave</span
>

<span
  class="translation"
  id="selectStoreViewDataGraph"
  i18n="SelectStoreViewDataGraph|Select a store to view data on the graph stores@@storeSelectionDialog_storeSelectionCaption"
  >Selecione uma loja para visualizar dados no gráfico
</span>

<span class="translation" id="DashboardTitleErrorMessage" i18n="DashboardTitleErrorMessage| title error Dashboard@@translate_DashboardTitleErrorMessage"
  >Dashboard Incompleto</span
>

<span class="translation" id="DashboardErrorMessage" i18n="DashboardErrorMessage| message error Dashboard@@translate_DashboardErrorMessage"
  >Reconfigure o resumo online em questão</span
>
