import { Injector, Pipe, PipeTransform, Type } from '@angular/core';

@Pipe({ name: 'signal' })
export class SignalPipe implements PipeTransform {
  constructor(private injector: Injector) {}

  public transform(value: any, formatPipeToken: Type<PipeTransform>, args: any[]): any {
    let result = value;

    try {
      let signal = '';
      let formatPipe: PipeTransform = null;
      const number = Number(value);

      if (formatPipeToken) {
        formatPipe = this.injector.get(formatPipeToken);
      }

      if (value && !isNaN(number)) {
        if (number > 0) {
          signal = `+`;
        }
      }
      if (formatPipe) {
        result = `${signal}${formatPipe.transform(number, ...Array.of(args))}`;
      } else {
        result = `${signal}${number}`;
      }
    } catch {}

    return result;
  }
}
