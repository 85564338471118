import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import {
  DxBoxModule,
  DxBulletModule,
  DxButtonModule,
  DxCalendarModule,
  DxChartModule,
  DxDateBoxModule,
  DxPieChartModule,
  DxPolarChartModule,
  DxPopupModule,
  DxResponsiveBoxModule,
  DxSliderModule,
  DxTemplateModule,
  DxTooltipModule
} from 'devextreme-angular';
import { SharedModule } from '../shared/shared.module';
import { ChartComponent } from './chart/chart.component';
import { DynamicReportComponent } from './dynamic-report/dynamic-report.component';
import { ParametersComponent } from './parameters/parameters.component';
import { ReportComponent } from './report/report.component';
import { WidgetService } from './services/widget.service';
import { StoreComponent } from './store/store.component';
import { WidgetCarouselContainerComponent } from './widget-containers/widget-carousel-container/widget-carousel-container.component';
import { WidgetGridContainerComponent } from './widget-containers/widget-grid-container/widget-grid-container.component';
import { WidgetFactoryService } from './widget-factory';
import { AvatarValueComponent } from './widget-values/avatar/avatar-value.component';
import { BulletValueComponent } from './widget-values/bullet/bullet-value.component';
import { EmphasizeValueComponent } from './widget-values/emphasize/emphasize-value.component';
import { ProgressValueComponent } from './widget-values/progress/progress-value.component';
import { SlideValueComponent } from './widget-values/slide/slide-value.component';
import { TextCellValueComponent } from './widget-values/text-cell/text-cell-value.component';
import { TextValueComponent } from './widget-values/text/text-value.component';
import { EmptyContainerComponent } from './widget-containers/empty-container/empty-container.component';
import { ReportWidgetContainerComponent } from './widget-containers/report-widget-container/report-widget-container.component';
import { ReportWidgetValueComponent } from './report/report-widget-value/report-widget-value.component';

@NgModule({
  declarations: [
    ChartComponent,
    ReportComponent,
    AvatarValueComponent,
    BulletValueComponent,
    EmphasizeValueComponent,
    ProgressValueComponent,
    SlideValueComponent,
    TextValueComponent,
    WidgetCarouselContainerComponent,
    WidgetGridContainerComponent,
    DynamicReportComponent,
    TextCellValueComponent,
    ParametersComponent,
    StoreComponent,
    EmptyContainerComponent,
    ReportWidgetContainerComponent,
    ReportWidgetValueComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,

    DxButtonModule,
    DxTooltipModule,
    DxBulletModule,
    DxBoxModule,
    DxResponsiveBoxModule,
    DxTemplateModule,
    DxSliderModule,
    DxDateBoxModule,
    DxCalendarModule,
    DxPopupModule,
    DxPolarChartModule,
    DxChartModule,
    DxPieChartModule,

    MatGridListModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatListModule,
    MatToolbarModule,
    MatTabsModule,
    MatRadioModule,

    PortalModule,

    SharedModule
  ],
  exports: [
    ChartComponent,
    ReportComponent,
    AvatarValueComponent,
    BulletValueComponent,
    EmphasizeValueComponent,
    ProgressValueComponent,
    SlideValueComponent,
    TextValueComponent,
    WidgetGridContainerComponent,
    WidgetCarouselContainerComponent,
    DynamicReportComponent,
    TextCellValueComponent,
    ParametersComponent,
    StoreComponent
  ],
  providers: [WidgetService, WidgetFactoryService]
})
export class WidgetsModule {}
