import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ValueDataKind } from '../../shared/models/value-data-kind';
import { WidgetValueDisplayKind } from '../models/widget-enums';
import { WidgetValueConfiguration } from '../models/widget-value-configuration';

@Component({ template: '' })
export abstract class WidgetValueComponent {
  @Input()
  public value: any;
  @Input()
  public valueConfig: WidgetValueConfiguration;
  @Output()
  public followLink: EventEmitter<void>;
  public ValueDisplayKind = WidgetValueDisplayKind;
  public ValueDataKind = ValueDataKind;

  constructor() {
    this.followLink = new EventEmitter<void>();
  }

  public onClick(): void {
    if (this.valueConfig.link) {
      this.followLink.emit();
    }
  }
}
