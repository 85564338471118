import { Component, ElementRef, Input, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { ComponentOverlayService } from '../../shared/services/component-overlay/component-overlay.service';
import { SpinnerService } from '../../shared/services/spinner/spinner.service';
import { ICloudWidget } from '../models/cloud-widget';
import { WidgetValueDisplayKind } from '../models/widget-enums';
import { WidgetLayout } from '../models/widget-layout';
import { WidgetPropertyGetter } from '../models/widget-property-getter';
import { WidgetValueConfiguration } from '../models/widget-value-configuration';
import { WidgetService } from '../services/widget.service';
import { WidgetComponent } from '../widget.component';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['../widget.component.scss', './report.component.scss']
})
export class ReportComponent extends WidgetComponent implements OnDestroy {
  @Input() public parametersOverrides: Array<WidgetPropertyGetter>;
  public layout: WidgetLayout;
  public cloudWidget: ICloudWidget;
  public rowspan: number;
  public paginationActive: boolean;

  constructor(
    widgetService: WidgetService,
    elementRef: ElementRef,
    spinnerService: SpinnerService,
    componentOverlayService: ComponentOverlayService
  ) {
    super(widgetService, elementRef, spinnerService, componentOverlayService);
    this.paginationActive = false;
  }

  public ngOnDestroy(): void {
    // Used by the rxjs operator untilDestroy
  }

  private getValueRowSize(valueConfig: WidgetValueConfiguration): number {
    const defaultRowSize = 1.8;
    let rowSize: number;

    switch (valueConfig.displayType) {
      case WidgetValueDisplayKind.avatar:
        rowSize = 4;
        break;
      case WidgetValueDisplayKind.bullet:
        rowSize = 3;
        break;
      case WidgetValueDisplayKind.emphasize:
      case WidgetValueDisplayKind.underemphasize:
        rowSize = 4;
        break;
      case WidgetValueDisplayKind.progress:
        rowSize = defaultRowSize;
        break;
      case WidgetValueDisplayKind.slide:
        rowSize = defaultRowSize;
        break;
      case WidgetValueDisplayKind.text:
        rowSize = defaultRowSize;
        break;
      default:
        rowSize = defaultRowSize;
        break;
    }

    return rowSize;
  }

  public setupWidget(widget$: Observable<ICloudWidget>): Observable<ICloudWidget> {
    return widget$;
  }
  public setupWidgetSize(layout: WidgetLayout, cloudWidget: ICloudWidget): void {
    this.layout = layout;
    this.cloudWidget = cloudWidget;
    let height = this.defaultWidgetHeight;

    if (cloudWidget) {
      this.rowspan = cloudWidget.valuesConfiguration.reduce<number>((acc, val) => {
        const rowSize = Math.max(...val.map(this.getValueRowSize));

        return acc + rowSize;
      }, 0);
      const valuesMultiplier = cloudWidget.values.length === 0 ? 1 : cloudWidget.values.length;

      height = (this.rowspan || 1) * (layout.rowspan || 1) * valuesMultiplier + this.titleSize;

      if (height < this.defaultWidgetHeight) {
        this.paginationActive = false;
      } else {
        this.paginationActive = true;
      }
      height = this.defaultWidgetHeight;
    }
  }
}
