import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MyNotification, MyNotificationAction } from '../../models/notification';
import { NotificationService } from '../../services/notification/notification.service';
import { NotificationShowMoreDialogComponent } from '../notification-show-more-dialog/notification-show-more-dialog.component';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {
  public note: MyNotification;

  constructor(public notificationService: NotificationService, public dialog: MatDialog) {}

  @Input()
  public set notification(value: MyNotification) {
    this.note = value || null;
  }

  public execAction(action: MyNotificationAction): void {
    if (action.actionCallback) {
      action.actionCallback();
      if (action.showMore) {
        this.dialog.open(NotificationShowMoreDialogComponent, {
          data: { notification: this.note, action: action }
        });
      }
      if (action.closes) {
        const groupId = this.note.group == null ? undefined : this.note.group.groupId;
        this.notificationService.deleteNotification(this.note.id, groupId);
      }
    }
  }
}
