/* eslint-disable no-undef */
import { Injectable } from '@angular/core';
import { HammerGestureConfig } from '@angular/platform-browser';

@Injectable()
export class AppHammerGestureConfig extends HammerGestureConfig {
  public overrides = <any>{
    pan: { direction: Hammer.DIRECTION_ALL }
  };

  public option = <HammerOptions>{ domEvents: true };
}
