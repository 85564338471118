import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, ElementRef, ComponentRef } from '@angular/core';

import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { v4 as uuid } from 'uuid';
import { DynamicOverlayService } from '../dynamic-overlay/dynamic-overlay.service';
import { ProgressBarComponent } from '../../components/progress-bar/progress-bar.component';

@Injectable({
  providedIn: 'root'
})
export class ProgressBarService {
  private readonly _dynamicOverlayService: DynamicOverlayService = null;
  private readonly _progressBars: Map<string, { overlay: OverlayRef; progressBar: ProgressBarComponent }> = null;
  private readonly _overlay: Overlay = null;

  public percent: number;
  public stopProgressBar: boolean = false;
  public currentStep: number;
  public elemRef?: ElementRef;

  constructor(dynamicOverlayService: DynamicOverlayService, overlay: Overlay) {
    this._progressBars = new Map<string, { overlay: OverlayRef; progressBar: ProgressBarComponent }>();
    this._dynamicOverlayService = dynamicOverlayService;
    this._overlay = overlay;
  }
  public setProgress(id: string, step: number) {
    if (this._progressBars.has(id)) {
      const progressBar = this._progressBars.get(id).progressBar;
      progressBar.setProgress(step);
    }
  }

  public setDescription(id: string, description: string) {
    if (this._progressBars.has(id)) {
      const progressBar = this._progressBars.get(id).progressBar;
      progressBar.setDescription(description);
    }
  }

  public setTitle(id: string, title: string) {
    if (this._progressBars.has(id)) {
      const progressBar = this._progressBars.get(id).progressBar;
      progressBar.setTitle(title);
    }
  }

  public stopProgress(statusProgressBar: boolean) {
    this.stopProgressBar = statusProgressBar;
  }

  /**
   * Creates a progress bar over the element referenced by elemRef
   *
   * @param elemRef - Element reference where progress bar overlay will be placed
   * @returns progress bar id
   *
   * @remarks Use the returned progress bar id to remove it otherwise it will stay over the elemRef forever
   */
  public createProgressBar(maxValue: number, showBackdrop: boolean, elemRef: ElementRef): string {
    const id: string = uuid();
    this.create(id, maxValue, showBackdrop, elemRef);

    return id;
  }

  /**
   * Removes the progress bar.
   *
   * @param id - The id of the progress bar to be remove.
   */
  public removeProgressBar(id: string): void {
    this.destroy(id);
  }

  private destroy(id: string): void {
    if (this._progressBars.has(id)) {
      const progressBar = this._progressBars.get(id);
      progressBar.overlay.detach();
      progressBar.overlay.dispose();
      this._progressBars.delete(id);
    }
  }

  private create(id: string, maxValue: number, showBackdrop: boolean, elemRef?: ElementRef): void {
    let overlayRef: OverlayRef = null;
    if (elemRef) {
      const positionStrategy = this._dynamicOverlayService.position().global().centerHorizontally().centerVertically();

      this._dynamicOverlayService.setContainerElement(elemRef.nativeElement);
      overlayRef = this._dynamicOverlayService.create({
        positionStrategy: positionStrategy,
        width: '100%',
        hasBackdrop: showBackdrop
      });
    } else {
      overlayRef = this._overlay.create({
        positionStrategy: this._overlay.position().global().centerHorizontally().centerVertically(),
        hasBackdrop: showBackdrop
      });
    }

    const componentRef: ComponentRef<ProgressBarComponent> = overlayRef.attach(new ComponentPortal(ProgressBarComponent));

    componentRef.instance.maxValue = maxValue;
    this._progressBars.set(id, { overlay: overlayRef, progressBar: componentRef.instance });
  }
}
