import { Component } from '@angular/core';
import { WidgetValueComponent } from '../widget-value.component';

@Component({
  selector: 'app-emphasize-value',
  templateUrl: './emphasize-value.component.html',
  styleUrls: ['../widget-value.component.scss', './emphasize-value.component.scss']
})
export class EmphasizeValueComponent extends WidgetValueComponent {
  public get emphasize(): boolean {
    return this.valueConfig.displayType === this.ValueDisplayKind.emphasize;
  }
}
