import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { subYears } from 'date-fns/esm/fp';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CurrencyPipe } from '../../../shared/pipes/Currency.pipe';
import { untilDestroy } from '../../../shared/rxjs/operators/until-destroy';
import { SpinnerService } from '../../../shared/services/spinner/spinner.service';
import { TranslateService } from '../../../shared/services/translate-service/translate.service';
import { ItemAccumulatedTotal } from '../../model/accumulated-totals';
import { AccumulatedTotalsService } from '../../services/accumulated-totals.service';
import { ColumnConfig } from '../../../shared/models/column-config';
import { ValueDataKind } from '../../../shared/models/value-data-kind';
import { ExportData } from '../../../shared/models/export-data';
import { ExportService } from '../../../shared/services/export-service/export.service';
import { DxChartComponent } from 'devextreme-angular';

export enum unnecessaryKeys {
  syncDate = 'syncDate',
  qnt = 'qnt'
}

@Component({
  selector: 'app-stores-chart',
  templateUrl: './stores-chart.component.html',
  styleUrls: ['./stores-chart.component.scss']
})
export class StoresChartComponent implements OnInit, OnDestroy {
  public topStores$: Observable<ItemAccumulatedTotal[]>;
  public topStoresData: ItemAccumulatedTotal[];
  public topStoresDataSource = new MatTableDataSource<ItemAccumulatedTotal>();
  public topStoresTableColumns: string[];
  public topsStoresInterval: Array<{ value: number; description: string }>;
  public selectedStoresDate: {
    value: number;
    description: string;
  };
  @ViewChild('storesChart', { static: false, read: DxChartComponent }) public monthsChartBars: DxChartComponent;
  @ViewChild('storesChart', { static: true, read: ElementRef })
  private storesChart: ElementRef;
  public storesTableVisible = false;
  constructor(
    public currencyPipe: CurrencyPipe,
    private _spinnerService: SpinnerService,
    private _translateService: TranslateService,
    private _accumulatedTotalsService: AccumulatedTotalsService,
    private _exportservice: ExportService
  ) {
    this.customizeTopStoreTooltip = this.customizeTopStoreTooltip.bind(this);
    this.topsStoresInterval = new Array<{ value: number; description: string }>();
    this.topsStoresInterval = [
      { value: 1, description: this._translateService.translate('last_year') },
      { value: 5, description: this._translateService.translate('last_5_years') },
      { value: 10, description: this._translateService.translate('last_10_years') }
    ];

    this.selectedStoresDate = this.topsStoresInterval[1];
    this.topStoresTableColumns = new Array<string>();
  }

  public ngOnInit(): void {
    this.storesIntervalChanged();
  }

  public ngOnDestroy(): void {
    // Used by the rxjs operator untilDestroy
  }

  public storesIntervalChanged(): void {
    const now = new Date();
    const initialDate = subYears(this.selectedStoresDate.value, now);

    this.topStores$ = this._spinnerService
      .showFor(this._accumulatedTotalsService.GetSalesAccumulatedTotalCloudTop(initialDate, now), this.storesChart)
      .pipe(
        untilDestroy(this),
        tap(list => {
          this.topStoresData = list.length > 10 ? list.slice(list.length - 10, list.length) : list;
          const newList: ItemAccumulatedTotal[] = [];
          const storeNames: string[] = [];
          list.forEach(e => {
            if (!storeNames.includes(e.storeName)) {
              storeNames.push(e.storeName);
              newList.push(e);
            } else {
              const i = newList.find(r => r.storeName === e.storeName);
              const idx = newList.findIndex(r => r.storeName === e.storeName);
              i.cost = parseFloat(i.cost.toString()) + parseFloat(e.cost.toString());
              i.costNet = parseFloat(i.costNet.toString()) + parseFloat(e.costNet.toString());
              i.profit = parseFloat(i.profit.toString()) + parseFloat(e.profit.toString());
              i.profitNet = parseFloat(i.profitNet.toString()) + parseFloat(e.profitNet.toString());
              i.qnt = parseFloat(i.qnt.toString()) + parseFloat(e.qnt.toString());
              i.total = parseFloat(i.total.toString()) + parseFloat(e.total.toString());
              i.totalNet = parseFloat(i.totalNet.toString()) + parseFloat(e.totalNet.toString());
              i.totalDiscounts = parseFloat(i.totalDiscounts.toString()) + parseFloat(e.totalDiscounts.toString());
              i.totalDiscountsNet += parseFloat(i.totalDiscountsNet.toString()) + parseFloat(e.totalDiscountsNet.toString());
              newList[idx] = i;
            }
          });
          this.topStoresDataSource = new MatTableDataSource(newList);
          this.topStoresDataSource.data.forEach(e => {
            for (const key in e) {
              if (this.topStoresTableColumns.indexOf(key) === -1) {
                if (key !== unnecessaryKeys.syncDate && key !== unnecessaryKeys.qnt) {
                  this.topStoresTableColumns.push(key);
                }
              }
            }
          });
        })
      );
  }

  public customizeTopStoreTooltip(info: any): any {
    return {
      text: info.seriesName + '<br/>' + this.currencyPipe.transform(info.valueText, null, null, null, null)
    };
  }

  public showMoreStoresSales(): void {
    this.storesTableVisible = !this.storesTableVisible;
  }

  public exportXlSX(): void {
    const data = this.topStoresData;
    const header = this.getExportColumnConfigs();
    const fileName = this._translateService.translate('ItemsSummary_itemTopStoresTitle');
    const exportData = new ExportData(null, data, header, fileName);
    this._exportservice.ExportXLSX(exportData);
  }

  public exportChartPdf(): void {
    const fileName = this._translateService.translate('ItemsSummary_itemTopStoresTitle');
    this.monthsChartBars?.instance.exportTo(fileName, 'pdf');
  }

  public exportChartPng() {
    const fileName = this._translateService.translate('ItemsSummary_itemTopStoresTitle');
    this.monthsChartBars?.instance.exportTo(fileName, 'png');
  }

  public getExportColumnConfigs(): ColumnConfig[] {
    return [
      {
        header: this._translateService.translate('storeName'),
        propertyName: 'storeName',
        kind: ValueDataKind.string,
        columnConfigs: null,
        usesParentHeader: false
      },
      {
        header: this._translateService.translate('storeId'),
        propertyName: 'storeId',
        kind: ValueDataKind.number,
        columnConfigs: null,
        usesParentHeader: false
      },
      {
        header: this._translateService.translate('totalNet'),
        propertyName: 'totalNet',
        kind: ValueDataKind.currency,
        columnConfigs: null,
        usesParentHeader: false
      },
      {
        header: this._translateService.translate('total'),
        propertyName: 'total',
        kind: ValueDataKind.currency,
        columnConfigs: null,
        usesParentHeader: true
      },
      {
        header: this._translateService.translate('totalDiscountsNet'),
        propertyName: 'totalDiscountsNet',
        kind: ValueDataKind.currency,
        columnConfigs: null,
        usesParentHeader: true
      },
      {
        header: this._translateService.translate('totalDiscounts'),
        propertyName: 'totalDiscounts',
        kind: ValueDataKind.currency,
        columnConfigs: null,
        usesParentHeader: true
      },
      {
        header: this._translateService.translate('costNet'),
        propertyName: 'costNet',
        kind: ValueDataKind.currency,
        columnConfigs: null,
        usesParentHeader: true
      },
      {
        header: this._translateService.translate('cost'),
        propertyName: 'cost',
        kind: ValueDataKind.currency,
        columnConfigs: null,
        usesParentHeader: true
      },
      {
        header: this._translateService.translate('profitNet'),
        propertyName: 'profitNet',
        kind: ValueDataKind.currency,
        columnConfigs: null,
        usesParentHeader: true
      },
      {
        header: this._translateService.translate('profit'),
        propertyName: 'profit',
        kind: ValueDataKind.currency,
        columnConfigs: null,
        usesParentHeader: true
      }
    ];
  }
}
