<ng-container class="storeSelection">
  <div class="stores" [ngClass]="{ 'hidden': (availableStores?.length || 0) <= 1 }">
    <mat-form-field>
      <input
        type="text"
        matInput
        i18n-placeholder="storeSummaryStoreInput|store Summary Store input@@storeSummary_storesInput"
        placeholder="Loja:"
        required
        [formControl]="storeControl"
        [matAutocomplete]="auto"
      />
      <mat-icon class="dropDownArrow">arrow_drop_down</mat-icon>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="storeSelectionChanged()" [displayWith]="storeDisplayValue">
        <mat-option *ngFor="let store of filteredStores$ | async" [value]="store">
          {{ store.description }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
</ng-container>
<ng-container *ngIf="finishedStoresRequest && availableStores?.length">
  <div class="cards">
    <mat-grid-list [cols]="columns" rowHeight="160px">
      <mat-grid-tile class="gridCards" [colspan]="1" [rowspan]="1" *ngFor="let card of cardsData$ | async">
        <mat-card class="card">
          <mat-card-header class="cardHeader">
            <div mat-card-avatar>
              <mat-icon
                class="flowIcon"
                [ngClass]="{
                  negative: card.status == businessStatus.negative,
                  neutral: card.status == businessStatus.neutral,
                  positive: card.status == businessStatus.positive
                }"
              >
                <ng-container *ngIf="card.type == businessType.others">
                  <ng-container *ngIf="card.status == businessStatus.negative">trending_down</ng-container>
                  <ng-container *ngIf="card.status == businessStatus.neutral">trending_flat</ng-container>
                  <ng-container *ngIf="card.status == businessStatus.positive">trending_up</ng-container>
                </ng-container>
                <ng-container class="todayIcon" *ngIf="card.type == businessType.today"> assessment </ng-container>
              </mat-icon>
            </div>
            <mat-card-title>{{ card.description | translate }}</mat-card-title>
          </mat-card-header>
          <mat-card-content class="cardContent">
            <section class="cardInfo">
              <span class="cardValue">{{ card.value | cloudCurrency }}</span>
            </section>
            <section
              class="cardStatus"
              [ngClass]="{
                negative: card.status == businessStatus.negative,
                neutral: card.status == businessStatus.neutral,
                positive: card.status == businessStatus.positive
              }"
              [matTooltip]="card.tooltipDescription ? (card.tooltipDescription | translate) : null"
            >
              <div class="balancePercentage">{{ card.balancePercentage }}</div>
              <div class="balance">
                <ng-container *ngIf="card.type == businessType.others">
                  {{ card.balance | cloudCurrency }}
                </ng-container>
                <ng-container
                  *ngIf="card.type == businessType.today"
                  i18n="storeSummaryTodaysCardDescription|store Summary Todays Card Description@@storeSummary_todaysCardDescription"
                >
                  até ao momento
                </ng-container>
              </div>
            </section>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
      <ng-container *ngIf="selectedStore.value == '0'">
        <mat-grid-tile #storesTable class="gridCards" [colspan]="columns" [rowspan]="3">
          <app-sales-table class="charts"></app-sales-table>
        </mat-grid-tile>
      </ng-container>
      <mat-grid-tile class="gridCards" [colspan]="columns" [rowspan]="3">
        <app-days-chart class="charts" [requestedStores]="requestedStores"></app-days-chart>
      </mat-grid-tile>
      <mat-grid-tile class="gridCards" [colspan]="columns" [rowspan]="3">
        <app-months-chart class="charts" [requestedStores]="requestedStores"></app-months-chart>
      </mat-grid-tile>
      <ng-container *ngIf="selectedStore.value == '0'">
        <mat-grid-tile class="gridCards" [colspan]="columns" [rowspan]="3">
          <app-stores-chart class="charts"></app-stores-chart>
        </mat-grid-tile>
      </ng-container>
      <mat-grid-tile class="gridCards" [colspan]="columns == 4 ? 2 : columns" [rowspan]="3">
        <app-salesman-chart class="charts" [requestedStores]="selectedStore.value"></app-salesman-chart>
      </mat-grid-tile>
      <ng-container *ngIf="selectedStore.value != '0'">
        <mat-grid-tile class="gridCards" [colspan]="columns == 4 ? 2 : columns" [rowspan]="3">
          <app-terminals-chart class="charts" [requestedStores]="selectedStore.value"></app-terminals-chart>
        </mat-grid-tile>
      </ng-container>
      <mat-grid-tile class="gridCards" [colspan]="columns == 4 ? 2 : columns" [rowspan]="3">
        <app-items-chart class="charts" [requestedStores]="selectedStore.value"></app-items-chart>
      </mat-grid-tile>
      <mat-grid-tile class="gridCards" [colspan]="selectedStore.value === '0' ? columns : columns == 4 ? 2 : columns" [rowspan]="3">
        <app-item-groups-chart class="charts" [requestedStores]="selectedStore.value"></app-item-groups-chart>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</ng-container>

<ng-container *ngIf="finishedStoresRequest && !availableStores?.length">
  <div class="noStores">
    <mat-card class="storesNotFound">
      <mat-card-header class="cardHeader">
        <mat-card-title i18n="storeSummaryStoresUnavailable|store Summary Stores Unavailable@@storeSummary_storesUnavailable"
          >Resumo de loja(s) indisponível</mat-card-title>
      </mat-card-header>
      <div class="center-error">
        <div class="storesNotFoundContent">
          <p class="col text-center text-color"
            i18n="storeSummaryStoresNotFoundTitle|store Summary Stores Not Found Title@@storeSummary_storesNotFoundTitle">
            Não foram encontradas lojas.</p>
          <p class="col text-center text-color"
            i18n="storeSummaryStoresNotFoundSubTitle|store Summary Empty Chart SubTitle@@storeSummary_storesNotFoundSubTitle">
            Poderá estar com problemas de acesso à rede ou o servidor não está acessível.</p>
          <button
            mat-flat-button
            i18n="storeSummaryUnavailableRetry|Store Summary unavailable message action retry@@storeSummary_retryGetSummary"
            (click)="ngAfterViewInit()"
            style="margin-right: 4px;"
            >Tentar novamente</button>
          <button
            mat-flat-button
            i18n="storeSummaryUnavailableHelp|Store Summary unavailable message action show help@@storeSummary_help"
            routerLink="/helper"
            >Ajuda</button>
        </div>
      </div>
    </mat-card>
  </div>
</ng-container>
