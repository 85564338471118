import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  DateRange,
  DefaultMatCalendarRangeStrategy,
  MatRangeDateSelectionModel,
  MAT_DATE_RANGE_SELECTION_STRATEGY
} from '@angular/material/datepicker';
import { RangePickerComponent } from '../../../shared/components/range-picker/range-picker.component';
import { PopUpService } from '../../../shared/services/pop-up/pop-up.service';
import { TranslateService } from '../../../shared/services/translate-service/translate.service';
import { AccumulatedTotalsDateParameters, AccumulatedTotalsDateParametersEnum } from '../../model/accumulated-totals-date-parameters-enum';
import { AccumulatedTotalsService } from '../../services/accumulated-totals.service';

export interface Parameters {
  value: AccumulatedTotalsDateParametersEnum;
  viewValue: string;
}

export interface ParametersGroup {
  disabled?: boolean;
  name: string;
  parameters: Parameters[];
}

@Component({
  selector: 'app-accumulated-totals-container',
  templateUrl: './accumulated-totals-container.component.html',
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DefaultMatCalendarRangeStrategy
    },
    DefaultMatCalendarRangeStrategy,
    MatRangeDateSelectionModel
  ],
  styleUrls: ['./accumulated-totals-container.component.scss']
})
export class AccumulatedTotalsContainerComponent implements OnInit {
  private _selectedItemGroup: string;
  public get selectedItemGroup(): string {
    return this._selectedItemGroup;
  }
  public set selectedItemGroup(value: string) {
    this._selectedItemGroup = value;
  }
  public dateParameters: AccumulatedTotalsDateParameters;
  public parametersGroups: ParametersGroup[] = [
    {
      name: this._translationService.translate('default'),
      parameters: [
        { value: AccumulatedTotalsDateParametersEnum.lastWeek, viewValue: this._translationService.translate('last_week') },
        { value: AccumulatedTotalsDateParametersEnum.lastMonth, viewValue: this._translationService.translate('last_month') },
        { value: AccumulatedTotalsDateParametersEnum.lastYear, viewValue: this._translationService.translate('last_year') },
        { value: AccumulatedTotalsDateParametersEnum.last5Year, viewValue: this._translationService.translate('last_5_years') }
      ]
    }
  ];
  public dateRange: DateRange<Date>;
  @ViewChild('customDateButton', { read: ElementRef, static: true })
  private _customDateButtonRef: ElementRef;

  constructor(
    private readonly _service: AccumulatedTotalsService,
    private readonly _popUpService: PopUpService,
    private readonly _translationService: TranslateService,
    private readonly selectionModel: MatRangeDateSelectionModel<Date>,
    private readonly selectionStrategy: DefaultMatCalendarRangeStrategy<Date>
  ) {}

  public ngOnInit(): void {
    const dateParameters = this._service.DateParameters;

    if (dateParameters.customInititalDate && dateParameters.customFinalDate) {
      this.addCustomParameterGroup(dateParameters.customInititalDate, dateParameters.customFinalDate);
    }

    this.dateParameters = dateParameters;
    this.dateRange = this.createDateRangeFromDateParameters(dateParameters);
  }

  private createDateRangeFromDateParameters(dateParameters: AccumulatedTotalsDateParameters): DateRange<Date> {
    let result: DateRange<Date>;

    if (dateParameters.key === AccumulatedTotalsDateParametersEnum.custom) {
      result = new DateRange(new Date(dateParameters.customInititalDate), new Date(dateParameters.customFinalDate));
    } else {
      const initialDate = this._service.GetInitialDateFromEnum(dateParameters.key);

      result = new DateRange(initialDate, new Date());
    }

    return result;
  }

  private addCustomParameterGroup(initialDate: Date, finalDate: Date) {
    const groupName = this._translationService.translate('customized');

    const index = this.parametersGroups.findIndex(g => g.name === groupName);
    if (index >= 0) {
      this.parametersGroups.splice(index, 1);
    }

    const customGroup: ParametersGroup = {
      name: groupName,
      parameters: [
        {
          value: AccumulatedTotalsDateParametersEnum.custom,
          viewValue:
            initialDate.getTime() === finalDate.getTime()
              ? initialDate.toLocaleDateString()
              : `${initialDate.toLocaleDateString()} - ${finalDate.toLocaleDateString()}`
        }
      ]
    };
    this.parametersGroups.push(customGroup);
  }

  public dateParametersChanged(event: any): void {
    let initialDate: Date = new Date();
    const finalDate: Date = new Date();
    let customInititalDate: Date = this.dateParameters.customInititalDate;
    let customFinalDate: Date = this.dateParameters.customFinalDate;

    if (event.value === AccumulatedTotalsDateParametersEnum.custom) {
      customInititalDate = new Date(this.dateParameters.customInititalDate);
      customFinalDate = new Date(this.dateParameters.customFinalDate);
      this.dateRange = new DateRange<Date>(customInititalDate, customFinalDate);
    } else {
      initialDate = this._service.GetInitialDateFromEnum(event.value);
      this.dateRange = new DateRange<Date>(initialDate, finalDate);
    }

    const value: AccumulatedTotalsDateParameters = {
      key: event.value,
      initialDate: initialDate,
      finalDate: finalDate,
      customInititalDate: customInititalDate,
      customFinalDate: customFinalDate
    };
    this._service.DateParameters = value;

    if (event.value === AccumulatedTotalsDateParametersEnum.custom) {
      this.addCustomParameterGroup(value.customInititalDate, value.customFinalDate);
    }

    this.dateParameters = value;
  }

  public openRangePickerPopUp(): void {
    const popUp = this._popUpService.openPopup(RangePickerComponent, this._customDateButtonRef, null, c => {
      c.dateRange = this.dateRange;
    });

    popUp.subscribe(rangePicker => {
      if (rangePicker.dateChanged) {
        let dateRange: DateRange<Date>;

        if (rangePicker.dateRange.start > rangePicker.dateRange.end) {
          dateRange = new DateRange<Date>(rangePicker.dateRange.start, rangePicker.dateRange.start);
        } else {
          dateRange = rangePicker.dateRange;
        }

        this.dateRange = dateRange;
        this.dateParameters.initialDate = this.dateRange.start;
        this.dateParameters.finalDate = this.dateRange.end;
        this.dateParameters.customInititalDate = this.dateRange.start;
        this.dateParameters.customFinalDate = this.dateRange.end;
        this.dateParameters.key = AccumulatedTotalsDateParametersEnum.custom;
        this.dateParametersChanged({ value: AccumulatedTotalsDateParametersEnum.custom });
      }
    });
  }
}
