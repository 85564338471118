import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../services/translate-service/translate.service';

@Pipe({ name: 'translate' })
export class TranslatePipe implements PipeTransform {
  constructor(private _translateService: TranslateService) {}
  public transform(value: any): any {
    return this._translateService.translate(value);
  }
}
