import { Component, ElementRef, Injector, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { CurrencyPipe } from '../../../shared/pipes/Currency.pipe';
import { CurrencyConfigService } from '../../../shared/services/configs/currency-config.service';
import { SpinnerService } from '../../../shared/services/spinner/spinner.service';
import { SalesmanAccumulatedTotal } from '../../model/accumulated-totals';
import { AccumulatedTotalsDateParameters, AccumulatedTotalsDateParametersEnum } from '../../model/accumulated-totals-date-parameters-enum';
import { AccumulatedTotalsService } from '../../services/accumulated-totals.service';

@Component({
  selector: 'app-salesman-accumulated-total',
  templateUrl: './salesman-accumulated-total.component.html',
  styleUrls: ['./salesman-accumulated-total.component.scss']
})
export class SalesmanAccumulatedTotalComponent {
  public data$: Observable<SalesmanAccumulatedTotal[]>;
  public dateParametersValue: AccumulatedTotalsDateParameters;

  private _currencyPipe: CurrencyPipe;

  constructor(
    private _service: AccumulatedTotalsService,
    private _spinnerService: SpinnerService,
    private _injector: Injector,
    private _currencyService: CurrencyConfigService,
    private _elementRef: ElementRef
  ) {
    this._currencyPipe = new CurrencyPipe(this._injector, this._currencyService);

    this.customizeTooltip = this.customizeTooltip.bind(this);
    this.customizeValuesText = this.customizeValuesText.bind(this);
  }

  @Input() public set dateParameters(value: AccumulatedTotalsDateParameters) {
    if (this.dateParametersValue !== value) {
      this.dateParametersValue = value;
      this.refresh();
    }
  }

  public refresh(): void {
    let initialDate = this.dateParametersValue.initialDate;
    let finalDate = this.dateParametersValue.finalDate;

    if (this.dateParametersValue.key === AccumulatedTotalsDateParametersEnum.custom) {
      initialDate = this.dateParametersValue.customInititalDate;
      finalDate = this.dateParametersValue.customFinalDate;
    }

    const data = this._service.GetSalesmanAccumulatedTotalCloudFull(initialDate, finalDate);

    this.data$ = this._spinnerService.showFor(data, this._elementRef);
  }

  public customizeValuesText(arg: any): string {
    if (this._currencyPipe) {
      return this._currencyPipe.transform(arg.value, null, null, null, null);
    }

    return arg.value;
  }

  protected onPointClick(e: any): void {
    e.target.select();
  }

  public customizeTooltip(info: any): any {
    // callback functions needs to acess component context explicitly; check constructor function bind to component
    return {
      text: this._currencyPipe.transform(info.valueText, null, null, null, null)
    };
  }
}
