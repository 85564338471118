<!-- HEADER -->
<mat-toolbar color="primary" class="header">
  <mat-toolbar-row>
    <!--Left nav-->
    <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"
      style="margin-right: 10px;" *ngIf="loggedStatus$ | async">
      <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
    </button>

    <span class="spacer"></span>

    <button mat-icon-button [matMenuTriggerFor]="loginLanguageMenu" style="margin-left: 10px;"
      *ngIf="!(loggedStatus$ | async)"><mat-icon>language</mat-icon></button>

    <mat-menu #loginLanguageMenu="matMenu">
      <button mat-menu-item (click)="onSetLanguage(LanguageEnum.Portuguese)">
        <span>Português</span>
      </button>
      <button mat-menu-item (click)="onSetLanguage(LanguageEnum.Spanish)">
        <span>Español</span>
      </button>
    </mat-menu>


    <button type="button" aria-label="Toggle sidenav" mat-icon-button
      [ngClass]="{ 'hidden': !(hasNotifications$ | async) }" (click)="notifyDrawer.toggle()" style="margin-right: 10px;"
      *ngIf="loggedStatus$ | async">
      <mat-icon [matBadge]="notificationCount$ | async" matBadgeColor="warn">announcement</mat-icon>
    </button>

    <button mat-icon-button routerLink="/summary">
      <img src="..\assets\logo_xd_white.png" height="28" width="38" style="padding-left: 3px;" alt="logo xd" />
    </button>

    <button mat-icon-button [matMenuTriggerFor]="themeMenu" id="theme-selector" style="margin-left: 10px;"
      *ngIf="loggedStatus$ | async">
      <mat-icon>format_color_fill</mat-icon>
    </button>

    <mat-menu #themeMenu="matMenu">
      <ng-container *ngFor="let mapItem of themeService.available | keyvalue">
        <button mat-menu-item (click)="onSetTheme(mapItem.key)">
          <mat-icon [ngStyle]="{ color: mapItem.value.color }">fiber_manual_record</mat-icon>
          <span>{{ mapItem.value.name }}</span>
        </button>
      </ng-container>
    </mat-menu>
  </mat-toolbar-row>
</mat-toolbar>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport="false"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" mode="over" [(opened)]="opened">
    <mat-nav-list>
      <a mat-list-item routerLink="/summary" (click)="drawer.toggle()"><mat-icon>home</mat-icon><span
          class="list-item-text"
          i18n="MainNavBeginning|Main nav beginning menu option caption@@mainNav_BeginningOption">Início</span></a>
      <a mat-list-item routerLink="/online-summary" (click)="drawer.toggle()"><mat-icon>assessment</mat-icon><span
          class="list-item-text"
          i18n="MainNavOnlineSummary|Main nav online summary menu option caption@@mainNav_SummaryOption">Resumo
          Online</span></a>
      <a mat-list-item routerLink="/dashboard" (click)="drawer.toggle()"><mat-icon>dashboard</mat-icon><span
          class="list-item-text"
          i18n="MainNavDynamicDashboards|Main nav dynamic dashboards menu option caption@@mainNav_DynamicDashboards">Resumo
          Online Dinâmico</span></a>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header> <mat-icon>toc</mat-icon>Vendas </mat-expansion-panel-header>
          <a mat-list-item routerLink="/sales-summary/customers" (click)="drawer.toggle()"
            class="group-item"><mat-icon>account_circle</mat-icon><span class="list-item-text"
              i18n="MainNavOnlineCustomersSummary|Main nav online customers summary menu option caption@@mainNav_CustomersSummaryOption">Clientes</span></a>
          <a mat-list-item routerLink="/sales-summary/item-groups" (click)="drawer.toggle()"
            class="group-item"><mat-icon>supervised_user_circle</mat-icon><span class="list-item-text"
              i18n="MainNavItemGroups|Main nav item groups option caption@@mainNav_ItemGroupsOption">Famílias/Artigos</span>
          </a>
          <a mat-list-item routerLink="/sales-summary/transactions" (click)="drawer.toggle()"
            class="group-item"><mat-icon>sync_alt</mat-icon>
            <span
              i18n="MainNavTransactions|Main transactions option caption@@mainNav_transactionsOption">Transações</span>
          </a>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-icon>account_balance</mat-icon><span
              i18n="MainNavConsults|Main nav consults option caption@@mainNav_ConsultsOption">Consultas</span>
          </mat-expansion-panel-header>
          <a mat-list-item routerLink="/consults/movements" (click)="drawer.toggle()"
            class="group-item"><mat-icon>payment</mat-icon><span class="list-item-text"
              i18n="MainNavMovementsConsults|Main nav movements consults option caption@@mainNav_MovementConsultsOption">Movimentos</span>
          </a>
          <a mat-list-item routerLink="/consults/employee-activity" (click)="drawer.toggle()"
            class="group-item"><mat-icon>play_for_work</mat-icon><span class="list-item-text"
              i18n="MainNavEmployeeActivityConsults|Main nav employee activity consults option caption@@mainNav_EmployeeActivityOption">Registo
              Ponto</span>
          </a>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-icon>list</mat-icon> <span
              i18n="MainNavListings|Main nav listings option caption@@mainNav_ListingsOption">Tabelas</span>
          </mat-expansion-panel-header>
          <a mat-list-item routerLink="/entities/listings/items" (click)="drawer.toggle()"
            class="group-item"><mat-icon>widgets</mat-icon><span class="list-item-text"
              i18n="MainNavItemsListings|Main nav items listings option caption@@mainNav_ItemsListingsOption">Artigos</span>
          </a>
          <a mat-list-item routerLink="/entities/listings/item-groups" (click)="drawer.toggle()"
            class="group-item"><mat-icon>supervised_user_circle</mat-icon><span class="list-item-text"
              i18n="MainNavItemGroupsListings|Main nav item groups listings option caption@@mainNav_ItemsGroupsListingsOption">Famílias</span>
          </a>
          <a mat-list-item routerLink="/entities/listings/customers" (click)="drawer.toggle()"
            class="group-item"><mat-icon>account_circle</mat-icon><span class="list-item-text"
              i18n="MainNavCustomersListings|Main nav customers listings option caption@@mainNav_Customers ListingsOption">Clientes</span>
          </a>
          <a mat-list-item routerLink="/entities/listings/price-table" (click)="drawer.toggle()"
            class="group-item"><mat-icon>monetization_on</mat-icon><span class="list-item-text"
              i18n="MainNavPriceTablesListings|Main nav price tables listings option caption@@mainNav_PriceTablesListingsOption">Tabelas
              de Preços</span>
          </a>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header><mat-icon>description</mat-icon><span
              i18n="MainNavReports|Main nav reports option caption@@mainNav_ReportsOption">Relatórios</span>
          </mat-expansion-panel-header>
          <a mat-list-item routerLink="/reports/documents" (click)="drawer.toggle()"
            class="group-item"><mat-icon>receipt_long</mat-icon><span class="list-item-text"
              i18n="MainNavDocumentsReport|Main nav documents report option caption@@mainNav_DocumentsReportOption">Documentos</span>
          </a>
          <a mat-list-item routerLink="/reports/discounts" (click)="drawer.toggle()"
            class="group-item"><mat-icon>card_giftcard</mat-icon><span class="list-item-text"
              i18n="MainNavDiscountsReport|Main nav discounts report option caption@@mainNav_DiscountsReportOption">Ofertas/Descontos</span>
          </a>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header><mat-icon>description</mat-icon><span
              i18n="MainNavReports|Main nav reports option caption@@mainNav_ReportsOption">Saft</span>
          </mat-expansion-panel-header>
          <a mat-list-item routerLink="/saft/download" (click)="drawer.toggle()"
            class="group-item"><mat-icon>cloud_download</mat-icon><span class="list-item-text"
              i18n="MainNavDiscountsReport|Main nav discounts report option caption@@mainNav_DiscountsReportOption">Download
              Saft</span>
          </a>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header><mat-icon>settings</mat-icon><span
              i18n="MainNavConfigurations|Main nav configurations option caption@@mainNav_ConfigurationsOption">Configuração</span></mat-expansion-panel-header>
          <div mat-list-item class="group-item">
            <div class="language-selector">
              <mat-icon>language</mat-icon>
              <mat-form-field>
                <mat-label
                  i18n="MainNavLanguageConfigurations|Main nav language configurations option caption@@mainNav_LanguageConfigurationsOption">Idioma</mat-label>
                <mat-select [value]="language$ | async" (selectionChange)="onSetLanguage($event.value)">
                  <mat-option [value]="LanguageEnum.Portuguese">Português</mat-option>
                  <mat-option [value]="LanguageEnum.Spanish">Español</mat-option>
                </mat-select>
              </mat-form-field>
            </div>


          </div>
          <div class="istax-service">
            <mat-checkbox *ngIf="displayCheckbox" color="primary" [(ngModel)]="isChecked"
              (change)="onCheckboxChange()">Taxa de
              Serviço</mat-checkbox>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <a mat-list-item [ngClass]="{ 'hidden': !(hasMultipleStores$ | async) }" routerLink="/summary/stores-overview"
        (click)="drawer.toggle()"><mat-icon>storefront</mat-icon><span class="list-item-text"
          i18n="MainNavStores|Main nav stores menu option caption@@mainNav_StoresOption">Lojas</span></a>
      <a mat-list-item *ngIf="loggedStatus$ | async" (click)="onLogout()"><mat-icon color="warn">logout</mat-icon><span
          i18n="MainNavLogout|Main Nav Logout button caption@@mainNav_logoutButton">Logout</span></a>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav position="end" #notifyDrawer class="rsidenav mat-drawer-hidden-overflow" fixedInViewport="false"
    fixedTopGap="0" fixedBottomGap="0" [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" mode="over"
    [(opened)]="notifyPanelOpened" style="width: 320px;">
    <app-notification-container> </app-notification-container>
  </mat-sidenav>

  <mat-sidenav-content>
    <ng-content></ng-content>
    <app-footer></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
