import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Store } from '../../shared/models/store';

const API_ENDPOINT = `${environment.api.schema}://${environment.api.host}`;
const ALL_STORES_ENDPOINT = `${API_ENDPOINT}/store`;

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  private readonly _http: HttpClient = null;

  private readonly _storesSubject: BehaviorSubject<Store[]>;
  private readonly _stores$: Observable<Store[]>;
  private readonly _storesCount$: Observable<number>;

  public get stores$(): Observable<Store[]> {
    return this._stores$;
  }

  public get storesCount$(): Observable<number> {
    return this._storesCount$;
  }

  constructor(http: HttpClient) {
    this._http = http;
    this._storesSubject = new BehaviorSubject<Store[]>([]);
    this._stores$ = this._storesSubject.asObservable();
    this._storesCount$ = this._stores$.pipe(map(stores => stores?.length || 0));
  }

  public getStores(): Observable<Store[]> {
    return this._http.get<Store[]>(ALL_STORES_ENDPOINT, { withCredentials: true }).pipe(tap(stores => this._storesSubject.next(stores)));
  }
}
