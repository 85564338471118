<ng-container *ngIf="data$ | async as values">
  <ng-container *ngIf="values?.length; else emptyChart">
    <dx-chart
      id="chart"
      [dataSource]="values"
      (onIncidentOccurred)="incidentOcurred($event)"
      (onLegendClick)="onLegendClick($event)"
      (onDrawn)="chartDrawn($event)"
    >
      <dxo-common-series-settings argumentField="syncDate" type="spline" valueField="total"> </dxo-common-series-settings>
      <dxo-series-template nameField="storeName"> </dxo-series-template>
      <dxi-value-axis>
        <dxo-label [customizeText]="customizeValueLabel('{value}')"></dxo-label>
      </dxi-value-axis>
      <dxo-argument-axis
        #argumentAxisSettings
        argumentType="datetime"
        aggregationInterval="month"
        [valueMarginsEnabled]="false"
        [tickInterval]="tickInterval"
      >
      </dxo-argument-axis>
      <dxo-legend verticalAlignment="right" horizontalAlignment="right" [visible]="isLegendVisible"> </dxo-legend>
      <dxo-tooltip
        [enabled]="true"
        [zIndex]="5000"
        [customizeTooltip]="customizeTooltip('{seriesName}\<br /\>{argument}\<br /\>{value}')"
      ></dxo-tooltip>
    </dx-chart>
  </ng-container>
</ng-container>

<ng-template #emptyChart>
  <div class="center-error">
    <div class="pl-3 pr-3">
      <span
        i18n="SalesAccumulatedTotalError|Sales Accumulated Total Error Label@@salesAccumulatedTotal_unavailableWidget"
        >Informação indisponível</span
      >
    </div>
    <p>
      <button
        mat-flat-button
        (click)="refresh()"
        i18n="SalesAccumulatedTotalErrorRetry|Sales Accumulated Total Error Retry Label@@salesAccumulatedTotal_retryWidget"
        >Tentar novamente</button
      >
    </p>
  </div>
</ng-template>
