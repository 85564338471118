import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent {
  public progress: number;
  public descriptionProgress: string;
  public title: string;
  public showDescription: boolean;
  public showTitle: boolean;
  @Input()
  public maxValue: number;

  constructor() {}

  public setProgress(step: number): void {
    this.progress = this.percentage(step);
  }
  public setDescription(description: string) {
    this.descriptionProgress = description;
    this.showDescription = !!description;
  }
  public setTitle(title: string) {
    this.title = title;
    this.showTitle = !!title;
  }
  private percentage(step: number) {
    if (step > this.maxValue) step = this.maxValue;
    else if (step < 0) step = 0;

    return (step * 100) / this.maxValue;
  }
}
