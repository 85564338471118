import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../auth/services/authentication.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  constructor(public authService: AuthenticationService, private router: Router) {}

  public ngOnInit(): void {
    this.authService.isLogged().subscribe(isLogged => {
      if (isLogged) {
        this.router.navigate(['summary']);
      } else {
        this.router.navigate(['login']);
      }
    });
  }
}
