import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from '../shared/components/page-not-found/page-not-found.component';
import { StoreSummaryComponent } from './components/store-summary/store-summary.component';
import { StoresOverviewComponent } from './components/stores-overview/stores-overview.component';

const routes: Routes = [
  { path: 'store/:id', component: StoreSummaryComponent },
  { path: 'stores-overview', component: StoresOverviewComponent },
  { path: '', component: StoreSummaryComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BussinessSummaryRoutingModule {}
