<article class="col mat-card-content">
  <section class="widget-header">
    <div class="center-widget-title">
      <strong class="text-truncate text-success" i18n="ParametersOptions|Parameters Options@@parameters_options">Opções</strong>
    </div>
    <button mat-icon-button (click)="goBackHandler()">
      <mat-icon>exit_to_app</mat-icon>
    </button>
    <button mat-icon-button (click)="confirmHandler()">
      <mat-icon>done</mat-icon>
    </button>
  </section>
  <br />

  <section class="form-container">
    <form [formGroup]="parametersForm">
      <ng-container *ngFor="let paramConfig of parametersConfig">
        <ng-container [ngSwitch]="paramConfig.optionsKind">
          <ng-container *ngSwitchCase="OptionsKind.none">
            <ng-container [ngSwitch]="paramConfig.dataType">
              <ng-container *ngSwitchCase="ParameterDataKind.string">
                <ng-container *ngIf="paramConfig.isEnabled && paramConfig.isVisible">
                  <ng-container
                    *ngTemplateOutlet="
                      stringTemplate;
                      context: { config: paramConfig, value: parameters[paramConfig.name], form: parametersForm }
                    "
                  >
                  </ng-container>
                </ng-container>
              </ng-container>

              <ng-container *ngSwitchCase="ParameterDataKind.number">
                <ng-container *ngIf="paramConfig.isEnabled && paramConfig.isVisible">
                  <ng-container
                    *ngTemplateOutlet="
                      numberTemplate;
                      context: { config: paramConfig, value: parameters[paramConfig.name], form: parametersForm }
                    "
                  >
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="OptionsKind.combo">
            <ng-container *ngIf="paramConfig.isEnabled && paramConfig.isVisible">
              <ng-container
                *ngTemplateOutlet="
                  comboTemplate;
                  context: { config: paramConfig, value: parameters[paramConfig.name], form: parametersForm }
                "
              >
              </ng-container>
            </ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="OptionsKind.list">
            <ng-container *ngIf="paramConfig.isEnabled && paramConfig.isVisible">
              <ng-container
                *ngTemplateOutlet="
                  listTemplate;
                  context: { config: paramConfig, value: parameters[paramConfig.name], form: parametersForm }
                "
              >
              </ng-container>
            </ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="OptionsKind.lookup">
            <!-- TODO -->
          </ng-container>
        </ng-container>
      </ng-container>
    </form>
  </section>
</article>

<ng-template #stringTemplate let-config="config" let-value="value" let-form="form">
  <ng-container [formGroup]="form">
    <mat-form-field>
      <input matInput [formControlName]="config.name" [placeholder]="config.displayName || config.name" [defaultValue]="value || ''" />
    </mat-form-field>
  </ng-container>
</ng-template>

<ng-template #numberTemplate let-config="config" let-value="value" let-form="form">
  <ng-container [formGroup]="form">
    <mat-form-field>
      <input
        matInput
        mask="0*"
        [formControlName]="config.name"
        [placeholder]="config.displayName || config.name"
        [defaultValue]="value || ''"
      />
    </mat-form-field>
  </ng-container>
</ng-template>

<ng-template #comboTemplate let-config="config" let-value="value" let-form="form">
  <ng-container [formGroup]="form">
    <mat-form-field>
      <mat-select [formControlName]="config.name" [placeholder]="config.displayName || config.name">
        <mat-option value=""></mat-option>
        <ng-container *ngFor="let option of options.get(config.name)">
          <mat-option [value]="option[0]">{{ option[1] }}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </ng-container>
</ng-template>

<ng-template #listTemplate let-config="config" let-value="value" let-form="form">
  <ng-container [formGroup]="form">
    <mat-form-field>
      <mat-selection-list [formControlName]="config.name">
        <mat-list-option value=""></mat-list-option>
        <ng-container *ngFor="let option of options.get(config.name)">
          <mat-list-option [value]="option[0]">{{ option[1] }}</mat-list-option>
        </ng-container>
      </mat-selection-list>
    </mat-form-field>
  </ng-container>
</ng-template>
