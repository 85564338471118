import { animate, animation, group, query, style } from '@angular/animations';

export const slideLeftAnimation = animation([
  style({ position: 'relative' }),
  query(':enter, :leave', [
    style({
      position: 'absolute',
      width: '100%'
    })
  ]),
  group([
    query(
      ':enter',
      [
        style({ opacity: 0, transform: 'translateX(100%)' }),
        animate('400ms ease-in-out', style({ transform: 'translateX(0%)', opacity: 1 }))
      ],
      { optional: true }
    ),
    query(
      ':leave',
      [
        style({ opacity: 1, transform: 'translateX(0%)' }),
        animate('400ms ease-in-out', style({ transform: 'translateX(-100%)', opacity: 0 }))
      ],
      { optional: true }
    )
  ])
]);
