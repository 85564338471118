<div class="store-widget-container">
  <mat-toolbar class="store-widgets-toolbar">
    <div class="store-widget-title">
      <div>
        <span
          class="cloud-link"
          routerLink="/summary"
          i18n="CarouselContainerTitle|Landing Page Carousel Container Title@@storeWidgetCarousel_title"
          >Lojas</span
        >
      </div>
      <div>
        <small
          class="show-details"
          [hidden]="showDetails"
          i18n="CarouselContainerDetails|Landing Page Carousel Container Details@@storeWidgetCarousel_details"
          (click)="showDetails = !showDetails"
          >Tabela</small
        >
        <small
          class="show-details"
          [hidden]="!showDetails"
          i18n="CarouselContainerCards|Landing Page Carousel Container Cards@@storeWidgetCarousel_cards"
          (click)="showDetails = !showDetails"
          >Cartões</small
        >
      </div>
    </div>
    <mat-form-field class="store-widget-search" [hidden]="showDetails">
      <mat-icon matPrefix>search</mat-icon>
      <input matInput [matAutocomplete]="storeWidgetSearch" [formControl]="searchControl" (keyup)="searchValue($event.target.value)" />
      <mat-autocomplete
        #storeWidgetSearch="matAutocomplete"
        [displayWith]="getStoreDescription"
        (optionSelected)="searchStore($event.option.value)"
      >
        <mat-option *ngFor="let store of filteredStores$ | async" [value]="store">{{ store.name }}</mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <button mat-flat-button (click)="refresh()">
      <mat-icon matPrefix>cached</mat-icon>
    </button>
  </mat-toolbar>
  <div [hidden]="showDetails" class="store-widget-content">
    <ng-container *ngIf="!(hasError$ | async); else emptyStore">
      <app-widget-carousel-container
        [searchableProperties]="searchableProperties"
        [searchValue]="searchInputEvent"
        (refreshWidget)="onRefreshWidget($event)"
        [getWidgetDefaultValues]="getWidgetDefaultValues.bind(this)"
      ></app-widget-carousel-container>
    </ng-container>
  </div>
  <div [hidden]="!showDetails">
    <table
      mat-table
      [dataSource]="dataSource"
      i18n-aria-describedby="StoreWidgetContainerStoreDetails|StoreWidgetContainer Store Details@@StoreWidgetContainer_storeDetails"
      aria-describedby="Detalhes lojas"
    >
      <!-- Position Column -->
      <ng-container matColumnDef="name">
        <th
          mat-header-cell
          *matHeaderCellDef
          i18n="StoreWidgetContainerTableColumnStore|StoreWidgetContainer Table Column Store@@StoreWidgetContainerTableColumn_store"
          >Lojas</th
        >
        <td mat-cell *matCellDef="let element"
          ><span class="cloud-link" routerLink="/summary/store/{{ element.storeId }}">{{ element.name }}</span></td
        >
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="account">
        <th
          mat-header-cell
          *matHeaderCellDef
          i18n="StoreWidgetContainerTableColumnName|StoreWidgetContainer Table Column account@@StoreWidgetContainerTableColumn_account"
          >Licença</th
        >
        <td mat-cell *matCellDef="let element"> {{ element.account }} </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="sales">
        <th
          mat-header-cell
          *matHeaderCellDef
          i18n="StoreWidgetContainerTableColumnSales|StoreWidgetContainer Table Column Sales@@StoreWidgetContainerTableColumn_sales"
          >Vendas</th
        >
        <td mat-cell *matCellDef="let element"> {{ element.sales | cloudCurrency }} </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="cashier">
        <th
          mat-header-cell
          *matHeaderCellDef
          i18n="StoreWidgetContainerTableColumnCashier|StoreWidgetContainer Table Column Cashier@@StoreWidgetContainerTableColumn_Cachier"
          >Caixa</th
        >
        <td mat-cell *matCellDef="let element"> {{ element.cachier | cloudCurrency }} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>

<ng-template #emptyStore>
  <div class="center-card">
    <mat-card>
      <mat-card-title i18n="carouselUnavailableTitle|Carousel unavailable message title@@storeWidgetCarousel_carouselUnavailable"
        >Dados indisponíveis</mat-card-title
      >

      <mat-card-content>
        <p
          i18n="carouselUnavailableDescription|Carousel unavailable message description@@storeWidgetCarousel_carouselUnavailableDescription"
        >
          Poderá estar com problemas de acesso à rede ou o seu servidor não está acessível.
        </p>
        <br />
        <p
          ><em
            i18n="carouselUnavailableSolution|Carousel unavailable message solution hint@@storeWidgetCarousel_carouselUnavailableSuggestion"
            >Se o problema persistir, por favor contacte a equipa de suporte da XD Software.</em
          ></p
        >
      </mat-card-content>

      <mat-card-actions class="center-actions">
        <button
          mat-flat-button
          i18n="carouselUnavailableRetry|Carousel unavailable message action retry@@storeWidgetCarousel_retryGetCarousel"
          (click)="ngOnInit()"
          style="margin-right: 4px;"
          >Tentar novamente</button
        >
        <button
          mat-flat-button
          i18n="carouselUnavailableHelp|Carousel unavailable message action show help@@storeWidgetCarousel_help"
          routerLink="/helper"
          >Ajuda</button
        >
      </mat-card-actions>
    </mat-card>
  </div>
</ng-template>
