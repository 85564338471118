import { ComponentType } from '@angular/cdk/overlay';
import { Component, ComponentFactoryResolver, ComponentRef, EventEmitter, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'app-pop-up-container',
  templateUrl: './pop-up-container.component.html',
  styleUrls: ['./pop-up-container.component.scss']
})
export class PopUpContainerComponent {
  @ViewChild('container', { read: ViewContainerRef, static: true })
  private _containerRef: ViewContainerRef;
  public readonly close: EventEmitter<void>;
  constructor(private _componentFactoryResolver: ComponentFactoryResolver) {
    this.close = new EventEmitter();
  }

  public attach<T>(contentType: ComponentType<T>): ComponentRef<T> {
    let result: ComponentRef<T> = null;

    if (this._containerRef) {
      const componentFactory = this._componentFactoryResolver.resolveComponentFactory(contentType);
      result = this._containerRef.createComponent(componentFactory);
    }
    return result;
  }

  public closePopUp(): void {
    this.close.emit();
  }
}
