import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export enum CommunicationChannelType {
  none = 0
}

@Injectable({
  providedIn: 'root'
})
export class DataCommunicationService {
  // this service is used to send data from one component to another
  private readonly _dataChannels: Map<string, Subject<any>>;

  constructor() {
    this._dataChannels = new Map<string, Subject<any>>();
  }

  public sendData<T>(channelID: string, data: T): boolean {
    let result = false;

    if (this._dataChannels.has(channelID)) {
      const channel = this._dataChannels.get(channelID);
      result = true;
      channel.next(data);
    }

    return result;
  }

  public getDataChannel<T>(channelID: string): Observable<T> | null {
    let channel$: Observable<T> = null;

    if (this._dataChannels.has(channelID)) {
      channel$ = this._dataChannels.get(channelID).asObservable();
    }

    return channel$;
  }

  public createChannel<T>(channelID: string): void {
    let channel: Subject<T> = null;

    if (this._dataChannels.has(channelID)) {
      throw new Error('channelID ' + channelID + ' already beeing used');
    } else {
      channel = new Subject<T>();
      this._dataChannels.set(channelID, channel);
    }
  }

  public hasChannel(channelID: string): boolean {
    return this._dataChannels.has(channelID);
  }
}
