import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { format, subDays } from 'date-fns/esm/fp';
import { flow } from 'lodash-es';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { untilDestroy } from '../../../shared/rxjs/operators/until-destroy';
import { SpinnerService } from '../../../shared/services/spinner/spinner.service';
import { TranslateService } from '../../../shared/services/translate-service/translate.service';
import { BusinessSummaryTableSales } from '../../model/businessSummaryTableSales';
import { BusinessSummaryService } from '../../services/business-summary.service';

@Component({
  selector: 'app-sales-table',
  templateUrl: './sales-table.component.html',
  styleUrls: ['./sales-table.component.scss']
})
export class SalesTableComponent implements OnInit, OnDestroy {
  public storesSales$: Observable<BusinessSummaryTableSales[]>;
  public storesSalesData: Array<BusinessSummaryTableSales>;
  public storesSalesDataSource = new MatTableDataSource<BusinessSummaryTableSales>();
  @ViewChild('storesTable', { static: true, read: ElementRef })
  private storesTable: ElementRef;

  public daysList: string[] = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30'
  ];
  public displayedColumns: string[] = [
    'storeName',
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    'lineTotal'
  ];

  constructor(
    public businessSummaryService: BusinessSummaryService,
    private _spinnerService: SpinnerService,
    private _translateService: TranslateService
  ) {}

  public ngOnInit(): void {
    this.getStoresTableData();
  }

  public ngOnDestroy(): void {
    // Used by the rxjs operator untilDestroy
  }

  public getStoresTableData(): void {
    this.storesSales$ = this._spinnerService.showFor(this.businessSummaryService.getTableSales(new Date()), this.storesTable).pipe(
      untilDestroy(this),
      tap(list => {
        this.storesSalesData = list;
        this.storesSalesDataSource = new MatTableDataSource(list);
      })
    );
  }

  public customizeTableHeader(info: string): string {
    let result;

    if (info === '0') {
      result = this._translateService.translate('yesterday');
    } else {
      const yesterday = subDays(1, new Date());

      result = flow(subDays(parseInt(info, 10)), format('dd/MM'))(yesterday);
    }

    return result;
  }

  public getColumnsTotals(i: string): number {
    return this.storesSalesData.map(e => e.total[parseInt(i, 10)]).reduce((acc, val) => acc + val, 0);
  }

  public getLineTotals(): number {
    return this.storesSalesData.map(e => e.lineTotal).reduce((acc, val) => acc + val, 0);
  }
}
