import { HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../auth/services/authentication.service';
import { InterceptorSkipHeader } from '../../core/interceptors/skip-headers';
import { defaultAvatar } from '../../shared/models/default-avatar';
import { untilDestroy } from '../../shared/rxjs/operators/until-destroy';
import { SpinnerService } from '../../shared/services/spinner/spinner.service';
import { LoginAccount } from '../_models/login-account';
import { AccountListService } from './../_services/account-list.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['../login-form-base.scss', './login-form.component.scss']
})
export class LoginFormComponent implements OnInit, OnDestroy {
  public loginForm: UntypedFormGroup = null;
  public hide = true;
  public loginError = false;
  public submitError = false;
  public accountIcon: string;
  public currentAccount: string;
  public currentAccountDescription: string;

  constructor(
    public accountService: AccountListService,
    private router: Router,
    private authService: AuthenticationService,
    private spinnerService: SpinnerService,
    private _sanitizer: DomSanitizer
  ) {}

  /**
   * Creates Form Group
   *
   * @memberof LoginFormComponent
   */
  public ngOnInit(): void {
    this.loginForm = new UntypedFormGroup({
      account: new UntypedFormControl('', [Validators.required]),
      password: new UntypedFormControl('', [Validators.required]),
      remember: new UntypedFormControl(true)
    });

    if (this.accountService.selectedAccount) {
      this.currentAccount = this.accountService.selectedAccount.account;
      this.currentAccountDescription = this.accountService.selectedAccount.description;
      this.accountIcon = this.accountService.selectedAccount.icon;
      this.account.setValue(this.accountService.selectedAccount.account);
    } else {
      this.router.navigate(['/login/new']);
    }
  }

  public ngOnDestroy(): void {
    // Used by the rxjs operator untilDestroy
  }

  /**
   * Submits form
   *
   * @memberof LoginFormComponent
   */
  public onSubmit(): void {
    const headers = new HttpHeaders().set(InterceptorSkipHeader.antiforgery, 'skip').set(InterceptorSkipHeader.error, 'skip');
    const authAction = this.authService.login(this.account.value, this.password.value, this.remember.value, headers);
    const submitAction = this.spinnerService.showFor(authAction);

    this.loginError = false;
    this.submitError = false;

    submitAction.pipe(untilDestroy(this)).subscribe(
      li => {
        this.loginError = !li;
        if (li) {
          this.accountService.updateAccount(new LoginAccount(this.account.value, li.description, li.icon));
          this.router.navigate(['/']);
        }
      },
      err => {
        console.log(err);

        this.submitError = true;
      }
    );
  }

  /**
   * @ignore
   *
   * @readonly
   * @memberof LoginFormComponent
   */
  public get account(): AbstractControl {
    return this.loginForm.controls['account'];
  }

  /**
   * @ignore
   *
   * @readonly
   * @memberof LoginFormComponent
   */
  public get password(): AbstractControl {
    return this.loginForm.controls['password'];
  }

  /**
   * @ignore
   *
   * @readonly
   * @memberof LoginFormComponent
   */
  public get remember(): AbstractControl {
    return this.loginForm.controls['remember'];
  }

  public sanatizeIcon(icon: string): SafeUrl {
    return this._sanitizer.bypassSecurityTrustUrl(icon || defaultAvatar);
  }
}
