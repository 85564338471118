export enum XDProgram {
  None = 0,
  XDPos = 1,
  XDRest = 2,
  XDDisco = 3,
  XDGC = 4,
  XDSpa = 5,
  XDRetail = 6,
  XDWebAPI = 7
}
