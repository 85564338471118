import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { subMonths } from 'date-fns/esm/fp';
import { DxPieChartComponent } from 'devextreme-angular';
import { indexOf } from 'lodash-es';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ExportData } from '../../../shared/models/export-data';
import { ValueDataKind } from '../../../shared/models/value-data-kind';
import { CurrencyPipe } from '../../../shared/pipes/Currency.pipe';
import { untilDestroy } from '../../../shared/rxjs/operators/until-destroy';
import { ExportService } from '../../../shared/services/export-service/export.service';
import { SpinnerService } from '../../../shared/services/spinner/spinner.service';
import { TranslateService } from '../../../shared/services/translate-service/translate.service';
import { SalesmanAccumulatedTotal } from '../../model/accumulated-totals';
import { AccumulatedTotalsService } from '../../services/accumulated-totals.service';
import { ColumnConfig } from '../../../shared/models/column-config';

export enum unnecessaryKeys {
  syncDate = 'syncDate',
  qnt = 'qnt',
  syncGuid = 'syncGuid'
}

@Component({
  selector: 'app-salesman-chart',
  templateUrl: './salesman-chart.component.html',
  styleUrls: ['./salesman-chart.component.scss']
})
export class SalesmanChartComponent implements OnInit, OnDestroy {
  public topSalesman$: Observable<SalesmanAccumulatedTotal[]>;
  public topSalesmanData: SalesmanAccumulatedTotal[];
  public exportData: SalesmanAccumulatedTotal[];

  public topSalesmanDataSource = new MatTableDataSource<SalesmanAccumulatedTotal>();
  public topSalesmanTableColumns: string[];
  public salesmansTableVisible = false;
  public selectedSalesmanDate: {
    value: number;
    description: string;
  };
  @ViewChild(DxPieChartComponent, { static: false }) salesmansPieChart: DxPieChartComponent;
  @ViewChild('salesmansChart', { static: true, read: ElementRef })
  private salesmansChart: ElementRef;
  public topsInterval: Array<{ value: number; description: string }>;
  @Input() public requestedStores: string;

  constructor(
    private _accumulatedTotalsService: AccumulatedTotalsService,
    private _spinnerService: SpinnerService,
    private _translateService: TranslateService,
    private _exportservice: ExportService,
    public currencyPipe: CurrencyPipe
  ) {
    this.customizeTopsTooltip = this.customizeTopsTooltip.bind(this);
    this.topsInterval = new Array<{ value: number; description: string }>();
    this.topsInterval = [
      { value: 1, description: this._translateService.translate('last_month') },
      { value: 3, description: this._translateService.translate('last_3_months') },
      { value: 6, description: this._translateService.translate('last_6_months') }
    ];

    this.selectedSalesmanDate = this.topsInterval[0];
    this.topSalesmanTableColumns = new Array<string>();
  }

  public ngOnInit(): void {
    this.salesmanIntervalChanged();
  }

  public ngOnDestroy(): void {
    // Used by the rxjs operator untilDestroy
  }

  public salesmanIntervalChanged(): void {
    const now = new Date();
    this.topSalesman$ = this._spinnerService
      .showFor(
        this._accumulatedTotalsService.GetSalesmanAccumulatedTotalCloud(
          subMonths(this.selectedSalesmanDate.value, now),
          now,
          this.requestedStores
        ),
        this.salesmansChart
      )
      .pipe(
        untilDestroy(this),
        tap(list => {
          this.topSalesmanData = list.length > 10 ? list.slice(list.length - 10, list.length) : list;
          this.topSalesmanDataSource = new MatTableDataSource(list);
          this.topSalesmanDataSource.data.forEach(e => {
            for (const key in e) {
              if (this.topSalesmanTableColumns.indexOf(key) === -1) {
                if (key !== unnecessaryKeys.syncGuid && key !== unnecessaryKeys.syncDate && key !== unnecessaryKeys.qnt) {
                  this.topSalesmanTableColumns.push(key);
                }
              }
            }
          });
        })
      );
  }

  public showMoreSalesmanSales(): void {
    this.salesmansTableVisible = !this.salesmansTableVisible;
  }

  public customizeTopsTooltip(info: any): any {
    return {
      text: info.argumentText + '<br/>' + this.currencyPipe.transform(info.valueText, null, null, null, null)
    };
  }

  public updateStores(value: string): void {
    this.requestedStores = value;
    this.salesmanIntervalChanged();
  }

  public exportXlsx(): void {
    this.topSalesman$.pipe(untilDestroy(this)).subscribe(list => {
      this.exportData = list;
      this.exportData.forEach(row => {
        delete row.syncDate;
        delete row.qnt;
        delete row.syncGuid;
      });
      const header = this.getExportColumnConfigs();
      const fileName = this._translateService.translate('onlineSummary_LoadingSalesmenSales');
      const exportData = new ExportData(null, this.exportData, header, fileName);

      this._exportservice.ExportXLSX(exportData);
    });
  }

  public exportChartPdf(): void {
    const fileName = this._translateService.translate('storeSummary_topSalesmanChartTitle');
    this.salesmansPieChart?.instance.exportTo(fileName, 'pdf');
  }

  public exportChartPng() {
    const fileName = this._translateService.translate('storeSummary_topSalesmanChartTitle');
    this.salesmansPieChart?.instance.exportTo(fileName, 'png');
  }

  public getExportColumnConfigs(): ColumnConfig[] {
    return [
      {
        header: this._translateService.translate('salesmanName'),
        propertyName: 'salesmanName',
        kind: ValueDataKind.string,
        columnConfigs: null,
        usesParentHeader: false
      },
      {
        header: this._translateService.translate('storeName'),
        propertyName: 'storeName',
        kind: ValueDataKind.string,
        columnConfigs: null,
        usesParentHeader: false
      },
      {
        header: this._translateService.translate('storeId'),
        propertyName: 'storeId',
        kind: ValueDataKind.number,
        columnConfigs: null,
        usesParentHeader: false
      },
      {
        header: this._translateService.translate('salesmanId'),
        propertyName: 'salesmanId',
        kind: ValueDataKind.number,
        columnConfigs: null,
        usesParentHeader: false
      },
      {
        header: this._translateService.translate('totalNet'),
        propertyName: 'totalNet',
        kind: ValueDataKind.currency,
        columnConfigs: null,
        usesParentHeader: false
      },
      {
        header: this._translateService.translate('total'),
        propertyName: 'total',
        kind: ValueDataKind.currency,
        columnConfigs: null,
        usesParentHeader: false
      },
      {
        header: this._translateService.translate('totalDiscountsNet'),
        propertyName: 'totalDiscountsNet',
        kind: ValueDataKind.currency,
        columnConfigs: null,
        usesParentHeader: false
      },
      {
        header: this._translateService.translate('totalDiscounts'),
        propertyName: 'totalDiscounts',
        kind: ValueDataKind.currency,
        columnConfigs: null,
        usesParentHeader: false
      },
      {
        header: this._translateService.translate('costNet'),
        propertyName: 'costNet',
        kind: ValueDataKind.currency,
        columnConfigs: null,
        usesParentHeader: false
      },
      {
        header: this._translateService.translate('cost'),
        propertyName: 'cost',
        kind: ValueDataKind.currency,
        columnConfigs: null,
        usesParentHeader: false
      },
      {
        header: this._translateService.translate('profitNet'),
        propertyName: 'profitNet',
        kind: ValueDataKind.currency,
        columnConfigs: null,
        usesParentHeader: false
      },
      {
        header: this._translateService.translate('profit'),
        propertyName: 'profit',
        kind: ValueDataKind.currency,
        columnConfigs: null,
        usesParentHeader: false
      }
    ];
  }
}
