<article class="col m-1 mat-card-content">
  <div class="widgetContainer">
    <ng-container *ngIf="widget$ | async as templateWidget">
      <section class="p-1 m-1 d-inline-flex w-100">
        <div class="w-100 center-widget-title" *ngIf="templateWidget.configuration?.showLabel">
          <strong class="text-truncate text-success" [style.font-size]="titleSize + 'em'">{{ templateWidget.name }}</strong>
        </div>
        <button mat-icon-button *ngIf="isOverlaying" (click)="goBack()">
          <mat-icon>exit_to_app</mat-icon>
        </button>
        <button mat-icon-button *ngIf="templateWidget.configuration?.supportsConfig" (click)="configure()">
          <mat-icon fontSet="material-icons-outlined">settings_applications</mat-icon>
        </button>
        <button mat-icon-button *ngIf="templateWidget.configuration?.supportsRefresh && !isOverlaying" (click)="refresh()">
          <mat-icon>cached</mat-icon>
        </button>
      </section>

      <section col-12 class="widget-overflow">
        <ng-container *ngIf="templateWidget.values?.length; else emptyReport">
          <ng-container *ngFor="let value of templateWidget.values">
            <ng-container *ngIf="!paginationActive">
              <app-report-widget-value [value]="value" [valuesConfiguration]="templateWidget.valuesConfiguration"></app-report-widget-value>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="paginationActive">
            <app-report-widget-container
              [cloudWidget]="cloudWidget"
              [layout]="layout"
              [rowspanHeight]="rowspan"
              [widgetHeight]="defaultWidgetHeight"
              [titleHeight]="titleSize"
            ></app-report-widget-container>
          </ng-container>
        </ng-container>
      </section>
    </ng-container>
    <ng-container *ngIf="hasError$ | async; then errorReport"></ng-container>
  </div>
</article>

<ng-template #errorReport>
  <div class="center-error">
    <div class="pl-3 pr-3">
      <span
        i18n="ReportWidgetComponentError|Report Widget Component Error Label@@report_unavailableWidget"
        >Informação indisponível</span
      >
    </div>
    <p>
      <button
        mat-flat-button
        (click)="refresh()"
        i18n="ReportWidgetComponentErrorRetry|Report Widget Component Error retry Label@@report_retryWidget"
        >Tentar novamente</button
      >
    </p>
  </div>
</ng-template>

<ng-template #emptyReport>
  <app-empty-container class="emptyContainer"></app-empty-container>
</ng-template>
