import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, HostBinding, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../../auth/services/authentication.service';
import { StoreService } from '../../../entities/store/store.service';
import { Language, LocalizationService } from '../../../shared/services/localization/localization.service';
import { NotificationService } from '../../../shared/services/notification/notification.service';
import { SpinnerService } from '../../../shared/services/spinner/spinner.service';
import { ThemingService } from '../../../shared/services/theming/theming.service';
import { ServiceTaxService } from '../../../shared/services/local-storage-tax-service/local-storage-tax-service.service';
import { LoginInfoService } from '../../../shared/services/login-info/login-info.service';
import { XDCountryISO } from '../../../shared/models/xdcountry-ISO';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent implements OnInit {
  public opened: boolean;
  public notifyPanelOpened: boolean;
  public notificationCount$: Observable<number>;
  public hasNotifications$: Observable<boolean>;
  public LanguageEnum = Language;
  public language$: Observable<Language>;
  public hasMultipleStores$: Observable<boolean>;

  @HostBinding('class') public componentCssClass: string;
  public isHandset$: Observable<boolean> = this._breakpointObserver.observe(Breakpoints.Handset).pipe(map(result => result.matches));
  public loggedStatus$: Observable<boolean>;
  public isChecked: boolean = false;
  public displayCheckbox = false;

  constructor(
    private _breakpointObserver: BreakpointObserver,
    public themeService: ThemingService,
    private _authService: AuthenticationService,
    private _storeService: StoreService,
    private _router: Router,
    private _spinnerService: SpinnerService,
    public notificationService: NotificationService,
    private _localizationService: LocalizationService,
    private _serviceTaxService: ServiceTaxService,
    private _loginInfoService: LoginInfoService
  ) {
    this.themeService.themeChanged$.subscribe(themeId => (this.componentCssClass = themeId));
    this.language$ = this._localizationService.language$;
    this.hasMultipleStores$ = this._storeService.storesCount$.pipe(map(count => count > 1));
    this.loggedStatus$ = this._authService.loggedStatus$;
  }

  public ngOnInit(): void {
    this.notificationCount$ = this.notificationService.notificationCount$;
    this.hasNotifications$ = this.notificationCount$.pipe(map(count => count > 0));
    this.componentCssClass = this.themeService.selected;
    if (this.isDisplayCheckbox() && this._serviceTaxService.get()) {
      this.isChecked = true;
    }
  }

  public onLogout(): void {
    this.opened = false;
    this.notifyPanelOpened = false;
    this._spinnerService.showFor(this._authService.logout()).subscribe(()=>{
      this._router.navigate(['login']);
    });

  }

  public onSetTheme(themeId: string): void {
    this.themeService.setThemeId(themeId);
  }

  public onSetLanguage(lang: Language): void {
    this._localizationService.setLanguage(lang);
  }

  public onCheckboxChange(): void {
    if (this.isChecked) this._serviceTaxService.set(this.isChecked);
    else this._serviceTaxService.delete();
  }

  private isDisplayCheckbox(): boolean {
    const infoLogin = this._loginInfoService.get();
    if (infoLogin.countryISO === XDCountryISO.BR) {
      this.displayCheckbox = true;
      return true;
    }
    return false;
  }
}
