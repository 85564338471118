import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Type } from '@angular/core';
import { parseJSON } from 'date-fns/esm/fp';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { formatServiceRequestDatetime } from '../../shared/utils/date-time.utils';
import {
  AccumulatedTotals,
  ItemAccumulatedTotal,
  ItemGroupAccumulatedTotal,
  SalesmanAccumulatedTotal,
  TerminalAccumulatedTotal
} from '../model/accumulated-totals';
import { AccumulatedTotalsDateParameters, AccumulatedTotalsDateParametersEnum } from '../model/accumulated-totals-date-parameters-enum';

const DATE_PARAMETERS = 'AccumulatedDateParameters';
const API_ENDPOINT = `${environment.api.schema}://${environment.api.host}`;
const ACCUMULATED_ENDPOINT = `${API_ENDPOINT}/accumulatedtotals`;
const SALES_FULL_ENDPOINT = `${ACCUMULATED_ENDPOINT}/sales/full`;
const SALES_TOP_ENDPOINT = `${ACCUMULATED_ENDPOINT}/sales/top`;
const ITEMGROUP_FULL_ENDPOINT = `${ACCUMULATED_ENDPOINT}/itemgroup/full`;
const SALESMAN_ENDPOINT = `${ACCUMULATED_ENDPOINT}/salesman`;
const SALESMAN_FULL_ENDPOINT = `${SALESMAN_ENDPOINT}/full`;
const TERMINAL_ENDPOINT = `${ACCUMULATED_ENDPOINT}/terminal`;
const ITEMGROUP_ENDPOINT = `${ACCUMULATED_ENDPOINT}/itemgroup`;
const ITEMS_ENDPOINT = `${ACCUMULATED_ENDPOINT}/items`;

interface IAccumulatedTotalsDTO extends Omit<AccumulatedTotals, 'syncDate'> {
  syncDate: string;
}

function toAccumulatedTotals<T extends IAccumulatedTotalsDTO, K extends AccumulatedTotals>(dtos: T[], subType: Type<K>): K[] {
  return dtos.map(dto => {
    const accTotals = new subType();

    Object.assign(accTotals, dto);
    accTotals.syncDate = parseJSON(dto.syncDate);

    return accTotals;
  });
}

@Injectable({
  providedIn: 'root'
})
export class AccumulatedTotalsService {
  private readonly _http: HttpClient = null;
  private _dateParameters: AccumulatedTotalsDateParameters;

  constructor(http: HttpClient) {
    this._http = http;
  }

  public GetSalesAccumulatedTotalCloudFull(initialDate: Date, finalDate: Date): Observable<ItemAccumulatedTotal[]> {
    const parameters = new HttpParams()
      .set('initialDate', formatServiceRequestDatetime(initialDate))
      .set('finalDate', formatServiceRequestDatetime(finalDate));
    return this._http
      .get<IAccumulatedTotalsDTO[]>(`${SALES_FULL_ENDPOINT}`, {
        params: parameters,
        withCredentials: true
      })
      .pipe(map(accTotals => toAccumulatedTotals(accTotals, ItemAccumulatedTotal)));
  }

  public GetSalesAccumulatedTotalCloudTop(initialDate: Date, finalDate: Date): Observable<ItemAccumulatedTotal[]> {
    const parameters = new HttpParams()
      .set('initialDate', formatServiceRequestDatetime(initialDate))
      .set('finalDate', formatServiceRequestDatetime(finalDate));
    return this._http
      .get<IAccumulatedTotalsDTO[]>(`${SALES_TOP_ENDPOINT}`, {
        params: parameters,
        withCredentials: true
      })
      .pipe(map(accTotals => toAccumulatedTotals(accTotals, ItemAccumulatedTotal)));
  }

  public GetItemGroupAccumulatedTotalCloudFull(initialDate: Date, finalDate: Date): Observable<ItemGroupAccumulatedTotal[]> {
    const parameters = new HttpParams()
      .set('initialDate', formatServiceRequestDatetime(initialDate))
      .set('finalDate', formatServiceRequestDatetime(finalDate));
    return this._http
      .get<IAccumulatedTotalsDTO[]>(`${ITEMGROUP_FULL_ENDPOINT}`, {
        params: parameters,
        withCredentials: true
      })
      .pipe(map(accTotals => toAccumulatedTotals(accTotals, ItemGroupAccumulatedTotal)));
  }

  public getItemGroupByStore(itemGroupId: any, initialDate: Date, finalDate: Date): Observable<ItemGroupAccumulatedTotal[]> {
    const parameters = new HttpParams()
      .set('initialDate', formatServiceRequestDatetime(initialDate))
      .set('finalDate', formatServiceRequestDatetime(finalDate));
    return this._http
      .get<IAccumulatedTotalsDTO[]>(`${ITEMGROUP_FULL_ENDPOINT}/${itemGroupId}`, {
        params: parameters,
        withCredentials: true
      })
      .pipe(map(accTotals => toAccumulatedTotals(accTotals, ItemGroupAccumulatedTotal)));
  }

  public GetSalesmanAccumulatedTotalCloudFull(initialDate: Date, finalDate: Date): Observable<SalesmanAccumulatedTotal[]> {
    const parameters = new HttpParams()
      .set('initialDate', formatServiceRequestDatetime(initialDate))
      .set('finalDate', formatServiceRequestDatetime(finalDate));
    return this._http
      .get<IAccumulatedTotalsDTO[]>(`${SALESMAN_FULL_ENDPOINT}`, {
        params: parameters,
        withCredentials: true
      })
      .pipe(map(accTotals => toAccumulatedTotals(accTotals, SalesmanAccumulatedTotal)));
  }

  public GetSalesmanAccumulatedTotalCloud(initialDate: Date, finalDate: Date, storeId: string): Observable<SalesmanAccumulatedTotal[]> {
    const parameters = new HttpParams()
      .set('initialDate', formatServiceRequestDatetime(initialDate))
      .set('finalDate', formatServiceRequestDatetime(finalDate));
    return this._http
      .get<IAccumulatedTotalsDTO[]>(`${SALESMAN_ENDPOINT}/${storeId}`, {
        params: parameters,
        withCredentials: true
      })
      .pipe(map(accTotals => toAccumulatedTotals(accTotals, SalesmanAccumulatedTotal)));
  }

  public GetTerminalAccumulatedTotalCloud(initialDate: Date, finalDate: Date, storeId: string): Observable<TerminalAccumulatedTotal[]> {
    const parameters = new HttpParams()
      .set('initialDate', formatServiceRequestDatetime(initialDate))
      .set('finalDate', formatServiceRequestDatetime(finalDate));
    return this._http
      .get<IAccumulatedTotalsDTO[]>(`${TERMINAL_ENDPOINT}/${storeId}`, {
        params: parameters,
        withCredentials: true
      })
      .pipe(map(accTotals => toAccumulatedTotals(accTotals, TerminalAccumulatedTotal)));
  }

  public GetItemsAccumulatedTotalCloud(initialDate: Date, finalDate: Date, storeId: string): Observable<ItemAccumulatedTotal[]> {
    const parameters = new HttpParams()
      .set('initialDate', formatServiceRequestDatetime(initialDate))
      .set('finalDate', formatServiceRequestDatetime(finalDate));
    return this._http
      .get<IAccumulatedTotalsDTO[]>(`${ITEMS_ENDPOINT}/${storeId}`, {
        params: parameters,
        withCredentials: true
      })
      .pipe(map(accTotals => toAccumulatedTotals(accTotals, ItemAccumulatedTotal)));
  }

  public GetItemGroupAccumulatedTotalCloud(initialDate: Date, finalDate: Date, storeId: string): Observable<ItemGroupAccumulatedTotal[]> {
    const parameters = new HttpParams()
      .set('initialDate', formatServiceRequestDatetime(initialDate))
      .set('finalDate', formatServiceRequestDatetime(finalDate));
    return this._http
      .get<IAccumulatedTotalsDTO[]>(`${ITEMGROUP_ENDPOINT}/${storeId}`, {
        params: parameters,
        withCredentials: true
      })
      .pipe(map(accTotals => toAccumulatedTotals(accTotals, ItemGroupAccumulatedTotal)));
  }

  public get DateParameters(): AccumulatedTotalsDateParameters {
    if (!this._dateParameters) {
      this._dateParameters = new AccumulatedTotalsDateParameters({
        key: AccumulatedTotalsDateParametersEnum.lastWeek,
        initialDate: this.GetInitialDateFromEnum(AccumulatedTotalsDateParametersEnum.lastWeek),
        finalDate: new Date(),
        customInititalDate: null,
        customFinalDate: null
      });

      const storedParameters = localStorage.getItem(DATE_PARAMETERS);

      if (storedParameters !== null) {
        const dateParameters: any = JSON.parse(storedParameters);

        this._dateParameters.key = dateParameters.key;

        if (dateParameters.key !== AccumulatedTotalsDateParametersEnum.custom) {
          this._dateParameters.initialDate = this.GetInitialDateFromEnum(dateParameters.key);
          this._dateParameters.finalDate = new Date();
          this._dateParameters.customInititalDate = null;
          this._dateParameters.customFinalDate = null;
        } else {
          this._dateParameters.customInititalDate = parseJSON(dateParameters.customInititalDate);
          this._dateParameters.customFinalDate = parseJSON(dateParameters.customFinalDate);
        }
      }
    }

    return this._dateParameters;
  }

  public set DateParameters(value: AccumulatedTotalsDateParameters) {
    this._dateParameters = value;
    const json = JSON.stringify(value);
    localStorage.setItem(DATE_PARAMETERS, json);
  }

  public GetInitialDateFromEnum(value: AccumulatedTotalsDateParametersEnum): Date {
    let initialDate = new Date();

    switch (value) {
      case AccumulatedTotalsDateParametersEnum.last5Year: {
        initialDate = new Date(initialDate.setFullYear(initialDate.getFullYear() - 5));
        break;
      }
      case AccumulatedTotalsDateParametersEnum.lastMonth: {
        initialDate = new Date(initialDate.setMonth(initialDate.getMonth() - 1));
        break;
      }
      case AccumulatedTotalsDateParametersEnum.lastWeek: {
        initialDate = new Date(initialDate.setDate(initialDate.getDate() - 7));
        break;
      }
      case AccumulatedTotalsDateParametersEnum.lastYear: {
        initialDate = new Date(initialDate.setFullYear(initialDate.getFullYear() - 1));
        break;
      }
    }

    return initialDate;
  }
}
