export enum WidgetValueDisplayKind {
  none = 0,
  text = 1,
  avatar = 2,
  bullet = 4,
  slide = 8,
  progress = 16,
  emphasize = 32,
  underemphasize = 64
}

export enum WidgetKind {
  none = 0,
  chart = 1,
  report = 2,
  dynamicChart = 3,
  dynamicReport = 4,
  image = 5,
  menu = 6,
  newsFeed = 7,
  webPage = 8,
  store = 9
}

export enum WidgetParameterConfigurationOptionsKind {
  none = 0,
  lookup = 1,
  combo = 2,
  list = 3
}
