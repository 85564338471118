import { Component } from '@angular/core';

import { WidgetValueComponent } from '../widget-value.component';

@Component({
  selector: 'app-text-value',
  templateUrl: './text-value.component.html',
  styleUrls: ['../widget-value.component.scss', './text-value.component.scss']
})
export class TextValueComponent extends WidgetValueComponent {}
