import { ColumnConfig } from '../models/column-config';

export class ExportData {
  public data: any[];
  public recursionProperty: string;
  public columnConfigs: ColumnConfig[];
  public fileName: string;

  public constructor(recursionProperty: string, data: any[], columnConfigs: ColumnConfig[], fileName: string) {
    this.data = data;
    this.columnConfigs = columnConfigs;
    this.fileName = fileName;
    this.recursionProperty = recursionProperty;
  }
}
