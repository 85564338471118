//#region External dependencies
import { LayoutModule } from '@angular/cdk/layout';
import { Overlay } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BusinessSummaryModule } from '../business-summary/business-summary.module';
//#endregion
import { SharedModule } from '../shared/shared.module';
import { WidgetsModule } from '../widgets/widgets.module';
import { FooterComponent } from './components/footer/footer.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { LegacyViewerComponent } from './components/legacy-viewer/legacy-viewer.component';
import { MainNavComponent } from './components/main-nav/main-nav.component';
import { AntiforgeryInterceptor } from './interceptors/antiforgery.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { XsrfInterceptor } from './interceptors/xsrf.interceptor';
import { XsrfCookieExtractorService } from './services/xsrf-cookie-extractor.service';

@NgModule({
  declarations: [LandingPageComponent, FooterComponent, MainNavComponent, LegacyViewerComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    MatToolbarModule,
    MatButtonModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    LayoutModule,
    MatSidenavModule,
    MatListModule,
    MatBadgeModule,
    MatFormFieldModule,
    MatInputModule,
    WidgetsModule,
    MatExpansionModule,
    MatTableModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    BusinessSummaryModule,
    MatSelectModule,
    MatCheckboxModule,
    FormsModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AntiforgeryInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: XsrfInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    Overlay,
    XsrfCookieExtractorService
  ],
  exports: [LandingPageComponent, FooterComponent, MainNavComponent]
})
export class CoreModule {}
