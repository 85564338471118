import { OverlayContainer } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DynamicOverlayContainerService extends OverlayContainer {
  /**
   * Sets the container where the overlay will be created
   *
   * @param htmlElement - Element for the overlay
   */
  public setContainerElement(htmlElement: HTMLElement): void {
    this._containerElement = htmlElement;
  }
}
