import { ViewContainerRef } from '@angular/core';

export class ElementCreationData<T> {
  public containerRef: ViewContainerRef = null;
  public element: T = null;

  constructor(init?: Partial<ElementCreationData<T>>) {
    Object.assign(this, init);
  }
}
