import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { parseJSON } from 'date-fns/esm/fp';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../../../auth/services/authentication.service';
import { CurrencyConfig } from '../../../shared/models/CurrencyConfig';

const CONFIG_STORAGE = 'CloudCurrencyConfig';
const DEFAULT_CURRENCY_ENDPOINT = `${environment.api.schema}://${environment.api.host}/currency/default`;

export interface ICurrencyConfigDTO extends Omit<CurrencyConfig, 'date' | 'syncStamp'> {
  date: string;
  syncStamp: string;
}

function toCurrencyConfig(dto: ICurrencyConfigDTO): CurrencyConfig {
  const currencyConfig = new CurrencyConfig();

  Object.assign(currencyConfig, dto);
  currencyConfig.date = parseJSON(dto.date);
  currencyConfig.syncStamp = parseJSON(dto.syncStamp);

  return currencyConfig;
}

@Injectable({
  providedIn: 'root'
})

/**
 * Handles and serves Currency config
 *
 * @export
 * @class CurrencyConfigService
 */
export class CurrencyConfigService {
  private _defaultCurrencyConfig = new CurrencyConfig({
    decimalPlaces: 2,
    decimalItemPlaces: 2,
    symbolPosition: 1,
    symbol: '€'
  });
  private _currentConfig: CurrencyConfig;
  private _mask_value: string;

  constructor(private _httpClient: HttpClient, private _authenticationService: AuthenticationService) {
    this.loadLocalCurrencyConfig();

    _authenticationService.isLogged().subscribe(
      status => {
        if (status) {
          this.init();
        }
      },
      error => {
        this.setCurrencyConfig(this._defaultCurrencyConfig);
        console.log(error);
      }
    );
  }

  private loadLocalCurrencyConfig(): void {
    try {
      const json = localStorage.getItem(CONFIG_STORAGE);
      const loadedCurrencyConfig = <ICurrencyConfigDTO>JSON.parse(json);
      const currencyConfig: CurrencyConfig = loadedCurrencyConfig ? toCurrencyConfig(loadedCurrencyConfig) : this._defaultCurrencyConfig;

      this.setCurrencyConfig(currencyConfig);
    } catch (error) {
      this.setCurrencyConfig(this._defaultCurrencyConfig);
    }
  }

  private init(): void {
    const options = { withCredentials: true };

    this._httpClient
      .get<ICurrencyConfigDTO>(DEFAULT_CURRENCY_ENDPOINT, options)
      .pipe(map(toCurrencyConfig))
      .subscribe(
        (currency: CurrencyConfig) => {
          this.setCurrencyConfig(currency);
        },
        error => {
          this.setCurrencyConfig(this._defaultCurrencyConfig);
          console.log(error);
        }
      );
  }

  public get mask(): string {
    return this._mask_value;
  }

  public updateMask(): void {
    this._mask_value = '0*.';

    for (let i = 0; i < this._currentConfig.decimalItemPlaces; i++) {
      this._mask_value += '0';
    }
  }

  public get customPatterns(): any {
    return {
      'V': { pattern: new RegExp('-|[0-9]') },
      '0': { pattern: new RegExp('[0-9]') }
    };
  }

  public get sufix(): string {
    if (this._currentConfig.symbolPosition === 1) {
      return ' ' + this._currentConfig.symbol;
    }

    return '';
  }

  public get prefix(): string {
    if (this._currentConfig.symbolPosition !== 1) {
      return this._currentConfig.symbol + ' ';
    }

    return '';
  }

  public getConfig(): CurrencyConfig {
    return this._currentConfig;
  }

  public setCurrencyConfig(value: CurrencyConfig): void {
    this._currentConfig = value;
    localStorage.setItem(CONFIG_STORAGE, JSON.stringify(value));
    this.updateMask();
  }

  public getFormat(): string {
    return `1.${this._currentConfig.decimalPlaces}-${this._currentConfig.decimalPlaces}`;
  }
}
