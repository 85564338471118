import { OverlayRef } from '@angular/cdk/overlay';

/***
 * @summary Extend a component with this asbtract class to allow it to be overlayed on top
 * of another component by the ComponentOverlayService.
 */
export abstract class ComponentOverlay {
  private _overlayRef: OverlayRef = null;
  public get isOverlaying(): boolean {
    return this._overlayRef && this._overlayRef.hasAttached();
  }

  private dispose(): void {
    if (this._overlayRef) {
      if (this.isOverlaying) {
        this._overlayRef.detach();
      }

      this._overlayRef.dispose();
      this._overlayRef = null;
    }
  }

  public setOverlay(overlayRef: OverlayRef): void {
    if (this._overlayRef) {
      this.dispose();
    }

    this._overlayRef = overlayRef;
  }

  public close(): void {
    this.dispose();
  }
}
