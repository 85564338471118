import { transition, trigger, useAnimation } from '@angular/animations';

import { slideLeftAnimation } from '../shared/animations/slide-left.animation';
import { slideRightAnimation } from '../shared/animations/slide-right.animation';

export const slideInAnimation = trigger('animationTrigger', [
  transition('list => login', [useAnimation(slideLeftAnimation)]),
  transition('list => new', [useAnimation(slideLeftAnimation)]),
  transition('new => submit', [useAnimation(slideLeftAnimation)]),
  transition('list => remove', [useAnimation(slideLeftAnimation)]),
  transition('login => list', [useAnimation(slideRightAnimation)]),
  transition('new => list', [useAnimation(slideRightAnimation)]),
  transition('submit => list', [useAnimation(slideRightAnimation)]),
  transition('submit => new', [useAnimation(slideRightAnimation)]),
  transition('remove => list', [useAnimation(slideRightAnimation)]),
  transition('remove => new', [useAnimation(slideLeftAnimation)])
]);
