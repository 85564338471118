import { Observable, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/***
 * Based on https://github.com/w11k/ngx-componentdestroyed
 * is almost the same, I just don't want to expose the componentDestroyed function
 * and fixed some linting warnings so kudos to the developers of this package.
 */
function componentDestroyed(component: { ngOnDestroy(): void }): Observable<true> {
  const modifiedComponent = component as { __componentDestroyed$?: Observable<true>; ngOnDestroy(): void };
  if (modifiedComponent.__componentDestroyed$) {
    return modifiedComponent.__componentDestroyed$;
  }
  const oldNgOnDestroy = component.ngOnDestroy.bind(component);
  const stop = new ReplaySubject<true>();
  modifiedComponent.ngOnDestroy = function () {
    if (oldNgOnDestroy) {
      oldNgOnDestroy();
    }
    stop.next(true);
    stop.complete();
  };
  return (modifiedComponent.__componentDestroyed$ = stop.asObservable());
}

/**
 * Completes observable upon component destruction
 */
export function untilDestroy<T>(component: { ngOnDestroy(): void }): (source$: Observable<T>) => Observable<T> {
  return (source$: Observable<T>) => source$.pipe(takeUntil(componentDestroyed(component)));
}
