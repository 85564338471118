import { Injectable } from '@angular/core';
import { PlaceholderConfiguration } from '../../models/placeholder-configuration';

/**
 * Provides a simple way to replace the values of the properties from an object inside a template string.
 * Be aware that in this context template has nothing to do with a Angular TemplateRef.
 */
@Injectable({
  providedIn: 'root'
})
export class TemplateRendererService {
  /**
   * Creates a function that when given an object of type T it renders a new string replacing properties from such object,
   * according to the configured placeholders.
   * @param {string} template String template to be processed and rendered into a new string.
   * @param {PlaceholderConfiguration<T>} placeholders Template placeholder configurations used by the resulting template
   * renderer.
   */
  public CreateTemplateRenderer<T>(template: string, placeholders: PlaceholderConfiguration<T>[]): (obj: T) => string {
    return (obj: T) => {
      const result: string = placeholders.reduce((acc: string, conf: PlaceholderConfiguration<T>) => {
        const placeholder: string = conf.placeholder ? conf.placeholder : `{${conf.propertyName}}`;
        let reduced = '';

        if (conf.pipes) {
          let transformedValue = obj[conf.propertyName];

          conf.pipes.forEach(pipeConfig => {
            try {
              transformedValue = pipeConfig.pipe.transform(transformedValue, ...(pipeConfig.pipeArgs || []));
            } catch (err) {
              console.log(err);
            }
          });
          reduced = acc.replace(placeholder, String(transformedValue));
        } else {
          reduced = acc.replace(placeholder, String(obj[conf.propertyName]));
        }
        return reduced;
      }, template);

      return result;
    };
  }
}
