import { ComponentType, Overlay, OverlayConfig, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ElementRef, Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { PopUpContainerComponent } from '../../components/pop-up-container/pop-up-container.component';

const CDK_OVERLAY_TRANSPARENT_BACKDROP = 'cdk-overlay-transparent-backdrop';

@Injectable({
  providedIn: 'root'
})
export class PopUpService {
  private readonly _overlayMap: Map<OverlayRef, Subscription[]>;

  constructor(private _overlay: Overlay) {
    this._overlayMap = new Map<OverlayRef, Subscription[]>();
  }

  /**
   * @returns Observable that emits on popup close
   */
  public openPopup<T>(
    content: ComponentType<T>,
    relativeTo: ElementRef,
    position?: PositionStrategy,
    defaultsSetter?: (c: T) => void
  ): Observable<T> {
    const overlayConfig: OverlayConfig = this.getOverlayConfig(relativeTo, position);
    const overlayRef = this._overlay.create(overlayConfig);

    this._overlayMap.set(overlayRef, []);

    let subscription = overlayRef.backdropClick().subscribe(() => this.detachOverlay(overlayRef));

    this._overlayMap.get(overlayRef).push(subscription);

    const popupContainerRef = overlayRef.attach(new ComponentPortal(PopUpContainerComponent));
    const componentRef = popupContainerRef.instance.attach(content);

    if (defaultsSetter) {
      defaultsSetter(componentRef.instance);
    }

    subscription = popupContainerRef.instance.close.pipe().subscribe(() => {
      this.detachOverlay(overlayRef);
    });
    this._overlayMap.get(overlayRef).push(subscription);

    return popupContainerRef.instance.close.pipe(map(() => componentRef.instance));
  }

  private detachOverlay(overlayRef: OverlayRef): void {
    if (overlayRef) {
      if (this._overlayMap.has(overlayRef)) {
        this._overlayMap.get(overlayRef).forEach(s => s.unsubscribe());
        this._overlayMap.delete(overlayRef);
      }

      overlayRef.detach();
      overlayRef.dispose();
    }
  }

  private getOverlayConfig(relativeTo: ElementRef<any>, position: PositionStrategy): OverlayConfig {
    const overlayConfig = new OverlayConfig();
    overlayConfig.disposeOnNavigation = true;
    overlayConfig.hasBackdrop = true;
    overlayConfig.backdropClass = CDK_OVERLAY_TRANSPARENT_BACKDROP;
    overlayConfig.scrollStrategy = this._overlay.scrollStrategies.reposition();
    overlayConfig.positionStrategy =
      position ||
      this._overlay
        .position()
        .flexibleConnectedTo(relativeTo)
        .withFlexibleDimensions()
        .withViewportMargin(5)
        .withGrowAfterOpen()
        .withPositions([
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom'
          },
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top'
          }
        ])
        .withPush();

    return overlayConfig;
  }
}
