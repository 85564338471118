<div class='container login-wrapper'>
  <div class='cloud-hero-container'>
    <div class='cloud-hero'></div>
  </div>
  <div class='cloud-hero-container'>
    <div class='cloud-login' [@animationTrigger]="getState(routerOutlet)">
      <router-outlet #routerOutlet="outlet"></router-outlet>
    </div>
  </div>
</div>
