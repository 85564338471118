import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AccountListComponent } from './account-list/account-list.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { LoginWrapperComponent } from './login-wrapper/login-wrapper.component';
import { NewLoginFormSubmitComponent } from './new-login-form-submit/new-login-form-submit.component';
import { NewLoginFormComponent } from './new-login-form/new-login-form.component';
import { RemoveAccountComponent } from './remove-account/remove-account.component';

const routes: Routes = [
  {
    path: '',
    component: LoginWrapperComponent,
    children: [
      { path: 'list', component: AccountListComponent, data: { state: 'list' } },
      { path: '', component: LoginFormComponent, data: { state: 'login' } },
      { path: 'new', component: NewLoginFormComponent, data: { state: 'new' } },
      { path: 'new-submit', component: NewLoginFormSubmitComponent, data: { state: 'submit' } },
      { path: 'remove-account', component: RemoveAccountComponent, data: { state: 'remove' } }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoginRoutingModule {}
