import { AfterViewInit, Component, QueryList, ViewChildren } from '@angular/core';

import { TranslateService } from '../../services/translate-service/translate.service';
import { TranslationDirective } from '../../directives/translate.directive';

@Component({
  selector: 'app-translate',
  templateUrl: './translate.component.html',
  styleUrls: ['./translate.component.scss']
})
export class TranslateComponent implements AfterViewInit {
  @ViewChildren(TranslationDirective) public children!: QueryList<TranslationDirective>;

  constructor(private translationsService: TranslateService) {}

  public ngAfterViewInit(): void {
    const translations = new Map<string, string>();

    this.children.forEach(value => {
      translations.set(value.id, value.value);
    });

    this.translationsService.setTranslations(translations);
  }
}
