<article class="col m-1 mat-card-content">
  <div class="widgetContainer">
    <ng-container *ngIf="widget$ | async as templateWidget">
      <div class="widgetHeadContainer">
        <section class="p-1 m-1 d-inline-flex w-100">
          <div class="w-100 center-widget-title" *ngIf="templateWidget.configuration?.showLabel">
            <strong class="text-truncate text-success" [style.font-size]="titleSize + 'em'">{{ templateWidget.name }} </strong>
          </div>
          <button mat-icon-button *ngIf="isOverlaying" (click)="goBack()">
            <mat-icon>exit_to_app</mat-icon>
          </button>
          <button mat-icon-button *ngIf="templateWidget.configuration?.supportsConfig" (click)="configure()">
            <mat-icon fontSet="material-icons-outlined">settings_applications</mat-icon>
          </button>
          <button mat-icon-button *ngIf="templateWidget.configuration?.supportsRefresh" (click)="refresh()">
            <mat-icon>cached</mat-icon>
          </button>
        </section>

        <ng-container *ngIf="dataSource$ | async as templateDatasource">
          <div *ngIf="templateDatasource.data?.length; else emptyReport" class="report-table">
            <div class="report-table-container">
              <table mat-table [dataSource]="templateDatasource" aria-describedby="dynamic repor data table">
                <ng-container [matColumnDef]="valueConfig.name" *ngFor="let valueConfig of valuesConfigs$ | async">
                  <th class="headers" mat-header-cell *matHeaderCellDef>{{ valueConfig.displayName }}</th>
                  <td class="cells" mat-cell *matCellDef="let row" matTooltip="{{ row[valueConfig.name] }}">
                    <div [ngClass]="{ 'linked': !!valueConfig.link }">
                      <ng-container [ngSwitch]="valueConfig.displayType">
                        <ng-container *ngSwitchCase="ValueDisplayKind.bullet">
                          <app-bullet-value [value]="row[valueConfig.name]" [valueConfig]="valueConfig"></app-bullet-value>
                        </ng-container>
                        <ng-container *ngSwitchCase="ValueDisplayKind.avatar">
                          <app-avatar-value [value]="row[valueConfig.name]" [valueConfig]="valueConfig"></app-avatar-value>
                        </ng-container>
                        <ng-container *ngSwitchCase="ValueDisplayKind.progress">
                          <app-progress-value [value]="row[valueConfig.name]" [valueConfig]="valueConfig"></app-progress-value>
                        </ng-container>
                        <ng-container *ngSwitchCase="ValueDisplayKind.slide">
                          <app-slide-value [value]="row[valueConfig.name]" [valueConfig]="valueConfig"></app-slide-value>
                        </ng-container>
                        <ng-container *ngSwitchCase="ValueDisplayKind.emphasize">
                          <app-emphasize-value [value]="row[valueConfig.name]" [valueConfig]="valueConfig"></app-emphasize-value>
                        </ng-container>
                        <ng-container *ngSwitchCase="ValueDisplayKind.underemphasize">
                          <app-emphasize-value [value]="row[valueConfig.name]" [valueConfig]="valueConfig"></app-emphasize-value>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                          <app-text-cell-value
                            *ngIf="row[valueConfig.name]"
                            [value]="row"
                            [valueConfig]="valueConfig"
                            (click)="followLink(valueConfig.link, row)"
                          ></app-text-cell-value>
                        </ng-container>
                      </ng-container>
                    </div>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="columns$ | async"></tr>
                <tr mat-row *matRowDef="let row; columns: columns$ | async"></tr>
              </table>
            </div>
          </div>
          <mat-paginator
            [ngClass]="{
              'show-paginator': showPaginator,
              'hide-paginator': !showPaginator
            }"
            (createElement)="onCreatePaginator(templateDatasource, $event)"
            [elementType]="MatPaginatorType"
            class="paginator"
            [pageSizeOptions]="[paginatorSize]"
            [pageSize]="paginatorSize"
          ></mat-paginator>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="hasError$ | async; then errorReport"></ng-container>
  </div>
</article>

<ng-template #errorReport>
  <div class="center-error">
    <div class="pl-3 pr-3">
      <span
        i18n="ReportWidgetComponentError|Report Widget Component Error Label@@dynamicReport_unavailableWidget"
        >Informação indisponível</span
      >
    </div>
    <p>
      <button
        mat-flat-button
        (click)="refresh()"
        i18n="ReportWidgetComponentErrorRetry|Report Widget Component Error retry Label@@dynamicReport_retryGetWidget"
        >Tentar novamente</button
      >
    </p>
  </div>
</ng-template>

<ng-template #emptyReport>
  <app-empty-container class="emptyContainer"></app-empty-container>
</ng-template>
