import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable } from 'rxjs';
import { CalloutComponent } from '../../components/callout/callout.component';
import { Callout } from '../../models/callout';

@Injectable({
  providedIn: 'root'
})
export class CalloutService {
  private _list: BehaviorSubject<Callout> = new BehaviorSubject<Callout>(null);
  public _snackBar: MatSnackBar;

  constructor(snackBar: MatSnackBar) {
    this._snackBar = snackBar;
  }

  public get calloutList$(): Observable<Callout> {
    return this._list.asObservable();
  }

  public addCallout(value: Callout): void {
    this._list.next(value);
    // this._snackBar.open(value.message, null, {duration: 5000, data: {} });
    this._snackBar.openFromComponent(CalloutComponent, {
      duration: value.timeout,
      data: { callout: value, snackbar: this._snackBar },
      horizontalPosition: 'right',
      verticalPosition: 'top',
      politeness: 'polite'
    });
  }
}
