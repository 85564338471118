<div class="widget-carousel-wrapper" (swipeleft)="swipeLeft($event)" (swiperight)="swipeRight($event)">
  <div class="widget-carousel" (swipeleft)="swipeLeft($event)" (swiperight)="swipeRight($event)">
    <button
      mat-icon-button
      [hidden]="pageCount === 1"
      class="widget-carousel-navButtons"
      type="button"
      color="primary"
      i18n-matTooltip="WidgetCarouselContainerPreviousPage|Widget carousel container previous page@@widgetCarouselContainer_previousPage"
      matTooltip="Página anterior"
      (click)="previous()"
    >
      <mat-icon>navigate_before</mat-icon>
    </button>
    <mat-tab-group
      class="widgets-pages-container"
      [selectedIndex]="selectedPage"
      (swipeleft)="swipeLeft($event)"
      (swiperight)="swipeRight($event)"
    >
      <mat-tab *ngFor="let widgets of pages$ | async" (swipeleft)="swipeLeft($event)" (swiperight)="swipeRight($event)">
        <ng-container *ngFor="let widget of widgets">
          <div
            [ngClass]="{ 'selected-widget': selectedWidget === widget.id }"
            (swipeleft)="swipeLeft($event)"
            (swiperight)="swipeRight($event)"
          >
            <ng-container (createElement)="onCreateWidgetContainer(widget, $event)"></ng-container>
          </div>
        </ng-container>
      </mat-tab>
    </mat-tab-group>
    <button
      mat-icon-button
      [hidden]="this.pageCount === 1"
      class="widget-carousel-navButtons"
      type="button"
      color="primary"
      i18n-matTooltip="WidgetCarouselContainerNextPage|Widget carousel container next page@@widgetCarouselContainer_nextPage"
      matTooltip="Próxima página"
      (click)="next()"
    >
      <mat-icon>navigate_next</mat-icon>
    </button>
  </div>
  <div class="widget-carousel-page-buttons" [hidden]="widgetsComponents.size === 0">
    <button
      class="skipToFirst"
      type="button"
      color="primary"
      mat-icon-button
      i18n-matTooltip="WidgetCarouselContainerFirstPage|Widget carousel container first page@@widgetCarouselContainer_firstPage"
      matTooltip="Primeira página"
      [hidden]="pageCount === 1"
      [disabled]="selectedPage === 0"
      (click)="skipToFirst()"
    >
      <mat-icon class="skipIcons">skip_previous</mat-icon>
    </button>
    <button
      class="skipButtonL"
      type="button"
      color="primary"
      mat-icon-button
      i18n-matTooltip="WidgetCarouselContainerSkipPrevious|Widget carousel container skip previous@@widgetCarouselContainer_skipPrevious"
      matTooltip="Recuar {{ navigationPageSize }} páginas"
      [hidden]="navigationPageCount === 1"
      [disabled]="selectedNavigationPage === 0"
      (click)="skipPagesLeft()"
    >
      <mat-icon class="skipIcons">fast_rewind</mat-icon>
    </button>
    <mat-tab-group
      class="widget-carousel-navigation-pages"
      [selectedIndex]="selectedNavigationPage"
      [style.width.px]="navigationPageSize * 28 + 10"
    >
      <mat-tab *ngFor="let navPage of navigationPages$ | async; index as idx">
        <mat-radio-group class="widget-carousel-navigation" [value]="selectedPage" [hidden]="pageCount === 1">
          <mat-radio-button
            *ngFor="let page of navPage"
            i18n-matTooltip="WidgetCarouselContainerRadioButton|Widget carousel container radio button@@widgetCarouselContainer_radioButton"
            matTooltip="Página {{ idx * navigationPageSize + page + 1 }}"
            [checked]="selectedPage === idx * navigationPageSize + page"
            [value]="idx * navigationPageSize + page"
            (click)="selectedPage = idx * navigationPageSize + page"
          >
          </mat-radio-button>
        </mat-radio-group>
      </mat-tab>
    </mat-tab-group>
    <button
      class="skipButtonR"
      type="button"
      color="primary"
      mat-icon-button
      i18n-matTooltip="WidgetCarouselContainerSkipNext|Widget carousel container skip next@@widgetCarouselContainer_skipNext"
      matTooltip="Avançar {{ navigationPageSize }} páginas"
      [hidden]="navigationPageCount === 1"
      [disabled]="selectedNavigationPage === navigationPageCount - 1"
      (click)="skipPagesRight()"
    >
      <mat-icon class="skipIcons">fast_forward</mat-icon>
    </button>
    <button
      class="skipToLast"
      type="button"
      color="primary"
      mat-icon-button
      i18n-matTooltip="WidgetCarouselContainerLastPage|Widget carousel container last page@@widgetCarouselContainer_lastPage"
      matTooltip="Última página"
      [hidden]="pageCount === 1"
      [disabled]="selectedPage === pageCount - 1"
      (click)="skipToLast()"
    >
      <mat-icon class="skipIcons">skip_next</mat-icon>
    </button>
  </div>
</div>
