import { Component } from '@angular/core';

import { WidgetValueComponent } from '../widget-value.component';

@Component({
  selector: 'app-progress-value',
  templateUrl: './progress-value.component.html',
  styleUrls: ['../widget-value.component.scss', './progress-value.component.scss']
})
export class ProgressValueComponent extends WidgetValueComponent {}
