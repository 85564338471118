import { HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../auth/services/authentication.service';
import { InterceptorSkipHeader } from '../../core/interceptors/skip-headers';
import { defaultAvatar } from '../../shared/models/default-avatar';
import { untilDestroy } from '../../shared/rxjs/operators/until-destroy';
import { DataCommunicationService } from '../../shared/services/data-communication-service/data-communication-service';
import { SpinnerService } from '../../shared/services/spinner/spinner.service';
import { NEW_ACCOUNTID_CHANNEL } from '../_models/login-data-channels';
import { LoginAccount } from './../_models/login-account';
import { AccountListService } from './../_services/account-list.service';

@Component({
  selector: 'app-new-login-form-submit',
  templateUrl: './new-login-form-submit.component.html',
  styleUrls: ['../login-form-base.scss', './new-login-form-submit.component.scss']
})
export class NewLoginFormSubmitComponent implements OnInit, OnDestroy {
  public accountIcon: string;
  public accountID: string;
  public loginForm: UntypedFormGroup = null;
  public hide = true;
  public loginError = false;
  public submitError = false;

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private spinnerService: SpinnerService,
    public dataService: DataCommunicationService,
    public accountService: AccountListService,
    private _sanitizer: DomSanitizer
  ) {
    this.dataService
      .getDataChannel<string>(NEW_ACCOUNTID_CHANNEL)
      .pipe(untilDestroy(this))
      .subscribe(id => {
        this.accountID = id;
      });
  }

  public ngOnInit(): void {
    this.loginForm = new UntypedFormGroup({
      password: new UntypedFormControl('', [Validators.required]),
      remember: new UntypedFormControl(true)
    });

    if (!this.accountID) {
      this.router.navigate(['/login/new']);
    }
  }

  public onSubmit(): void {
    const headers = new HttpHeaders().set(InterceptorSkipHeader.antiforgery, 'skip').set(InterceptorSkipHeader.error, 'skip');
    const authAction = this.authService.login(this.accountID, this.password.value, this.remember.value, headers);
    const submitAction = this.spinnerService.showFor(authAction);

    this.loginError = false;
    this.submitError = false;

    submitAction.pipe(untilDestroy(this)).subscribe(
      li => {
        this.loginError = !li;

        if (li) {
          if (this.remember.value) {
            this.accountService.addAccount(new LoginAccount(this.accountID, li.description, li.icon));
          }
          this.router.navigate(['/']);
        }
      },
      err => {
        console.log(err);

        this.submitError = true;
      }
    );
  }

  public ngOnDestroy(): void {
    // Used by the rxjs operator untilDestroy
  }

  public redirect(): void {
    if (this.accountService.accounts.length === 0) {
      this.router.navigate(['login/new']);
    } else {
      this.router.navigate(['login/list']);
    }
  }

  /**
   * @ignore
   *
   * @readonly
   * @memberof LoginFormComponent
   */
  public get password(): AbstractControl {
    return this.loginForm.controls['password'];
  }

  /**
   * @ignore
   *
   * @readonly
   * @memberof LoginFormComponent
   */
  public get remember(): AbstractControl {
    return this.loginForm.controls['remember'];
  }

  public sanatizeIcon(): SafeUrl {
    return this._sanitizer.bypassSecurityTrustUrl(defaultAvatar);
  }
}
