import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { DocumentsListingsComponent } from '../reports/components/documents-listings/documents-listings.component';
import { AuthDialogComponent } from './components/auth-dialog/auth-dialog.component';
import { CalloutComponent } from './components/callout/callout.component';
import { ConfigXdcloudHelperComponent } from './components/config-xdcloud-helper/config-xdcloud-helper.component';
import { ConfimationDialogComponent } from './components/confimation-dialog/confimation-dialog.component';
import { EntitiesListingsComponent } from './components/entities-listings/entities-listings.component';
import { IFrameComponent } from './components/iframe/iframe.component';
import { LogoutComponent } from './components/logout/logout.component';
import { NotificationContainerComponent } from './components/notification-container/notification-container.component';
import { NotificationShowMoreDialogComponent } from './components/notification-show-more-dialog/notification-show-more-dialog.component';
import { NotificationComponent } from './components/notification/notification.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PopUpContainerComponent } from './components/pop-up-container/pop-up-container.component';
import { RangePickerComponent } from './components/range-picker/range-picker.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { StoreSelectorComponent } from './components/store-selector/store-selector.component';
import { TranslateComponent } from './components/translate/translate.component';
import { CreateElementDirective } from './directives/create-element.directive';
import { CrossSiteMessageDirective } from './directives/cross-site-message.directive';
import { DefaultImageDirective } from './directives/default-image.directive';
import { LetVariableDirective } from './directives/let-variable.directive';
import { ScaleImageKeepRatioDirective } from './directives/scale-image-keep-ratio.directive';
import { TranslationDirective } from './directives/translate.directive';
import { AsyncSpinnerPipe } from './pipes/async-spinner.pipe';
import { CurrencyPipe } from './pipes/Currency.pipe';
import { DataTypePipe } from './pipes/data-type.pipe';
import { SignalPipe } from './pipes/signal.pipe';
import { TranslatePipe } from './pipes/translate.pipe';
import { ComponentOverlayService } from './services/component-overlay/component-overlay.service';
import { DynamicOverlayContainerService } from './services/dynamic-overlay/dynamic-overlay-container.service';
import { DynamicOverlayService } from './services/dynamic-overlay/dynamic-overlay.service';
import { NotificationService } from './services/notification/notification.service';
import { SpinnerService } from './services/spinner/spinner.service';
import { TemplateRendererService } from './services/template-renderer/template-renderer.service';
import { TranslatedMatSnackBar } from './services/translated-matSnackBar/translated-mat-snack-bar.service';
import { ResizeDirective } from './directives/element-resize.directive';
import { LocalizationService } from './services/localization/localization.service';
import { LanguageSettingGuard } from './Guards/language-setting-Guard';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { MatMenuModule } from '@angular/material/menu';
import { ErrorMessageComponent } from './components/error-message/error-message.component';

export const LAST_SELECTED_SESSION_DATE = 'last_selected_session_date';

@NgModule({
  declarations: [
    PageNotFoundComponent,
    SpinnerComponent,
    LogoutComponent,
    NotificationComponent,
    NotificationContainerComponent,
    NotificationShowMoreDialogComponent,
    CalloutComponent,
    ConfimationDialogComponent,
    AuthDialogComponent,
    ConfigXdcloudHelperComponent,
    TranslateComponent,
    EntitiesListingsComponent,
    DocumentsListingsComponent,
    RangePickerComponent,
    PopUpContainerComponent,

    SignalPipe,
    CurrencyPipe,
    TranslatePipe,
    DataTypePipe,

    TranslationDirective,
    CreateElementDirective,
    DefaultImageDirective,
    ScaleImageKeepRatioDirective,
    IFrameComponent,
    CrossSiteMessageDirective,
    AsyncSpinnerPipe,
    LetVariableDirective,
    StoreSelectorComponent,
    ResizeDirective,
    ProgressBarComponent,
    ErrorMessageComponent
  ],
  imports: [
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatGridListModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    MatListModule,
    MatToolbarModule,
    MatExpansionModule,
    MatDialogModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSortModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    RouterModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    OverlayModule,
    MatMenuModule
  ],
  providers: [
    DynamicOverlayContainerService,
    DynamicOverlayService,
    SpinnerService,
    SignalPipe,
    CurrencyPipe,
    TranslatePipe,
    NotificationService,
    ComponentOverlayService,
    TranslatedMatSnackBar,
    TemplateRendererService,
    LocalizationService,
    LanguageSettingGuard
  ],
  exports: [
    PageNotFoundComponent,
    NotificationComponent,
    NotificationContainerComponent,
    LogoutComponent,
    SignalPipe,
    CurrencyPipe,
    TranslatePipe,
    DataTypePipe,
    ConfigXdcloudHelperComponent,
    TranslateComponent,
    CreateElementDirective,
    DefaultImageDirective,
    ScaleImageKeepRatioDirective,
    ResizeDirective,
    IFrameComponent,
    SpinnerComponent,
    CrossSiteMessageDirective,
    EntitiesListingsComponent,
    DocumentsListingsComponent,
    RangePickerComponent,
    StoreSelectorComponent,
    AsyncSpinnerPipe,
    LetVariableDirective
  ]
})
export class SharedModule {}
