<mat-grid-list cols="8" rowHeight="6:1">
  <mat-grid-tile [colspan]="2" [rowspan]="10">
    <mat-icon>{{callout.icon}}</mat-icon>
  </mat-grid-tile>
  <mat-grid-tile [colspan]="4" [rowspan]="5">
    <span class="mat-h2">{{callout.subject}}</span><span class="spacer"></span>
  </mat-grid-tile>
  <mat-grid-tile [colspan]="4" [rowspan]="6">
    <span class="mat-small">{{callout.message}}</span>
    <span class="spacer"></span>
  </mat-grid-tile>
  <mat-grid-tile [colspan]="2" [rowspan]="10">
    <button type="button" mat-icon-button (click)="onClose()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-grid-tile>
  <mat-grid-tile [colspan]="8" [rowspan]="1">
    <mat-progress-bar mode="determinate" value="100"></mat-progress-bar>
  </mat-grid-tile>
</mat-grid-list>