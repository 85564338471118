import { ComponentRef, OnDestroy, ElementRef, EventEmitter, Component } from '@angular/core';

import { WidgetComponent } from '../widget.component';
import { WidgetService } from '../services/widget.service';
import { WidgetPropertyGetter } from '../models/widget-property-getter';
import { IWidget } from '../models/widget.interface';
import { SpinnerService } from '../../shared/services/spinner/spinner.service';
import { untilDestroy } from '../../shared/rxjs/operators/until-destroy';
import { WidgetFactoryService } from '../widget-factory';
import { WidgetKind } from '../models/widget-enums';
import { WidgetData } from '../models/widget-data';

@Component({ template: '' })
export abstract class WidgetContainerComponent implements OnDestroy {
  public readonly widgetsComponents: Map<string, ComponentRef<WidgetComponent>>;
  public abstract parametersOverrides: Array<WidgetPropertyGetter>;
  public abstract refreshWidget: EventEmitter<WidgetData>;
  /***
   * @summary Setup defaults is handled by the widget container and if not initialized
   * does nothing
   * @returns Something like [{ 'prop1': 'val1', 'prop2': 'val2' }, { ... }]
   */
  public abstract getWidgetDefaultValues: (widgetId: string) => any[];
  public abstract searchableProperties: string[];

  constructor(
    protected widgetService: WidgetService,
    protected spinnerService: SpinnerService,
    protected widgetFactoryService: WidgetFactoryService
  ) {
    this.widgetsComponents = new Map<string, ComponentRef<WidgetComponent>>();
  }

  public abstract ngOnDestroy(): void;

  public abstract setupWidgets(widgets: Array<IWidget>): void;

  protected handleWidgetLink(widgetRef: ComponentRef<WidgetComponent>, linkId: string, values: any, defaults?: any[], config?: any): void {
    if (linkId) {
      const widgetKind = this.widgetService.getWidgetKind(linkId).pipe(untilDestroy(this));
      const elementRef = widgetRef.injector.get(ElementRef);

      this.spinnerService
        .showFor(widgetKind, elementRef)
        .pipe(untilDestroy(this))
        .subscribe((kind: WidgetKind) => {
          if (kind !== WidgetKind.none) {
            const newWidgetRef = this.widgetFactoryService.createWidget(
              { id: linkId, kind: kind },
              this.parametersOverrides,
              elementRef,
              defaults,
              config
            );

            newWidgetRef.instance.parameters = values;
          } else {
            console.log('Can\'t use link widget of kind "none"');
          }
        });
    }
  }
}
