import { Component, Input, OnInit } from '@angular/core';
import { ICloudWidget } from '../../models/cloud-widget';
import { WidgetLayout } from '../../models/widget-layout';

@Component({
  selector: 'app-report-widget-container',
  templateUrl: './report-widget-container.component.html',
  styleUrls: ['./report-widget-container.component.scss']
})
export class ReportWidgetContainerComponent implements OnInit {
  @Input() public cloudWidget: ICloudWidget;
  @Input() public layout: WidgetLayout;
  @Input() public rowspanHeight: number;
  @Input() public widgetHeight: number;
  @Input() public titleHeight: number;
  public tabsMap: Array<Array<any>>;
  public selectedPage: number;
  public paginatorLength: number;

  constructor() {
    this.selectedPage = 0;
    this.paginatorLength = 0;
    this.tabsMap = new Array<Array<any>>();
  }

  public ngOnInit(): void {
    const valuesCount = this.cloudWidget.values.length === 0 ? 1 : this.cloudWidget.values.length;
    const valuesPerTab = this.initializeTabs(this.rowspanHeight, this.layout.rowspan);
    const tabsCount = Math.ceil(valuesCount / valuesPerTab);

    let currentTab = -1;

    this.tabsMap = [];

    for (const value of this.cloudWidget.values) {
      if (this.tabsMap[currentTab]?.length < valuesPerTab) {
        this.tabsMap[currentTab].push(value);
      } else if (currentTab + 1 < tabsCount) {
        currentTab++;
        this.tabsMap[currentTab] = [value];
      }
    }

    this.paginatorLength = valuesCount;
  }

  private initializeTabs(rowspanSize: number, rowspan: number): number {
    let valuesPerTab = 1;
    const paginatorHeight = 4.5;
    while (rowspanSize * valuesPerTab + this.titleHeight + paginatorHeight < this.widgetHeight * rowspan) {
      valuesPerTab += 1;
    }
    return valuesPerTab - 1;
  }

  public onPageChange(event: any): void {
    this.selectedPage = event.pageIndex;
  }
}
