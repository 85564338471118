import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Callout } from '../../models/callout';

@Component({
  selector: 'app-callout',
  templateUrl: './callout.component.html',
  styleUrls: ['./callout.component.scss']
})
export class CalloutComponent {
  public callout: Callout;
  public snackBarRef: MatSnackBarRef<CalloutComponent>;

  constructor(snackBarRef: MatSnackBarRef<CalloutComponent>, @Inject(MAT_SNACK_BAR_DATA) public data: any) {
    this.callout = data.callout;
    this.snackBarRef = snackBarRef;
  }

  public onClose(): void {
    this.snackBarRef.dismissWithAction();
  }
}
