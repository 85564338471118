<div class="center-card">
  <mat-card style="width: 100%;">
    <mat-card-title i18n="ConfigHelperTitle|Config Helper Title@@configXdcloudHelper_title">Ajuda Configuração</mat-card-title>

    <mat-card-content>
      <br />
      <p
        ><strong i18n="ConfigHelperWarning|Config Helper Warning@@configXdcloudHelper_warning">Atenção</strong
        ><span i18n="ConfigHelperWarningMessage|Config Helper Warning Message@@configXdcloudHelper_warningMessage"
          >: A conta actual não contem o Serviço Cloud correctamente configurado.</span
        ></p
      >
      <p i18n="ConfigHelperConfigurationMessage|Config Helper Configuration Message@@configXdcloudHelper_configurationMessage"
        >Para configurar o serviço siga os seguintes passos:</p
      >
      <ul>
        <li i18n="ConfigHelperConfigurationStep1|Config Helper Configuration Step1@@configXdcloudHelper_configurationStep1"
          >Abrir a Gestão Comercial</li
        >
        <li
          ><strong i18n="ConfigHelperConfigurationStep2|Config Helper Configuration Step2@@configXdcloudHelper_configurationStep2"
            >Smart Connect</strong
          ></li
        >
        <li>
          <span i18n="ConfigHelperConfigurationStep3|Config Helper Configuration Step3@@configXdcloudHelper_configurationStep3"
            >Configurar e certificar que ativa o
          </span>
          <strong i18n="ConfigHelperCloud|Config Helper Cloud label@@configXdcloudHelper_cloud">XD Cloud</strong></li
        >
      </ul>
      <br />
      <br />
      <br />
      <p
        ><em i18n="DefaultError|Default Error@@configXdcloudHelper_defaultError"
          >Se o problema persistir, por favor contacte a equipa de suporte da XD Software</em
        ></p
      >
    </mat-card-content>

    <mat-card-actions class="center-actions">
      <button mat-flat-button i18n="RetryGetDashboard|Retry get dashboard@@configXdcloudHelper_retryGetDashboard" routerLink="/dashboard"
        >Tente novamente</button
      >
    </mat-card-actions>
  </mat-card>
</div>
