<div class="popup-container">
  <ng-container #container></ng-container>
  <button
    mat-button
    class="confirm-btn"
    (click)="closePopUp()"
    i18n="PopUpContainerOkButton|PopUp Container Ok Button@@PopUp_containerOkButton"
    >OK</button
  >
</div>
