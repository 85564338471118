import { Component, Input } from '@angular/core';
import { WidgetValueDisplayKind } from '../../models/widget-enums';
import { WidgetValueConfiguration } from '../../models/widget-value-configuration';

@Component({
  selector: 'app-report-widget-value',
  templateUrl: './report-widget-value.component.html',
  styleUrls: ['./report-widget-value.component.scss']
})
export class ReportWidgetValueComponent {
  @Input() public valuesConfiguration: Array<Array<WidgetValueConfiguration>>;
  @Input() public value: any;
  public ValueDisplayKind = WidgetValueDisplayKind;
}
