<mat-card class="mat-card">
  <mat-card-title class="mat-card-title" i18n="AccountListTitle|Account List Title@@accountList_title">Selecione uma conta</mat-card-title>

  <mat-card-content>
    <mat-list>
      <mat-list-item *ngFor="let loginAccount of accounts" (click)="setAccount(loginAccount)" class="list-item">
        <img class="accountAvatar" [src]="sanatizeIcon(loginAccount.icon)" mat-list-icon  alt="account avatar"/>
        <div class="loginStatusSize" i18n="AccountListAccountStatus|Account List Account Status@@accountList_accountStatus">Sem sessão iniciada</div>
        <div class="accountTitleSize" mat-line>{{loginAccount.account}}</div>
        <div mat-line>
          <div class="accountDescriptionSize" *ngIf="!!loginAccount.description">{{loginAccount.description}}</div>
        </div>
      </mat-list-item>
      <mat-list-item class="list-item" type="flat" routerLink="/login/new">
        <mat-icon class="icons" mat-list-icon color="primary">account_circle</mat-icon>
        <div class="itemTitleSize" mat-line color="primary" i18n="AccountListAddNewAccountLabel|Account List Add New Account Label@@accountList_addNewAccount">Adicionar outra conta</div>
      </mat-list-item>
      <mat-list-item class="list-item" type="flat" routerLink="/login/remove-account">
        <mat-icon class="icons" mat-list-icon color="warn">remove_circle_outline</mat-icon>
        <div class="itemTitleSize" mat-line color="primary" i18n="AccountListRemoveAccountLabel|Account List Remove Account Label@@accountList_removeAccount">Remover uma conta</div>
      </mat-list-item>
    </mat-list>
  </mat-card-content>

</mat-card>
