import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isBefore } from 'date-fns/esm/fp';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { LoginInfo } from '../../shared/models/LoginInfo';
import { InterceptorSkipHeader } from '../../core/interceptors/skip-headers';
import { LoginInfoService } from '../../shared/services/login-info/login-info.service';

const LOGIN_ENDPOINT = `${environment.api.schema}://${environment.api.host}/authentication/login`;
const LOGOUT_ENDPOINT = `${environment.api.schema}://${environment.api.host}/authentication/logout`;
const USER_ENDPOINT = `${environment.api.schema}://${environment.api.host}/authentication/user`;
const USER_ENDPOINT_CHECK = `${USER_ENDPOINT}/check`;
const USER_ENDPOINT_LOGIN = `${USER_ENDPOINT}/login`;

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private readonly _loggedStatusSubject: BehaviorSubject<boolean>;

  public readonly loggedStatus$: Observable<boolean>;

  constructor(private _httpClient: HttpClient, private _loginInfoService: LoginInfoService) {
    this._loggedStatusSubject = new BehaviorSubject<boolean>(false);
    this.loggedStatus$ = this._loggedStatusSubject.asObservable();
  }

  public isLogged(): Observable<boolean> {
    const loginInfo = this._loginInfoService.get();
    let loggedStatus$: Observable<boolean> = null;

    if (!loginInfo) {
      loggedStatus$ = of(false);
    } else {
      const expired = isBefore(new Date(), new Date(loginInfo.expiryDate));

      loggedStatus$ = expired ? this.expiredLogin() : of(true);
    }

    loggedStatus$ = loggedStatus$.pipe(
      map(status => {
        this._loggedStatusSubject.next(status);

        return status;
      })
    );

    return loggedStatus$;
  }

  public expiredLogin(): Observable<boolean> {
    const httpOptions = {
      params: new HttpParams().set('remember', 'true'),
      withCredentials: true
    };
    return this._httpClient.post<LoginInfo>(LOGIN_ENDPOINT, null, httpOptions).pipe(
      map(li => {
        this._loginInfoService.set(li);
        this._loggedStatusSubject.next(true);

        return true;
      }),
      catchError(err => {
        console.log(err);
        this._loggedStatusSubject.next(false);

        return of(false);
      })
    );
  }

  public login(username: string, password: string, remember: boolean, headers?: HttpHeaders): Observable<LoginInfo> {
    const authHeaderValue = btoa(username + ':' + password);

    if (!headers) {
      headers = new HttpHeaders().set('authorization', `basic ${authHeaderValue}`);
    } else {
      headers = headers.set('authorization', `basic ${authHeaderValue}`);
    }

    const httpOptions = {
      headers: headers,
      params: new HttpParams().set('remember', remember.toString()),
      withCredentials: true
    };
    return this._httpClient.post<LoginInfo>(LOGIN_ENDPOINT, null, httpOptions).pipe(
      map(li => {
        this._loginInfoService.set(li);
        this._loggedStatusSubject.next(true);

        return li;
      }),
      catchError(err => {
        console.log(err);
        this._loggedStatusSubject.next(false);

        return of(null);
      })
    );
  }

  public logout(): Observable<void> {
    return this._httpClient.post<void>(LOGOUT_ENDPOINT, null, { withCredentials: true }).pipe(
      finalize(() => {
        this._loginInfoService.delete();
        this._loggedStatusSubject.next(false);
      })
    );
  }

  /**
   * checkUser is authenticated
   *
   * @param {string} ownerdId
   * @returns
   * @memberof AuthenticationService
   */
  public checkUser(storeId: number, userId: number): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders().set(InterceptorSkipHeader.error, 'skip'),
      params: new HttpParams({ fromObject: { storeId: `${storeId}`, userId: `${userId}` } }),
      withCredentials: true
    };
    return this._httpClient.get<boolean>(USER_ENDPOINT_CHECK, httpOptions).pipe(catchError(this.error2Bool));
  }

  /**
   * Login user with given credentials
   *
   * @param storeId
   * @param userId
   * @param password
   */
  public loginUser(storeId: number, userId: number, password: string): Observable<boolean> {
    const authValue = { userId: userId, password: password, storeId: storeId };
    const httpOptions = {
      headers: new HttpHeaders().set(InterceptorSkipHeader.error, 'skip'),
      withCredentials: true
    };
    return this._httpClient.post<boolean>(USER_ENDPOINT_LOGIN, authValue, httpOptions).pipe(catchError(this.error2Bool));
  }

  private error2Bool(err: HttpErrorResponse) {
    if (err.status !== 401) {
      console.log(err);
    }

    return of(false);
  }
}
