import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { asapScheduler, Observable } from 'rxjs';
import { observeOn } from 'rxjs/operators';
import { untilDestroy } from '../../shared/rxjs/operators/until-destroy';
import { ComponentOverlayService } from '../../shared/services/component-overlay/component-overlay.service';
import { SpinnerService } from '../../shared/services/spinner/spinner.service';
import { ICloudWidget } from '../models/cloud-widget';
import { WidgetPropertyGetter } from '../models/widget-property-getter';
import { WidgetService } from '../services/widget.service';
import { WidgetReservedParameters } from '../widget-reserved-parameters';
import { WidgetComponent } from '../widget.component';

const DEFAULT_STORE_IMAGE =
  'data:image/svg+xml;base64, PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmc' +
  'KICAgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIgogICB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tb' +
  'W9ucy5vcmcvbnMjIgogICB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiCiAgIHhtbG5' +
  'zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgeG1sb' +
  'nM6c29kaXBvZGk9Imh0dHA6Ly9zb2RpcG9kaS5zb3VyY2Vmb3JnZS5uZXQvRFREL3NvZGlwb2RpLTAuZHRkIgogICB4bWxuczppbmtzY2F' +
  'wZT0iaHR0cDovL3d3dy5pbmtzY2FwZS5vcmcvbmFtZXNwYWNlcy9pbmtzY2FwZSIKICAgd2lkdGg9IjI0IgogICBoZWlnaHQ9IjI0IgogI' +
  'CB2aWV3Qm94PSIwIDAgMjQgMjQiCiAgIHZlcnNpb249IjEuMSIKICAgaWQ9InN2ZzYiCiAgIHNvZGlwb2RpOmRvY25hbWU9InN0b3JlLTI' +
  '0cHguc3ZnIgogICBpbmtzY2FwZTp2ZXJzaW9uPSIwLjkyLjQgKDVkYTY4OWMzMTMsIDIwMTktMDEtMTQpIj4KICA8bWV0YWRhdGEKICAgI' +
  'CBpZD0ibWV0YWRhdGExMiI+CiAgICA8cmRmOlJERj4KICAgICAgPGNjOldvcmsKICAgICAgICAgcmRmOmFib3V0PSIiPgogICAgICAgIDx' +
  'kYzpmb3JtYXQ+aW1hZ2Uvc3ZnK3htbDwvZGM6Zm9ybWF0PgogICAgICAgIDxkYzp0eXBlCiAgICAgICAgICAgcmRmOnJlc291cmNlPSJod' +
  'HRwOi8vcHVybC5vcmcvZGMvZGNtaXR5cGUvU3RpbGxJbWFnZSIgLz4KICAgICAgPC9jYzpXb3JrPgogICAgPC9yZGY6UkRGPgogIDwvbWV' +
  '0YWRhdGE+CiAgPGRlZnMKICAgICBpZD0iZGVmczEwIiAvPgogIDxzb2RpcG9kaTpuYW1lZHZpZXcKICAgICBwYWdlY29sb3I9IiNmZmZmZ' +
  'mYiCiAgICAgYm9yZGVyY29sb3I9IiM2NjY2NjYiCiAgICAgYm9yZGVyb3BhY2l0eT0iMSIKICAgICBvYmplY3R0b2xlcmFuY2U9IjEwIgo' +
  'gICAgIGdyaWR0b2xlcmFuY2U9IjEwIgogICAgIGd1aWRldG9sZXJhbmNlPSIxMCIKICAgICBpbmtzY2FwZTpwYWdlb3BhY2l0eT0iMCIKI' +
  'CAgICBpbmtzY2FwZTpwYWdlc2hhZG93PSIyIgogICAgIGlua3NjYXBlOndpbmRvdy13aWR0aD0iNzYwIgogICAgIGlua3NjYXBlOndpbmR' +
  'vdy1oZWlnaHQ9IjQ4MCIKICAgICBpZD0ibmFtZWR2aWV3OCIKICAgICBzaG93Z3JpZD0iZmFsc2UiCiAgICAgaW5rc2NhcGU6em9vbT0iO' +
  'S44MzMzMzMzIgogICAgIGlua3NjYXBlOmN4PSIxMiIKICAgICBpbmtzY2FwZTpjeT0iMTIiCiAgICAgaW5rc2NhcGU6d2luZG93LXg9IjU' +
  'wOCIKICAgICBpbmtzY2FwZTp3aW5kb3cteT0iMTE4IgogICAgIGlua3NjYXBlOndpbmRvdy1tYXhpbWl6ZWQ9IjAiCiAgICAgaW5rc2Nhc' +
  'GU6Y3VycmVudC1sYXllcj0ic3ZnNiIgLz4KICA8cGF0aAogICAgIGQ9Ik0wIDBoMjR2MjRIMHoiCiAgICAgZmlsbD0ibm9uZSIKICAgICB' +
  'pZD0icGF0aDIiIC8+CiAgPHBhdGgKICAgICBkPSJNMjAgNEg0djJoMTZWNHptMSAxMHYtMmwtMS01SDRsLTEgNXYyaDF2NmgxMHYtNmg0d' +
  'jZoMnYtNmgxem0tOSA0SDZ2LTRoNnY0eiIKICAgICBpZD0icGF0aDQiCiAgICAgc3R5bGU9ImZpbGw6IzEwOTVjOTtmaWxsLW9wYWNpdHk' +
  '6MSIgLz4KPC9zdmc+Cg==';

export const STORE_WIDGET_ID = '1FAC5F64-1C94-45E8-9740-9E401D2A15A6';

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['../widget.component.scss', './store.component.scss']
})
export class StoreComponent extends WidgetComponent implements OnInit, OnDestroy {
  @Input() public parametersOverrides: Array<WidgetPropertyGetter>;
  public name: string;
  public sales: number;
  public cashier: number;
  public account: string;
  public online: boolean;
  public storeId: number;
  public image: SafeResourceUrl;
  public get defaultImage(): SafeResourceUrl {
    return this._sanitizer.bypassSecurityTrustResourceUrl(DEFAULT_STORE_IMAGE);
  }

  private _widgetId: string;

  constructor(
    private _sanitizer: DomSanitizer,
    widgetService: WidgetService,
    elementRef: ElementRef<any>,
    spinnerService: SpinnerService,
    componentOverlayService: ComponentOverlayService
  ) {
    super(widgetService, elementRef, spinnerService, componentOverlayService);
    this.hasOfflineWidget = true;
    this.cloudWidgetId = STORE_WIDGET_ID;
    /**
     * This is a store widget so its id is the storeId and so we override the widgetId property.
     */
    Object.defineProperty(this, 'widgetId', {
      get() {
        return this._widgetId;
      },
      set(value) {
        this.parameters[WidgetReservedParameters.storeId] = value;
        this._widgetId = value;
      }
    });
  }

  public ngOnInit(): void {
    this.widget$.pipe(untilDestroy(this), observeOn(asapScheduler)).subscribe(cw => {
      this.processValues(cw.values);
    });
    this.hasError$.subscribe(hasError => (this.online = !hasError && this.online));
  }

  public ngOnDestroy(): void {
    // Used by the rxjs operator untilDestroy
  }

  public setupWidget(widget$: Observable<ICloudWidget>): Observable<ICloudWidget> {
    return widget$;
  }

  public setupWidgetSize(): number {
    return 10;
  }

  public setDefaults(values: any[]): void {
    this.processValues(values);
  }

  private processValues(values: any[]): void {
    if (values) {
      this.name = values[0]['name'] ? String(values[0]['name']) : null;
      this.sales = Number(values[0]['sales']);
      this.cashier = Number(values[0]['cashier']);
      this.account = values[0]['account'] ? String(values[0]['account']) : null;
      this.online = Boolean(values[0]['online']);
      this.storeId = Number(values[0]['storeId']);
      this.image = this.sanitizeImage(values[0]['image']);
    }
  }
  private sanitizeImage(image: string): SafeResourceUrl {
    let imageSrc: SafeResourceUrl = this.defaultImage;

    if (image && image.length) {
      imageSrc = this._sanitizer.bypassSecurityTrustResourceUrl(image);
    }

    return imageSrc;
  }
}
