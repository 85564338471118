<article class="store-container">
  <div class="store-row">
    <div class="store-column">
      <section class="store-name">
        <span class="cloud-link" routerLink="/summary/store/{{ storeId }}">
          {{ name }}
        </span>
      </section>
      <div class="store-row">
        <section class="store-image cloud-link" routerLink="/summary/store/{{ storeId }}">
          <div>
            <div><img *ngIf="image" [defaultImage]="defaultImage" scaleImageKeepRatio [src]="image" alt="" /></div>
          </div>
        </section>
        <section class="store-info">
          <section class="store-sales-resume">
            <div>
              <span class="store-info-title" i18n="StoreWidgetComponentSales|Store widget component sales label@@store_sales">Vendas</span>
              <span class="store-info-value">{{ sales | cloudCurrency }}</span>
            </div>
            <br />
            <div>
              <span class="store-info-title" i18n="StoreWidgetComponentCashier|Store widget component cashier label@@store_cashier"
                >Caixa</span
              >
              <span class="store-info-value">{{ cashier | cloudCurrency }}</span>
            </div>
          </section>
          <section class="store-account-info">
            <div>
              <span class="store-info-title" i18n="StoreWidgetComponentaccount|Store widget component account label@@store_account"
                >Conta</span
              >
              <span class="store-info-value">{{ account }}</span>
            </div>
          </section>
        </section>
      </div>
    </div>
    <div class="store-buttons-container">
      <button
        mat-flat-button
        class="dashboard-button"
        color="primary"
        [disabled]="!online"
        [hidden]="!online"
        routerLink="/dashboard"
        [queryParams]="{ store: storeId }"
      >
        <mat-icon fontSet="material-icons-outlined">assessment</mat-icon>
      </button>
      <mat-divider [hidden]="!online"></mat-divider>
      <button class="refresh-button" mat-flat-button color="primary" (click)="refresh()" [ngStyle]="{ 'height.%': online ? 25 : 100 }">
        <mat-icon fontSet="material-icons-outlined">cached</mat-icon>
      </button>
      <mat-divider [hidden]="!online"></mat-divider>
    </div>
  </div>
  <div [ngClass]="{ 'store-status': true, 'store-online': online, 'store-offline': !online }"> </div>
</article>
