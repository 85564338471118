import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';
import { AuthenticationGuard } from './auth/guards/authentication-guard';
import { LandingPageComponent } from './core/components/landing-page/landing-page.component';
import { LegacyViewerComponent } from './core/components/legacy-viewer/legacy-viewer.component';
import { ConfigXdcloudHelperComponent } from './shared/components/config-xdcloud-helper/config-xdcloud-helper.component';
import { LogoutComponent } from './shared/components/logout/logout.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { LanguageSettingGuard } from './shared/Guards/language-setting-Guard';

const routes: Routes = [
  {
    path: '',
    component: LandingPageComponent,
    canLoad: [AuthenticationGuard],
    canActivate: [LanguageSettingGuard]
  },
  { path: 'logout', component: LogoutComponent },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(mod => mod.LoginModule),
    canActivate: [LanguageSettingGuard]
  },
  {
    path: 'entities',
    loadChildren: () => import('./entities/entities.module').then(mod => mod.EntitiesModule),
    canLoad: [AuthenticationGuard],
    canActivate: [LanguageSettingGuard]
  },
  {
    path: 'online-summary',
    loadChildren: () => import('./online-summary/online-summary.module').then(m => m.OnlineSummaryModule),
    canLoad: [AuthenticationGuard],
    canActivate: [LanguageSettingGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(mod => mod.DashboardModule),
    canLoad: [AuthenticationGuard],
    canActivate: [LanguageSettingGuard]
  },
  {
    path: 'sales',
    loadChildren: () => import('./sales/sales.module').then(mod => mod.SalesModule),
    canLoad: [AuthenticationGuard],
    canActivate: [LanguageSettingGuard]
  },
  {
    path: 'helper',
    component: ConfigXdcloudHelperComponent,
    canLoad: [AuthenticationGuard],
    canActivate: [LanguageSettingGuard]
  },
  {
    path: 'summary',
    loadChildren: () => import('./business-summary/business-summary.module').then(mod => mod.BusinessSummaryModule),
    canLoad: [AuthenticationGuard],
    canActivate: [LanguageSettingGuard]
  },
  {
    path: 'sales-summary',
    loadChildren: () => import('./sales-summary/sales-summary.module').then(mod => mod.SalesSummaryModule),
    canLoad: [AuthenticationGuard],
    canActivate: [LanguageSettingGuard]
  },
  {
    path: 'consults',
    loadChildren: () => import('./consults/consults.module').then(mod => mod.ConsultsModule),
    canLoad: [AuthenticationGuard],
    canActivate: [LanguageSettingGuard]
  },
  {
    path: 'transactions',
    loadChildren: () => import('./transactions/transactions.module').then(mod => mod.TransactionsModule),
    canLoad: [AuthenticationGuard],
    canActivate: [LanguageSettingGuard]
  },
  { path: 'admin', component: LegacyViewerComponent, canLoad: [AuthenticationGuard] },
  {
    path: 'reports',
    loadChildren: () => import('./reports/reports.module').then(mod => mod.ReportsModule),
    canLoad: [AuthenticationGuard],
    canActivate: [LanguageSettingGuard]
  },
  {
    path: 'saft',
    loadChildren: () => import('./saft/saft.module').then(mod => mod.SaftModule),
    canLoad: [AuthenticationGuard],
    canActivate: [LanguageSettingGuard]
  },
  { path: '**', component: PageNotFoundComponent, canLoad: [AuthenticationGuard] }
];

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: environment.enableRouteTrancing,
      preloadingStrategy: NoPreloading,
      relativeLinkResolution: 'legacy'
    })
  ]
})
export class AppRoutingModule {}
