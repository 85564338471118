import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';

import { TranslateService } from '../translate-service/translate.service';

@Injectable({
  providedIn: 'root'
})
export class TranslatedMatSnackBar {
  constructor(private snackBar: MatSnackBar, private _translationService: TranslateService) {}
  public openTranslate(translationId: string, actionTranslationId?: string, config?: MatSnackBarConfig): MatSnackBarRef<SimpleSnackBar> {
    const message = this._translationService.translate(translationId);
    let action = null;
    if (actionTranslationId) {
      action = this._translationService.translate(actionTranslationId);
    }
    return this.snackBar.open(message, action, config);
  }
}
