import { ValueDataKind } from './../../../shared/models/value-data-kind';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { subMonths } from 'date-fns/esm/fp';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ExportData } from '../../../shared/models/export-data';
import { CurrencyPipe } from '../../../shared/pipes/Currency.pipe';
import { untilDestroy } from '../../../shared/rxjs/operators/until-destroy';
import { ExportService } from '../../../shared/services/export-service/export.service';
import { SpinnerService } from '../../../shared/services/spinner/spinner.service';
import { TranslateService } from '../../../shared/services/translate-service/translate.service';
import { TerminalAccumulatedTotal } from '../../model/accumulated-totals';
import { AccumulatedTotalsService } from '../../services/accumulated-totals.service';
import { DxPieChartComponent } from 'devextreme-angular';
import { ColumnConfig } from '../../../shared/models/column-config';

export enum unnecessaryKeys {
  syncDate = 'syncDate',
  qnt = 'qnt'
}

@Component({
  selector: 'app-terminals-chart',
  templateUrl: './terminals-chart.component.html',
  styleUrls: ['./terminals-chart.component.scss']
})
export class TerminalsChartComponent implements OnInit, OnDestroy {
  public topTerminals$: Observable<TerminalAccumulatedTotal[]>;
  public topTerminalsData: TerminalAccumulatedTotal[];
  public topTerminalsDataSource = new MatTableDataSource<TerminalAccumulatedTotal>();
  public ExportTopterminaldataXls: string[];
  public topTerminalsTableColumns: string[];
  @ViewChild(DxPieChartComponent, { static: false }) terminalsPieChart: DxPieChartComponent;
  @ViewChild('terminalsChart', { static: true, read: ElementRef })
  private terminalsChart: ElementRef;
  public terminalsTableVisible = false;
  public topsInterval: Array<{ value: number; description: string }>;
  public selectedTerminalDate: {
    value: number;
    description: string;
  };
  @Input() public requestedStores: string;

  constructor(
    private _spinnerService: SpinnerService,
    private _translateService: TranslateService,
    private _exportservice: ExportService,
    private _accumulatedTotalsService: AccumulatedTotalsService,
    public currencyPipe: CurrencyPipe
  ) {
    this.customizeTopsTooltip = this.customizeTopsTooltip.bind(this);
    this.topsInterval = new Array<{ value: number; description: string }>();
    this.topsInterval = [
      { value: 1, description: this._translateService.translate('last_month') },
      { value: 3, description: this._translateService.translate('last_3_months') },
      { value: 6, description: this._translateService.translate('last_6_months') }
    ];

    this.selectedTerminalDate = this.topsInterval[0];
    this.topTerminalsTableColumns = new Array<string>();
  }

  public ngOnInit(): void {
    this.terminalIntervalChanged();
  }

  public ngOnDestroy(): void {
    // Used by the rxjs operator untilDestroy
  }

  public terminalIntervalChanged(): void {
    const now = new Date();
    const initialDate = subMonths(this.selectedTerminalDate.value, now);

    this.topTerminals$ = this._spinnerService
      .showFor(this._accumulatedTotalsService.GetTerminalAccumulatedTotalCloud(initialDate, now, this.requestedStores), this.terminalsChart)
      .pipe(
        untilDestroy(this),
        tap(list => {
          this.topTerminalsData = list.length > 10 ? list.slice(list.length - 10, list.length) : list;
          this.topTerminalsDataSource = new MatTableDataSource(list);
          this.topTerminalsDataSource.data.forEach(e => {
            for (const key in e) {
              if (this.topTerminalsTableColumns.indexOf(key) === -1) {
                if (key !== unnecessaryKeys.syncDate && key !== unnecessaryKeys.qnt) {
                  this.topTerminalsTableColumns.push(key);
                }
              }
            }
          });
        })
      );
  }

  public customizeTopsTooltip(info: any): any {
    return {
      text: info.argumentText + '<br/>' + this.currencyPipe.transform(info.valueText, null, null, null, null)
    };
  }

  public showMoreTerminalSales(): void {
    this.terminalsTableVisible = !this.terminalsTableVisible;
  }

  public updateStores(value: string): void {
    this.requestedStores = value;
    this.terminalIntervalChanged();
  }

  public exportXlSX(): void {
    this.topTerminals$.pipe(untilDestroy(this)).subscribe(list => {
      const data = list;
      const headers = this.getExportXLSXColumnConfigs();
      const fileName = this._translateService.translate('onlineSummary_LoadingTerminalSales');
      data.forEach(row => {
        delete row.syncDate;
        delete row.qnt;
      });

      const exportData = new ExportData(null, data, headers, fileName);
      this._exportservice.ExportXLSX(exportData);
    });
  }

  public exportChartPdf(): void {
    const fileName = this._translateService.translate('storeSummary_topTerminalsChartTitle');
    this.terminalsPieChart?.instance.exportTo(fileName, 'pdf');
  }

  public exportChartPng() {
    const fileName = this._translateService.translate('storeSummary_topTerminalsChartTitle');
    this.terminalsPieChart?.instance.exportTo(fileName, 'png');
  }

  public getExportXLSXColumnConfigs(): ColumnConfig[] {
    return this.topTerminalsTableColumns.map(c => ({
      header: this._translateService.translate(c),
      propertyName: c,
      kind: ValueDataKind.string,
      columnConfigs: null,
      usesParentHeader: false
    }));
  }
}
