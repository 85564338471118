export class BusinessSummarySales {
  public date: string;
  public total: number;
  public totalNet: number;
  public totalDiscountsNet: number;
  public totalDiscounts: number;
  public costNet: number;
  public cost: number;
  public profitNet: number;
  public profit: number;
  public totalServiceTax: number;
}

export enum BusinessSummarySalesType {
  none = 0,
  byMonth = 1
}
