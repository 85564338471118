import { ValueDataKind } from './../../models/value-data-kind';
import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import { ExportData } from '../../models/export-data';
import { ColumnConfig } from '../../models/column-config';
import { DataCell } from '../../models/data-cell';
import { XLSXCell } from '../../models/xlsx-cell';
import { CellFormat } from '../../models/cell-format';
import { CurrencyConfigService } from '../configs/currency-config.service';

class StackItem {
  public value: any;
  public level: number = 0;
}

enum XLSXCurrencySymbolFormat {
  USD_LEFT = '$#,##0.00',
  USD_RIGHT = '#,##0.00 $',
  EUR_LEFT = '€#,##0.00',
  EUR_RIGHT = '#,##0.00 €',
  BRL_LEFT = 'R$ #,##0.00',
  BRL_RIGHT = '#,##0.00 R$',
  AOA_LEFT = 'Kz #,##0.00',
  AOA_RIGHT = '#,##0.00 Kz'
}

@Injectable({
  providedIn: 'root'
})
export class ExportService {
  constructor(private currencyConfig: CurrencyConfigService) {}

  ExportXLSX(exportData: ExportData): void {
    const data: DataCell[][] = this.processDataForExport(exportData);
    const worksheet = {};
    const workbook = XLSX.utils.book_new();
    let sheetRangeInfo = 'A1';
    let cellRef = null;
    let colRef = null;
    let cellValueLength = null;
    const numberColumns: Map<string, { wch?: number }> = new Map();

    for (let rows = 0; rows < data.length; ++rows) {
      for (let column = 0; column < data[rows]?.length; ++column) {
        let newData = data[rows][column];
        cellRef = XLSX.utils.encode_cell({ r: rows, c: column });
        colRef = XLSX.utils.encode_col(column);

        if (newData?.cellFormat) {
          worksheet[cellRef] = this.convertDataCellToXLSXCell(newData);
        } else {
          const cellValue = { v: newData || '' };
          worksheet[cellRef] = cellValue;
          newData = { value: cellValue, cellFormat: null };
        }

        cellValueLength = newData.value?.['v']
          ? newData.value
            ? newData.value['v'].toString().length + 2
            : 0
          : newData.value
          ? newData.value.toString().length + 2
          : 0;

        if (!numberColumns.has(colRef) || cellValueLength > (numberColumns.get(colRef)?.wch || 0)) {
          numberColumns.set(colRef, { wch: cellValueLength + 2 });
        }
      }
    }

    worksheet['!cols'] = Array.from(numberColumns.values());
    sheetRangeInfo += cellRef ? `:${cellRef}` : '';
    worksheet['!ref'] = sheetRangeInfo;
    XLSX.utils.book_append_sheet(workbook, worksheet, exportData.fileName);
    XLSX.writeFile(workbook, `${exportData.fileName}.xlsx`);
  }

  private processDataForExport(exportData: ExportData): DataCell[][] {
    return exportData.recursionProperty ? this.getRecursiveDataForExport(exportData) : this.getDataForExport(exportData);
  }

  private getDataForExport(exportData: ExportData): DataCell[][] {
    const columnConfigsMap: Map<string, ColumnConfig> = new Map(exportData.columnConfigs.map(cc => [cc.propertyName, cc]));
    const maxLevel = 0;
    const currentLevel = 0;
    let result: DataCell[][] = [];

    result.push(this.addColumns(exportData.columnConfigs, maxLevel));

    for (const data of exportData.data) result.push(this.processSingleRows(data, columnConfigsMap, maxLevel, currentLevel));

    return result;
  }

  private getRecursiveDataForExport(exportData: ExportData): DataCell[][] {
    const maxLevel = this.getMaxLevel(exportData, false, 0);

    return this.processRecursiveExportData(
      exportData,
      true,
      maxLevel,
      this.createStackItemProcessor(exportData.recursionProperty, maxLevel)
    );
  }

  private getMaxLevel(exportData: ExportData, addHeader: boolean, maxLevel: number): number {
    return this.processRecursiveExportData(exportData, addHeader, maxLevel, i => i.level).reduce((prev, next) => Math.max(prev, next), 0);
  }

  private processRecursiveExportData<T>(
    exportData: ExportData,
    addHeader: boolean,
    maxLevel: number,
    stackItemProcessor: (item: StackItem, configs: Map<string, ColumnConfig>) => T
  ): T[] {
    const result: any[] = [];
    const stack: StackItem[] = [...exportData.data].map(val => ({ value: val, level: 1 })).reverse();
    const columnConfigsMap: Map<string, ColumnConfig> = new Map(exportData.columnConfigs.map(cc => [cc.propertyName, cc]));

    if (addHeader) result.push(this.addColumns(exportData.columnConfigs, maxLevel));

    while (stack.length) {
      const stackItem: StackItem = stack.pop()!;
      const processedItem: any = stackItemProcessor(stackItem, columnConfigsMap);

      result.push(processedItem);

      if (stackItem.value[exportData.recursionProperty]) {
        const newStackItem = Array.isArray(stackItem.value[exportData.recursionProperty])
          ? [...stackItem.value[exportData.recursionProperty]].map(val => ({ value: val, level: stackItem.level + 1 })).reverse()
          : [{ value: stackItem.value[exportData.recursionProperty], level: stackItem.level + 1 }];

        stack.push(...newStackItem);
      } else if (!this.isNumber(processedItem))
        result.push(...this.createCollectionStackItemProcessor(stackItem, columnConfigsMap, maxLevel));
    }

    return result;
  }

  private createStackItemProcessor(
    recursionProperty: string,
    maxLevel: number
  ): (item: StackItem, configs: Map<string, ColumnConfig>) => any[] {
    return (item, configs) => {
      const result: any[] = [];

      if (item.value != null && item.value != undefined) {
        for (const prop in item.value)
          if (item.value.hasOwnProperty(prop) && prop !== recursionProperty && configs.has(prop))
            result.push(this.processSingleValue(item.value[prop], configs.get(prop)));
      }

      const prevLevel = item.level - 1;
      result.splice(1, 0, ...this.repeat('', item.level == 1 ? maxLevel : maxLevel - prevLevel));

      return item.level == 1
        ? [...result, ...this.repeat('', maxLevel - 1)]
        : [...this.repeat('', item.level - 1), ...result, ...this.repeat('', maxLevel - item.level)];
    };
  }

  private createCollectionStackItemProcessor(item: StackItem, configs: Map<string, ColumnConfig>, maxLevel: number): DataCell[] {
    const result: DataCell[] = [];

    if (item.value != null && item.value != undefined) {
      for (const propName in item.value) {
        const config = configs.get(propName);

        if (config) {
          if (config.kind == ValueDataKind.collection)
            result.push(...this.processCollectionValue(item.value[propName], config, configs, item.level, maxLevel));
        }
      }
    }

    return result;
  }

  private processCollectionValue(
    data: any,
    collectionColumnConfig: ColumnConfig,
    parentColumnConfigs: Map<string, ColumnConfig>,
    currentLevel: number,
    maxLevel: number
  ): DataCell[] {
    if (collectionColumnConfig.usesParentHeader)
      return this.processCollectionUsingParentHeaders(data, parentColumnConfigs, currentLevel, maxLevel);
    else if (collectionColumnConfig.columnConfigs?.length > 0)
      return this.processCollectionUsingColumnConfigs(data, collectionColumnConfig, parentColumnConfigs);
    else return this.processCollectionData(data);
  }

  private repeat<T>(value: T, count: number): T[] {
    return Array(count).fill(value);
  }

  private isNumber(value: any): boolean {
    return typeof value === 'number';
  }

  private addColumnsCollectionUsingColumnConfigs(columnConfigs: Map<string, ColumnConfig>): any[] {
    const result: any = [];

    for (const columns of columnConfigs.values()) {
      if (columns.header) result.push(...[columns.header]);
    }
    return result;
  }

  private processCollectionUsingParentHeaders(
    data: any,
    parentColumnConfigs: Map<string, ColumnConfig>,
    currentLevel: number,
    maxLevel: number
  ): any[] {
    const result: any[] = [];

    for (const dataCollection of data) {
      const newData = this.processSingleRows(dataCollection, parentColumnConfigs, maxLevel, currentLevel);
      result.push([...this.repeat('', currentLevel), ...newData]);
    }

    return result;
  }

  private processCollectionUsingColumnConfigs(
    data: any,
    currentColumn: ColumnConfig,
    columnConfigs: Map<string, ColumnConfig>
  ): DataCell[] {
    const result: DataCell[] = [];

    result.push(...this.addColumnsCollectionUsingColumnConfigs(columnConfigs));
    for (const dataProcess of data) result.push(this.processSingleValue(dataProcess, currentColumn));

    return result;
  }

  private processSingleRows(data: any, columnConfigs: Map<string, ColumnConfig>, maxLevel: number, currentLevel: number): DataCell[] {
    const result: DataCell[] = [];

    for (const columnKey of columnConfigs.keys()) {
      const columns = columnConfigs.get(columnKey);

      if (columns) {
        const newData = this.getDataCellFormat(data[columns.propertyName], columns);
        if (columns.header) result.push(newData);
      }
    }
    result.splice(1, 0, ...this.repeat(null, maxLevel - currentLevel));
    return result;
  }

  private processSingleValue(value: any, columnConfig: ColumnConfig): DataCell {
    return this.getDataCellFormat(value, columnConfig);
  }

  private addColumns(columnConfigs: ColumnConfig[], level: number): any[] {
    const result: any = [];

    for (const columns of columnConfigs) {
      if (columns.header) result.push(...[columns.header]);
    }
    result.splice(1, 0, ...this.repeat('', level));
    return result;
  }

  private processCollectionData(data: any): any[] {
    let result: any = [];

    for (const propName in data) {
      if (Object.prototype.hasOwnProperty.call(data, propName)) {
        const propValue = data[propName];

        result.push(`${propValue}`);
      }
    }

    return result.join(', ');
  }

  private getDataCellFormat(value: any, columnConfig: ColumnConfig): DataCell {
    let cellFormat: CellFormat = null;

    switch (columnConfig.kind) {
      case ValueDataKind.number:
        cellFormat = {
          type: 'number',
          format: 'number',
          style: { s: { alignment: { horizontal: 'right' } }, z: { Number: '0.###' } }
        };
        break;
      case ValueDataKind.string:
        cellFormat = {
          type: 'text',
          format: 'text',
          style: { s: { alignment: { horizontal: 'center' }, font: { size: 12 } } }
        };
        break;
      case ValueDataKind.date:
        cellFormat = {
          type: 'date',
          format: 'shortDate',
          style: { z: { shortDate: 'dd/mm/yyyy' } }
        };
        break;
      case ValueDataKind.datetime:
        cellFormat = {
          type: 'date',
          format: 'longDate',
          style: { z: { longDate: 'dd/mm/yyyy hh:mm:ss' } }
        };
        break;
      case ValueDataKind.currency:
        cellFormat = {
          type: 'number',
          format: 'currency',
          style: { s: { alignment: { horizontal: 'right' } } }
        };
        break;
    }
    const dataCellFormat = new DataCell(value, cellFormat);
    return dataCellFormat;
  }

  private convertDataCellToXLSXCell(dataCellFormat: DataCell): XLSXCell {
    let xlsxCell: XLSXCell = null;
    let numberFormatStrins: string;
    const currencyConfig = this.currencyConfig.getConfig();
    const currencyFormat = this.getCurrencyFormat(currencyConfig.keyId, currencyConfig.symbolPosition);

    switch (dataCellFormat.cellFormat.type) {
      case 'number':
        if (dataCellFormat.cellFormat.format === 'currency') {
          numberFormatStrins = currencyFormat;
        }
        xlsxCell = {
          v: dataCellFormat.value || '',
          t: 'n',
          s: dataCellFormat.cellFormat.style,
          z: numberFormatStrins
        };
        break;
      case 'date':
        numberFormatStrins = dataCellFormat.cellFormat.format === 'longDate' ? 'yyyy-mm-dd hh:mm:ss' : 'yyyy-mm-dd';
        xlsxCell = {
          v: dataCellFormat.value || '',
          t: 'd',
          s: dataCellFormat.cellFormat.style,
          z: numberFormatStrins
        };
        break;
      case 'text':
        xlsxCell = {
          v: dataCellFormat.value || '',
          t: 's',
          s: dataCellFormat.cellFormat.style
        };
        break;
    }
    return xlsxCell;
  }

  public getCurrencyFormat(currencyConfigKey: string, symbolPosition: any): XLSXCurrencySymbolFormat {
    currencyConfigKey = currencyConfigKey;
    const defaultFormat = '#,##0.00';
    symbolPosition = symbolPosition == 1 ? 'RIGHT' : 'LEFT';
    const formatKey = currencyConfigKey + '_' + symbolPosition;
    return XLSXCurrencySymbolFormat[formatKey] || defaultFormat;
  }
}
