import { DatePipe, DecimalPipe, PercentPipe } from '@angular/common';
import { Injector, Pipe, PipeTransform, Type } from '@angular/core';
import { formatRFC7231, isValid, parse, parseISO, parseJSON } from 'date-fns/esm/fp';
import { CurrencyConfig } from '../../shared/models/CurrencyConfig';
import { ValueDataKind } from '../../shared/models/value-data-kind';
import { CurrencyPipe } from '../../shared/pipes/Currency.pipe';
import { SignalPipe } from '../../shared/pipes/signal.pipe';
import { CurrencyConfigService } from '../../shared/services/configs/currency-config.service';

@Pipe({ name: 'datatype' })
export class DataTypePipe implements PipeTransform {
  constructor(private _injector: Injector, private _currencyService: CurrencyConfigService) {}

  public transform(value: any, dataType: ValueDataKind, dataFormat: string): any {
    let result = value;

    if (dataType && result) {
      const pipes = this.getPipeToken(dataType, dataFormat);

      result = this.preProcessValue(result, dataType);
      pipes.forEach(pipeConfig => {
        const pipe = this._injector.get(pipeConfig.pipeToken);
        if (pipe) {
          try {
            result = pipe.transform(result, ...pipeConfig.pipeArgs);
          } catch (err) {
            console.log(err);
          }
        }
      });
    }

    return result;
  }

  private preProcessValue(value: any, dataType: ValueDataKind): any {
    let result: any;
    let date: Date;

    try {
      switch (dataType) {
        case ValueDataKind.date:
          result = formatRFC7231(this.parseDate(value, ['dd/MM/yyyy']));
          break;
        case ValueDataKind.time:
          result = formatRFC7231(this.parseDate(value, ['HH:mm:ss']));
          break;
        case ValueDataKind.datetime:
          result = formatRFC7231(this.parseDate(value, ['dd/MM/yyyy HH:mm:ss']));
          break;
        default:
          result = value;
          break;
      }
    } catch (err) {
      console.log(err);
      result = value || '';
    }

    return result;
  }

  private parseDate(value: any, customFormats?: string[]): Date {
    const now = new Date();
    let date = null;

    if (customFormats?.length) {
      for (const format of customFormats) {
        date = parse(now, format, value);

        if (isValid(date)) {
          return date;
        }
      }
    }

    date = parseISO(value);

    if (isValid(date)) {
      return date;
    }

    return parseJSON(value);
  }

  private getPipeToken(dataType: ValueDataKind, dataFormat: string): Array<{ pipeToken: Type<any>; pipeArgs: any[] }> {
    const result: Array<{ pipeToken: Type<any>; pipeArgs: any[] }> = [];
    const args = [];
    let currencyConfig = this._currencyService.getConfig();

    if (!currencyConfig) {
      currencyConfig = new CurrencyConfig();
      currencyConfig.symbol = '€';
      currencyConfig.symbolPosition = 1;
      currencyConfig.decimalPlaces = 2;
    }

    switch (dataType) {
      case ValueDataKind.number:
        result.push({
          pipeToken: DecimalPipe,
          pipeArgs: [dataFormat || `1.${currencyConfig.decimalItemPlaces}-${currencyConfig.decimalItemPlaces}`]
        });
        break;
      case ValueDataKind.signalNumber:
        args.push(DecimalPipe);
        args.push(dataFormat || '1.0-0');
        result.push({ pipeToken: SignalPipe, pipeArgs: args });
        break;
      case ValueDataKind.currency:
        args.push(currencyConfig.symbol);
        args.push(currencyConfig.symbolPosition);
        args.push(DecimalPipe);
        args.push(dataFormat || this._currencyService.getFormat());
        result.push({ pipeToken: CurrencyPipe, pipeArgs: args });
        break;
      case ValueDataKind.signalCurrency:
        args.push(CurrencyPipe);
        args.push(dataFormat || '1.2-2');
        result.push({ pipeToken: SignalPipe, pipeArgs: args });
        break;
      case ValueDataKind.percentage:
        args.push(dataFormat || '1.2');
        result.push({ pipeToken: PercentPipe, pipeArgs: args });
        break;
      case ValueDataKind.date:
        result.push({ pipeToken: DatePipe, pipeArgs: [dataFormat || 'dd-MM-yyyy'] });
        break;
      case ValueDataKind.time:
        result.push({ pipeToken: DatePipe, pipeArgs: [dataFormat || 'HH:mm:ss'] });
        break;
      case ValueDataKind.datetime:
        result.push({ pipeToken: DatePipe, pipeArgs: [dataFormat || 'dd-MM-yyyy HH:mm:ss'] });
        break;
    }

    return result;
  }
}
