import { DatePipe, TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { differenceInDays, addDays, format, parse } from 'date-fns/esm/fp';
import { flow } from 'lodash-es';

import { DxTooltipInfo } from '../../../shared/models/dx-tooltip-info';
import { CurrencyPipe } from '../../../shared/pipes/Currency.pipe';
import { SpinnerService } from '../../../shared/services/spinner/spinner.service';
import { TemplateRendererService } from '../../../shared/services/template-renderer/template-renderer.service';
import { ItemAccumulatedTotal } from '../../model/accumulated-totals';
import { AccumulatedTotalsDateParameters, AccumulatedTotalsDateParametersEnum } from '../../model/accumulated-totals-date-parameters-enum';
import { AccumulatedTotalsService } from '../../services/accumulated-totals.service';

type DxoTickInterval = 'day' | 'hour' | 'millisecond' | 'minute' | 'month' | 'quarter' | 'second' | 'week' | 'year';

@Component({
  selector: 'app-sales-accumulated-total',
  templateUrl: './sales-accumulated-total.component.html',
  styleUrls: ['./sales-accumulated-total.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SalesAccumulatedTotalComponent {
  public data$: Observable<ItemAccumulatedTotal[]>;
  public isLegendVisible = true;
  public tickInterval: DxoTickInterval;

  private _incidentOcurred = false;
  private _dateParameters: AccumulatedTotalsDateParameters;

  constructor(
    private _service: AccumulatedTotalsService,
    private _spinnerService: SpinnerService,
    private _elementRef: ElementRef,
    private _templateRendererService: TemplateRendererService,
    private _currencyPipe: CurrencyPipe,
    private _datePipe: DatePipe,
    private _titleCasePipe: TitleCasePipe,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}

  @Input() public set dateParameters(value: AccumulatedTotalsDateParameters) {
    if (this._dateParameters !== value) {
      this._dateParameters = value;
      this.refresh();
    }
  }

  public refresh(): void {
    let initialDate = this._dateParameters.initialDate;
    let finalDate = this._dateParameters.finalDate;

    if (this._dateParameters.key === AccumulatedTotalsDateParametersEnum.custom) {
      initialDate = this._dateParameters.customInititalDate;
      finalDate = this._dateParameters.customFinalDate;
    }

    this.tickInterval = this.getTickInterval(initialDate, finalDate);

    const sales = this._service.GetSalesAccumulatedTotalCloudFull(initialDate, finalDate);
    let data$: Observable<ItemAccumulatedTotal[]> = null;

    if (
      this._dateParameters.key === AccumulatedTotalsDateParametersEnum.lastWeek ||
      this._dateParameters.key === AccumulatedTotalsDateParametersEnum.lastMonth
    ) {
      data$ = this.fillMissingDays(initialDate, finalDate, sales);
    } else {
      data$ = sales;
    }

    this.data$ = this._spinnerService.showFor(data$, this._elementRef);
  }

  public incidentOcurred(event: any): void {
    if (event.target.id === 'W2104') {
      this._incidentOcurred = true;
    }
  }

  public customizeValueLabel(template: string): (obj: { value: any }) => string {
    return this._templateRendererService.CreateTemplateRenderer(template, [
      { pipes: [{ pipe: this._currencyPipe, pipeArgs: null }], propertyName: 'value' as const }
    ]);
  }

  public customizeTooltip(template: string): (value: DxTooltipInfo) => { text: string } {
    const dateFormat = this.getTooltipDateFormat();
    const templateRenderer = this._templateRendererService.CreateTemplateRenderer(template, [
      { propertyName: 'seriesName' as const },
      {
        pipes: [
          { pipe: this._datePipe, pipeArgs: [dateFormat] },
          { pipe: this._titleCasePipe, pipeArgs: null }
        ],
        propertyName: 'argument' as const
      },
      { pipes: [{ pipe: this._currencyPipe, pipeArgs: null }], propertyName: 'value' as const }
    ]);
    return (value: DxTooltipInfo) => ({ text: templateRenderer(value) });
  }

  public onLegendClick(e: any): void {
    e.target.isVisible() ? e.target.hide() : e.target.show();
    this._changeDetectorRef.detectChanges();
  }

  public chartDrawn(): void {
    this.isLegendVisible = !this._incidentOcurred;
  }

  private getTooltipDateFormat() {
    let dateFormat = 'dd-MM-yyyy';

    if (this._dateParameters) {
      switch (this._dateParameters.key) {
        case AccumulatedTotalsDateParametersEnum.last5Year:
          dateFormat = 'yyyy';
          break;

        case AccumulatedTotalsDateParametersEnum.lastYear:
          dateFormat = 'LLLL';
          break;

        case AccumulatedTotalsDateParametersEnum.custom: {
          const tickInterval = this.getTickInterval(this._dateParameters.customInititalDate, this._dateParameters.customFinalDate);

          switch (tickInterval) {
            case 'year':
              dateFormat = 'yyyy';
              break;

            case 'month':
              dateFormat = 'LLLL';
          }
        }
      }
    }
    return dateFormat;
  }

  private getTickInterval(inititalDate: Date, finalDate: Date): DxoTickInterval {
    const daysCount = differenceInDays(inititalDate, finalDate);
    let tickInterval: DxoTickInterval;

    if (daysCount > 365) {
      tickInterval = 'year';
    } else if (daysCount > 31) {
      tickInterval = 'month';
    } else {
      tickInterval = 'day';
    }

    return tickInterval;
  }

  private fillMissingDays(
    initialDate: Date,
    finalDate: Date,
    sales$: Observable<ItemAccumulatedTotal[]>
  ): Observable<ItemAccumulatedTotal[]> {
    const daysCount = differenceInDays(initialDate, finalDate);
    const result$: Observable<ItemAccumulatedTotal[]> = sales$.pipe(
      map(itemTotals => {
        const dateFormat = 'yyyy-MM-dd';
        const allDays = [...Array(daysCount).keys()].map(i => flow(addDays(i), format(dateFormat))(initialDate));
        // Inject all days into stores
        const storeTotals = itemTotals.reduce((acc, next) => {
          if (acc.has(next.storeName)) {
            acc.get(next.storeName).push(new ItemAccumulatedTotal(next));
          } else {
            acc.set(
              next.storeName,
              allDays.map(
                day => new ItemAccumulatedTotal({ storeName: next.storeName, syncDate: parse(new Date(), dateFormat, day), total: 0 })
              )
            );
            acc.get(next.storeName).push(new ItemAccumulatedTotal(next));
          }

          return acc;
        }, new Map<string, ItemAccumulatedTotal[]>());

        // Aggregate store totals values by date
        return Array.from(storeTotals.values()).reduce(
          (prevStore, nextStore) =>
            prevStore.concat(
              Array.from(
                nextStore
                  .reduce((daysTotalsMapping, nextTotal) => {
                    const date = format(dateFormat, nextTotal.syncDate);

                    if (daysTotalsMapping.has(date)) {
                      daysTotalsMapping.get(date).total += nextTotal.total;
                    } else {
                      daysTotalsMapping.set(date, nextTotal);
                    }
                    return daysTotalsMapping;
                  }, new Map<string, ItemAccumulatedTotal>())
                  .values()
              )
            ),
          []
        );
      })
    );

    return result$;
  }
}
