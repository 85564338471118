import { Component } from '@angular/core';
import { DateAdapter, NativeDateAdapter } from '@angular/material/core';
import {
  DateRange,
  DefaultMatCalendarRangeStrategy,
  MatRangeDateSelectionModel,
  MAT_DATE_RANGE_SELECTION_STRATEGY
} from '@angular/material/datepicker';

@Component({
  selector: 'app-range-picker',
  templateUrl: './range-picker.component.html',
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DefaultMatCalendarRangeStrategy
    },
    { provide: DateAdapter, useClass: NativeDateAdapter },
    DefaultMatCalendarRangeStrategy,
    MatRangeDateSelectionModel
  ],
  styleUrls: ['./range-picker.component.scss']
})
export class RangePickerComponent {
  public dateRange: DateRange<Date>;
  public dateChanged: boolean;

  constructor(
    private readonly selectionModel: MatRangeDateSelectionModel<Date>,
    private readonly selectionStrategy: DefaultMatCalendarRangeStrategy<Date>
  ) {}

  public rangeChanged(selectedDate: Date): void {
    const selection = this.selectionModel.selection;
    const newSelection = this.selectionStrategy.selectionFinished(selectedDate, selection);

    this.selectionModel.updateSelection(newSelection, this);
    this.dateRange = new DateRange<Date>(newSelection.start, newSelection.end);
    this.dateChanged = true;
  }
}
