<div class="container">
  <mat-toolbar class="accumulatedToolbar">
    <span i18n="AccumulatedTotalsContainerTitle|Accumulated Totals Container Title@@accumulatedTotals_title">Totais Acumulados</span>
    <div class="parameters">
      <mat-form-field>
        <mat-select [value]="dateParameters.key" (selectionChange)="dateParametersChanged($event)">
          <mat-optgroup *ngFor="let group of parametersGroups" [label]="group.name" [disabled]="group.disabled">
            <mat-option *ngFor="let parameters of group.parameters" [value]="parameters.value">
              {{ parameters.viewValue }}
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
      <button #customDateButton mat-icon-button (click)="openRangePickerPopUp()"><mat-icon>edit</mat-icon></button>
    </div>
  </mat-toolbar>
  <mat-accordion multi="true">
    <mat-expansion-panel class="firstPanel" expanded="true">
      <mat-expansion-panel-header>
        <mat-panel-title
          i18n="AccumulatedTotalsContainerSalesPanelTitle|Accumulated Totals Container Sales Panel Title@@accumulatedTotals_salesPanelitle"
          >Acumulados Vendas</mat-panel-title
        >
      </mat-expansion-panel-header>
      <div class="accumulatedComponent">
        <app-sales-accumulated-total [dateParameters]="dateParameters"></app-sales-accumulated-total>
      </div>
      </mat-expansion-panel>
    <mat-expansion-panel expanded="true" class="accumulated-panel">
      <mat-expansion-panel-header>
        <mat-panel-title
          i18n="
            AccumulatedTotalsContainerItemGroupPanelTitle|Accumulated Totals Container ItemGroup Panel
            Title@@accumulatedTotals_itemgroupPanelTitle"
          >Acumulados Famílias</mat-panel-title
        >
        <small [hidden]="!selectedItemGroup">{{ selectedItemGroup }}</small>
      </mat-expansion-panel-header>
      <div class="accumulatedComponent">
        <app-itemgroup-accumulated-total
        [(selectedItemGroup)]="selectedItemGroup"
        [dateParameters]="dateParameters"
      ></app-itemgroup-accumulated-total>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel expanded="true" class="accumulated-panel">
      <mat-expansion-panel-header>
        <mat-panel-title
          i18n="
            AccumulatedTotalsContainerSalesmanPanelTitle|Accumulated Totals Container Salesman Panel
            Title@@accumulatedTotals_salesmanPanelTitle"
          >Acumulados Vendedores</mat-panel-title
        >
      </mat-expansion-panel-header>
      <div class="accumulatedComponent" >
        <app-salesman-accumulated-total [dateParameters]="dateParameters"></app-salesman-accumulated-total>
      </div>
      </mat-expansion-panel>
  </mat-accordion>
</div>
