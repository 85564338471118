import { Component } from '@angular/core';

import { WidgetValueComponent } from '../widget-value.component';

@Component({
  selector: 'app-avatar-value',
  templateUrl: './avatar-value.component.html',
  styleUrls: ['../widget-value.component.scss', './avatar-value.component.scss']
})
export class AvatarValueComponent extends WidgetValueComponent {
  public createAvatarText(value: string): string {
    value = String(value);
    return value
      .replace(/(([A-Z])\w+)+/g, '$2')
      .replace(' ', '')
      .substr(0, 2);
  }
}
