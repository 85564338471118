/* eslint-disable @angular-eslint/no-host-metadata-property */
import { Directive, Renderer2 } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'img[scaleImageKeepRatio]',
  host: {
    '(load)': 'onLoad($event)'
  }
})
export class ScaleImageKeepRatioDirective {
  constructor(private _renderer: Renderer2) {}
  public onLoad(event: Event): void {
    const element = event.target as HTMLImageElement;

    if (element.naturalHeight > element.naturalWidth) {
      this._renderer.setStyle(element, 'height', '100%');
      this._renderer.setStyle(element, 'width', 'auto');
    } else if (element.naturalHeight < element.naturalWidth) {
      this._renderer.setStyle(element, 'height', 'auto');
      this._renderer.setStyle(element, 'width', '100%');
    }
  }
}
