import { CellFormat } from "./cell-format";

export class DataCell {
  public value: any;
  public cellFormat: CellFormat;

  public constructor(value: any, cellFormat: CellFormat) {
    this.value = value;
    this.cellFormat = cellFormat;
  }
}
