<div [ngClass]="{ 'text-value-container': true, 'linked': !!valueConfig?.link, 'cloud-link': !!valueConfig?.link }">
  <ng-container *ngIf="valueConfig.displayName; else noTitleCaption">
    <div class="col">
      <span class="float-left font-weight-bold small">{{ valueConfig.displayName }}</span>
      <span class="float-right small">{{ value[valueConfig.name] | datatype: valueConfig.dataType:valueConfig.dataFormat }}</span>
    </div>
  </ng-container>
</div>
<ng-template #noTitle>
  <span class="float-right small">{{ value[valueConfig.name] | datatype: valueConfig.dataType:valueConfig.dataFormat }}</span>
</ng-template>
<ng-template #noTitleCaption>
  <ng-container *ngIf="valueConfig.displayType === ValueDisplayKind.text; else noTitle">
    <div class="col d-flex justify-content-center align-items-center">
      <div class="row">
        <div class="d-flex justify-content-center align-items-center">
          <span class="text-center font-weight-bold">{{
            value[valueConfig.name] | datatype: valueConfig.dataType:valueConfig.dataFormat
          }}</span>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>
