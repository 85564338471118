export class AccumulatedTotals {
  public syncDate: Date;
  public totalNet: number;
  public total: number;
  public qnt: number;
  public totalDiscountsNet: number;
  public totalDiscounts: number;
  public costNet: number;
  public cost: number;
  public profitNet: number;
  public profit: number;
  public storeName: string;
}

export class ItemAccumulatedTotal extends AccumulatedTotals {
  public itemKeyId: string;
  public syncGuid: string;
  constructor(itemAccumulatedTotal?: Partial<ItemAccumulatedTotal>) {
    super();
    Object.assign(this, itemAccumulatedTotal);
  }
}

export class ItemGroupAccumulatedTotal extends AccumulatedTotals {
  public groupId: number;
  public groupName: string;
  public syncGuid: string;

  constructor(itemGroupAccumulatedTotal?: Partial<ItemGroupAccumulatedTotal>) {
    super();
    Object.assign(this, itemGroupAccumulatedTotal);
  }
}

export class TerminalAccumulatedTotal extends AccumulatedTotals {
  filter(arg0: (value: any, index: any) => boolean): any {
    throw new Error('Method not implemented.');
  }
  public terminalId: number;
  public terminalDescription: string;

  constructor(terminalAccumulatedTotal?: Partial<TerminalAccumulatedTotal>) {
    super();
    Object.assign(this, terminalAccumulatedTotal);
  }
}

export class SalesmanAccumulatedTotal extends AccumulatedTotals {
  public salesmanId: number;
  public salesmanName: string;
  public syncGuid: string; // SyncGuide to syncGuid

  constructor(salesmanAccumulatedTotal?: Partial<SalesmanAccumulatedTotal>) {
    super();
    Object.assign(this, salesmanAccumulatedTotal);
  }
}
