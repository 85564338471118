<mat-card #monthsChart class="monthlySales">
  <mat-card-header class="cardHeader">
    <mat-card-title
      i18n="storeSummaryMonthsChartTitle|store Summary Months Chart Title@@storeSummary_monthsChartTitle">Vendas |
      Mês</mat-card-title>
    <mat-card-subtitle *ngIf="monthlySalesData?.length" (click)="showMoreMonthlySales()" class="showMoreButton">
      <span *ngIf="!monthsTableVisible"
        i18n="storeSummaryChartsShowMoreSubTitle|store Summary Charts Show More SubTitle@@storeSummary_chartsShowMoreSubTitle">Ver
        mais</span>
      <span *ngIf="monthsTableVisible"
        i18n="storeSummaryChartsShowLessSubTitle|store Summary Charts Show Less SubTitle@@storeSummary_chartsShowLessSubTitle">Ver
        menos</span>
    </mat-card-subtitle>
    <div class="parameters">
      <mat-form-field class="dateInterval">
        <mat-select [(value)]="selectedMonth" (selectionChange)="monthlyIntervalChanged()">
          <mat-option *ngFor="let date of monthsInterval" [value]="date">
            {{ date.description }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-icon (click)="monthlyIntervalChanged()" class="refreshButton">cached</mat-icon>
      <button mat-icon-button class="export-button" [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item [matMenuTriggerFor]="chartExportTo"><mat-icon>donut_small</mat-icon><span
            i18n="ExportChartButton|Export Chart Button@@export_chart">Exportar Gráfico</span></button>
        <button mat-menu-item [matMenuTriggerFor]="tableExportTo"><mat-icon>document_scanner</mat-icon><span
            i18n="ExportTableButton|Export Table Button@@export_table">Exportar Tabela</span></button>
        <mat-checkbox *ngIf="displayCheckbox" color="primary" class="service-tax" [(ngModel)]="isChecked"
          (change)="onCheckboxChange()">Taxa
          de Serviço</mat-checkbox>
      </mat-menu>
      <mat-menu #chartExportTo="matMenu">
        <button mat-menu-item (click)="exportChartPdf()">PDF</button>
        <button mat-menu-item (click)="exportChartPng()">PNG</button>
      </mat-menu>
      <mat-menu #tableExportTo="matMenu">
        <button mat-menu-item (click)="exportXlSX()">XLS</button>
      </mat-menu>
    </div>
  </mat-card-header>
  <ng-container *ngIf="monthlySales$ | async">
    <ng-container *ngIf="monthlySalesData?.length; else emptyChart">
      <ng-container *ngIf="!monthsTableVisible">
        <mat-card-content class="monthlySalesContent">
          <dx-chart #monthsChart class="chart" [dataSource]="monthlySalesData">
            <dxi-series argumentField="date" type="bar" valueField="total"></dxi-series>
            <dxo-argument-axis argumentType="datetime" type="continuous">
              <dxo-minor-tick-interval [months]="1"></dxo-minor-tick-interval>
              <dxo-tick-interval [months]="1"></dxo-tick-interval>
              <dxo-label [overlappingBehavior]="'rotate'" [rotationAngle]="45"></dxo-label>
            </dxo-argument-axis>
            <dxo-margin [top]="22" [left]="20" [bottom]="38" [right]="20"></dxo-margin>
            <dxo-legend [visible]="false"></dxo-legend>
            <dxo-tooltip enabled="true" [zIndex]="5000" [customizeTooltip]="customizeTooltip"></dxo-tooltip>
          </dx-chart>
        </mat-card-content>
      </ng-container>
      <ng-container *ngIf="monthsTableVisible">
        <mat-card-content class="monthlySalesTableContent">
          <table class="monthlySalesTable" mat-table [dataSource]="monthlySalesDataSource">
            <ng-container [matColumnDef]="col" *ngFor="let col of monthsTableColumns">
              <ng-container *ngIf="col === 'date'">
                <th class="tableHeaders" mat-header-cell *matHeaderCellDef> {{ col | translate }} </th>
                <td class="tableCells" mat-cell *matCellDef="let element"> {{ element[col] }} </td>
              </ng-container>
              <ng-container *ngIf="col !== 'date'">
                <th class="tableHeaders" mat-header-cell *matHeaderCellDef> {{ col | translate }} </th>
                <td class="tableCells" mat-cell *matCellDef="let element"> {{ element[col] | cloudCurrency }} </td>
              </ng-container>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="monthsTableColumns; sticky: true"></tr>
            <tr class="tableRows" mat-row *matRowDef="let row; columns: monthsTableColumns"></tr>
          </table>
        </mat-card-content>
      </ng-container>
    </ng-container>
  </ng-container>
</mat-card>

<ng-template #emptyChart>
  <div class="center-error">
    <div class="pl-3 pr-3">
      <p class="col text-center text-color"
        i18n="storeSummaryEmptyChartTitle|store Summary Empty Chart Title@@storeSummary_emptyChartTitle">Não foram
        encontradas vendas para os parametros selecionados.</p>
      <p class="col text-center text-color"
        i18n="storeSummaryEmptyChartSubTitle|store Summary Empty Chart SubTitle@@storeSummary_emptyChartSubTitle">As
        lojas selecionadas poderão ter estado encerradas durante o intervalo de tempo selecionado.</p>
    </div>
  </div>
</ng-template>
