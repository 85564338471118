import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DxTooltipInfo } from '../../../shared/models/dx-tooltip-info';
import { CurrencyPipe } from '../../../shared/pipes/Currency.pipe';
import { untilDestroy } from '../../../shared/rxjs/operators/until-destroy';
import { SpinnerService } from '../../../shared/services/spinner/spinner.service';
import { TemplateRendererService } from '../../../shared/services/template-renderer/template-renderer.service';
import { TranslatedMatSnackBar } from '../../../shared/services/translated-matSnackBar/translated-mat-snack-bar.service';
import { ItemGroupAccumulatedTotal } from '../../model/accumulated-totals';
import { AccumulatedTotalsDateParameters, AccumulatedTotalsDateParametersEnum } from '../../model/accumulated-totals-date-parameters-enum';
import { AccumulatedTotalsService } from '../../services/accumulated-totals.service';

@Component({
  selector: 'app-itemgroup-accumulated-total',
  templateUrl: './itemgroup-accumulated-total.component.html',
  styleUrls: ['./itemgroup-accumulated-total.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemgroupAccumulatedTotalComponent implements OnDestroy {
  @Input() public selectedItemGroup: string;
  @Output() public selectedItemGroupChange: EventEmitter<string>;
  public data$: Observable<ItemGroupAccumulatedTotal[]>;
  public isFirstLevel: boolean;
  public dateParametersValue: AccumulatedTotalsDateParameters;
  public currencyPipe: CurrencyPipe;
  public argumentField = 'groupName';

  constructor(
    private _service: AccumulatedTotalsService,
    private _snackBar: TranslatedMatSnackBar,
    private _spinnerService: SpinnerService,
    private _currencyPipe: CurrencyPipe,
    private _elementRef: ElementRef,
    private _templateRendererService: TemplateRendererService
  ) {
    this.isFirstLevel = true;
    this.selectedItemGroupChange = new EventEmitter<string>();
  }

  public ngOnDestroy(): void {
    // Used by the rxjs operator untilDestroy
  }

  @Input() public set dateParameters(value: AccumulatedTotalsDateParameters) {
    if (this.dateParametersValue !== value) {
      this.dateParametersValue = value;
      this.refresh();
    }
  }

  public refresh(): void {
    let initialDate = this.dateParametersValue.initialDate;
    let finalDate = this.dateParametersValue.finalDate;

    if (this.dateParametersValue.key === AccumulatedTotalsDateParametersEnum.custom) {
      initialDate = this.dateParametersValue.customInititalDate;
      finalDate = this.dateParametersValue.customFinalDate;
    }

    const data = this._service.GetItemGroupAccumulatedTotalCloudFull(initialDate, finalDate);

    this.data$ = this._spinnerService.showFor(data, this._elementRef).pipe(untilDestroy(this));
  }

  public onButtonClick(): void {
    if (!this.isFirstLevel) {
      this.isFirstLevel = true;
      this.argumentField = 'groupName';
      this.selectedItemGroup = null;
      this.selectedItemGroupChange.emit(this.selectedItemGroup);

      this.refresh();
    }
  }
  public onPointClick(e: any): void {
    if (this.isFirstLevel) {
      const groupId = e.target.data.groupId;
      this.selectedItemGroup = e.target.data.groupName;
      this.selectedItemGroupChange.emit(this.selectedItemGroup);
      this.argumentField = 'storeName';
      this.isFirstLevel = false;

      let inititalDate = this.dateParametersValue.initialDate;
      let finalDate = this.dateParametersValue.finalDate;

      if (this.dateParametersValue.key === AccumulatedTotalsDateParametersEnum.custom) {
        inititalDate = this.dateParametersValue.customInititalDate;
        finalDate = this.dateParametersValue.customFinalDate;
      }
      const data = this._service.getItemGroupByStore(groupId, inititalDate, finalDate);

      this.data$ = this._spinnerService.showFor(data, this._elementRef).pipe(
        catchError(err => {
          this._snackBar.openTranslate('notfound_ItemGroupAccumulatedTotal', 'close_1', { duration: 5000 });

          return EMPTY;
        })
      );
    }
  }

  public customizeValueLabel(template: string): (obj: { value: any }) => string {
    const templateRenderer = this._templateRendererService.CreateTemplateRenderer(template, [
      { pipes: [{ pipe: this._currencyPipe, pipeArgs: null }], propertyName: 'value' as const }
    ]);

    return templateRenderer;
  }

  public customizeTooltip(template: string): (value: DxTooltipInfo) => { text: string } {
    const templateRenderer = this._templateRendererService.CreateTemplateRenderer(template, [
      { propertyName: 'seriesName' as const },
      { pipes: [{ pipe: this._currencyPipe, pipeArgs: null }], propertyName: 'value' as const }
    ]);
    const tooltipCustomizer = (value: DxTooltipInfo) => ({ text: templateRenderer(value) });

    return tooltipCustomizer;
  }

  public onLegendClick(e: any): void {
    e.target.isVisible() ? e.target.hide() : e.target.show();
  }
}
