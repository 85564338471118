import { AfterViewInit, Component, ElementRef, OnDestroy } from '@angular/core';
import { BehaviorSubject, concat, ConnectableObservable, EMPTY, Observable, Subject, Subscription, timer } from 'rxjs';
import { catchError, finalize, map, multicast, take } from 'rxjs/operators';
import { ComponentOverlay } from '../shared/models/component-overlay';
import { ValueDataKind } from '../shared/models/value-data-kind';
import { ProxySubject } from '../shared/rxjs';
import { logError } from '../shared/rxjs/operators/log-error';
import { untilDestroy } from '../shared/rxjs/operators/until-destroy';
import { ComponentOverlayService } from '../shared/services/component-overlay/component-overlay.service';
import { SpinnerService } from '../shared/services/spinner/spinner.service';
import { ICloudWidget } from './models/cloud-widget';
import { WidgetData } from './models/widget-data';
import { WidgetKind, WidgetValueDisplayKind } from './models/widget-enums';
import { WidgetLayout } from './models/widget-layout';
import { WidgetParameterConfiguration } from './models/widget-parameter-configuration';
import { WidgetPropertyGetter } from './models/widget-property-getter';
import { ParametersComponent } from './parameters/parameters.component';
import { WidgetService } from './services/widget.service';

@Component({ template: '' })
export abstract class WidgetComponent extends ComponentOverlay implements OnDestroy, AfterViewInit {
  //#region Private properties
  private readonly _widgetSubject: Subject<ICloudWidget>;
  private readonly _widget$: Observable<ICloudWidget>;
  private readonly _isLoadingSubject: Subject<boolean>;
  private readonly _hasErrorSubject: BehaviorSubject<boolean> = null;
  //#endregion Private properties

  //#region protected properties

  /**
   * Some widgets have fixed ids meaning they are not tied to a dashboard.
   * Such widgets need to have its cloud id initialized.
   */
  protected cloudWidgetId: string = null;

  /**
   * Set this to true to force get offline widget before the actual widget
   */
  protected hasOfflineWidget = false;

  protected defaultWidgetHeight = 25;
  //#endregion protected properties

  //#region public properties
  public abstract parametersOverrides: Array<WidgetPropertyGetter>;
  public hasError$: Observable<boolean> = null;
  public onRefresh$: Observable<WidgetData> = null;
  public isLoading$: Observable<boolean> = null;
  public ValueDisplayKind = WidgetValueDisplayKind;
  public ValueDataKind = ValueDataKind;
  public widgetId: string;
  public parametersConfig: Array<WidgetParameterConfiguration>;
  public parameters: any = {}; // TODO: show parameters inputs/info
  public titleSize = 1.25;
  public kind: WidgetKind;
  public resize$: Observable<void>;

  /***
   * @summary Follow widget link is handled by the widget container and if not initialized
   * does nothing
   */
  public followLink: (linkId: string, value: any) => void;

  public get widget$(): Observable<ICloudWidget> {
    return this._widget$;
  }
  //#endregion public properties

  constructor(
    protected readonly widgetService: WidgetService,
    private readonly _elementRef: ElementRef,
    private readonly _spinnerService: SpinnerService,
    private readonly _componentOverlayService: ComponentOverlayService
  ) {
    super();
    this._widgetSubject = new Subject<ICloudWidget>();
    this._widget$ = this._widgetSubject.asObservable();
    this._isLoadingSubject = new Subject<boolean>();
    this._hasErrorSubject = new BehaviorSubject<boolean>(false);
    this.hasError$ = this._hasErrorSubject.asObservable();
    this.isLoading$ = this._isLoadingSubject.asObservable();
    this.onRefresh$ = this._widgetSubject.pipe(map(cw => ({ widgetId: this.widgetId, cloudWidget: cw })));
    this.followLink = () => {
      // If a widget uses the follow link feature it has to assign a function to this property
    };
  }

  public ngAfterViewInit(): void {
    // Without this delay isLoading property will be changed after view is checked
    timer(100)
      .pipe(take(1))
      .subscribe(() => this.refresh());
  }
  public abstract ngOnDestroy(): void;

  /***
   * @summary Used to setup any extra behavior needed by child classes on the incoming widget data
   */
  public abstract setupWidget(widget$: Observable<ICloudWidget>): Observable<ICloudWidget>;

  /**
   * Override if widget needs to change size acording to layout and/or values
   */
  public setupWidgetSize(widgetLayout: WidgetLayout, cloudWidget: ICloudWidget): void {
    // This method doesn't do a thing
  }

  /**
   * If widget needs to get default values from outer source override this method.
   */
  public setDefaults(values: any[]): void {
    // this function doesn't do a thing
  }

  /***
   * @summary Widget parameter configuration is handled by the widget container and if not initialized
   * does nothing
   */
  public configure(): void {
    const parametersComponentRef = this._componentOverlayService.create<ParametersComponent>(this._elementRef, ParametersComponent);
    const parametersComponent = parametersComponentRef.instance;

    parametersComponent.parametersConfig = this.parametersConfig;
    parametersComponent.parameters = this.parameters;

    parametersComponent.confirm.pipe(untilDestroy(parametersComponent)).subscribe(paramValues => {
      this.parameters = paramValues;
      this.refresh();
    });
  }

  /**
   * Should this be overriden, keep in mind that to use container parameters overrides
   * the method overrideWidgetParameters needs to be called.
   */
  public refresh(): void {
    this.refreshWidget();
  }

  /**
   * @summary Force the override of parameters commonly configured by the widget container
   */
  protected refreshWidget(): void {
    let widgetRequest$: Observable<ICloudWidget>;

    this._isLoadingSubject.next(true);
    this.overrideWidgetParameters();
    const proxyWidget = new ProxySubject<ICloudWidget>(this._widgetSubject, this._hasErrorSubject);
    const widget = this.widgetService.getWidget(this.cloudWidgetId || this.widgetId, this.parameters);

    if (this.hasOfflineWidget) {
      const offlineWidget = this.widgetService
        .getOfflineWidget(this.cloudWidgetId || this.widgetId, this.parameters)
        .pipe(catchError(() => EMPTY));

      widgetRequest$ = concat(offlineWidget, widget);
    } else {
      widgetRequest$ = widget;
    }

    const setupWidget = this.setupWidget(widgetRequest$);
    const showWidget = this._spinnerService
      .showFor(setupWidget, this._elementRef)
      .pipe(untilDestroy(this), logError(true), multicast(proxyWidget)) as ConnectableObservable<ICloudWidget>;
    const subscription: Subscription = this.widget$.pipe(finalize(() => subscription.unsubscribe())).subscribe(cloudWidget => {
      if (cloudWidget) {
        this.parametersConfig = cloudWidget.parametersConfiguration;
        Object.assign(this.parameters, cloudWidget.parametersValues);
      }
      this._isLoadingSubject.next(false);
    });
    showWidget.connect();
  }

  public goBack(): void {
    this.close();
  }

  /**
   * Override parameters
   */
  protected overrideWidgetParameters(): void {
    this.parametersOverrides.forEach(po => {
      if (!this.parameters) {
        this.parameters = {};
      }

      this.parameters[po.property] = po.getter(this.widgetId);
    });
  }

  protected getEmptyCloudWidget(): ICloudWidget {
    return {
      name: null,
      configuration: {},
      customProperties: {},
      parametersConfiguration: [],
      parametersValues: {},
      type: this.kind,
      values: [{}],
      valuesConfiguration: []
    };
  }

  /**
   * If widget needs to get default configurations from the widget container this method
   * should be overriden
   */
  public setDefaultConfig(config: any): void {
    // this function doesn't do a thing
  }
}
