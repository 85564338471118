import { Subject } from 'rxjs';

/**
 * @class
 * @summary ProxySubject<T> acts as a proxy to the given subject without completing it.
 */
export class ProxySubject<T> extends Subject<T> {
  /**
   * @constructor
   * @param _nextObserver Observer through which next values are passed.
   * @param _errorObserver Observer through which error values are passed
   * without setting the errorObserver in the error state.
   */
  constructor(private _nextObserver: Subject<T>, private _errorObserver?: Subject<any>) {
    super();
  }

  public next(value: T): void {
    super.next(value);
    this._nextObserver.next(value);

    if (this._errorObserver) {
      this._errorObserver.next(false);
    }
  }

  public error(err: any): void {
    super.error(err);
    if (this._errorObserver) {
      this._errorObserver.next(err);
    }
  }
}
