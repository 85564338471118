import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataCommunicationService } from '../../shared/services/data-communication-service/data-communication-service';
import { NEW_ACCOUNTID_CHANNEL } from '../_models/login-data-channels';
import { AccountListService } from './../_services/account-list.service';

@Component({
  selector: 'app-new-login-form',
  templateUrl: './new-login-form.component.html',
  styleUrls: ['../login-form-base.scss', './new-login-form.component.scss']
})
export class NewLoginFormComponent implements OnInit {
  public loginForm: UntypedFormGroup = null;
  public loginError = false;
  public buttonDisable = false;

  constructor(public dataService: DataCommunicationService, private router: Router, private accountService: AccountListService) {}

  public ngOnInit(): void {
    this.loginForm = new UntypedFormGroup({
      account: new UntypedFormControl('', [Validators.required])
    });

    if (this.accountService.accounts.length === 0) {
      this.buttonDisable = true;
    }
  }

  public onSubmit(): void {
    this.loginError = false;

    if (this.loginForm.invalid) {
      return;
    }

    if (this.account.value != null) {
      const accountID = this.loginForm.controls['account'].value;
      this.router.navigate(['/login/new-submit']).then(() => this.dataService.sendData(NEW_ACCOUNTID_CHANNEL, accountID));
    }
  }

  /**
   * @ignore
   *
   * @readonly
   * @memberof LoginFormComponent
   */
  public get account(): AbstractControl {
    return this.loginForm.controls['account'];
  }
}
