import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { from, Observable, of, Subscription } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AuthenticationService } from '../../auth/services/authentication.service';
import { untilDestroy } from '../../shared/rxjs/operators/until-destroy';
import { DataCommunicationService } from '../../shared/services/data-communication-service/data-communication-service';
import { slideInAnimation } from '../login-transitions';
import { NEW_ACCOUNTID_CHANNEL } from '../_models/login-data-channels';
import { AccountListService } from './../_services/account-list.service';

@Component({
  selector: 'app-login-wrapper',
  templateUrl: './login-wrapper.component.html',
  styleUrls: ['./login-wrapper.component.scss'],
  animations: [slideInAnimation]
})
export class LoginWrapperComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    public accountService: AccountListService,
    private authService: AuthenticationService,
    private _dataService: DataCommunicationService
  ) {
    if (!this._dataService.hasChannel(NEW_ACCOUNTID_CHANNEL)) {
      this._dataService.createChannel(NEW_ACCOUNTID_CHANNEL);
    }
  }

  public ngOnInit(): void {this.authService
      .isLogged()
      .pipe(
        mergeMap(isLogged => {
          let result$: Observable<boolean | void>;

          if (isLogged) {
            result$ = from(this.router.navigate(['/']));
          } else if (this.accountService.selectedAccount === null) {
            result$ = from(this.router.navigate(['login/new']));
          } else {
            result$ = of();
          }
          return result$;
        }),
        untilDestroy(this)
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    // Do not remove, needed by rxjs operator untilDestroy
  }

  public getState(outlet: RouterOutlet): any {
    return outlet.activatedRouteData.state;
  }
}
