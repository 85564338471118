import { DOCUMENT, ɵparseCookieValue as parseCookieValue } from '@angular/common';
import { HttpXsrfTokenExtractor } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

export const COOKIE_NAME = 'XSRF-TOKEN';

/**
 * `XsrfTokenExtractor` Retrieves the token from a cookie.
 */
@Injectable()
export class XsrfCookieExtractorService implements HttpXsrfTokenExtractor {
  private lastCookieString = '';
  private lastToken: string | null = null;
  constructor(
    @Inject(DOCUMENT)
    private document: any,
    @Inject(PLATFORM_ID)
    private platform: string
  ) {}
  public getToken(): string | null {
    if (this.platform === 'server') {
      return null;
    }
    const cookieString = this.document.cookie || '';
    if (cookieString !== this.lastCookieString) {
      this.lastToken = parseCookieValue(cookieString, COOKIE_NAME);
      this.lastCookieString = cookieString;
    }
    return this.lastToken;
  }
}
