import { Directive, EventEmitter, Injector, Input, OnInit, Output, Type, ViewContainerRef } from '@angular/core';
import { ElementCreationData } from '../models/element-creation-data';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[createElement]'
})
export class CreateElementDirective<T> implements OnInit {
  @Input()
  public elementType: Type<T>;
  @Output()
  public createElement: EventEmitter<ElementCreationData<T>> = new EventEmitter<ElementCreationData<T>>();

  constructor(private _viewContainerRef: ViewContainerRef, private _injector: Injector) {}

  public ngOnInit(): void {
    let element: T = null;

    if (this.elementType) {
      element = this._injector.get(this.elementType);
    }
    const data: ElementCreationData<T> = new ElementCreationData<T>({
      containerRef: this._viewContainerRef,
      element: element
    });

    this.createElement.emit(data);
  }
}
