import { WidgetConfiguration } from './widget-configuration';

export class ChartWidgetConfiguration extends WidgetConfiguration {
  public diagramType: DiagramType;
  public seriesType: SeriesType;
  public argumentDataMember: string;
  public valueDataMember: string;
  public myPointView: MyPointView;
  public chartType: DxChartType;
  public isDynamicSeries: boolean;
}

export enum DiagramType {
  SimpleDiagram2D = 0,
  XYDiagram2D = 1,
  RadarDiagram2D = 2,
  PolarDiagram2D = 3,
  SimpleDiagram3D = 4,
  XYDiagram3D = 5
}

export enum SeriesType {
  // SimpleDiagram2D/ SimpleDiagram3D
  Pie = 0,
  Donut = 1,

  // XYDiagram2D / XYDiagram3D
  Area = 2,
  StackedArea = 3,
  FullStackedArea = 4,
  Bubble = 5,
  Point = 6,

  // XYDiagram2D
  StepArea = 7,
  RangeArea = 8,
  SideBySideBar = 9,
  SideBySideStackedBar = 10,
  SideBySideFullStackedBar = 11,
  StackedBar = 12,
  FullStackedBar = 13,
  SideBySideRangeBar = 14,
  OverlappedRangeBar = 15,
  Line = 16,
  StackedLine = 17,
  FullStackedLine = 18,
  StepLine = 19,
  ScatterLine = 20,
  Stock = 21,
  CandleStick = 22,

  // XYDiagram3D
  ManhattanBar = 23,

  // RadarDiagram2D
  RadarArea = 24,
  RadarLine = 25,
  RadarPoint = 26,

  // PolarDiagram2D
  PolarArea = 27,
  PolarLine = 28,
  PolarPoint = 29
}

export enum MyPointView {
  Argument = 0,
  Values = 1,
  ArgumentAndValues = 2,
  SeriesName = 3,
  Undefined = 4
}

export enum DxChartType {
  PieChart = 1,
  BarChart = 2,
  AreaChart = 3,
  LineChart = 4,
  RadarChart = 5
}
