import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import 'hammerjs';
import { SELECTED_LANGUAGE_KEY } from './app/shared/services/localization/localization.service';

export function getBaseUrl(): string {
  return document.getElementsByTagName('base')[0].href;
}

const baseUrl = getBaseUrl();
const selectedLanguage = localStorage.getItem(SELECTED_LANGUAGE_KEY) || 'pt';

if (environment.production && !baseUrl.endsWith(`/${selectedLanguage}/`)) {
  window.location.href = `${environment.schema}://${environment.host}/${selectedLanguage}/`;
} else {
  if (environment.production) {
    enableProdMode();
  }

  const providers = [{ provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }];

  platformBrowserDynamic(providers)
    .bootstrapModule(AppModule)
    .catch(err => console.log(err));
}
