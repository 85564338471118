import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {
  public _translations: Map<string, string>;

  public setTranslations(translations: Map<string, string>): void {
    this._translations = translations;
  }

  public translate(key: string): string {
    const checkObject = /^{.*}$/m;
    if (checkObject.test(key)) {
      const translation: { transId: string; parameters: Array<string> } = JSON.parse(key);

      if (this._translations.has(translation.transId)) {
        let result = this._translations.get(translation.transId);

        for (let i = 0; i < translation.parameters.length; i++) {
          result = result.replace(`[[${i}]]`, translation.parameters[i]);
        }
        return result;
      }
    } else if (this._translations.has(key)) {
      return this._translations.get(key);
    }

    console.log('Missing Translation: ' + key + ';');
    return key;
  }

  public translateWithParams(transId: string, ...parameters: Array<any>): string {
    if (this._translations.has(transId)) {
      let result = this._translations.get(transId);

      for (let i = 0; i < parameters.length; i++) {
        result = result.replace(`[[${i}]]`, parameters[i]);
      }
      return result;
    }
  }
}
